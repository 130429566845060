import styled from 'styled-components';

export default styled.section`
  .item-name {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .label-code {
      font-size: 11px;
      color: #999999;
    }
  }
  .table {
    border: 1px solid #dddddd;

    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
    }

    .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      background: #f9f9f9;
    }
    .ant-table-tbody > tr.ant-table-row-level-1 > td {
      border-bottom: 1px dashed #eeeeee;
    }
    .ant-table-tbody .ant-table-row .ant-table-cell {
      border-left: 1px solid rgba(85, 85, 85, 0.08);
    }

    .ant-table-tbody
      .ant-table-row-level-0
      .ant-table-cell.name.ant-table-cell-with-append
      .item-name
      .label-code {
      display: none;
    }
  }
`;
