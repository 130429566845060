import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { publicManagerSelector } from 'pages/PublicManagement/selectors';
import { sharedFileInMinIO } from 'services/minioService';
import * as Types from 'types';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detail: Types.OfficialCurriculumMaster.ResponseType;
  creator: string;
}

const useCurriculumContent = (props: ReceivedProps) => {
  const { setIsOpen, detail, creator } = props;

  const [isOpenReleaseNote, setIsOpenReleaseNote] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const { officialCurriculumMaster, providerInformation } = useSelector(publicManagerSelector);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const getImageUrl = useCallback(async () => {
    if (!detail?.fileID) {
      setUrl('');
      return;
    }

    const url = await sharedFileInMinIO(detail?.fileID);
    setUrl(url);
  }, [detail]);

  useEffect(() => {
    getImageUrl();
    return () => setUrl('');
  }, [getImageUrl]);

  return {
    ...props,
    handleClose,
    dataDetail: { ...detail, fileID: url },
    creator,
    isOpenReleaseNote,
    setIsOpenReleaseNote,
    officialCurriculumMaster,
    providerInformation,
  };
};

export type Props = ReturnType<typeof useCurriculumContent>;

export default useCurriculumContent;
