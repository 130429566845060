import { createGlobalStyle } from 'styled-components';
import '@syncfusion/ej2-react-spreadsheet/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-grids/styles/material.css';
import '@syncfusion/ej2-base/styles/material.css';

import 'assets/fonts/index.css';

export default createGlobalStyle`
  body {
    font-family: 'Noto Sans Javanese';
    margin: 0;
    height: 100%;
    background: #F9F8F8;
    overflow-y: hidden;
    textarea {
      resize: none;
    }
    .ant-menu-submenu-popup > .ant-menu {
      background: #101010;
      color: #ffffff;
    }
    .ant-menu-inline-collapsed-tooltip {
      .ant-tooltip-inner {
        background: #101010
      }
      .ant-tooltip-arrow-content {
        background: #101010
      }
    }
    input[type='number'] {
      -moz-appearance:textfield;
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .ant-form .ant-input-affix-wrapper {
      border-color: #ccc;
    }
    .ant-form .ant-input-affix-wrapper-readonly {
      background: #f9f8f8;
      border-color: #eaeaea;
      color: #2a2a2a;
      &:hover {
        border-color: #eaeaea;
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
      .ant-input {
        background-color: transparent;
      }
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
      padding-bottom: 4px;
    }
    .ant-form-item-has-error {
      .ant-select-selector {
        background-color: #fffaf9 !important;
        border: 1px solid rgba(240, 51, 10, 0.8) !important;
      }
      .ant-input-affix-wrapper, input {
        background-color: #fffaf9 !important;
      }
    }
    .ant-popover {
      z-index: 999;
    }
    .popover-tab-custom {
      .ant-popover-inner .ant-popover-inner-content {
        padding: 2px;
      }
    }
    .popover-tab-1 {
      .ant-popover-arrow .ant-popover-arrow-content::before {
        background: linear-gradient(to left, #009ACB 50%, #009ACB 50%) no-repeat -10px -10px;
      }
      .ant-popover-inner .ant-popover-inner-content {
        padding: 0;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        border: 1px solid #009ACB;
      }
    }
    .ant-popover-inner-content {
      padding: 2px;
    }
    .tooltip-QA {
      .ant-popover-content .ant-popover-arrow {
        display: none;
      }
    }
    .question-tooltip {
      .ant-tooltip-arrow .ant-tooltip-arrow-content::before{
        background: #ffffff;
      }
      .ant-tooltip-inner {
        background: #ffffff;
        border-radius: 2px;
        width: 702px;
        font-weight: 400;
        color: #2A2A2A;
        h6 {
          font-size: 16px;
          margin-bottom: 20px;
        }
        p{
          font-size: 13px;
        }
      }
    }
    .modal-concurrent-additional {
      top: 30px;
      .ant-modal-body {
        padding: 0;
      }
    }
    .ant-table-wrapper {
      .ant-pagination {
        position: relative;
        .ant-pagination-item-active,
        .ant-pagination-item:hover,
        .ant-pagination-prev .ant-pagination-item-link:hover,
        .ant-pagination-next .ant-pagination-item-link:hover {
          border-color: #00A3A5;
          color: #00A3A5;
          a {
            color: #00A3A5;
          }
        }
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-thead {
        border-radius: 2px 2px 0 0;
        .ant-table-cell {
          background-color: #ebebeb;
        }
      }
    }
    .ant-popover-title{
      border: none;
    }
    .tooltip-setup-time-limit {
      width: 280px;
      height: auto;
      .ant-tooltip-arrow{
        .ant-tooltip-arrow-content {
          --antd-arrow-background-color: unset;
          background-color: #ffffff;
        }
      }
      .ant-tooltip-inner{
        background-color: #ffffff;
        
        .text-content{
          color: #424242;
          font-size: 12px;
          display: flex;
          align-items: center;
          .icon{
            color: #08A3A5;
            font-size: 24px;
            margin-right: 12px;
          }
        }
        .box-center {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
          .btn-set-time{
            background-color: #F6AC00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            font-weight: bold;
            font-size: 15px;
            color: #FFFFFF;
            border: none;
            padding: 0 22px;
          }
        }
      }
    }
    .ant-modal-wrap.img-crop-modal {
      .ant-modal-content{
        .ant-modal-close{
          display: none;
        }
        .ant-modal-header{
          display: flex;
          justify-content: center;
        }
        .ant-modal-footer {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          margin-top: 16px;
          .ant-btn.ant-btn-default {
            width:140px;
            height:40px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
          }
          .ant-btn.ant-btn-primary{
            width:140px;
            height:40px;
            margin-right: 16px;
            border: none;
            background: #F6AC00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
          }
        }
      }
    }
    .datetime-picker-skill-check {
      .ant-picker-panels {
        .ant-picker-date-panel .ant-picker-body {
          .ant-picker-content tbody .ant-picker-cell-range-end,
          .ant-picker-content tbody .ant-picker-cell-range-start,
          .ant-picker-content tbody .ant-picker-cell-selected { 
            .ant-picker-cell-inner {
              background: #08A3A5;
            }
          }
        }
        .ant-picker-decade-panel,
        .ant-picker-year-panel { 
          .ant-picker-cell-selected .ant-picker-cell-inner {
            background: #08A3A5;
          }
        }
        .ant-picker-time-panel .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
          background: rgb(227, 238, 233);
        }
      }
      .ant-picker-footer {
        .ant-picker-preset {
          .ant-tag-blue {
            background: #FFFFFF;
            border: 1px solid #08A3A5;
            border-radius: 2px;
            color: #08A3A5;
          }
          &:nth-child(2) {
            .ant-tag {
              padding: 0 25px;
            }
          }
        }
        .ant-picker-ok .ant-btn-primary {
          background: #08A3A5;
          border-color: #08A3A5;
          &[disabled] {
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
          }
        }
      }
    }
    .ant-menu-submenu-popup .ant-menu-sub {
      .ant-menu-item-only-child:hover {
        color: #00A3A5;
        font-weight: 700;
      }

      .ant-menu-item-selected {
        background-color: rgba(233, 224, 203, 0.15);
        font-weight: 700;
        color: #00A3A5;
      }
    }
    .react-pdf__Page__textContent{
      user-select: none;
    }
  }
`;
