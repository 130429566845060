import React from 'react';
import { CheckCircleFilled, LockFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { curriculumSelector } from '../../selectors';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { Arrow } from 'assets';

interface Props {
  visible: boolean;
  onSubmit: () => void;
  nodeLocked?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CurriculumConfirmPublish: React.FC<Props> = ({
  onSubmit,
  visible,
  setVisible,
  nodeLocked,
}) => {
  const { loading } = useSelector(curriculumSelector);

  const { messages } = useIntl();

  return (
    <Modal
      title={messages['M-21-90']}
      width={720}
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: onSubmit,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      {nodeLocked ? (
        <SectionStyled>
          <div className="flex">
            <div className="open">
              <span>{messages['M-21-92']}</span>
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="lock scale">
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
              <span>{messages['M-21-91']}</span>
            </div>
          </div>
          <p className="sub-title">
            カリキュラムを編集します。よろしいですか？
            <br />
            編集権限のないユーザーからは非表示となります。
          </p>
        </SectionStyled>
      ) : (
        <SectionStyled>
          <div className="flex">
            <div className="lock">
              <span>{messages['M-21-91']}</span>
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="open scale">
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
              <span>{messages['M-21-92']}</span>
            </div>
          </div>
          <p className="sub-title">{messages['M-21-93']}</p>
        </SectionStyled>
      )}
    </Modal>
  );
};

export default CurriculumConfirmPublish;
