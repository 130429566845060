import styled from 'styled-components';

export default styled.section`
  margin-bottom: 45px;

  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    display: flex;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }

    .right-side {
      padding: 20px;
      width: 100%;
      .wrap-header {
        .wrap-action {
          display: flex;
          width: 100%;
          justify-content: end;
        }
        .legend {
          display: flex;
          align-items: center;

          .sort-column {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-left: 20px;
            .legend-filter {
              width: 14px;
              height: 14px;
              display: flex;
              align-items: center;
            }

            .yellow {
              background: #e5c82d;
            }
            .blue {
              background: #4365bd;
            }
            .orange {
              background: #f0995a;
            }

            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
          }
        }
        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }
      }
      .chart {
        width: 98%;
        margin-top: 20px;
        .column {
          position: relative;
          margin-bottom: 25px;

          .unit {
            position: absolute;
            right: 0px;
            top: -30px;
            font-size: 11px;
            color: #999999;
          }
        }
      }
      .title {
        margin-bottom: 25px;
      }
    }
  }
`;
