import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_ROLE,
  SELECT_DEPARTMENT,
  SELECT_ROLE,
  EMPLOYEE_USER,
  SELECT_EMPLOYEE_USER,
  USERS,
  SELECT_POSITION,
  EMPLOYEE_USER_EXPORT,
} from 'configs';

export const getSelectAdminDepartment = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_DEPARTMENT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminUsers = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_EMPLOYEE_USER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminPosition = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_POSITION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminRole = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminUserList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAdminUserList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER.id, {
      ...req,
      include_item_ref: true,
      sort_fields: [{ id: 'employee_code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSubPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminUsers = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/createAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminUsers = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdminUsers.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/updateAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminUsers = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/thunk/deleteAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminAffiliationAssignRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/createAdminAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminAffiliationAssignRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/updateAdminAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/thunk/deleteAdminAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminUserExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAdminUserExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER_EXPORT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUserAdmin = createAsyncThunk<
  Types.AddUserAdminResponse,
  Types.AddUserAdminRequest,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/addUserAdmin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addUserAdmin(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllGmail = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAllGmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
