import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Styled from '../SkillCheckImplement/styles';
import { dashboardSelector } from '../selectors';
import { useAppDispatch } from 'hooks';
import {
  getDataNumOfSkillChecksPerformed,
  getDataNumOfSkillChecksPerformedMonth,
  getDataSkillCheckTrans,
  getNumberOfInterviewsConducted,
} from '../thunk';

const SkillCheckImplementPDF: React.FC = () => {
  const [selected] = useState<string>('all');

  const {
    numOfImplementations,
    numOfInternalImplementations,
    numOfInterviewsConducted,
    dataNumOfImplementations,
  } = useSelector(dashboardSelector);

  const dispatch = useAppDispatch();

  const config = {
    data: dataNumOfImplementations,
    isGroup: true,
    xField: 'implementation',
    yField: 'value',
    seriesField: 'type',
  };

  useEffect(() => {
    dispatch(
      getDataSkillCheckTrans({
        page: 1,
        per_page: 0,
        return_count_only: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getNumberOfInterviewsConducted({
        conditions: [
          {
            id: 'user_type',
            search_value: ['interview'],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataNumOfSkillChecksPerformed({
        conditions: [
          {
            id: 'user_type',
            search_value: ['interview'],
            not_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataNumOfSkillChecksPerformedMonth({
        conditions: [
          ...(selected !== 'all'
            ? [
                {
                  id: 'user_type',
                  search_value: ['interview'],
                  not_match: selected !== 'interview',
                },
              ]
            : []),
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, selected]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <EditOutlined className="icon" /> スキルチェック実施情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイム実施件数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">総実施回数</span>
                <div className="wrap-number">
                  <span className="number">{numOfImplementations}</span>
                  <span className="unit">回</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">総実施回数</span>
                <div className="wrap-number">
                  <span className="number">{numOfInternalImplementations}</span>
                  <span className="unit">回</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">総実施回数</span>
                <div className="wrap-number">
                  <span className="number">{numOfInterviewsConducted}</span>
                  <span className="unit"> 回</span>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">ユーザー推移</span>
            </div>
            <div className="chart">
              <Column
                {...config}
                legend={{
                  position: 'top-right',
                }}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                  label: {
                    formatter: (text) => `${text}`,
                  },
                }}
                xAxis={{
                  tickLine: null,
                  label: {
                    formatter: (text) => dayjs(text).format('YYYY/MM'),
                  },
                }}
                maxColumnWidth={15}
                dodgePadding={4}
                color={['#4E83D3', '#F08B84', '#C38BC2']}
                label={{
                  position: 'top',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default SkillCheckImplementPDF;
