import { routes } from 'navigations/routes';
import { Roles } from 'types';

type RouterWithScreenPermissionsType = {
  [key in keyof Omit<
    Roles.ResponseType,
    | 'i_id'
    | 'company_id'
    | 'code'
    | 'name'
    | 'deletedat'
    | 'updatedat'
    | 'updatedby'
    | 'createdat'
    | 'createdby'
    | 'payment_master_permission'
    | 'timer_master_permission'
    | 'bundle_master_permission'
  >]: string[];
};

export const ROUTER_WITH_SCREEN_PERMISSIONS: RouterWithScreenPermissionsType = {
  admin_dashboard_permission: [routes.Dashboard.path],
  report_view_permission: [],
  roles_master_permission: [],
  departments_master_permission: [],
  positions_master_permission: [],
  users_master_permission: [],
  // payment_master_permission: [],
  admin_master_permission: [],
  // timer_master_permission: [],
  curricullum_master_permission: [],
  question_master_permission: [],
  groups_master_permission: [],
  skill_check_tree_permission: [],
  skill_check_master_permission: [],
  inter_users_master_permission: [],
  // bundle_master_permission: [],
  official_curriculum_publish_permission: [routes.PublicManagement.path],
};
