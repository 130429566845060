import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .left-side {
      width: 280px !important;
    }
    .right-side {
      width: 900px !important;
    }
    .column-chart {
      height: 365px !important;
    }
    .body-detail {
      height: 120px !important;
    }
    .body-info {
      height: 519px !important;
    }
  }
  margin-bottom: 45px;
  .wrap-icon {
    display: flex;
    color: #08a3a5;
    font-size: 16px;
    margin-bottom: 14px;
    .icon {
      font-size: 22px;
      margin-right: 10px;
    }
  }
  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    display: flex;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }
    .left-side {
      width: 20%;
      padding: 31px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .info {
        width: 100%;
        margin-top: 40px;
      }
      .body-detail {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 20px 20px 30px 20px;
        margin-bottom: 9px;
        .title-info {
          color: #424242;
          font-size: 14px;
        }
        .number {
          color: #08a3a5;
          font-size: 36px;
        }
        .unit {
          color: #08a3a5;
        }
        .wrap-number {
          text-align: right;
        }
      }
    }
    .column {
      width: 5px;
      background: #efefef;
      border: 1px solid #e8e8e8;
    }
    .right-side {
      padding: 20px;
      width: 80%;
      .wrap-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrap-action {
          display: flex;
        }
        .wrap-select {
          display: flex;
          align-items: center;
          width: 100%;
          .ant-select {
            height: 36px;
            width: 220px;
          }
          label {
            color: #777777;
            font-size: 12px;
          }
        }
        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }
      }
      .chart {
        width: 98%;
        margin-top: 21px;
        position: relative;
        .unit {
          position: absolute;
          right: 20px;
          top: 30px;
          font-size: 11px;
          color: #999999;
        }
        .line-chart {
          height: 20vh !important;
        }
      }
    }
  }
`;
