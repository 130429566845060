import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DocViewer, { DocViewerRenderers } from 'components/PartnerManagementViewer';
import { getFilePartnerManagement } from '../thunk';
import { managementSelector } from '../selectors';
import { Modal, SpinLoading } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  visibleContract: boolean;
  setVisibleContract: React.Dispatch<React.SetStateAction<boolean>>;
  contractFileId: string;
}

const ModalContract: React.FC<Props> = ({
  visibleContract,
  setVisibleContract,
  contractFileId,
}) => {
  const [partnerViewerDetail, setPartnerViewerDetail] = useState<{
    manualFile?: Types.ResponseFileType;
    attach?: Types.Attach.ResponseType;
    file?: File;
    uri?: string;
    fileData?: string;
  }>({});

  const { loading, dataInformation } = useSelector(managementSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (contractFileId) {
        const fileAction = await dispatch(
          getFilePartnerManagement({
            file_id: contractFileId,
          })
        );
        if (getFilePartnerManagement.fulfilled.match(fileAction)) {
          const base64url = await new Promise((r) => {
            const reader = new FileReader();
            reader.onload = () => r(reader.result);
            reader.readAsDataURL(new Blob([fileAction.payload]));
          });
          const fileName =
            (dataInformation &&
              dataInformation.filter((i) => i.contract_file_id === contractFileId)[0]
                ?.contract_file_name) ||
            '';
          setPartnerViewerDetail({
            file: new File([fileAction.payload], fileName, {
              type: 'application/pdf',
            }),
            fileData: String(base64url),
            uri: URL.createObjectURL(
              new Blob([
                new File([fileAction.payload], fileName, {
                  type: 'application/pdf',
                }) as Blob,
              ])
            ),
          });
        }
      }
    })();
  }, [dispatch, contractFileId, dataInformation]);

  return (
    <Modal
      title={partnerViewerDetail && partnerViewerDetail.file?.name}
      width={720}
      visible={visibleContract}
      onCancel={() => setVisibleContract(false)}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisibleContract(false),
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SpinLoading loading={loading} size="large">
        {partnerViewerDetail.uri && (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: partnerViewerDetail.uri,
                fileName: partnerViewerDetail.file?.name,
                fileType: 'application/pdf',
                fileData: partnerViewerDetail.fileData,
              },
            ]}
          />
        )}
      </SpinLoading>
    </Modal>
  );
};

export default ModalContract;
