import React, { useState } from 'react';
import { FormikProps, FormikProvider } from 'formik';
import dayjs from 'dayjs';

import { QuestionCircleOutlined } from '@ant-design/icons';

import ExecuteUnpaidAlert from 'components/Modal/ExecuteUnpaidAlert';
import CheckboxField from 'components/Forms/CheckboxField';
import { TextArea, DatePicker } from 'components';
import { Styled, ModalStyled } from './styles';
import * as Types from 'types';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikProps<Types.UpdateCompanyUserDetailFormik>;
  corporateUserDetail?: Types.CorporateUserInformationType;
  onSubmit: () => Promise<void>;
  visible: boolean;
}

const ModalCorporateUserManagementDetail: React.FC<Props> = ({
  corporateUserDetail,
  setVisible,
  onSubmit,
  visible,
  formik,
}) => {
  const [keyModal, setKeyModal] = useState('');
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  const handleCloseModal = () => {
    setKeyModal('');
    setOpen(false);
  };

  const renderTitleModal = (key: string) => {
    switch (key) {
      case 'unpaid-alert-date':
        return '未払いアラート実行＆警告メールを送信';

      case 'exploit-stop-date':
        return '利用停止＆警告メールを送信';

      case 'forced-withdrawal-date':
        return '強制退会＆警告メールを送信';

      case 'unpaid-alert-checkbox':
        return '未払いアラート解除';

      case 'exploit-stop-checkbox':
        return '利用停止解除';

      case 'forced-withdrawal-checkbox':
        return '利用停止解除';

      default:
        return '';
    }
  };

  const renderBodyModal = (key: string) => {
    switch (key) {
      case 'unpaid-alert-date':
        return (
          <>
            未払いアラートは未払いになった法人ユーザーの管理者のHOME画面上に
            <br />
            警告のモーダルを展開する機能です。未払いのお知らせとお支払いの催促を表示します。
            <br /> <br />
            警告メールは未払いのお知らせとお支払いの催促を記載。
            <br />
            また、未払いが一定期間経過した場合の利用停止も警告します。
            <br />
            （社内的には目安60日経過で利用停止）
            <br /> <br />
            クレジット決済のユーザーに対しては自動で実行されます。
            <br />
            請求書（銀行振込）のユーザーに対しては手動で実行してください。
            <br /> <br />
            これらは法人ユーザー情報 詳細モーダルを更新時に設定日時で実行されます。
          </>
        );

      case 'exploit-stop-date':
        return (
          <>
            利用停止は未払い状態が一定期間継続した場合に
            <br />
            （社内的には目安60日（61日目））
            <br />
            SKILL FAMILIARの利用を停止するための機能です。
            <br />
            【実施後の状態】
            <br />
            ・一般ユーザーのログイン停止（利用停止）
            <br />
            ・管理者のHOME画面上に警告のモーダル表示
            <br />
            ・管理者の利用機能の制限（HOME、ご利用状況画面のみ）
            <br /> <br />
            警告メールはSKILL FAMILIARの利用を停止していること、お支払いの催促、
            <br />
            今後未払いが継続すると（社内的には目安90日）
            <br />
            強制解約になることをお知らせする警告メールです。
            <br /> <br />
            クレジット決済・請求書（銀行振込）どちらも手動で実行してください。
            <br /> <br />
            これらは法人ユーザー情報 詳細モーダルを更新時に確定され、設定日時に実行されます。
          </>
        );

      case 'forced-withdrawal-date':
        return (
          <>
            強制退会は未払い状態が一定期間継続した場合（社内的には90日（91日目））に
            <br />
            SKILL FAMILIARを強制退会させるための機能です。（利用停止後30日）
            <br />
            【実施後の状態】
            <br />
            ・データの削除
            <br /> <br />
            警告メールはSKILL FAMILIARを強制退会処理になったこと、
            <br />
            強制退会時点で未払いの利用料全てのお支払いの催促（残債請求書の添付）のメールです。
            <br /> <br />
            クレジット決済・請求書（銀行振込）どちらも手動で実行してください。
            <br /> <br />
            これらは法人ユーザー情報 詳細モーダルを更新時に確定され、設定日時に実行されます。
          </>
        );

      case 'unpaid-alert-checkbox':
        return (
          <>
            未払いアラートを解除します。
            <br />
            対象の債権回収が確認された場合に実行してください。 <br />
            チェック後に更新ボタンをクリックで実行されます。
          </>
        );

      case 'exploit-stop-checkbox':
        return (
          <>
            利用停止を解除します。
            <br />
            対象の債権回収が確認された場合に実行してください。 <br />
            チェック後に更新ボタンをクリックで実行されます。
          </>
        );

      case 'forced-withdrawal-checkbox':
        return (
          <>
            利用停止を解除します。
            <br />
            対象の債権回収が確認された場合に実行してください。 <br />
            チェック後に更新ボタンをクリックで実行されます。
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <ModalStyled
      open={visible}
      okButton={{
        text: '更新',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setVisible(false);
          formik.resetForm();
        },
      }}
      onCancel={() => setVisible(false)}
      title="法人ユーザー情報 詳細"
      width={920}
    >
      <Styled>
        <FormikProvider value={formik}>
          <div className="header-modal">
            <span>
              {corporateUserDetail?.company_name}
              <span className="sub-text"> ({corporateUserDetail?.company_name_furigana}) </span>
            </span>
            <button
              className={corporateUserDetail?.deletedat ? 'under-contract-1' : 'under-contract'}
            >
              {corporateUserDetail?.deletedat ? '退会' : '契約中'}
            </button>
          </div>
          <div className="table-above">
            <div className="contract-start-date">
              <div className="contract-first">
                <span>契約開始日</span>
              </div>
              <div className="contract-second">
                <span>
                  {corporateUserDetail?.plan_start_at &&
                    dayjs(corporateUserDetail.plan_start_at).format('YYYY年MM月DD日')}
                </span>
              </div>
              <div className="contract-third">
                <span>退会日</span>
              </div>
              <div className="contract-fourth">
                <span>{dayjs(corporateUserDetail?.deletedat).format('YYYY年MM月DD日')}</span>
              </div>
            </div>
            <div className="usage-plan">
              <div className="usage-first">
                <span>利用プラン</span>
              </div>
              <div className="usage-second">
                <span>{corporateUserDetail?.plan_id}</span>
              </div>
            </div>
            <div className="usage-plan">
              <div className="usage-first">
                <span>利用ユーザー数</span>
              </div>
              <div className="usage-second">
                <span>{corporateUserDetail?.max_users}人</span>
              </div>
            </div>
            <div className="request-method">
              <div className="method-first">
                <span>請求方法</span>
              </div>
              <div className="method-second">
                <span>
                  {corporateUserDetail?.payment_method === 'Test' ? '請求書' : 'クレジット'}
                </span>
              </div>
              <div className="method-third">
                <CheckboxField
                  name="invoice_exception"
                  className="checkbox"
                  disabled={!!corporateUserDetail?.deletedat}
                >
                  <span className="text-checkbox">請求書特例</span>
                </CheckboxField>
              </div>
            </div>
            <div className="unpaid-alert">
              <div className="unpaid-first">
                <span>未払いアラート</span>
              </div>
              <div className="unpaid-second">
                <div className="execution-checkbox">
                  <button
                    className={
                      !corporateUserDetail?.unpaid_alert_flg
                        ? 'button-disabled'
                        : !corporateUserDetail?.suspension_flg
                        ? 'button-error'
                        : 'button-success'
                    }
                  >
                    {!corporateUserDetail?.unpaid_alert_flg
                      ? '未実行'
                      : !corporateUserDetail?.suspension_flg
                      ? '実行中'
                      : '実行済'}
                  </button>
                  <div className="values-check">
                    <div className="label">実行日時：</div>
                    <div className="value">
                      {corporateUserDetail?.unpaid_alert_date &&
                        dayjs(corporateUserDetail.unpaid_alert_date).format('YYYY/MM/DD HH:mm')}
                    </div>
                  </div>
                  <div className="values-check">
                    <div className="label">解除：</div>
                    <div className="value">
                      <CheckboxField
                        name="unpaid_alert_delete"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !corporateUserDetail?.unpaid_mail_send_flg
                        }
                      />
                    </div>
                    <QuestionCircleOutlined
                      className="icon-question"
                      onClick={() => {
                        setKeyModal('unpaid-alert-checkbox');
                        setOpen(true);
                      }}
                    />
                  </div>
                </div>
                <div className="datetime-select">
                  <div className="key-value">
                    <div className="label">日時を選択して実行：</div>
                    <div className="values">
                      <DatePicker
                        key="unpaid_mail_send_date"
                        name="unpaid_mail_send_date"
                        value={
                          formik.values.unpaid_mail_send_date
                            ? dayjs(formik.values.unpaid_mail_send_date)
                            : undefined
                        }
                        className="date-input"
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder=""
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.unpaid_mail_send_date
                        }
                        onChange={(date) => {
                          if (date) {
                            formik.setFieldValue('unpaid_mail_send_date', date.toISOString());
                          }
                        }}
                      />
                      <QuestionCircleOutlined
                        className="icon-question"
                        onClick={() => {
                          setKeyModal('unpaid-alert-date');
                          setOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="key-value">
                    <div className="label">同時に警告メールを送信：</div>
                    <div className="values">
                      <CheckboxField
                        name="unpaid_mail_send_flg"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.unpaid_mail_send_flg
                        }
                      />
                      <span className="label">送信情報：</span>

                      {corporateUserDetail?.unpaid_mail_send_date ? (
                        <span className="value-date">
                          送信済
                          {dayjs(corporateUserDetail.unpaid_mail_send_date).format(
                            'YYYY/MM/DD HH:mm'
                          )}
                        </span>
                      ) : (
                        <span className="value">未送信</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="unpaid-alert">
              <div className="unpaid-first">
                <span>利用停止</span>
              </div>
              <div className="unpaid-second">
                <div className="execution-checkbox">
                  <button
                    className={
                      !corporateUserDetail?.suspension_flg
                        ? 'button-disabled'
                        : !corporateUserDetail?.forced_withdrawal_flg
                        ? 'button-error'
                        : 'button-success'
                    }
                  >
                    {!corporateUserDetail?.suspension_flg
                      ? '未実行'
                      : !corporateUserDetail?.forced_withdrawal_flg
                      ? '実行中'
                      : '実行済'}
                  </button>
                  <div className="values-check">
                    <div className="label">実行日時：</div>
                    <div className="value">
                      {corporateUserDetail?.suspension_date &&
                        dayjs(corporateUserDetail.suspension_date).format('YYYY/MM/DD HH:mm')}
                    </div>
                  </div>
                  <div className="values-check">
                    <div className="label">解除：</div>
                    <div className="value">
                      <CheckboxField
                        name="suspension_delete"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !corporateUserDetail?.suspension_mail_send_flg
                        }
                      />
                    </div>
                    <QuestionCircleOutlined
                      className="icon-question"
                      onClick={() => {
                        setKeyModal('exploit-stop-checkbox');
                        setOpen(true);
                      }}
                    />
                  </div>
                </div>
                <div className="datetime-select">
                  <div className="key-value">
                    <div className="label">日時を選択して実行：</div>
                    <div className="values">
                      <DatePicker
                        key="suspension_mail_send_date"
                        name="suspension_mail_send_date"
                        value={
                          formik.values.suspension_mail_send_date
                            ? dayjs(formik.values.suspension_mail_send_date)
                            : undefined
                        }
                        className="date-input"
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder=""
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.suspension_mail_send_date
                        }
                        onChange={(date) => {
                          if (date) {
                            formik.setFieldValue('suspension_mail_send_date', date.toISOString());
                          }
                        }}
                      />
                      <QuestionCircleOutlined
                        className="icon-question"
                        onClick={() => {
                          setKeyModal('exploit-stop-date');
                          setOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="key-value">
                    <div className="label">同時に警告メールを送信：</div>
                    <div className="values">
                      <CheckboxField
                        name="suspension_mail_send_flg"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.suspension_mail_send_flg
                        }
                      />
                      <span className="label">送信情報：</span>
                      {corporateUserDetail?.suspension_mail_send_date ? (
                        <span className="value-date">
                          送信済
                          {dayjs(corporateUserDetail.suspension_mail_send_date).format(
                            'YYYY/MM/DD HH:mm'
                          )}
                        </span>
                      ) : (
                        <span className="value">未送信</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="unpaid-alert">
              <div className="unpaid-first">
                <span>強制退会</span>
              </div>
              <div className="unpaid-second">
                <div className="execution-checkbox">
                  <button
                    className={
                      !corporateUserDetail?.forced_withdrawal_flg
                        ? 'button-disabled'
                        : 'button-error'
                    }
                  >
                    {!corporateUserDetail?.forced_withdrawal_flg ? '未実行' : '実行済'}
                  </button>
                  <div className="values-check">
                    <div className="label">実行日時：</div>
                    <div className="value">
                      {corporateUserDetail?.forced_withdrawal_date &&
                        dayjs(corporateUserDetail.forced_withdrawal_date).format(
                          'YYYY/MM/DD HH:mm'
                        )}
                    </div>
                  </div>
                  <div className="values-check">
                    <div className="label">解除：</div>
                    <div className="value">
                      <CheckboxField
                        name="forced_withdrawal_delete"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !corporateUserDetail?.forced_withdrawal_mail_send_flg
                        }
                      />
                    </div>
                    <QuestionCircleOutlined
                      className="icon-question"
                      onClick={() => {
                        setKeyModal('forced-withdrawal-checkbox');
                        setOpen(true);
                      }}
                    />
                  </div>
                </div>
                <div className="datetime-select">
                  <div className="key-value">
                    <div className="label">日時を選択して実行：</div>
                    <div className="values">
                      <DatePicker
                        key="forced_withdrawal_mail_send_date"
                        name="forced_withdrawal_mail_send_date"
                        value={
                          formik.values.forced_withdrawal_mail_send_date
                            ? dayjs(formik.values.forced_withdrawal_mail_send_date)
                            : undefined
                        }
                        className="date-input"
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder=""
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.forced_withdrawal_mail_send_date
                        }
                        onChange={(date) => {
                          if (date) {
                            formik.setFieldValue(
                              'forced_withdrawal_mail_send_date',
                              date.toISOString()
                            );
                          }
                        }}
                      />
                      <QuestionCircleOutlined
                        className="icon-question"
                        onClick={() => {
                          setKeyModal('forced-withdrawal-date');
                          setOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="key-value">
                    <div className="label">同時に警告メールを送信：</div>
                    <div className="values">
                      <CheckboxField
                        name="forced_withdrawal_mail_send_flg"
                        className="checkbox"
                        disabled={
                          !!corporateUserDetail?.deletedat ||
                          !!corporateUserDetail?.forced_withdrawal_mail_send_flg
                        }
                      />
                      <span className="label">送信情報：</span>
                      {corporateUserDetail?.forced_withdrawal_mail_send_flg ? (
                        <span className="value-date">
                          送信済
                          {dayjs(corporateUserDetail.forced_withdrawal_mail_send_flg).format(
                            'YYYY/MM/DD HH:mm'
                          )}
                        </span>
                      ) : (
                        <span className="value">未送信</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-below">
            <div className="address">
              <div className="address-first">
                <span>住所</span>
              </div>
              <div className="address-second">
                <span className="address-text">
                  〒 <span className="address-number">{corporateUserDetail?.postal_code}</span>
                </span>
                <span>{corporateUserDetail?.address}</span>
                <span>{corporateUserDetail?.building_name}</span>
              </div>
            </div>
            <div className="manager-intelligence">
              <div className="intelligence-first">
                <span>管理者情報</span>
              </div>
              <div className="intelligence-second">
                <span className="intelligence-title">{corporateUserDetail?.admin_name}</span>
                <span className="intelligence-text">
                  所属：
                  <span className="intelligence-sub-text">
                    {corporateUserDetail?.admin_department}
                  </span>
                </span>
                <span className="intelligence-text">
                  役職：
                  <span className="intelligence-sub-text">
                    {corporateUserDetail?.admin_position}
                  </span>
                </span>
                <span className="intelligence-text">
                  電話番号：
                  <span className="intelligence-sub-text">{corporateUserDetail?.admin_phone}</span>
                </span>
                <span className="intelligence-text">
                  メールアドレス：
                  <span className="intelligence-email">{corporateUserDetail?.admin_email}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="wrap-memo">
            memo
            <TextArea
              id="memo"
              name="memo"
              className="input-textarea-group"
              showCount
              maxLength={1050}
              placeholder="全角：最大1050文字"
              style={{ height: 120, resize: 'inherit' }}
            />
          </div>
        </FormikProvider>
      </Styled>
      <ExecuteUnpaidAlert
        visible={open}
        handleCloseModal={handleCloseModal}
        title={renderTitleModal(keyModal)}
        body={renderBodyModal(keyModal)}
      />
    </ModalStyled>
  );
};

export default ModalCorporateUserManagementDetail;
