import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { settingSelector } from 'containers/AppSettings/selectors';
import { Header } from 'components';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  page: number;
  dataAdminAffiliationHierarchy: Types.AdminAffiliationHierarchy.ResponseType[];
}

const AffiliationMasterPDF: React.FC<Props> = ({ dataAdminAffiliationHierarchy, page }) => {
  const { headerTitle } = useSelector(settingSelector);

  const columns: any = [
    {
      key: 'affiliation_id',
      title: 'コード',
      dataIndex: 'affiliation_id',
      width: '13%',
      align: 'center',
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
  ];
  return (
    <>
      <Header title={headerTitle} exportPDF />
      <div className="container">
        <p className="content">
          所属マスタの作成・管理を行う画面です。
          <br />
          作成した所属マスタはユーザーに設定します。
        </p>
        <div className="border" />

        <div className="wrap-table">
          <div className="total-record-table-pdf">
            <span className="text-static">
              {page * PER_PAGE > dataAdminAffiliationHierarchy.length
                ? dataAdminAffiliationHierarchy.length
                : page * PER_PAGE}
              <small> 件表示</small> / {dataAdminAffiliationHierarchy.length}
              <small> 名</small>
            </span>
          </div>
          <Table
            dataSource={dataAdminAffiliationHierarchy.map((item, index) => ({ ...item, index }))}
            columns={columns}
            className="table"
            pagination={false}
            rowKey="index"
          />
        </div>
      </div>
    </>
  );
};

export default AffiliationMasterPDF;
