import React, { useCallback, useEffect, useState } from 'react';
import { FormikProps } from 'formik';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikProps<{
    creator: string;
    basic_info: string;
  }>;
}

const useEditBasicInfor = (props: ReceivedProps) => {
  const { setIsOpen, formik, isOpen } = props;
  const [text, setText] = useState<string>('');

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(() => {
    formik.setFieldValue('basic_info', text);
    handleClose();
  }, [handleClose, formik, text]);

  useEffect(() => {
    if (isOpen && formik.values.basic_info) {
      setText(formik.values.basic_info);
    }
  }, [formik.values.basic_info, isOpen]);

  return { ...props, handleClose, handleSubmit, text, setText };
};

export type Props = ReturnType<typeof useEditBasicInfor>;

export default useEditBasicInfor;
