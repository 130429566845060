import React, { useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
// import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';

// import { authSelector } from 'containers/Auth/selectors';
import { DatePicker, SelectField } from 'components';
import SaleInformationStyled from './styles';
// import { useAppDispatch } from 'hooks';
import Chart from './Chart';
import Table from './Table';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SaleInformation: React.FC = () => {
  const [visibleExpansionOfSale, setVisibleExpansionOfSale] = useState<boolean>(false);
  const [visibleCountUsage, setVisibleCountUsage] = useState<boolean>(false);
  const [valueSelect, setValueSelect] = useState<number>(0);
  const [segmented, setSegmented] = useState<number>(0);

  // const { userInfo } = useSelector(authSelector);

  // const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      employee_code: '',
      name: '',
      department_name: '',
      position_code: '',
    },
    onSubmit: (values) => {
      console.log(values, 'values');
    },
  });

  const onChange = (e: CheckboxChangeEvent) => {
    if (!visibleExpansionOfSale) {
      setVisibleCountUsage(false);
    }
    setVisibleExpansionOfSale(e.target.checked);
  };

  return (
    <SaleInformationStyled>
      <div className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="employee_code"
                    className="item"
                    label={<span className="text-label">集計方法選択</span>}
                  >
                    <Select
                      defaultValue={valueSelect}
                      onChange={setValueSelect}
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={0}>単月推移</Option>
                      <Option value={1}>累計推移</Option>
                      <Option value={2}>ユーザー別単月推移</Option>
                      <Option value={3}>ユーザー別累計推移</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="employee_code"
                    className="item date"
                    label={<span className="text-label">集計期間</span>}
                  >
                    <RangePicker
                      allowClear
                      name="date-item"
                      format="YYYY/MM/DD HH:mm"
                      placeholder={['集計開始日', '集計終了日']}
                      onChange={(dates) => {
                        if (dates) {
                          formik.setFieldValue('start_period', dates[0]?.toISOString());
                          formik.setFieldValue('end_period', dates[1]?.toISOString());
                        } else {
                          formik.setFieldValue('start_period', '');
                          formik.setFieldValue('end_period', '');
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    className="item"
                    label={<span className="text-label">ユーザー種類選択</span>}
                  >
                    <SelectField
                      name="name"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">a</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="department_name"
                    className="item"
                    label={<span className="text-label">売上種類選択</span>}
                  >
                    <SelectField
                      name="department_name"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">a</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="position_code"
                    className="item date"
                    label={<span className="text-label">ユーザー検索</span>}
                  >
                    <SelectField
                      name="position_code"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">a</Option>
                    </SelectField>
                  </Form.Item>
                  <div className="group-btn">
                    <span className="label-reset" onClick={() => formik.resetForm()}>
                      リセット
                    </span>
                    <SubmitButton className="btn-search" loading={false}>
                      検索
                    </SubmitButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={visibleExpansionOfSale} onChange={onChange}>
                  階層を折りたたむ
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  disabled={!visibleExpansionOfSale}
                  checked={visibleCountUsage && visibleExpansionOfSale}
                  onClick={() => setVisibleCountUsage(!visibleCountUsage)}
                >
                  利用件数表示
                </Checkbox>
              </div>
            </div>
          </div>
          {segmented === 0 ? (
            <Table
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleCountUsage={visibleCountUsage}
              valueSelect={valueSelect}
            />
          ) : (
            <Chart
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleCountUsage={visibleCountUsage}
            />
          )}
        </div>
      </div>
    </SaleInformationStyled>
  );
};

export default SaleInformation;
