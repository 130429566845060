import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { getCurriculum, getCurriculumMaster, updateLevelCurriculum } from '../../thunk';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../../selectors';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  page?: number;
  visible: boolean;
  per_page?: number;
  node: Types.TreeItem<Types.CurriculumItemType>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditLevelCurriculum: React.FC<Props> = ({ page, per_page, node, visible, setVisible }) => {
  const { userInfo } = useSelector(authSelector);
  const { filter_conditions } = useSelector(curriculumSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.AddLowerHierarchyFormik>({
    initialValues: {
      curriculumName: node.node?.name || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!node) return;
      const resultAction = await dispatch(
        updateLevelCurriculum({
          id: node.node?.i_id!,
          level: node.columnIndex!,
          data: {
            item: {
              name: values.curriculumName,
            },
          },
        })
      );
      if (updateLevelCurriculum.fulfilled.match(resultAction)) {
        await Promise.all([
          dispatch(
            getCurriculum({
              conditions: [
                ...filter_conditions.conditions,
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getCurriculumMaster({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: page || 1,
              per_page: per_page || 10,
            })
          ),
        ]);
      }
      handleCancel();
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setVisible(false);
  };

  return (
    <Modal
      title={`第${node.columnIndex}階層 編集`}
      width={720}
      visible={visible}
      okButton={{
        text: '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleCancel,
      }}
      onCancel={handleCancel}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="sub-title">
            第{node.columnIndex}階層名の編集が可能です。編集後に更新ボタンをクリックしてください。
          </p>
          <FormikProvider value={formik}>
            <Form layout="horizontal" className="form">
              <Form.Item
                name="curriculumName"
                label={<span className="text-label">第{node.columnIndex}階層名称</span>}
                className="form-input"
                colon={false}
              >
                <Input
                  name="curriculumName"
                  className="input"
                  type="text"
                  placeholder="最大30文字"
                  showCount
                  maxLength={30}
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default EditLevelCurriculum;
