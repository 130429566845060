import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, curriculumService } from 'services';
import * as Types from 'types';
import {
  OFFICIAL_CURRICULUM_QUESTION_SEARCH,
  SELECT_OFFICIAL_CURRICULUM_NAME,
  SELECT_QUESTION_CREATOR,
  UNRELATED_QUESTIONS,
} from 'configs';

export const getQuestionSearch = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearch.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getQuestionSearch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.QuestionSearch.ResponseType>(
      OFFICIAL_CURRICULUM_QUESTION_SEARCH.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUnrelatedQuestions = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UnrelatedQuestions.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getUnrelatedQuestions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.UnrelatedQuestions.ResponseType>(
      UNRELATED_QUESTIONS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCreatorQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectQuestionCreator.ResponseType>,
  undefined,
  Types.requestError
>('Curriculum/Search/getCreatorQuestionOption', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectQuestionCreator.ResponseType>(
      SELECT_QUESTION_CREATOR.name,
      {
        page: 1,
        per_page: 0,
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getCurriculumQuestionOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_OFFICIAL_CURRICULUM_NAME.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumLevelOption = createAsyncThunk<
  { level: number; data: Types.ReportsItemResponseType<Types.SelectCurriculums.LevelRes> },
  { level: number; provider_id: string },
  Types.requestError
>('Curriculum/Search/getCurriculumLevelOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.findAllCurriculumLevelOption(req.level, {
      page: 1,
      per_page: 0,
    });

    return { level: req.level, data };
  } catch (error) {
    return rejectWithValue(error);
  }
});
