import React from 'react';
import { Table } from 'antd';

import RegisteredInformationStyled from './styles';

interface Props {
  visibleExpansionOfSale: boolean;
}

const dataDate = [
  { date: '01/2022' },
  {
    date: '02/2022',
  },
  {
    date: '03/2022',
  },
  {
    date: '04/2022',
  },
  {
    date: '05/2022',
  },
  {
    date: '06/2022',
  },
  {
    date: '07/2022',
  },
  {
    date: '08/2022',
  },
];

const date = dataDate.map((item, index) => ({
  title: item.date,
  dataIndex: `date${index + 1}`,
  key: item.date,
  width: '8%',
  render: (text: string) => (text ? <span>{text}</span> : <span>-</span>),
}));

const column = [
  {
    title: '企業種類',
    dataIndex: 'user_type',
    key: 'user_type',
  },
  {
    title: '登録情報',
    dataIndex: 'sales_type',
    key: 'sales_type',
  },
  ...date,
];

const RegisteredInformation: React.FC<Props> = ({ visibleExpansionOfSale }) => {
  return (
    <RegisteredInformationStyled visibleExpansionOfSale={visibleExpansionOfSale}>
      <div className="text-count">
        3
        <span className="text-static">
          <small>件表示</small>
        </span>
        /3
        <span className="text-static">
          <small>名</small>
        </span>
      </div>
      <Table
        rowClassName={(record, index) => (record.sales_type === '月額利用料' ? 'child' : '')}
        className="table"
        rowKey="code"
        columns={column}
        dataSource={data1}
        expandable={{
          defaultExpandAllRows: true,
        }}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        pagination={false}
      />
    </RegisteredInformationStyled>
  );
};

const data1 = [
  {
    user_type: '全ユーザー',
    sales_type: '月末登録企業数',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '60,000,000',
        date2: '60,000,000',
        date3: '60,000,000',
        date4: '60,000,000',
        date5: '60,000,000',
        date6: '60,000,000',
        date7: '60,000,000',
        date8: '60,000,000',
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
  },
  {
    user_type: '月末登録企業数',
    sales_type: '月末登録企業数',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '48,000,000',
        date2: '48,000,000',
        date3: '48,000,000',
        date4: '48,000,000',
        date5: '48,000,000',
        date6: '48,000,000',
        date7: '48,000,000',
        date8: '48,000,000',
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
  },
];

export default RegisteredInformation;
