import { useMemo } from 'react';

import { Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { map } from 'lodash';
import dayjs from 'dayjs';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface TableRow {
  employee_code: string;
  name: string;
  kana: string;
  affiliation: string;
  position: string;
  age: string;
  hire_date: string;
  last_login_date: string;
  role_code: string;
}

const PDFUsersDocument = ({
  employeeSelected,
  total,
}: {
  employeeSelected: Types.EmployeeUser.ResponseType[];
  total: number;
}) => {
  const columns: { title: string; width: string | number }[] = [
    {
      title: '社員番号',
      width: 90,
    },
    {
      title: '氏名',
      width: 90,
    },
    {
      title: 'フリガナ',
      width: 90,
    },
    {
      title: '所属',
      width: 90,
    },
    {
      title: '役職',
      width: 90,
    },
    {
      title: '年齢',
      width: 90,
    },
    {
      title: '入社年月日',
      width: 90,
    },
    {
      title: '最終ログイン',
      width: 90,
    },
    {
      title: '権限',
      width: 90,
    },
  ];

  const rows = useMemo(() => {
    const employeeList: TableRow[] = map(employeeSelected, (employee) => ({
      employee_code: employee.employee_code,
      name: employee.name,
      kana: employee.kana,
      affiliation: employee.department_name,
      position: employee.position_code,
      age: employee.birthday ? `${dayjs().diff(employee.birthday, 'years', false)} 歳` : '0歳',
      hire_date: employee.hire_date ? dayjs(employee.hire_date).format('YYYY/MM/DD') : '',
      last_login_date: employee.last_login_date
        ? dayjs(employee.last_login_date).format('YYYY/MM/DD HH:mm')
        : '',
      role_code: employee.role_code,
    }));

    return employeeList;
  }, [employeeSelected]);

  return (
    <Document>
      <Page
        size={pageSize}
        orientation={pageOrientation}
        style={{ ...styles.page, padding: '30px 0 30px 0' }}
        break
      >
        <View style={styles.header}>
          <Text style={styles.textHeader}>社内ユーザーマスタ</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>
            社内ユーザーマスタの作成・管理を行う画面です。
          </Text>
          <Text style={styles.textSubscription}>
            ユーザーに紐づける必修カリキュラム・スキルチェックの設定もこの画面から行います。
          </Text>
        </View>
        <View style={styles.body}>
          <View style={styles.textCountGeneral}>
            <Text style={styles.textCountNumber}>{employeeSelected.length}</Text>
            <Text style={styles.textCountDescription}>件表示</Text>
            <Text style={styles.textCountNumber}>/</Text>
            <Text style={styles.textCountNumber}>{total}</Text>
            <Text style={styles.textCountDescription}>件中</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]} fixed>
              {columns.map((column, index) => (
                <View key={index} style={[styles.wrapCell, { width: column.width }]}>
                  <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
                </View>
              ))}
            </View>

            {rows.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={[
                  styles.tableRow,
                  { borderBottom: rowIndex !== rows.length - 1 ? 'none' : '1px solid #e5e5e5' },
                ]}
              >
                {Object.keys(row).map((key, colIndex) => (
                  <View
                    key={colIndex}
                    style={[styles.wrapCell, { width: columns[colIndex].width }]}
                  >
                    <Text style={styles.tableCell}>{row[key as keyof TableRow]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFUsersDocument;
