import { pick, omit } from 'lodash';

import * as Pages from 'pages';

export const routes = {
  Login: {
    path: '/login',
    title: '',
    element: <Pages.LoginPage />,
  },
  Dashboard: {
    index: true,
    element: <Pages.DashboardPage />,
    title: 'Home / Dashboard',
    path: '/',
  },
  UserMaster: {
    index: true,
    element: <Pages.UserMasterPage />,
    title: 'ユーザーマスタ',
    path: '/user_master',
  },
  CreateUserMaster: {
    path: '/user_master/create',
    title: 'ユーザーマスタ新規登録',
    element: <Pages.CreateUserMasterPage />,
  },
  EditUserMaster: {
    path: '/user_master/edit/:id',
    title: 'ユーザーマスタ編集画面',
    element: <Pages.EditUserMasterPage />,
  },
  AffiliationMaster: {
    path: '/affiliation_master',
    title: '所属マスタ',
    element: <Pages.AffiliationMasterPage />,
  },
  JobTitleMaster: {
    path: '/job_title_master',
    title: '役職マスタ',
    element: <Pages.JobTitleMasterPage />,
  },
  AuthorityMaster: {
    path: '/authority_master',
    title: '権限マスタ',
    element: <Pages.AuthorityMasterPage />,
  },
  AuthorityMasterCreate: {
    path: '/authority_master/create',
    title: '権限マスタ 新規登録',
    element: <Pages.AuthorityMasterCreatePage />,
  },
  AuthorityMasterEdit: {
    path: '/authority_master/edit/:id',
    title: '権限マスタ 編集',
    element: <Pages.AuthorityMasterEditPage />,
  },
  AdministratorMaster: {
    path: '/administrator_master',
    title: '管理者マスタ',
    element: <Pages.AdministratorMasterPage />,
  },
  CurriculumMaster: {
    element: <Pages.CurriculumPage />,
    title: 'カリキュラムツリー',
    path: '/curriculum',
  },
  CorporateUserInformationList: {
    index: true,
    element: <Pages.CorporateUserInformation />,
    title: '法人ユーザー管理',
    path: '/corporate-user-information',
  },
  FreeTrialManagement: {
    element: <Pages.FreeTrialManagementPage />,
    title: '無料トライアル管理',
    path: '/free-trial-management',
  },
  PartnerManagement: {
    index: true,
    element: <Pages.PartnerManagement />,
    title: 'パートナー管理',
    path: '/partner-management',
  },
  OfficialCurriculumList: {
    element: <Pages.OfficialCurriculumList />,
    title: 'パートナー　OFFICIALカリキュラム一覧',
    path: '/official-curriculum-list',
  },
  QuestionMasterPage: {
    path: '/question-master',
    title: '設問マスタ',
    element: <Pages.QuestionMasterPage />,
  },
  BillingDataManagement: {
    element: <Pages.BillingDataManagementPage />,
    title: '請求データ管理',
    path: '/billing_data_management',
  },
  IndividualUserManagement: {
    element: <Pages.IndividualUserManagement />,
    title: '個人ユーザ管理',
    path: '/individual_user_management',
  },
  PublicManagement: {
    title: 'OFFICIALカリキュラム 公開管理',
    path: '/public-management',
    element: <Pages.PublicManagementPage />,
  },
  InvoiceCorrespondence: {
    element: <Pages.InvoiceCorrespondencePage />,
    title: '請求書対応',
    path: '/invoice-correspondence',
  },
  Report: {
    element: <Pages.ReportPage />,
    title: 'レポート',
    path: '/report',
  },
  StorageManagement: {
    element: <Pages.StorageManagementPage />,
    title: 'ストレージ管理',
    path: '/storage-management',
  },
  ResetPassword: {
    title: '',
    path: '/pwd_reset',
    element: <Pages.ResetPassword />,
  },
  ResetPasswordForm: {
    title: '',
    path: '/pwd_reset/:confirmation_id',
    element: <Pages.ResetPasswordForm />,
  },
  CurriculumTree: {
    title: 'カリキュラムツリー',
    path: '/curriculum-tree',
    element: <Pages.CurriculumTree />,
  },
};

export type Routes = typeof routes;

export type RouteKeys = keyof Routes;

const publicKeys: RouteKeys[] = ['Login', 'ResetPassword', 'ResetPasswordForm'];

export const publicRoutes = pick<Routes, RouteKeys>(routes, publicKeys);

export const privateRoutes = omit<Routes, RouteKeys>(routes, publicKeys);
