import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createAdminAffiliationLevel,
  deleteAdminAffiliationLevel,
  getDataAdminAffiliationHierarchy,
  getSelectAdminAffiliationHierarchy,
  updateAdminAffiliationLevel,
} from './thunk';

export type InitialState = {
  dataAdminAffiliationHierarchy: Array<Types.AdminAffiliationHierarchy.ResponseType>;
  dataSelectAdminAffiliation: Array<Types.AdminAffiliationHierarchy.ResponseType>;
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  dataAdminAffiliationHierarchy: [],
  dataSelectAdminAffiliation: [],
  loading: false,
  total: 0,
};

export const affiliationMasterSlice = createSlice({
  name: 'affiliationMasterSlice-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getSelectAdminAffiliationHierarchy.pending, startLoading)
      .addCase(createAdminAffiliationLevel.pending, startLoading)
      .addCase(updateAdminAffiliationLevel.pending, startLoading)
      .addCase(deleteAdminAffiliationLevel.pending, startLoading)
      .addCase(getDataAdminAffiliationHierarchy.pending, startLoading);

    builder.addCase(getSelectAdminAffiliationHierarchy.fulfilled, (state, action) => {
      state.dataSelectAdminAffiliation = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataAdminAffiliationHierarchy.fulfilled, (state, action) => {
      state.dataAdminAffiliationHierarchy = action.payload.report_results;
      stopLoading(state);
    });
    builder
      .addCase(createAdminAffiliationLevel.fulfilled, stopLoading)
      .addCase(updateAdminAffiliationLevel.fulfilled, stopLoading)
      .addCase(deleteAdminAffiliationLevel.fulfilled, stopLoading);

    builder
      .addCase(getSelectAdminAffiliationHierarchy.rejected, stopLoading)
      .addCase(createAdminAffiliationLevel.rejected, stopLoading)
      .addCase(updateAdminAffiliationLevel.rejected, stopLoading)
      .addCase(deleteAdminAffiliationLevel.rejected, stopLoading)
      .addCase(getDataAdminAffiliationHierarchy.rejected, stopLoading);
  },
});

// export const {} = userMasterSlice.actions;

export default affiliationMasterSlice.reducer;
