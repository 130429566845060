import { PropsWithChildren } from 'react';
import { SelectProps } from 'formik-antd';

import SelectStyled from './styles';

type Props = PropsWithChildren<SelectProps & {}>;

const SelectField: React.FC<Props> = ({ children, ...props }) => {
  return (
    <SelectStyled {...props} getPopupContainer={(trigger) => trigger.parentNode}>
      {children}
    </SelectStyled>
  );
};
export default SelectField;
