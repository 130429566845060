import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';

import individualUserManagement from './mocks/individualUserManagement.json';
import invoiceCorrespondence from './mocks/invoiceCorrespondence.json';
import reportUserInformation from './mocks/reportUserInformation.json';
import curriculumPublished from './mocks/curriculumPublished.json';
import curriculumListUnder from './mocks/crruiclumListUnder.json';
import corporateUser from './mocks/corporateUserInfomation.json';
import releaseNoteSection from './mocks/releseNoteSection.json';
import curriculumMaster from './mocks/curriculumMaster.json';
import curriculumList from './mocks/curriculumList.json';
import manageRelease from './mocks/manageRelease.json';
import archiveList from './mocks/archiveList.json';
import management from './mocks/management.json';
import curriculum from './mocks/curriculum.json';
import question from './mocks/question.json';
import { config } from 'configs';

export const mockAdapter = (instance: AxiosInstance) => {
  const mock = new MockAdapter(instance, { delayResponse: 1500 });
  mock
    .onPost(`applications/${config.APP_ID}/reports/curriculum_hierarchy/filter`)
    .reply(200, curriculum);
  mock
    .onPost(`applications/${config.APP_ID}/reports/curriculum_hierarchy_master/filter`)
    .reply(200, curriculumMaster);
  mock.onPost(`applications/${config.APP_ID}/reports/question_search/filter`).reply(200, question);
  mock
    .onPost(`applications/${config.APP_ID}/reports/corporate_user_information/filter`)
    .reply(200, corporateUser);
  mock
    .onPost(`applications/${config.APP_ID}/reports/Official_Curriculum_Publishing_Settings/filter`)
    .reply(200, curriculumPublished);

  mock.onPost(`applications/${config.APP_ID}/reports/archiveList/filter`).reply(200, archiveList);
  mock
    .onPost(`applications/${config.APP_ID}/reports/reportUserInformation/filter`)
    .reply(200, reportUserInformation);
  mock
    .onPost(`applications/${config.APP_ID}/reports/release_note_management/filter`)
    .reply(200, manageRelease);
  mock
    .onPost(`applications/${config.APP_ID}/reports/invoice_correspondence/filter`)
    .reply(200, invoiceCorrespondence);
  mock
    .onPost(`applications/${config.APP_ID}/reports/release_note_detail/filter`)
    .reply(200, releaseNoteSection);
  mock
    .onPost(`applications/${config.APP_ID}/reports/individual_user_management/filter`)
    .reply(200, individualUserManagement);
  mock.onPost(`applications/${config.APP_ID}/reports/management/filter`).reply(200, management);
  mock
    .onPost(`applications/${config.APP_ID}/reports/curriculum_list/filter`)
    .reply(200, curriculumList);
  mock
    .onPost(`applications/${config.APP_ID}/reports/curriculum_list_under/filter`)
    .reply(200, curriculumListUnder);
};
