import React from 'react';

import { SectionStyled } from './styles';
import Modal from '..';

interface Props {
  handleCloseModal: () => void;
  body: React.ReactNode;
  visible: boolean;
  title: string;
}

const ExecuteUnpaidAlert: React.FC<Props> = ({ title, visible, body, handleCloseModal }) => {
  return (
    <Modal
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#424242',
            margin: '0',
          }}
        >
          {title}
        </h3>
      }
      open={visible}
      width={777}
      cancelButton={{
        text: 'OK',
        onClick: () => {
          handleCloseModal();
        },
        style: {
          width: 140,
          height: 40,
        },
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="text-content">{body}</p>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ExecuteUnpaidAlert;
