import React, { useCallback, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  QuestionCircleOutlined,
  CheckSquareOutlined,
  CheckSquareFilled,
  FileTextOutlined,
  SearchOutlined,
  RightOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { HEADER_COMPANY_USER_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { corporateUserInformationSelector } from './selector';
import CorporateUserInformationListStyled from './styles';
import { authSelector } from 'containers/Auth/selectors';
import ModalCorporateUserManagementDetail from './Modal';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import HeaderDashboard from 'components/Header';
import { PopoverStyled } from './Modal/styles';
import { routes } from 'navigations/routes';
import { filterDeleteAt } from './slice';
import { SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  executeAction,
  executeFunctionCreateBatchID,
  executeFunctionDeleteMailBatch,
  getCorprateUserInfomation,
  getDataCompanyUserExportCSV,
  getSelectCompany,
  getSelectPaymentMethod,
  updateCompanyUser,
} from './thunk';

const { Option } = Select;

const CorporateUserInformation: React.FC = () => {
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [openMail, setOpenMail] = useState<number>(-1);
  const [perPage, setPerPage] = useState<number>(100);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [corporateUserDetail, setCorporateUserDetail] =
    useState<Types.CorporateUserInformationType>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { headerTitle, loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { corporateUsers, selectCompany, selectPaymentMethod } = useSelector(
    corporateUserInformationSelector
  );

  const formik = useFormik<Types.CorporateUserFormSearchFormik>({
    initialValues: {
      contract_status: '',
      company_id: '',
      company_name: '',
      payment_method: '',
    },
    onSubmit: async (values) => {
      if (userInfo) {
        const conditions: Array<Types.ConditionsType> = [];
        Object.keys(values)
          .filter((e) => e !== 'contract_status')
          .forEach((key) => {
            if (values[key as keyof typeof values]) {
              conditions.push({
                id: key,
                search_value: [values[key as keyof typeof values]],
              });
            }
          });
        await dispatch(
          getCorprateUserInfomation({
            conditions,
            page: 1,
            per_page: 0,
            use_display_id: true,
            include_item_ref: true,
          })
        );
        if (values.contract_status) {
          dispatch(filterDeleteAt(values.contract_status));
        }
      }
    },
    onReset: () => {
      fetchCompanyUser();
    },
  });

  const formikDetail = useFormik<Types.UpdateCompanyUserDetailFormik>({
    initialValues: {
      forced_withdrawal_mail_send_flg: false,
      suspension_mail_send_flg: false,
      forced_withdrawal_delete: false,
      unpaid_mail_send_flg: false,
      unpaid_alert_delete: false,
      suspension_delete: false,
      invoice_exception: false,
      memo: '',
    },
    onSubmit: () => {},
  });

  const fetchCompanyUser = useCallback(async () => {
    if (!userInfo) return;
    dispatch(startLoading());
    await dispatch(
      getCorprateUserInfomation({
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  }, [userInfo, dispatch]);

  const handleUpdateInvoiceDetail = async () => {
    const itemUpdate = {
      invoice_exception: formikDetail.values.invoice_exception ? 1 : 0,
    };

    if (corporateUserDetail?.unpaid_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.unpaid_mail_batch_id,
            },
          },
        })
      );
    }

    if (!formikDetail.values.unpaid_alert_delete) {
      let batchIdUnpaid = '';

      if (formikDetail.values.unpaid_mail_send_flg) {
        const resultUnpaidCreateBatchId = await dispatch(
          executeFunctionCreateBatchID({ data: {} })
        );

        if (executeFunctionCreateBatchID.fulfilled.match(resultUnpaidCreateBatchId)) {
          batchIdUnpaid = resultUnpaidCreateBatchId.payload.data as string;
        }
      }

      await dispatch(
        executeAction({
          actionId: 'unpaid_mail_send',
          itemId: corporateUserDetail?.items[0].i_id || '',
          data: {
            item: {
              unpaid_mail_send_flg: formikDetail.values.unpaid_mail_send_flg ? 1 : 0,
              unpaid_mail_batch_id: batchIdUnpaid,
            },
            ...(formikDetail.values.unpaid_mail_send_date
              ? {
                  as_params: {
                    payment_method: corporateUserDetail?.payment_method_cd,
                    provided_date: dayjs().toISOString(),
                    unpaid_mail_batch_id: batchIdUnpaid,
                    unpaid_mail_send_date: formikDetail.values.unpaid_mail_send_date.toString(),
                  },
                }
              : {}),
            is_force_update: true,
          },
        })
      );

      if (formikDetail.values.unpaid_mail_send_date) {
        Object.assign(itemUpdate, {
          unpaid_alert_flg: 1,
          unpaid_alert_date: formikDetail.values.unpaid_mail_send_date,
          unpaid_mail_send_flg: 1,
          unpaid_mail_send_date: formikDetail.values.unpaid_mail_send_date,
        });
      }
    } else {
      Object.assign(itemUpdate, {
        unpaid_alert_flg: 0,
        unpaid_alert_date: null,
        unpaid_mail_send_flg: 0,
        unpaid_mail_batch_id: null,
        unpaid_mail_send_date: null,
      });
    }

    if (corporateUserDetail?.suspension_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.suspension_mail_batch_id,
            },
          },
        })
      );
    }

    if (!formikDetail.values.suspension_delete) {
      let batchIdSuspension = '';

      if (formikDetail.values.suspension_mail_send_flg) {
        const resultSuspensionCreateBatchId = await dispatch(
          executeFunctionCreateBatchID({ data: {} })
        );

        if (executeFunctionCreateBatchID.fulfilled.match(resultSuspensionCreateBatchId)) {
          batchIdSuspension = resultSuspensionCreateBatchId.payload.data as string;
        }
      }

      await dispatch(
        executeAction({
          actionId: 'suspension_mail_send',
          itemId: corporateUserDetail?.items[0].i_id || '',
          data: {
            item: {
              suspension_mail_send_flg: formikDetail.values.suspension_mail_send_flg ? 1 : 0,
              suspension_mail_batch_id: batchIdSuspension,
            },
            ...(formikDetail.values.suspension_mail_send_date
              ? {
                  as_params: {
                    payment_method: corporateUserDetail?.payment_method_cd,
                    provided_date: dayjs().toISOString(),
                    suspension_mail_batch_id: batchIdSuspension,
                    suspension_mail_send_date:
                      formikDetail.values.suspension_mail_send_date.toString(),
                  },
                }
              : {}),
            is_force_update: true,
          },
        })
      );

      if (formikDetail.values.suspension_mail_send_date) {
        Object.assign(itemUpdate, {
          suspension_flg: 1,
          suspension_date: formikDetail.values.suspension_mail_send_date,
          suspension_mail_send_flg: 1,
          suspension_mail_send_date: formikDetail.values.suspension_mail_send_date,
        });
      }
    } else {
      Object.assign(itemUpdate, {
        suspension_flg: 0,
        suspension_date: null,
        suspension_mail_send_flg: 0,
        suspension_mail_batch_id: null,
        suspension_mail_send_date: null,
      });
    }

    if (corporateUserDetail?.forced_withdrawal_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.forced_withdrawal_mail_batch_id,
            },
          },
        })
      );
    }

    if (!formikDetail.values.forced_withdrawal_delete) {
      let batchIdWithdrawal = '';

      if (formikDetail.values.forced_withdrawal_mail_send_flg) {
        const resultWithdrawalCreateBatchId = await dispatch(
          executeFunctionCreateBatchID({ data: {} })
        );

        if (executeFunctionCreateBatchID.fulfilled.match(resultWithdrawalCreateBatchId)) {
          batchIdWithdrawal = resultWithdrawalCreateBatchId.payload.data as string;
        }
      }

      await dispatch(
        executeAction({
          actionId: 'forced_withdrawal_mail_send',
          itemId: corporateUserDetail?.items[0].i_id || '',
          data: {
            item: {
              forced_withdrawal_mail_send_flg: formikDetail.values.forced_withdrawal_mail_send_flg
                ? 1
                : 0,
              forced_withdrawal_mail_batch_id: batchIdWithdrawal,
            },
            ...(formikDetail.values.forced_withdrawal_mail_send_date
              ? {
                  as_params: {
                    forced_withdrawal_mail_batch_id: batchIdWithdrawal,
                    forced_withdrawal_mail_send_date:
                      formikDetail.values.forced_withdrawal_mail_send_date.toString(),
                  },
                }
              : {}),
            is_force_update: true,
          },
        })
      );

      if (formikDetail.values.forced_withdrawal_mail_send_date) {
        Object.assign(itemUpdate, {
          forced_withdrawal_flg: 1,
          deletedat: formikDetail.values.forced_withdrawal_mail_send_date,
          forced_withdrawal_mail_send_flg: 1,
          forced_withdrawal_mail_send_date: formikDetail.values.forced_withdrawal_mail_send_date,
        });
      }
    } else {
      Object.assign(itemUpdate, {
        forced_withdrawal_flg: 0,
        deletedat: null,
        forced_withdrawal_mail_send_flg: 0,
        forced_withdrawal_mail_batch_id: null,
        forced_withdrawal_mail_send_date: null,
      });
    }

    if (formikDetail.values.memo) {
      Object.assign(itemUpdate, {
        memo: formikDetail.values.memo,
      });
    }

    const actionResult = await dispatch(
      updateCompanyUser({
        id: corporateUserDetail?.items[0].i_id || '',
        data: {
          item: itemUpdate,
          is_force_update: true,
        },
      })
    );
    if (updateCompanyUser.fulfilled.match(actionResult)) {
      setCompleted(true);
    }
    formikDetail.resetForm();
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataCompanyUserExportCSV({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataCompanyUserExportCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          company_id: item.company_id,
          company_name: item.company_name,
          payment_method_cd: item.payment_method_cd,
          payment_method: item.payment_method,
          admin_department: item.admin_department,
          admin_position: item.admin_position,
          admin_name: item.admin_name,
          last_login_at: item.last_login_at,
          invoice_exception: item.invoice_exception,
          skillcheck_tid: item.skillcheck_tid,
          max_users: item.max_users,
          contract_date: item.contract_date,
          paid_flg: item.paid_flg,
          deletedat: item.deletedat,
          forced_withdrawal_flg: item.forced_withdrawal_flg,
        }));

        const csvString = [
          HEADER_COMPANY_USER_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setVisibleExportFile(false);
    }
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const columnsSecond: ColumnsType<Types.CorporateUserInformationType> = [
    {
      title: '契約状況',
      dataIndex: 'contractStatus',
      key: 'contractStatus',
      width: '6%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-contract-status">
          <span>{item.deletedat ? '退会' : '契約中'}</span>
        </div>
      ),
    },
    {
      title: (
        <>
          <span>法人ID</span>
          <br />
          <span>法人名</span>
        </>
      ),
      dataIndex: 'companyIdCompanyName',
      key: 'company_id_company_name',
      className: 'name',
      width: '15%',
      ellipsis: true,
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span className="fax-id">{item.company_id}</span>
          <br />
          <span className="content-id">{item.company_name}</span>
        </span>
      ),
    },
    {
      title: '請求方法',
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '6%',
    },
    {
      title: (
        <>
          <span>ユーザー数</span>
          <br />
          <span>スキルチェック数</span>
        </>
      ),

      dataIndex: 'max_users',
      key: 'max_users',
      width: '10%',
      align: 'right',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span>{item.max_users}</span>
          <br />
          <span>{item.skillcheck_count}</span>
        </span>
      ),
    },
    {
      title: (
        <>
          <span>所属 / 役職</span>
          <br />
          <span>管理者氏名</span>
        </>
      ),
      dataIndex: 'positionManagerName',
      key: 'positionManagerName',
      className: 'position-name',
      width: '15%',
      ellipsis: true,
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span className="fax-id">
            {item.admin_department} / {item.admin_position}
          </span>
          <br />
          <span className="content-id">{item.admin_name}</span>
        </span>
      ),
    },
    {
      title: (
        <>
          <span>メール</span>
          <br />
          <span>送信</span>
        </>
      ),
      dataIndex: 'sendEmail',
      key: 'sendEmail',
      width: '5%',
      render: (_: any, item, index: number) => (
        <div className="wrap-icon-row">
          <MailOutlined
            onClick={(v) => {
              window.location.href = `mailto:${item.admin_email}`;
              setOpenMail((prevState) => (v && prevState !== index ? index : -1));
            }}
            className="icon"
            style={{ color: openMail === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </div>
      ),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_at',
      key: 'last_login_at',
      className: 'date',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span>{dayjs(item.last_login_at).format('YYYY/MM/DD')}</span>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>請求書</span>
            <br />
            <span>特例</span>
          </div>

          <Popover
            overlayStyle={{
              width: '33%',
            }}
            content={
              <PopoverStyled>
                <span>請求書特例について</span>
                <div className="main-content">
                  <span>
                    請求書特例は通常請求書（銀行振込）でのお支払いは500アカウント以上の場合のみ
                    選択可能なところ、特別な理由によって請求書支払を認める場合に使用する機能です。
                    例）
                  </span>
                  <br />
                  <span>・利用開始後すぐに500アカウントに到達見込み</span>
                  <br />
                  <span>・クレジットを利用しない会社だがアカウント数が300予定</span>
                  <br />
                  <span>基本的にはクレジットだが、柔軟に対応</span>
                  <br />
                  <br />
                  <span>請求書特例の実行は詳細ボタンから各法人ごとに実行することができます。</span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'invoice_exception',
      key: 'invoice_exception',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.max_users > 500 ? (
            <CheckSquareOutlined className="check-invoice-outlined" />
          ) : item.invoice_exception === 1 ? (
            <CheckSquareFilled className="check-invoice-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>未払い</span>
            <br />
            <span>アラート</span>
          </div>
          <Popover
            overlayStyle={{
              width: '17%',
            }}
            content={
              <PopoverStyled>
                <span>未払いアラートについて</span>
                <div className="main-content">
                  <span>未払いアラートは未払いになった法人ユーザーの管理者のHOME画面上に</span>
                  <br />
                  <span>
                    警告のモーダルを展開する機能です。未払いのお知らせとお支払いの督促を表示します。
                  </span>
                  <br />
                  <span>実行は未払いになった際に速やかに実行します。</span>
                  <br />
                  <br />
                  <span>
                    未払いアラートの実行は詳細ボタンから各法人ごとに実行することができます。
                  </span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'unpaid_alert_flg',
      key: 'unpaid_alert_flg',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.unpaid_alert_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.unpaid_alert_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div>
          利用停止
          <Popover
            overlayStyle={{
              width: '17%',
            }}
            content={
              <PopoverStyled>
                <span>利用停止について</span>
                <div className="main-content">
                  <span>
                    利用停止は未払い状態が一定期間継続した場合にSKILL
                    FAMILIARの利用を停止する機能です。（社内的な目安は未払い発生から60日（61日目）
                  </span>
                  <br />
                  <span>【実施後の状態】</span>
                  <br />
                  <span>・一般ユーザーのログイン停止（利用停止）</span>
                  <br />
                  <span>・管理者のHOME画面上に警告のモーダル表示</span>
                  <br />
                  <span>・管理者の利用機能の制限（HOME,ご利用状況画面のみ）</span>
                  <br />
                  <br />
                  <span>利用停止の実行は詳細ボタンから各法人ごとに実行することができます。</span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'suspension_flg',
      key: 'suspension_flg',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.suspension_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.suspension_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>強制</span>
            <br />
            <span>退会</span>
          </div>
          <Popover
            overlayStyle={{
              width: '17%',
            }}
            content={
              <PopoverStyled>
                <span>強制退会について</span>
                <div className="main-content">
                  <span>
                    請求方法が請求書のユーザーについて、お支払いの確認が取れない状態が90日間続いた
                  </span>
                  <br />
                  <span>
                    請求方法が請求書のユーザーについて、お支払いの確認が取れない状態が90日間続いた場合に強制的に退会していただくためのチェックボックスです。
                  </span>
                  <br />
                  <span>
                    強制退会までの連絡や強制退会の実行は責任者に必ず確認をとってから実行してください。
                  </span>
                  <br />
                  <span>
                    チェックを付ける場合は、詳細ボタンから強制退会日を入力・更新を行ってください。
                  </span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'forcedWithdrawal',
      key: 'forcedWithdrawal',
      width: '6%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.forced_withdrawal_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.forced_withdrawal_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: '詳細',
      dataIndex: 'detailed',
      key: 'detailed',
      width: '5%',
      render: (_: string, record: Types.CorporateUser.CorporateUserInformationType) => (
        <div className="wrap-icon-row">
          <FileTextOutlined
            onClick={() => {
              setVisible(true);
              setCorporateUserDetail(record);
              formikDetail.setFieldValue('memo', record.memo);
              formikDetail.setValues({
                ...formikDetail.initialValues,
                forced_withdrawal_mail_send_flg: record.forced_withdrawal_mail_send_flg
                  ? true
                  : false,
                suspension_mail_send_flg: record.suspension_mail_send_flg ? true : false,
                suspension_mail_send_date: record.suspension_mail_send_date,
                unpaid_mail_send_flg: record.unpaid_mail_send_flg ? true : false,
                unpaid_mail_send_date: record.unpaid_mail_send_date,
                forced_withdrawal_mail_send_date: record.forced_withdrawal_mail_send_date,
                memo: record.memo,
                invoice_exception: record.invoice_exception ? true : false,
              });
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getCorprateUserInfomation({
            use_display_id: true,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectCompany({
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectPaymentMethod({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch]);

  useEffect(() => {
    fetchCompanyUser();
  }, []);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <HeaderDashboard title={headerTitle} />
      <CorporateUserInformationListStyled checkTableNoData={!corporateUsers.length}>
        <div className="wrap-nav">
          <p className="text-note">
            法人ユーザーの登録情報の確認・管理を行う画面です
            <br />
            請求書対応の場合のURL発行もこの画面から行います。
          </p>
          <div className="wrap-button">
            <button onClick={() => navigate(routes.InvoiceCorrespondence.path)}>
              請求書対応管理 <RightOutlined className="icon-right-outline" />
            </button>
            <button onClick={() => navigate(routes.FreeTrialManagement.path)}>
              無料トライアル管理 <RightOutlined className="icon-right-outline" />
            </button>
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="contract_status"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  name="contract_status"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="0">契約中</Option>
                  <Option value="1">退会</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_id"
                className="item"
                label={<span className="text-label">法人ID</span>}
              >
                <SelectField
                  name="company_id"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectCompany.map(({ company_id }) => (
                    <Option key={company_id} value={company_id}>
                      {company_id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_name"
                className="item"
                label={<span className="text-label">法人名</span>}
              >
                <SelectField
                  name="company_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectCompany.map(({ company_name }, index) => (
                    <Option key={index} value={company_name}>
                      {company_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="payment_method"
                className="item"
                label={<span className="text-label">請求方法</span>}
              >
                <SelectField
                  name="payment_method"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectPaymentMethod?.map((e) => (
                    <Option key={e.select_payment_code} value={e.select_payment_code}>
                      {e.select_payment}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-body">
          <div className="text-count">
            {page * perPage > corporateUsers.length ? corporateUsers.length : page * perPage}
            <span className="text-static"> 件表示</span> /{corporateUsers.length}
            <span className="text-static"> 名</span>
            <div className="select-perpage">
              <div className="label">
                <span>表示件数</span>：
              </div>
              <Select defaultValue={perPage} onChange={handleSelectChange}>
                {[10, 20, 50, 100].map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="btn-div">
            <button
              className="btn-control-number"
              onClick={() => setVisibleExportFile(!visibleExportFile)}
            >
              <span className="text">エクスポート</span>
            </button>
          </div>
          <div className="wrap-table">
            <Table
              className="table"
              rowClassName="border-hight-light"
              dataSource={corporateUsers}
              columns={columnsSecond}
              pagination={{
                pageSize: perPage,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                position: ['topCenter', 'bottomCenter'],
              }}
              rowKey={(record) => JSON.stringify(record)}
              onRow={(data) => ({
                className: data.deletedat ? 'row-table-deleted' : 'row-table',
              })}
            />
          </div>
        </div>
        <ModalCorporateUserManagementDetail
          visible={visible}
          setVisible={setVisible}
          corporateUserDetail={corporateUserDetail}
          onSubmit={handleUpdateInvoiceDetail}
          formik={formikDetail}
        />
        <PopupConfirmExportFile
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
        />
        <Completed
          title="更新が完了しました"
          visible={completed}
          setVisible={setCompleted}
          onSubmit={fetchCompanyUser}
        />
      </CorporateUserInformationListStyled>
    </>
  );
};

export default CorporateUserInformation;
