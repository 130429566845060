import React, { useCallback, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  RightOutlined,
  FormOutlined,
  MailOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { HEADER_INVOICE_CORRESPONDENCE_LIST_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import InvoiceCorrespondenceStyled, { PopoverStyled } from './styles';
import { settingSelector } from 'containers/AppSettings/selectors';
import CompletedDeleteModal from './Modal/CompletedDeleteInvoice';
import ModalCreateEditInfoInvoice from './Modal/EditInfoInvoice';
import ConfirmDeleteModal from './Modal/ConfirmDeleteInvoice';
import { invoiceCorrespondenceSelector } from './selector';
import ResendRegistration from './Modal/ResendRegistration';
import CompletedModal from 'components/Modal/Completed';
import { authSelector } from 'containers/Auth/selectors';
import HeaderDashboard from 'components/Header';
import { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import { SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getDataExportCSVInvoiceCorrespondenceList,
  getInvoiceCorrespondence,
  getSelectCompany,
  getSelectManager,
  removeInvoiceCorrespondence,
} from './thunk';

const PER_PAGE = 10;

const { Option } = Select;

const InvoiceCorrespondence: React.FC = () => {
  const [visibleCompleteEditInvoice, setVisibleCompleteEditInvoice] = useState(false);
  const [visibleEditInvoiceControl, setVisibleEditInvoiceControl] = useState(false);
  const [visibleModalCompleteDelete, setModalCompleteDelete] = useState(false);
  const [visibleEditInvoice, setVisibleEditInvoice] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleResend, setVisibleResend] = useState<boolean>(false);
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [idSelected, setIdSelected] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(100);
  const [content, setContent] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const navigate = useNavigate();

  const { invoiceCorrespondence, selectCompany, selectManager, loading } = useSelector(
    invoiceCorrespondenceSelector
  );
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.InvoiceCorrespondenceFormSearchFormik>({
    initialValues: {
      account_registration_status: '',
      company_name: '',
      manager_name: '',
      email: '',
    },
    onSubmit: (values) => {
      const conditions: Array<Types.ConditionsType> = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
          });
        }
      });
      fetchInvoiceCorrespondence(conditions);
    },
    onReset: () => {
      fetchInvoiceCorrespondence([]);
    },
  });

  const handleDeleteInvoiceCorrespondence = async (id: string) => {
    if (!id) return;
    await dispatch(
      removeInvoiceCorrespondence({
        id,
      })
    );
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataExportCSVInvoiceCorrespondenceList({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataExportCSVInvoiceCorrespondenceList.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          company_id: item.company_id,
          company_name: item.company_name,
          manager_name: item.manager_name,
          email: item.email,
          account_registration_status: item.account_registration_status,
          url_issue_date: item.url_issue_date,
          memo: item.memo,
        }));

        const csvString = [
          HEADER_INVOICE_CORRESPONDENCE_LIST_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setVisibleExportFile(false);
    }
  };

  const columnsSecond: ColumnsType<Types.InvoiceCorrespondenceType> = [
    {
      title: '法人名',
      dataIndex: 'company_name',
      key: 'company_name',
      className: 'name',
    },
    {
      title: 'ご担当者氏名',
      dataIndex: 'manager_name',
      key: 'manager_name',
      width: '10%',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      render: (_: string, item: Types.InvoiceCorrespondenceType) => (
        <div className="wrap-mail-address">
          <span className="text-mail-address">{item.email}</span>
        </div>
      ),
    },
    {
      title: (
        <span>
          アカウント <br /> 登録状況
        </span>
      ),
      dataIndex: 'account_registration_status',
      key: 'account_registration_status',
      width: '10%',
      align: 'center',
      render: (_: string, item: Types.InvoiceCorrespondenceType) => (
        <div className="wrap-contract-status">
          <span className={item.account_registration_status ? 'text-status' : 'text-status-change'}>
            {!item.account_registration_status ? '未登録' : '登録済み'}
          </span>
        </div>
      ),
    },
    {
      title: 'URL発行日',
      dataIndex: 'url_issue_date',
      key: 'url_issue_date',
      className: 'issue-date',
      width: '13%',
      render: (text: string) => (
        <span className="text-status">{dayjs(text).format('YYYY/MM/DD HH:ss')}</span>
      ),
    },
    {
      title: 'memo',
      dataIndex: 'memo',
      className: 'memo',
      key: 'memo',
      render: (text: string) => (
        <div className="wrap-contract-status">
          {text && (
            <div className="wrap-icon-memo">
              <div className="wrap-icon-file">
                <Popover
                  overlayStyle={{
                    width: '33%',
                  }}
                  content={
                    <PopoverStyled>
                      <span>請求書特例について</span>
                      <div className="main-content">
                        <span>ご担当者部署：経理部</span>
                        <span>ご担当者役職：部長。</span>
                        <span>
                          連絡は平日の9:00〜18:00の間に限る。アカウント数600アカウント予定。
                        </span>
                      </div>
                    </PopoverStyled>
                  }
                  trigger="click"
                >
                  <FileTextOutlined className="icon" />
                </Popover>
              </div>
              <span className="text-memo">{text}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      render: (_: string, record) => (
        <div
          className="wrap-icon"
          onClick={() => {
            setVisibleEditInvoiceControl(true);
            setContent('更新が完了しました');
            fetchInvoiceCorrespondence([
              {
                id: 'company_id',
                search_value: [record.company_id],
              },
            ]);
          }}
        >
          <FormOutlined className="icon" />
        </div>
      ),
    },
    {
      title: 'URL 再送',
      dataIndex: 'URLs_resend',
      key: 'URLs_resend',
      width: '5%',
      render: () => (
        <div className="wrap-icon" onClick={() => setVisibleResend(true)}>
          <MailOutlined className="icon" />
        </div>
      ),
    },
    {
      title: '削除',
      dataIndex: 'delete',
      key: 'delete',
      width: '5%',
      render: (_: string, record) => (
        <div
          className="wrap-icon"
          onClick={() => {
            setVisibleModalDelete(!visibleModalDelete);
            setIdSelected(record.item_ref?.email.i_id ?? '');
          }}
        >
          <DeleteOutlined className="icon" />
        </div>
      ),
    },
  ];

  const fetchInvoiceCorrespondence = useCallback(
    (conditions?: Array<Types.ConditionsType>) => {
      dispatch(
        getInvoiceCorrespondence({
          conditions,
          page,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
          include_lookups: true,
          use_display_id: true,
        })
      );
    },
    [dispatch, page]
  );

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  useEffect(() => {
    if (!userInfo) return;

    Promise.all([
      dispatch(
        getSelectManager({
          page,
          per_page: PER_PAGE,
        })
      ),
      dispatch(
        getSelectCompany({
          page,
          per_page: PER_PAGE,
        })
      ),
    ]);
  }, [dispatch, page, userInfo]);

  useEffect(() => fetchInvoiceCorrespondence([]), [fetchInvoiceCorrespondence]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <HeaderDashboard title={headerTitle} />
      <InvoiceCorrespondenceStyled isEmpty={!invoiceCorrespondence.length}>
        <div className="wrap-nav">
          <p className="text-note">
            法人ユーザーで請求方法を請求書送付ご希望の方への専用アカウント登録要URL発行と
            <br />
            発行後の登録状況の管理画面
          </p>
          <div className="wrap-button">
            <button onClick={() => navigate(routes.CorporateUserInformationList.path)}>
              法人ユーザー管理 <RightOutlined className="icon-right-outline" />
            </button>
            <button onClick={() => navigate(routes.FreeTrialManagement.path)}>
              無料トライアル管理 <RightOutlined className="icon-right-outline" />
            </button>
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="account_registration_status"
                className="item"
                label={<span className="text-label">アカウント登録状況</span>}
              >
                <SelectField
                  name="account_registration_status"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">登録済み</Option>
                  <Option value="0">未登録</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_name"
                className="item"
                label={<span className="text-label">法人名</span>}
              >
                <SelectField
                  name="company_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectCompany?.map(({ company_name }) => (
                    <Option value={company_name}>{company_name}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="manager_name"
                className="item"
                label={<span className="text-label">ご担当者氏名</span>}
              >
                <SelectField
                  name="manager_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectManager?.map(({ manager_name, email }) => (
                    <Option value={email}>{manager_name}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="email"
                className="item"
                label={<span className="text-label">メールアドレス</span>}
              >
                <SelectField
                  name="email"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {selectManager?.map(({ email }) => (
                    <Option value={email}>{email}</Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              {/*<span className="label-reset" onClick={handleResetForm}>*/}
              <span className="label-reset" onClick={formik.handleReset}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-body">
          <div className="wrap-text-count">
            <div className="text-count">
              {page * perPage > invoiceCorrespondence.length
                ? invoiceCorrespondence.length
                : page * perPage}
              <span className="text-static"> 件表示</span> /{invoiceCorrespondence.length}
              <span className="text-static"> 名</span>
              <div className="select-perpage">
                <div className="label">
                  <span>表示件数</span>：
                </div>
                <Select defaultValue={perPage} onChange={handleSelectChange}>
                  {[10, 20, 50, 100].map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="wrap-group-button">
              <button
                className="btn-control-number"
                onClick={() => setVisibleExportFile(!visibleExportFile)}
              >
                <span className="text">エクスポート</span>
              </button>
              <button
                className="btn-control-file"
                onClick={() => {
                  setVisibleEditInvoice(!visibleEditInvoice);
                  setContent('URLの送信が完了しました');
                }}
              >
                <span className="text">＋ 請求書対応用URL発行</span>
              </button>
            </div>
          </div>
          <div className="wrap-table">
            <Table
              className="table"
              rowClassName="border-hight-light"
              dataSource={invoiceCorrespondence}
              columns={columnsSecond}
              pagination={{
                pageSize: perPage,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                position: ['topCenter', 'bottomCenter'],
              }}
              rowKey={(record) => JSON.stringify(record)}
            />
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
        />
        <ModalCreateEditInfoInvoice
          type="create"
          visible={visibleEditInvoice}
          setVisible={setVisibleEditInvoice}
          setVisibleComplete={setVisibleCompleteEditInvoice}
        />
        <ModalCreateEditInfoInvoice
          type="edit"
          visible={visibleEditInvoiceControl}
          setVisible={setVisibleEditInvoiceControl}
          setVisibleComplete={setVisibleCompleteEditInvoice}
        />
        <ConfirmDeleteModal
          visible={visibleModalDelete}
          setVisible={setVisibleModalDelete}
          onSubmit={() => {
            handleDeleteInvoiceCorrespondence(idSelected);
            setModalCompleteDelete(true);
          }}
        />
        <CompletedModal
          title={content || ''}
          setVisible={setVisibleCompleteEditInvoice}
          visible={visibleCompleteEditInvoice}
          onSubmit={() => {
            fetchInvoiceCorrespondence();
            setVisibleEditInvoiceControl(false);
            setVisibleEditInvoice(false);
          }}
        />
        <CompletedDeleteModal
          visible={visibleModalCompleteDelete}
          setVisible={setModalCompleteDelete}
          onSubmit={() => {
            setModalCompleteDelete(false);
            fetchInvoiceCorrespondence();
          }}
        />
        <ResendRegistration visible={visibleResend} setVisible={setVisibleResend} />
      </InvoiceCorrespondenceStyled>
    </>
  );
};

export default InvoiceCorrespondence;
