import styled from 'styled-components';

export const Wrapper = styled.div`
  .wrap_node_tree {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    .column {
      width: 16.67%;
      user-select: none;
      border: 3px solid #f2f2f2;
    }

    .column-active {
      background: #eff9fc;
      border-radius: 4px;
      border-color: #ffffff;
    }
  }
`;
