import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import { maxBy } from 'lodash';
import { Select } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CaretLeftOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FilterOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { curriculumExportDataCSV, getDataUserSetting, getCurriculum } from '../thunk';
import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { ItemMoveCopySelectedType, UserTreeviewType } from 'types/services/curriculum';
import { removeNodeLevel4Selected } from 'containers/Curriculum/Search/slice';
import { menuRightUserSelector } from 'containers/MenuRightUser/selectors';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import { HEADER_CURRICULUM_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { getQuestionSearch } from 'containers/Curriculum/Search/thunk';
import CreateQuestion from 'pages/QuestionMaster/Modal/CreateQuestion';
import { setFilterConditions, clearFilterConditions } from '../slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import NodeTreeView from 'containers/Curriculum/NodeTreeView';
import UserTreeView from 'containers/Curriculum/UserTreeView';
import SearchCurriculum from 'containers/Curriculum/Search';
import { CreateEditCurriculum, UploadCSV } from '../Modal';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { LIST_LABEL, LIST_TAB_BAR } from 'constant';
import { ErrorBoundary, Header } from 'components';
import { curriculumSelector } from '../selectors';
import { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import Wrapper, { Button } from './styles';
import { useAppDispatch } from 'hooks';
import { SortByDesc } from 'assets';
import * as Types from 'types';

const { Option } = Select;

type Props = {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
};

const Treeview: React.FC<Props> = ({ setOpenCurriculumMaster }) => {
  const [itemMoveCopySelected, setItemMoveCopySelected] = useState<ItemMoveCopySelectedType>();
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showConfirmImportFileModal, setShowConfirmImportFileModal] = useState<boolean>(false);
  const [openModalCreateCurriculum, setOpenModalCreateCurriculum] = useState<boolean>(false);
  const [curriculumSelected, setCurriculumSelected] = useState<UserTreeviewType>();
  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [isOpenMenuRight, setOpenMenuRight] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);
  const [tabActive, setTabActive] = useState<number>(0);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);
  const menuRightUser = useSelector(menuRightUserSelector);
  const { userInfo } = useSelector(authSelector);

  const { curricullum, data_user_setting, filter_conditions, loading } =
    useSelector(curriculumSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangeFilter = (
    type?: keyof typeof CurriculumType,
    status?: keyof typeof CurriculumStatus
  ) => {
    if (type) {
      dispatch(
        setFilterConditions({
          type,
        })
      );
    }
    if (status) {
      dispatch(
        setFilterConditions({
          status,
        })
      );
    }
  };

  const handleChangeCurriculumName = (value: string) => {
    dispatch(
      setFilterConditions({
        name: value,
      })
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 56) {
      setPageYOffset(56);
    } else {
      setPageYOffset(window.pageYOffset);
    }
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        curriculumExportDataCSV({
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );
      if (curriculumExportDataCSV.fulfilled.match(resultAction)) {
        const listCsv: Array<Types.ItemExport> =
          resultAction.payload.report_results.length > 0
            ? resultAction.payload.report_results.map((item: Types.ItemExport) => ({
                flag: item.flag,
                curriculum_code: item.curriculum_code,
                curriculum_name: item.curriculum_name,
                curriculum_description: item.curriculum_description,
                level1_name: item.level1_name,
                level1_code: item.level1_code,
                level2_name: item.level2_name,
                level2_code: item.level2_code,
                level3_name: item.level3_name,
                level3_code: item.level3_code,
                level4_name: item.level4_name,
                level4_code: item.level4_code,
                question_name: item.question_name,
                question_code: item.question_code,
                question_description: item.question_description,
                question_attach: item.question_attach,
                question2_attach: item.question2_attach,
                question3_attach: item.question3_attach,
                problems1: item.problems1,
                problems2: item.problems2,
                problems3: item.problems3,
                problems1_attach: item.problems1_attach,
                problems2_attach: item.problems2_attach,
                problems3_attach: item.problems3_attach,
                answer: item.answer,
                comment: item.comment,
                problems1_attach_fileID: item.problems1_attach_fileID,
                problems2_attach_fileID: item.problems2_attach_fileID,
                problems3_attach_fileID: item.problems3_attach_fileID,
                time_limit: item.time_limit,
                score: item.score,
              }))
            : [];
        const csvString = [
          HEADER_CURRICULUM_CSV.map(({ label }) => label),
          ...listCsv.map((item: any) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, 'data_curricullum_export.csv');
      }
    }
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getDataUserSetting({
            include_lookups: true,
            include_item_ref: true,
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, filter_conditions?.conditions, userInfo, tabActive]);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (nodeLevel4Selected || curriculumSelected) {
      setOpenMenuRight(true);
    }
  }, [nodeLevel4Selected, curriculumSelected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      dispatch(removeNodeLevel4Selected());
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearFilterConditions());
    };
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading || menuRightUser.loading);
  }, [loading, menuRightUser.loading]);

  return (
    <Wrapper
      isOpenMenuRight={tabActive !== 1 && isOpenMenuRight}
      collapsedMenu={collapsedMenu}
      tabActive={tabActive}
    >
      <Header title={headerTitle} className="header">
        <form className="form">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              className="select-input"
              placeholder="ステータス"
              value={filter_conditions.status}
              onChange={(value: keyof typeof CurriculumStatus) =>
                handleChangeFilter(undefined, value)
              }
            >
              {/* {CURRICULUM_TYPE.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))} */}
              <Option value="publish">公開中</Option>
              <Option value="edit">編集中</Option>
            </Select>
          </div>
          <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
          <div className="form-input">
            <Select
              showSearch
              className="select-input"
              placeholder="指定のカリキュラムを上位表示"
              value={filter_conditions.name}
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChangeCurriculumName}
            >
              {curricullum.map((curr, index) => (
                <Option key={index} value={curr.i_id}>
                  {curr.name}
                </Option>
              ))}
            </Select>
          </div>
          <button
            type="button"
            className="text-reset"
            onClick={() => dispatch(clearFilterConditions())}
          >
            クリア
          </button>
        </form>
      </Header>
      <div className="wrap-title">
        <div className="wrap-button">
          <div>
            {LIST_TAB_BAR.map((item, index) => (
              <Button
                key={index}
                tabActive={tabActive}
                index={index}
                onClick={() => {
                  setTabActive(index);
                  setOpenMenuRight(false);
                  setColumnClosed(undefined);
                  dispatch(removeNodeLevel4Selected());
                  if (itemMoveCopySelected) {
                    setItemMoveCopySelected(undefined);
                  }
                }}
              >
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {item}
              </Button>
            ))}
          </div>
          <div className="button-function">
            <button
              className="btn btn-outline"
              onClick={() => navigate(routes.PublicManagement.path)}
            >
              公開管理
              <RightOutlined className="size-icon-down-outline" />
            </button>
            <button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(true)}>
              階層リスト
              <RightOutlined className="size-icon-down-outline" />
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmImportFileModal(true)}>
              <CloudUploadOutlined className="size-icon" />
              インポート
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </button>
          </div>
        </div>
        <div className="flex-label">
          {tabActive !== 2 ? (
            LIST_LABEL.map((item, index) => (
              <p
                key={index}
                className={`label-text${index === columnClosed ? ' active' : ''}`}
                onClick={() =>
                  setColumnClosed((prevState) =>
                    prevState === index || index > 4 ? undefined : index
                  )
                }
              >
                {index < 5 ? (
                  index === columnClosed ? (
                    <CaretUpOutlined className="icon-label" />
                  ) : (
                    <CaretDownOutlined className="icon-label" />
                  )
                ) : null}
                {item}
              </p>
            ))
          ) : (
            <p
              className="label-text"
              onClick={() => setColumnClosed((prevState) => (prevState === 0 ? undefined : 0))}
            >
              {columnClosed === 0 ? (
                <CaretUpOutlined className="icon-label" />
              ) : (
                <CaretDownOutlined className="icon-label" />
              )}
              必修カリキュラム
            </p>
          )}
        </div>
        {tabActive !== 1 && (
          <div className={`setting-border ${pageYOffset >= 56 ? 'on-top' : ''}`}>
            <div className="border-green" />
            <div className="setting" onClick={() => setOpenMenuRight(!isOpenMenuRight)}>
              <div className="title">
                <CaretLeftOutlined className={isOpenMenuRight ? 'opened' : ''} />
                <p>設 問 リ ス ト</p>
              </div>
            </div>
          </div>
        )}
        {tabActive === 0 && (
          <p className="title-add">
            {curricullum?.length === 0 && 'カリキュラムがまだ登録されていません。'}
            <button className="btn-add" onClick={() => setOpenModalCreateCurriculum(true)}>
              + カリキュラム追加
            </button>
          </p>
        )}
      </div>
      <div className="flex">
        <div className="dashboard">
          <div className="wrap-body">
            {tabActive === 1 && (
              <div className="wrap-title-tab-1">
                <p className="title">
                  {itemMoveCopySelected ? (
                    <>
                      Step.2　ペースト先の階層を選択してください
                      <span
                        className="sub-title"
                        onClick={() => setItemMoveCopySelected(undefined)}
                      >
                        やり直す
                      </span>
                    </>
                  ) : (
                    'Step.1　移動 or コピーしたいアイテムを選択してください。'
                  )}
                </p>
              </div>
            )}
            {tabActive < 2
              ? curricullum.map((c, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${index < curricullum?.length - 1 ? 'bordered' : ''}`}
                  >
                    <NodeTreeView
                      treeData={c}
                      treeViewIndex={index}
                      tabActive={tabActive}
                      columnClosed={columnClosed}
                      itemMoveCopySelected={itemMoveCopySelected}
                      setItemMoveCopySelected={setItemMoveCopySelected}
                    />
                  </div>
                ))
              : data_user_setting?.map((u, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${
                      index < data_user_setting?.length - 1 ? 'bordered' : ''
                    }`}
                  >
                    <UserTreeView
                      treeData={u}
                      columnClosed={columnClosed}
                      curriculumSelected={curriculumSelected}
                      setCurriculumSelected={setCurriculumSelected}
                    />
                  </div>
                ))}
          </div>
        </div>
        {isOpenMenuRight ? (
          <SearchCurriculum
            pageYOffset={pageYOffset}
            setOpenModalCreateQuestion={setOpenModalCreateQuestion}
          />
        ) : null}
      </div>
      <CreateEditCurriculum
        title="カリキュラム新規作成"
        maxSortOrder={(maxBy(curricullum, (o) => o.sort_order)?.sort_order || 0) + 1}
        subTitle={
          <>
            カリキュラム名・説明の新規作成が可能です。
            <br />
            入力後、新規作成ボタンをクリックしてください。
          </>
        }
        textSubmit="新規作成"
        type="create"
        visible={openModalCreateCurriculum}
        setVisible={setOpenModalCreateCurriculum}
      />
      <ErrorBoundary>
        <CreateQuestion
          page={1}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
          fetchData={() => {
            dispatch(
              getQuestionSearch({
                conditions: [{ id: 'company_id', search_value: [userInfo?.company_id] }],
                page: 1,
                per_page: 0,
                include_item_ref: true,
              })
            );
          }}
        />
      </ErrorBoundary>
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <UploadCSV visible={showConfirmImportFileModal} setVisible={setShowConfirmImportFileModal} />
    </Wrapper>
  );
};

export default Treeview;
