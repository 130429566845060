import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
}

export default styled.section<Props>`
  position: relative;
  .text-count {
    position: absolute;
    top: -30px;
    right: 0;
  }
  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-cell {
      text-align: center;
      font-size: 13px;
    }
    border: 1px solid #dddddd;
    .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row-level-1 > td {
      display: ${(props) => (props.visibleExpansionOfSale ? '' : 'none')};
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      border-top: 1px solid #eeeeee;
    }
    .ant-table-tbody .ant-table-row .ant-table-cell {
      border-left: 1px solid rgba(85, 85, 85, 0.08);
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
      .ant-table-cell {
        &:nth-child(1) {
          &:before {
            display: ${(props) => (!props.visibleExpansionOfSale ? '' : 'none')};
          }
        }
      }
    }
    .ant-table-tbody > tr.ant-table-row-level-1.child > td {
      border-bottom: 1px dashed #eeeeee;
      border-top: 1px dashed #eeeeee;
    }
    .ant-table-tbody > tr.ant-table-row-level-1.child > td.ant-table-cell-with-append {
      border: none;
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td.ant-table-cell-with-append {
      border-bottom: ${(props) => (!props.visibleExpansionOfSale ? '1px solid #eeeeee' : 'none')};
    }
    .ant-table-tbody {
      .ant-table-cell {
        &:nth-child(2) {
          border-left: ${(props) => (!props.visibleExpansionOfSale ? '1px solid #eeeeee' : 'none')};
        }
      }
    }
  }
`;
