import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getDataAdminDetails, getDataSearchUsers, updateInformation } from './thunk';
import { AdministratorMasterFormik } from 'types';
import * as Types from 'types';

export type InitialState = {
  information: AdministratorMasterFormik;
  dataAdminDetail: Array<any>;
  dataUser: Array<Types.Users.ResponseType>;
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  information: {
    name: '',
    name_furigana: '',
    postal_code: '',
    prefectures: '',
    municipalities: '',
    address: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_department: '',
    admin_position: '',
    admin_phone: '',
    admin_email: '',
    admin_email_confirm: '',
  },
  dataAdminDetail: [],
  dataUser: [],
  loading: false,
  total: 0,
};

export const administratorMasterSlice = createSlice({
  name: 'administratorMasterSlice-page',
  initialState,
  reducers: {
    setInformationRegister(state, action: PayloadAction<AdministratorMasterFormik>) {
      state.information = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataAdminDetails.pending, startLoading)
      .addCase(updateInformation.pending, startLoading);

    builder.addCase(getDataAdminDetails.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        i_id: action.payload.report_results[0].i_id,
        name: action.payload.report_results[0].name,
        name_furigana: action.payload.report_results[0].name_furigana,
        postal_code: action.payload.report_results[0].postal_code,
        prefectures: action.payload.report_results[0].prefecture,
        municipalities: action.payload.report_results[0].plot_number,
        address: action.payload.report_results[0].address,
        building_name: action.payload.report_results[0].building_name,
        admin_name: action.payload.report_results[0].admin_name,
        admin_name_furigana: action.payload.report_results[0].admin_name_furigana,
        admin_department: action.payload.report_results[0].admin_department,
        admin_position: action.payload.report_results[0].admin_position,
        admin_phone: action.payload.report_results[0].admin_phone,
        admin_email: action.payload.report_results[0].admin_email,
        admin_email_confirm: action.payload.report_results[0].admin_email,
      };
      state.dataAdminDetail = action.payload.report_results;
      stopLoading(state);
    });

    builder.addCase(getDataSearchUsers.fulfilled, (state, action) => {
      state.dataAdminDetail = action.payload.items;
      stopLoading(state);
    });

    builder
      .addCase(getDataAdminDetails.rejected, stopLoading)
      .addCase(updateInformation.rejected, stopLoading);
  },
});

export const { setInformationRegister } = administratorMasterSlice.actions;

export default administratorMasterSlice.reducer;
