import styled from 'styled-components';

type Props = {
  collapseMenu: boolean;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  min-width: 1440px;
  overflow-x: auto;
  .ant-layout {
    background: none;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    position: sticky;
    transition: all 0.2s;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
  }
  .sider {
    .ant-menu-inline-collapsed .ant-menu-item-selected .anticon {
      color: #ffffff !important;
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title .anticon {
      color: #ffffff !important;
    }
    min-height: 100vh;
    background-color: #46433c;
    border-right: 1px solid #e5e5e5;
    font-family: unset;
    ::-webkit-scrollbar {
      display: none;
    }
    .wrap-logo {
      display: flex;
      justify-content: center;
      user-select: none;
      background: #ffffff;
      .image-logo {
        width: 50px;
        margin: 10px 0 68px 0;
        cursor: pointer;
      }
      .image-logo-large {
        width: 100px;
        margin: 8px auto 46px;
        transition: width 0.3s;
        cursor: pointer;
      }
    }
    .site-layout-background {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 0 10px;
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffffff;
      color: #cccccc;
      .trigger {
        font-size: 20px;
      }
      .anticon-menu-unfold.trigger {
        display: block;
        margin: 0 auto;
      }
    }
    .ant-menu {
      font-size: 12px;
      border-right: unset;
      background-color: #46433c;
      .ant-menu-item {
        margin-bottom: 8px !important;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        margin: 0;
        color: #ffffff;
        font-weight: 400;
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.5);
        }
        .item-icon {
          display: flex;
          align-items: center;
          .icon {
            width: 18px !important;
            height: 18px;
          }
        }
        .title-submenu {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            width: 10px;
            height: 10px;
          }
        }
        .official-text {
          width: 80%;
          display: grid;
          line-height: 20px;
        }
        &:hover {
          font-weight: 700;
          .anticon {
            color: #ffffff;
          }
        }
        .ant-menu-item-icon {
          width: 15%;
          font-size: 18px !important;
        }
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: #ffffff;
          font-weight: 700;
        }
        .anticon {
          color: #ffffff;
        }
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
      }

      .ant-menu-item-selected {
        background-color: rgba(233, 224, 203, 0.15);
        font-weight: 700;
        .anticon,
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
        &::after {
          border-left: 4px solid #08a3a5;
          border-right: unset;
          left: 0;
        }
      }

      .ant-menu-submenu-selected {
        font-weight: 700;
        .ant-menu-submenu-title {
          .anticon,
          .ant-menu-submenu-arrow {
            color: #ffffff;
          }
          &:after {
            border-left: 4px solid #08a3a5;
            border-right: unset;
            left: 0;
          }
        }
      }

      .sub-menu-setting {
        .ant-menu-item-group-title {
          padding: 0 34px 0 24px;
          font-weight: 700;
          color: #ffffff;
          font-size: 12px;
          .anticon {
            font-size: 18px;
            vertical-align: middle;
            margin-top: -2px;
          }
        }
      }

      .collapse {
        .ant-menu-item-group-title {
          text-align: center;
          padding: 0;
        }
        .sub-menu {
          color: #aaaaaa;
        }
      }
    }
    .ant-menu-inline-collapsed {
      .ant-menu-item,
      .ant-menu-submenu {
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.8);
        }
        &:hover {
          .anticon {
            color: #ffffff;
          }
        }
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        .anticon {
          color: #424242;
        }
      }

      .ant-menu-item-selected {
        .anticon,
        .ant-menu-submenu-arrow {
          color: #424242;
        }
        &::after {
          transform: scaleY(1);
          opacity: 1;
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          content: '';
        }
        .ant-menu-submenu-title {
          .anticon,
          .ant-menu-submenu-arrow {
            color: #424242;
          }
          &::after {
            transform: scaleY(1);
            opacity: 1;
          }
        }
      }
    }
  }
`;
