import React, { useEffect, useState } from 'react';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import {
  CloudUploadOutlined,
  CaretLeftOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { getArchiveList, getOfficialPublish, updateOfficialCurriculumMaster } from '../../thunk';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Modal, SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import { StopPublic } from 'assets';
import * as Types from 'types';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const { Option } = Select;
const PER_PAGE = 10;

const ArchiveList: React.FC<Props> = ({ visible, setVisible }) => {
  const [itemUpdate, setItemUpdate] =
    useState<Types.OfficialCurriculumPublishingSettings.ResponseType>();
  const [visibleDeleteComplete, setVisibleDeleteComplete] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visibleReturn, setVisibleReturn] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const { archiveList, loading } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.ArchiveListSearchFormik>({
    initialValues: {
      official_curriculum_code: '',
      official_curriculum_name: '',
      publish: '',
      createdby: '',
    },
    onSubmit: (values) => {
      if (userInfo) {
        const conditions: Types.ConditionsType[] = [];
        Object.keys(values).forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [values[key as keyof typeof values]],
            });
          }
          dispatch(
            getArchiveList({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'archive_flg',
                  search_value: ['0'],
                },
                ...conditions,
              ],
              page: 1,
              per_page: 0,
              include_item_ref: true,
            })
          );
        });
      }
    },
    onReset: () => {
      dispatch(
        getArchiveList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'archive_flg',
              search_value: ['1'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          omit_total_items: false,
        })
      );
    },
  });
  const dispatch = useAppDispatch();

  const handleUpdateArchive = async () => {
    if (!itemUpdate || !userInfo) return;
    await dispatch(
      updateOfficialCurriculumMaster({
        id: itemUpdate.i_id,
        data: {
          item: {
            archive_flag: 0,
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );
    dispatch(
      getArchiveList({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'archive_flg',
            search_value: ['1'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
      })
    );
    dispatch(
      getOfficialPublish({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'archive_flg',
            search_value: ['0'],
            exact_match: true,
          },
        ],
        page: page,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
    setCompleted(true);
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    setVisibleDelete(false);
  };

  const columns: ColumnsType<Types.OfficialCurriculumPublishingSettings.ResponseType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '7%',
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'official_curriculum_name',
      key: 'official_curriculum_name',
      width: '27%',
    },
    {
      title: '公開ステータス',
      dataIndex: 'public',
      key: 'public',
      align: 'center',
      render: (record: string) => (
        <div className="wrap-status">
          <img src={StopPublic} alt="" />
          <span className="status">公開停止中</span>
        </div>
      ),
    },
    {
      title: '前回公開日',
      dataIndex: 'last_published_date',
      key: 'last_published_date',
    },
    {
      title: '最終更新日',
      dataIndex: 'last_updated',
      key: 'last_updated',
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
    },
    {
      title: '公開管理に戻す',
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, item) => (
        <Button
          className="button-return"
          onClick={() => {
            setItemUpdate(item);
            setVisibleReturn(true);
          }}
        >
          <CaretLeftOutlined
            style={{
              color: '#ffffff',
            }}
          />
          <span>戻す</span>
        </Button>
      ),
    },
    {
      title: '削除',
      dataIndex: '',
      key: 'delete',
      width: '5%',
      align: 'center',
      render: () => (
        <DeleteOutlined
          style={{
            fontSize: 20,
            color: '#c4c4c4',
          }}
          onClick={() => setVisibleDelete(true)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (visible && userInfo) {
      dispatch(
        getArchiveList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'archive_flg',
              search_value: ['1'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          omit_total_items: false,
        })
      );
    }
  }, [visible, dispatch, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title="アーカイブリスト"
      visible={visible}
      width={1500}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #EAEAEA',
      }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Styled isEmptyData={!archiveList.length}>
        <div className="container">
          <p className="description">アーカイブしたOFFICIALカリキュラムの管理を行う画面です。</p>
          <div className="line" />
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="official_curriculum_code"
                  className="item"
                  label="OFFICIALカリキュラムID"
                >
                  <SelectField name="official_curriculum_code" placeholder="指定なし">
                    {archiveList?.map(({ official_curriculum_code }) => (
                      <Option value={official_curriculum_code} key={official_curriculum_code}>
                        {official_curriculum_code}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="official_curriculum_name"
                  className="item"
                  label="OFFICIALカリキュラム名"
                >
                  <SelectField name="official_curriculum_name" placeholder="指定なし">
                    {archiveList?.map(({ official_curriculum_name }) => (
                      <Option value={official_curriculum_name} key={official_curriculum_name}>
                        {official_curriculum_name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="publish" className="item" label="公開ステータス">
                  <SelectField name="publish" placeholder="指定なし">
                    {archiveList.map(({ publish }) => (
                      <Option value={`${publish}`} key={publish}>
                        {publish === 0
                          ? '編集中'
                          : publish === 1
                          ? '未公開'
                          : publish === 2
                          ? '公開中'
                          : '公開停止中'}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="createdby" className="item" label="作成者">
                  <SelectField name="createdby" placeholder="指定なし">
                    {archiveList.map(
                      ({ createdby }) =>
                        createdby && (
                          <Option value={createdby} key={createdby}>
                            {createdby}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <div className="button-search">
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </div>
            </Form>
            <div className="wrap-button">
              <Button className="btn btn-active" icon={<CloudUploadOutlined className="icon" />}>
                インポート
              </Button>
            </div>
            <Table
              key="index"
              className="table"
              loading={loading}
              dataSource={archiveList.map((item, index) => ({ ...item, index }))}
              columns={columns}
              pagination={{
                onChange: setPage,
                pageSize: 10,
                total: 20,
                showTotal: () => (
                  <span className="text-count">
                    {page * PER_PAGE > archiveList.length ? archiveList.length : page * PER_PAGE}
                    件表示 /{archiveList.length} 名
                  </span>
                ),
                position: ['topCenter'],
              }}
              scroll={{
                y: '35vh',
              }}
              rowSelection={{
                type: 'checkbox',
              }}
            />
          </FormikProvider>
        </div>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したOFFICIALカリキュラムを処理：
            <div className="wrap-bottom-button">
              <Button className="btn btn-active" onClick={() => setVisibleReturn(true)}>
                公開管理に戻す
              </Button>
              <Button className="btn btn-outline" onClick={() => setVisibleDelete(true)}>
                削除
              </Button>
            </div>
          </div>
          <Button className="btn-cancle" onClick={() => handleToggleModal()}>
            閉じる
          </Button>
        </div>
        <ConfirmDeleteModal
          title="OFFICIALカリキュラム削除"
          subTitle="選択したOFFICIALカリキュラムを削除します"
          description="※削除を実行すると、元に戻せませんのでご注意ください。"
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onCancel={() => handleDelete()}
          onSubmit={() => setVisibleDeleteComplete(true)}
        />
        <ConfirmDeleteModal
          title="公開管理に戻す"
          subTitle="選択したOFFICIALカリキュラムを公開管理に戻します。"
          visible={visibleReturn}
          setVisible={setVisibleReturn}
          onCancel={() => setVisibleReturn(false)}
          onSubmit={() => {
            handleUpdateArchive();
            setVisibleReturn(false);
          }}
        />
        <Completed
          title="公開管理に戻しました。"
          visible={completed}
          setVisible={setCompleted}
          onSubmit={() => setCompleted(false)}
        />
        <DeleteCompleted visible={visibleDeleteComplete} setVisible={setVisibleDeleteComplete} />
      </Styled>
    </Modal>
  );
};

export default ArchiveList;
