import * as Types from 'types';
import {
  convertDataFromTree,
  getFlatDataFromTree,
  getChildrenItemIDFromTree,
  convertDataFromImportFile,
} from './tree-data-utils';

const memoize = (f: Function): Function => {
  let savedArgsArray: any[] = [];
  let savedKeysArray: string[] = [];
  let savedResult: Types.TreeItem<Types.CurriculumItemType> | null = null;

  return (
    args: Types.FlatDataFromTreeType<Types.CurriculumItemType>
  ): Types.TreeItem<Types.CurriculumItemType> | null => {
    const keysArray = Object.keys(args).sort();
    const argsArray = keysArray.map((key) => args[key as keyof typeof args]);

    if (
      argsArray.length !== savedArgsArray.length ||
      argsArray.some((arg, index) => arg !== savedArgsArray[index]) ||
      keysArray.some((key, index) => key !== savedKeysArray[index])
    ) {
      savedArgsArray = argsArray;
      savedKeysArray = keysArray;
      savedResult = f(args);
    }

    return savedResult;
  };
};

export const memoizedGetFlatDataFromTree = memoize(getFlatDataFromTree);
export const memoizedConvertFlatDataFromTree = memoize(convertDataFromTree);
export const memoizedGetChildrenItemIDFromTree = memoize(getChildrenItemIDFromTree);
export const memoizedConvertFlatDataFromImportFile = memoize(convertDataFromImportFile);
