import styled from 'styled-components';

export default styled.div`
  .wrap-image {
    display: flex;
    justify-content: center;
    background: #ebebeb;
    padding-top: 20px;
  }
  .image {
    height: 290px;
  }
  .wrap-content {
    background: #f9f8f8;
    padding: 16px 40px 104px 40px;
    .producer {
      text-align: right;
      .label-producer {
        color: #777777;
        font-size: 12px;
      }
      .name-producer {
        color: #424242;
        font-size: 12px;
        text-decoration: underline;
      }
    }
    .wrap-common {
      margin-top: 28px;
      padding-bottom: 12px;
      border-bottom: 1px solid #cccccc;
      .common {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .text-common {
          color: #999999;
          font-size: 15px;
        }
        .version {
          color: #999999;
          font-size: 12px;
        }
      }
    }
    .wrap-description {
      margin-top: 20px;
      .label-area {
        color: #777777;
        font-size: 12px;
        margin-bottom: 3px;
      }
      .wrap-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .button {
        width: 120px;
        height: 28px;
        color: #08a3a5;
        border: 1px solid #08a3a5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-weight: bold;
      }
      .text-area {
        height: 260px;
        border-radius: 3px;
      }
    }
  }
`;
