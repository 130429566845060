import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { Form, SubmitButton } from 'formik-antd';
import { maxBy, padEnd, split } from 'lodash';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Button, Radio } from 'antd';

import {
  createReleaseNoteSection,
  getDataReleaseNoteID,
  createReleaseNote,
  getDataAdminReleaseNoteManagement,
  createFileAttach,
} from '../../thunk';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import ExecuteDelete from '../ExecuteDelete';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import ManageReleaseStyled from './styles';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import * as Types from 'types';
import { uploadFileToMinIO } from 'services/minioService';

interface Props {
  selected?: Types.AdminReleaseNoteManagement.ResponseType;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteReleaseNew: React.FC<Props> = ({ visible, setVisible, publish, selected }) => {
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleDeleted, setVisibleDeleted] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);

  const { userInfo } = useSelector(authSelector);

  const { dataReleaseNoteID, releaseNoteDetail, loading } = useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const maxCurriculumCode = useMemo(() => {
    const itemMaxCode =
      maxBy(dataReleaseNoteID, (e) => Number(split(e.release_note_id, 'RA').join(''))) ?? '';

    if (itemMaxCode) {
      const number = Number(split(itemMaxCode.release_note_id, 'RA').join(''));
      const subString = Array.from({
        length: 7 - (number + 1).toString().length,
      })
        .map((e) => '0')
        .concat((number + 1).toString())
        .join('');

      return padEnd('RA', 9, subString.toString());
    }
    return 'RA0000001';
  }, [dataReleaseNoteID]);

  const fetchDataReleaseNoteID = useCallback(() => {
    if (userInfo) {
      dispatch(
        getDataReleaseNoteID({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
    }
  }, [userInfo, dispatch]);

  const fetchDataReleaseNoteManagement = () => {
    if (!selected) return;
    dispatch(
      getDataAdminReleaseNoteManagement({
        conditions: [
          {
            id: 'provider_id',
            search_value: [selected.provider_id],
          },
          {
            id: 'official_curriculum_code',
            search_value: [selected.official_curriculum_code],
          },
        ],
        sort_fields: [
          {
            id: 'publish_start_date',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
  };

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: {
      title: '',
      description: '',
      version: 1.0,
      official_curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (userInfo && selected) {
        const resultAction = await dispatch(
          createReleaseNote({
            item: {
              company_id: userInfo.company_id,
              release_note_title: values.title,
              curriculum_publish_history_id: selected.history_id,
              curriculum_code: selected.official_curriculum_code,
              publish_status: 0,
              version: Number(selected.release_version),
              description: values.description,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );
        await Promise.all(
          formik.values.sections?.map((value, index) => {
            if (createReleaseNote.fulfilled.match(resultAction)) {
              return dispatch(
                createReleaseNoteSection({
                  item: {
                    company_id: userInfo.company_id,
                    section_name: value.section_name,
                    release_note_id: resultAction.payload?.item?.release_note_id,
                    text: value.text,
                    display_order: index,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                  realtime_auto_link: true,
                })
              );
            }
          })
        );
        await fetchDataReleaseNoteManagement();
        fetchDataReleaseNoteID();
        setVisibleComplete(true);
      }
    },
  });

  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleUpdateStatus = async () => {
    if (!userInfo || !selected) return;

    const resultAction = await dispatch(
      createReleaseNote({
        item: {
          company_id: userInfo.company_id,
          release_note_title: formik.values.title,
          curriculum_publish_history_id: selected.history_id,
          curriculum_code: selected.official_curriculum_code,
          publish_status: 2,
          version: Number(selected.release_version),
          description: formik.values.description,
          createdby: userInfo.login_id,
          createdat: new Date(),
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );
    await Promise.all(
      formik.values.sections?.map((value, index) => {
        if (createReleaseNote.fulfilled.match(resultAction)) {
          return dispatch(
            createReleaseNoteSection({
              item: {
                company_id: userInfo.company_id,
                section_name: value.section_name,
                release_note_id: maxCurriculumCode,
                text: value.text,
                display_order: index,
                createdby: userInfo.login_id,
                createdat: new Date(),
              },
              realtime_auto_link: true,
            })
          );
        }
      })
    );
    await fetchDataReleaseNoteManagement();
    await setVisiblePublished(true);
  };
  useEffect(fetchDataReleaseNoteID, [fetchDataReleaseNoteID]);

  const parser = new DOMParser();
  const doc = parser.parseFromString(formik.values.sections[indexSection]?.text, 'text/html');

  const imgElements = doc.querySelectorAll('img');
  const base64Array = Array.from(imgElements).reduce((acc: Array<string>, img) => {
    if (img.src.startsWith('data:image')) {
      acc.push(img.src);
    }
    return acc;
  }, []);

  const convertBase64ToFile = async (base64Data: string, fileName: string): Promise<File> => {
    const response = await fetch(base64Data);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: 'image/png' });
    return file;
  };

  useEffect(() => {
    (async () => {
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item, i) => {
              const imageSrc = await convertBase64ToFile(item, `file${i}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${i}`,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.sections[indexSection]?.text.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      formik.setFieldValue(`sections.${indexSection}.text`, replacedHTML);
    })();
  }, [dispatch, base64Array.length]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
  }, [visible]);

  return (
    <ManageReleaseStyled
      isEmptyData={!releaseNoteDetail?.sections.length}
      title={<span className="title">リリースノート 新規作成</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      open={visible}
      onCancel={handleToggleModal}
    >
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="information">
            <div>
              <span className="content">ここから始めるプログラミングの基礎</span>
              <span className="id">（ID：{selected?.official_curriculum_code})</span>
            </div>
            <div className="version">
              <div className="wrap-label">
                <Form.Item
                  name="version"
                  className="version-required"
                  label={
                    <label className="label">
                      OFFICIALカリキュラム＆リリースノート公開バージョン
                      <span className="required"> *</span> ：
                    </label>
                  }
                >
                  <Input name="version" />
                  <div className="version-errors">{formik.errors?.version}</div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="while-editing">
            <img
              src={
                !publish
                  ? WhiteEditing
                  : publish === 2
                  ? TickComplete
                  : publish === 1
                  ? UnPublished
                  : StopPublic
              }
              alt=""
            />
            <div className="wrap-input">
              <div className="wrap-input-version">
                <Form.Item className="item" name="title">
                  <Input name="title" placeholder="【タイトル】最大60文字" />
                </Form.Item>
              </div>
            </div>
          </div>
          <FieldArray
            name="sections"
            render={({ remove, push }) => (
              <div className="wrap-editor">
                <div className="left-side">
                  <div className="title-editor">
                    <div className="no-editor">{indexSection + 1}</div>
                    <Input
                      name={`sections.${indexSection}.section_name`}
                      className="input"
                      suffix={null}
                      placeholder="セクション名を入力..."
                    />
                  </div>
                  <JoditEditor
                    value={formik.values.sections[indexSection]?.text}
                    config={defaultConfig}
                    onBlur={(newContent) =>
                      formik.setFieldValue(`sections.${indexSection}.text`, newContent)
                    }
                  />
                  <div className="wrap-bottom-editor">
                    <div className="wrap-button-editor">
                      <Button
                        className="btn button-prev"
                        disabled={indexSection === 0}
                        onClick={() => setIndexSection((prevState) => prevState - 1)}
                      >
                        <CaretLeftOutlined />
                        <span>前のセクション</span>
                      </Button>
                      <Button
                        className="btn button-next"
                        disabled={indexSection === formik.values.sections.length - 1}
                        onClick={() => setIndexSection(indexSection + 1)}
                      >
                        <span>次のセクション</span>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                    <div
                      className="delete-editor"
                      onClick={() => {
                        setVisibleConfirmDelete(true);
                      }}
                    >
                      <DeleteOutlined
                        className="icon-delete"
                        style={{
                          color: '#00989A',
                        }}
                      />
                      <span>このセクションを削除</span>
                    </div>
                  </div>
                  <DeleteCompleted
                    onSubmit={() => {
                      setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                      remove(indexSection);
                    }}
                    visible={visibleDeleted}
                    setVisible={setVisibleDeleted}
                  />
                </div>
                <div className="right-side">
                  <Form.Item name="description" label="概要：" className="text-area">
                    <TextArea name="description" rows={5} placeholder="最大480文字" />
                  </Form.Item>
                  <span className="title-right">セクション</span>
                  <div className="dashed-line"></div>
                  <div className="wrap-radio">
                    <Radio.Group
                      value={indexSection}
                      onChange={(e) => setIndexSection(e.target.value)}
                    >
                      {formik.values.sections.map((_, index) => (
                        <div
                          className={`input-radio ${
                            formik.errors.sections && formik.errors.sections[index] ? 'error' : ''
                          } ${index === indexSection && 'section-checked'}`}
                          key={index}
                        >
                          <span className="label-radio">{index + 1}</span>
                          <Input
                            placeholder="セクション名を入力..."
                            bordered={false}
                            name={`sections.${index}.section_name`}
                            onClick={() => setIndexSection(index)}
                            suffix={<Radio value={index} />}
                          />
                        </div>
                      ))}
                    </Radio.Group>
                  </div>
                  <div className="wrap-button-add">
                    {formik.values.sections.length < 6 && (
                      <Button
                        className="add-section"
                        onClick={() =>
                          push({
                            section_name: '',
                            text: '',
                          })
                        }
                      >
                        ＋ セクションの追加
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
          <div className="wrap-submit">
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn-outline">一時保存</SubmitButton>
                <Button className="btn btn_submit" onClick={() => setVisibleUpdate(true)}>
                  公開
                </Button>
                <Button className="btn btn_close" onClick={() => setVisible(false)}>
                  キャンセル
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <Completed
          title="リリースノートを保存しました。"
          visible={visibleComplete}
          setVisible={setVisibleComplete}
          onSubmit={() => {
            navigate(routes.PublicManagement.path);
            setVisible(false);
          }}
          subTitle={
            <span
              style={{
                color: '#424242',
                fontSize: 13,
              }}
            >
              公開ステータスは未公開として表示されます。
            </span>
          }
        />
        <UpdateStatus
          publish={publish}
          status="unPublished"
          title="リリースノートを公開しま"
          from={UnPublished}
          to={TickComplete}
          description={
            <span>
              リリースノートを公開すると、
              <br /> SKILL FAMILIARで一般のユーザー様が閲覧できる状態になります。
            </span>
          }
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          onSubmit={handleUpdateStatus}
        />
        <Published
          visible={visiblePublished}
          setVisible={setVisiblePublished}
          onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          setVisibleCreated={setVisible}
        />
        <ExecuteDelete
          visible={visibleConfirmDelete}
          setVisible={setVisibleConfirmDelete}
          onSubmit={() => setVisibleDeleted(true)}
        />
      </FormikProvider>
    </ManageReleaseStyled>
  );
};

export default NoteReleaseNew;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  placeholder: '本文を入力...',
  readonly: false,
  enableDragAndDropFileToEditor: true,
  saveSelectionOnBlur: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
  preview: true,
  // readonly: !isEdit && isViewMode,
  extraButtons: {
    name: 'insertVideo',
    icon: 'video',
    tooltip: 'Insert Video',
    // exec: handleExtraButtonClick,
  },
};
