import React, { useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button, Tabs } from 'antd';

import OfficialCurriculumInformation from './OfficialCurriculumInformation';
import { settingSelector } from 'containers/AppSettings/selectors';
import SkillCheckInformation from './SkillCheckInformation';
import UserInformation from './UserInformation';
import SaleInformation from './SaleInformation';
import ReportStyled from './styles';
import { Header } from 'components';

const { TabPane } = Tabs;

const Report: React.FC = () => {
  const [valueTab, setValueTab] = useState('0');
  const { headerTitle } = useSelector(settingSelector);

  const onChange = (key: string) => {
    setValueTab(key);
  };

  return (
    <ReportStyled>
      <Header title={headerTitle} />
      <div className="wrap-tab">
        <Tabs className="tab-container" activeKey={valueTab} onChange={onChange}>
          <TabPane tab="売上情報" key="0" />
          <TabPane tab="ユーザー情報" key="1" />
          <TabPane tab="OFFICIALカリキュラム情報" key="2" />
          <TabPane tab="スキルチェック実施情報" key="3" />
        </Tabs>
        <div className="group-btn">
          <Button className="btn btn-active" icon={<CloudDownloadOutlined className="icon" />}>
            エクスポート
          </Button>
        </div>
      </div>
      {valueTab === '0' ? (
        <SaleInformation />
      ) : valueTab === '1' ? (
        <UserInformation />
      ) : valueTab === '2' ? (
        <OfficialCurriculumInformation />
      ) : (
        <SkillCheckInformation />
      )}
    </ReportStyled>
  );
};

export default Report;
