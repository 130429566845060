import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';

import { Modal, SpinLoading, TextArea } from 'components';
import { updateIndividualUser } from '../thunk';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selected: Types.IndividualUserManagement | undefined;
  setVisibleSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupComment: React.FC<Props> = ({ visible, setVisible, selected, setVisibleSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      comment: selected?.comment || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const updateResult = await dispatch(
        updateIndividualUser({
          id: selected?.item_ref?.email?.i_id!,
          data: {
            item: {
              comment: values.comment,
            },
            is_force_update: true,
          },
        })
      );
      if (updateIndividualUser.fulfilled.match(updateResult)) {
        setVisibleSuccess(true);
        setVisible(false);
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      title="memo"
      width={720}
      open={visible}
      onCancel={() => setVisible(false)}
      okButton={{
        text: '更新',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        width: 720,
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SpinLoading loading={loading}>
        <SectionStyled>
          <FormikProvider value={formik}>
            <Form.Item name="comment">
              <TextArea
                name="comment"
                showCount
                rows={6}
                maxLength={480}
                value={formik.values.comment}
              />
            </Form.Item>
          </FormikProvider>
        </SectionStyled>
      </SpinLoading>
    </Modal>
  );
};

export default PopupComment;
