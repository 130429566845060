import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .wrap-filter {
      .aggregation-conditions {
        .form-search {
          justify-content: start;
          .item {
            width: 160px !important;
            margin-right: 8px;
          }
          .date {
            width: 260px !important;
          }
          .group-btn {
            margin-left: 16px;
            margin-bottom: 16px !important;
            .btn-search {
              width: 80px;
            }
          }
        }
      }
    }
    .sub-container {
      .ant-table-wrapper .ant-table-thead .ant-table-cell {
        padding: 10px !important;
        height: 44px;
      }
      .ant-table-wrapper .ant-table-tbody .ant-table-cell {
        padding: 8px !important;
        height: 40px;
      }
      .wrap-segmented {
        .segmented {
          margin-right: 38px !important;
          .segmented-item {
            width: 80px;
            padding: 6px !important;
          }
        }
      }
      .user-info {
        .body-info {
          .right-side {
            .wrap-header {
              text-align: -webkit-right;
              .wrap-action {
                width: 70%;
                .wrap-select {
                  width: auto;
                }
              }
              .ant-select {
                width: 200px;
              }
              .legend {
                .sort-column {
                  padding: 0;
                  margin-left: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  .container {
    margin: 30px;
    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: 14px;
        color: #424242;
      }
      .button-import {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        border-radius: 5px;
        color: #ffffff;
      }
    }
    .wrap-filter {
      .label {
        font-size: 11px;
        color: #777777;
      }
      .aggregation-conditions {
        background: #ffffff;
        padding: 15px 16px 15px 16px;
        border: 1px solid #eaeaea;
        border-radius: 3px;
        width: 100%;
        .form-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text-label {
            font-size: 13px;
            display: block;
            color: #424242;
            padding-top: 10px;
          }
          .item {
            width: 16%;
          }
          .date {
            width: 21%;
          }
          .group-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 24px;
            .label-reset {
              text-align: center;
              font-size: 12px;
              cursor: pointer;
              text-decoration-line: underline;
              color: #00a3a5;
              margin-bottom: 5px;
            }
            .btn-search {
              background: #08a3a5;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              font-size: 14px;
              color: #ffffff;
              border: none;
              padding: 0 25px;
              cursor: pointer;
              .icon-search {
                font-size: 18px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
    .sub-container {
      margin-top: 25px;
      .wrap-segmented {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .left-side {
          display: flex;
          align-items: center;
          .label {
            font-size: 13px;
            color: #424242;
          }
          .ant-select-selector {
            border-radius: 3px;
          }
          .segmented {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 35px;
            .segmented-item {
              padding: 6px 10px;
              background: #ffffff;
              border: 1px solid #cccccc;
              cursor: pointer;
              color: #424242;
              font-size: 13px;
              .icon {
                margin-right: 10px;
                color: #c4c4c4;
              }
              &:nth-child(1) {
                border-radius: 3px 0 0 3px;
                border-right-color: #08a3a5;
              }
              &:nth-child(2) {
                border-radius: 0 3px 3px 0;
                border-left-width: 0;
              }
            }
            .segmented-item-selected {
              border-color: #08a3a5;
              color: #08a3a5;
              font-weight: bold;
              .icon {
                color: #08a3a5;
              }
            }
          }
          .item-checkbox {
            padding: 5px 10px;
            margin: 0 4px;
            background: #ffffff;
            border: 1px solid #cccccc;
            cursor: pointer;
            color: #424242;
            font-size: 13px;
            border-radius: 3px 3px 0 0;
          }
        }
      }
    }
  }
`;
