import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled, ModalStyled } from './styles';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmDeleteModal: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    setLoading(true);
    onSubmit && (await onSubmit());
    setLoading(false);
    setVisible(false);
  };

  return (
    <ModalStyled
      title="削除確認"
      open={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          データの削除を実行します。
        </p>
        <p className="text-content">※削除を実行すると復元できませんので、ご注意ください。</p>
      </SectionStyled>
    </ModalStyled>
  );
};

export default ConfirmDeleteModal;
