import React, { FC, useContext } from 'react';
import { Page } from 'react-pdf';
import styled from 'styled-components';
import { PDFContext } from '../../state';

interface Props {
  pageNum?: number;
}

const PDFSinglePage: FC<Props> = ({ pageNum }) => {
  const {
    state: { mainState, zoomLevel, numPages, currentPage },
  } = useContext(PDFContext);

  const rendererRect = mainState?.rendererRect || null;

  const _pageNum = pageNum || currentPage;

  return (
    <PageWrapper id="pdf-page-wrapper" last={_pageNum >= numPages}>
      <Page
        pageNumber={_pageNum || currentPage}
        scale={zoomLevel}
        height={(rendererRect?.height || 100) - 100}
        width={(rendererRect?.width || 100) - 100}
        loading="Loding"
      />
    </PageWrapper>
  );
};

export default PDFSinglePage;

interface PageWrapperProps {
  last?: boolean;
}

const PageWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  .react-pdf__Page__textContent {
    display: none;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
  .react-pdf__Page {
    text-align: -webkit-center;
  }
`;
