import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useDrag } from 'react-dnd';

import { ItemWrapper } from './styles';

interface PropsItemUserResult {
  item: {
    i_id: string;
    name: string;
    code: string;
  };
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<
      Array<{
        i_id: string;
        name: string;
        code: string;
      }>
    >
  >;
  onSubmit: () => void;
}

const Item: React.FC<PropsItemUserResult> = ({
  item,
  onSubmit,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const handleSelect = () => {
    setSelectedQuestion((prevState) => {
      if (prevState.some((i) => i.i_id === item.i_id)) {
        return prevState.filter((i) => i.i_id !== item.i_id);
      } else {
        return [...prevState, item];
      }
    });
  };

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'move-question',
      item,
      onSubmit,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ItemWrapper
      ref={drag}
      isDragging={isDragging}
      isChecked={selectedQuestion.some((i) => i.i_id === item.i_id)}
      onClick={handleSelect}
    >
      {selectedQuestion.some((i) => i.i_id === item.i_id) ? (
        <CheckOutlined className="icon" />
      ) : (
        <div className="icon" />
      )}
      <span className="text-bold">{item.name}</span>
    </ItemWrapper>
  );
};

export default Item;
