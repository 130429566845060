import React from 'react';

import GraphNonExpansion from './GraphNonExpansion';
import GraphExpansion from './GraphExpansion';
import Development from './Development';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
}

const Chart: React.FC<Props> = ({ visibleExpansionOfSale, visibleCountUsage }) => {
  return visibleExpansionOfSale && visibleCountUsage ? (
    <Development />
  ) : visibleExpansionOfSale ? (
    <GraphExpansion />
  ) : (
    <GraphNonExpansion />
  );
};

export default Chart;
