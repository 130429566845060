import React, { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { initialPDFState, IPDFState, PDFStateReducer, reducer } from './reducer';
import { IMainState } from '../../../store/mainStateReducer';
import { PDFActions } from './actions';

const PDFContext = createContext<{
  state: IPDFState;
  dispatch: Dispatch<PDFActions>;
}>({ state: initialPDFState, dispatch: () => null });

const PDFProvider: FC<PropsWithChildren<{ mainState: IMainState }>> = ({ children, mainState }) => {
  const [state, dispatch] = useReducer<PDFStateReducer>(reducer, {
    ...initialPDFState,
    defaultZoomLevel: mainState.config?.pdfZoom?.defaultZoom ?? initialPDFState.defaultZoomLevel,
    zoomLevel: mainState.config?.pdfZoom?.defaultZoom ?? initialPDFState.zoomLevel,
    zoomJump: mainState.config?.pdfZoom?.zoomJump ?? initialPDFState.zoomJump,
    mainState,
  });

  return <PDFContext.Provider value={{ state, dispatch }}>{children}</PDFContext.Provider>;
};

export { PDFContext, PDFProvider };
