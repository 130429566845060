import React, { useCallback, useEffect, useState } from 'react';
import { SearchOutlined, FormOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { getIndividualUserManagement, getIndividualUserManagementExport } from './thunk';
import { HEADER_INDIVIDUAL_USER_MANAGEMENT } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { individualUserManagementSelect } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import { filterIndividualByDeleted } from './slice';
import Completed from 'components/Modal/Completed';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import { UserMasterStyled } from './styles';
import { useAppDispatch } from 'hooks';
import ModalMemo from './Modal';
import * as Types from 'types';

const { Option } = Select;

const IndividualUserManagement: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<Types.IndividualUserManagement[]>([]);
  const [selected, setSelected] = useState<Types.IndividualUserManagement>();
  const [visibleModalMemo, setVisibleModalMemo] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);
  const { total, dataIndividualUserManagement, loading } = useSelector(
    individualUserManagementSelect
  );
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (id: string) => <div className="style-code">{id}</div>,
    },
    {
      title: 'ユーザー名',
      dataIndex: 'user_name',
      key: 'user_name',
      width: '10%',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      render: (email: string) => <div className="style-email">{email}</div>,
    },
    {
      title: '契約状況',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (_: string, record: Types.IndividualUserManagement) => (
        <div className={`${record.deletedat ? 'disable-status' : 'active-status'}`}>
          <span className="style-text-status">{record.deletedat ? '解約' : '契約中'}</span>
        </div>
      ),
    },
    {
      title: '初回登録日',
      dataIndex: 'createdat',
      key: 'createdat',
      width: '10%',
      render: (record: string) => (!!record ? dayjs(record).format('YYYY/MM/DD') : '-'),
    },
    {
      title: '継続期間',
      dataIndex: 'period',
      key: 'period',
      width: '10%',
      render: (_: string, record: Types.IndividualUserManagement) => {
        const years = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
          dayjs(record.createdat),
          'years'
        );
        const months = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
          dayjs(record.createdat),
          'months'
        );
        return `${months === 12 ? years + 1 : years}年${months === 12 ? 0 : months}ヶ月`;
      },
    },
    {
      title: '解約日',
      dataIndex: 'deletedat',
      key: 'end_date',
      width: '10%',
      render: (text: string) => <div>{text ? text : '-'}</div>,
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      width: '10%',
      render: (record: number) => (record === 1 ? 'iOS' : 'Android'),
    },
    {
      title: <div className="title-memo">memo</div>,
      dataIndex: 'comment',
      key: 'comment',
      width: '15%',
      render: (text: string) => <div className="style-memo">{text ? text : '-'}</div>,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, record: Types.IndividualUserManagement) => (
        <FormOutlined
          onClick={() => {
            setSelected(record);
            setVisibleModalMemo(true);
          }}
          className="icon"
        />
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      id: '',
      user_name: '',
      deletedat: '',
      os: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values)
        .filter((e) => e !== 'deletedat')
        .forEach((key) => {
          const value = values[key as keyof typeof values];
          if (value) {
            conditions.push({
              id: key,
              search_value: [value],
              exact_match: true,
            });
          }
        });
      await fetchIndividualManagement(conditions);
      if (values.deletedat) {
        await dispatch(filterIndividualByDeleted(values.deletedat));
      }
    },
    onReset: () => {
      fetchIndividualManagement();
    },
  });

  const fetchIndividualManagement = useCallback(
    async (conditions?: Types.ConditionsType[]) => {
      if (!userInfo) return;
      await dispatch(
        getIndividualUserManagement({
          conditions,
          page,
          per_page: perPage,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
          omit_total_items: false,
        })
      );
    },
    [dispatch, userInfo, page, perPage]
  );

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getIndividualUserManagementExport({
          conditions: [
            {
              id: 'id',
              search_value: [selectedRow.map((row) => row.id).join('|')],
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getIndividualUserManagementExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          id: item.id,
          company_id: item.company_id,
          login_id: item.login_id,
          user_name: item.user_name,
          period: item.period,
          createdat: item.createdat,
          deletedat: item.deletedat,
          comment: item.comment,
          status: item.status,
          os: item.os,
        }));

        const csvString = [
          HEADER_INDIVIDUAL_USER_MANAGEMENT.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '個人ユーザ管理.csv');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const PopupConfirmExportFileActive = () => {
    if (!selectedRow.length) {
      setVisiblePopupConfirmExportFile(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchIndividualManagement();
    })();
  }, [fetchIndividualManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title={headerTitle} />
      <UserMasterStyled collapsedMenu={collapsedMenu} isEmptyData={false}>
        <p className="text-note">個人ユーザーの登録情報の確認・管理を行う画面です。</p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item name="id" className="item" label={<span className="text-label">ID</span>}>
                <SelectField
                  name="id"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataIndividualUserManagement, 'id').map(({ id }, index) => (
                    <Option value={id} key={index}>
                      {id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="user_name"
                className="item"
                label={<span className="text-label">ユーザー名</span>}
              >
                <SelectField
                  name="user_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataIndividualUserManagement, 'user_name').map(
                    ({ user_name }, index) => (
                      <Option value={user_name} key={index}>
                        {user_name}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="deletedat"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  name="deletedat"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">契約中</Option>
                  <Option value="0">解約</Option>
                </SelectField>
              </Form.Item>
              <Form.Item name="os" className="item" label={<span className="text-label">OS</span>}>
                <SelectField
                  name="os"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">iOS</Option>
                  <Option value="0">Android</Option>
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={dataIndividualUserManagement}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.IndividualUserManagement[]) =>
              setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: perPage,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <span className="text-count">
                  {page * perPage > dataIndividualUserManagement.length
                    ? dataIndividualUserManagement.length
                    : page * perPage}
                  件表示 / {dataIndividualUserManagement.length} 名
                </span>
                <div>
                  <span className="label-select">表示件数：</span>
                  <Select className="select-option" onChange={setPerpage} value={perPage}>
                    {[100, 50, 20, 10].map((e) => (
                      <Option value={e}>{e}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したデーターを処理：
              <Button className="btn btn-active" onClick={PopupConfirmExportFileActive}>
                エクスポート
              </Button>
            </div>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ModalMemo
          visible={visibleModalMemo}
          setVisible={setVisibleModalMemo}
          selected={selected}
          setVisibleSuccess={setVisibleSuccess}
        />
        <Completed
          title={''}
          subTitle="更新が完了しました"
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          onSubmit={() => {
            fetchIndividualManagement();
          }}
        />
      </UserMasterStyled>
    </>
  );
};

export default IndividualUserManagement;
