import { createAsyncThunk } from '@reduxjs/toolkit';

import { OFFICIAL_CURRICULUM_EXPORT, OFFICIAL_CURRICULUM_HIERARCHY_LIST } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getOfficialCurriculumHierarchyList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getOfficialCurriculumHierarchyList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_HIERARCHY_LIST.name, {
      ...req,
      include_item_ref: true,
      sort_fields: [
        { id: 'curriculum_sort_order', order: 'asc' },
        { id: 'level1_sort_order', order: 'asc' },
        { id: 'level2_sort_order', order: 'asc' },
        { id: 'level3_sort_order', order: 'asc' },
        { id: 'level4_sort_order', order: 'asc' },
        { id: 'question_sort_order', order: 'asc' },
      ],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataOfficialCurriculumExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_EXPORT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
