import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { parse, ParseResult } from 'papaparse';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  SearchOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import CompletedDeleteModal from 'components/Modal/CompletedDelete';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { setHeaderTitle } from 'containers/AppSettings/slice';
import ActionErrorModal from 'components/Modal/ActionError';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import { authSelector } from 'containers/Auth/selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import PDFUsersDocument from './PDFUsersDocument';
import { Header, SelectField } from 'components';
import { userMasterSelector } from './selectors';
import PDFUserDocument from './PDFUserDocument';
import { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import UserMasterStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  createAdminAffiliationAssignRole,
  getSelectAdminDepartment,
  getDataAdminUserExport,
  getSelectAdminPosition,
  getDataAdminUserList,
  getSelectAdminUsers,
  deleteAdminUsers,
  createAdminUsers,
  getSubPosition,
  addUserAdmin,
} from './thunk';

const { Option } = Select;

const UserMaster: React.FC = () => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.EmployeeUser.ResponseType[]>([]);
  const [itemSelected, setItemSelected] = useState<Types.EmployeeUser.ResponseType>();
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const {
    dataSelectAdminUser,
    dataSelectDepartment,
    dataSelectPosition,
    dataAdminUserList,
    dataAllGmail,
    loading,
  } = useSelector(userMasterSelector);

  const { userInfo } = useSelector(authSelector);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.EmployeeUser.ResponseType> = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '10%',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '10%',
      ellipsis: true,
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && text.replace(/^\d+ /, ''),
    },
    {
      title: '役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && text.replace(/^\d+ /, ''),
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '10%',
      ellipsis: true,
      render: (text: string) => dayjs().diff(text, 'years', false),
    },
    {
      title: '入社年月日',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM')}`,
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM/DD HH:mm')}`,
    },
    {
      title: '権限',
      dataIndex: 'role_code',
      key: 'role_code',
      width: '10%',
      ellipsis: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      render: (_, item) => (
        <FormOutlined
          onClick={() => {
            dispatch(setHeaderTitle(getHeaderTitle(routes.EditUserMaster.path)));
            navigate(
              generatePath(routes.EditUserMaster.path, {
                id: item.i_id,
              })
            );
          }}
          className="icon"
        />
      ),
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, item) => (
        <DeleteOutlined
          className="icon"
          onClick={() => {
            setVisiblePopupConfirmDelete(true);
            setItemSelected(item);
          }}
        />
      ),
    },
  ];

  const fetchDataAdminUserList = useCallback(() => {
    if (userInfo) {
      dispatch(
        getDataAdminUserList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
    }
  }, [dispatch, userInfo]);

  const formik = useFormik({
    initialValues: {
      employee_code: '',
      name: '',
      department_name: '',
      position_code: '',
    },
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      await dispatch(
        getDataAdminUserList({
          conditions,
          include_lookups: true,
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
    },
  });

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleSubmitDelete = async () => {
    if (itemSelected?.i_id) {
      const resultAction = await dispatch(
        deleteAdminUsers({
          id: itemSelected?.i_id,
        })
      );
      if (deleteAdminUsers.fulfilled.match(resultAction)) {
        setVisiblePopupConfirmDelete(false);
        fetchDataAdminUserList();
      }
    }
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAdminUserExport({
          page: 1,
          per_page: 0,
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'i_id',
              search_value: selectedRow.map((row) => row.i_id),
            },
          ],
          include_lookups: true,
          include_item_ref: true,
        })
      );
      if (getDataAdminUserExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          employee_code: item.employee_code,
          name: item.name,
          kana: item.kana,
          email: item.email,
          password: item.password,
          department_name: item.department_name,
          position_code: item.position_code,
          birthday: item.birthday,
          hire_date: item.hire_date,
          role_code: item.role_code,
          i_id_role_code: item?.lookup_items?.role_code.i_id,
        }));

        const csvString = [
          HEADER_EMPLOYEE_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '内部ユーザー.csv');
      }
    } else {
      if (!selectedRow.length) return;

      if (selectedRow.length === 1) {
        const userObject: Types.EmployeeUser.EmployeeExportPDF = {
          employee_code: selectedRow[0].employee_code || '',
          name: selectedRow[0].name || '',
          kana: selectedRow[0].kana || '',
          login_id: selectedRow[0].login_id || '',
          birthday: selectedRow[0].birthday || '',
          hire_date: selectedRow[0].hire_date || '',
          main_position: {
            affiliation_name: '',
            position_name: '',
            main_role: '',
          },
        };

        const resultAction = await dispatch(
          getSubPosition({
            conditions: [
              { id: 'company_id', search_value: [selectedRow[0].company_id] },
              { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
            ],
            sort_fields: [{ id: 'sort_order', order: 'asc' }],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            include_links: true,
          })
        );

        if (getSubPosition.fulfilled.match(resultAction)) {
          const position = resultAction.payload.items.map((item) => ({
            affiliation_name: item.lookup_items?.affiliation_id?.name || '',
            position_name: item.lookup_items?.positions_code?.name || '',
            main_role: item.main_role || '',
          }));
          const main_position = position.find((pos) => pos.main_role === 'main');
          Object.assign(userObject, { main_position });
        }

        const blob = await pdf(<PDFUserDocument employee={userObject} />).toBlob();
        saveAs(blob, `${selectedRow[0].employee_code}_${selectedRow[0].name}.pdf`);
      } else {
        const blob = await pdf(
          <PDFUsersDocument employeeSelected={selectedRow} total={dataAdminUserList.length} />
        ).toBlob();
        saveAs(blob, 'users_master_table.pdf');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const isEmailDuplicate = (email: string, allEmails: Array<string>) => {
    return allEmails.includes(email);
  };

  const handleImportCSV = (file: File) => {
    parse(file, {
      complete: ({ data }: ParseResult<string>) => {
        const resultsDataCSV: Array<Types.EmployeeExportType> = data
          .map((item) =>
            Object.fromEntries(HEADER_EMPLOYEE_CSV.map(({ key }, i) => [key, item[i]]))
          )
          .splice(1) as unknown as Array<Types.EmployeeExportType>;
        resultsDataCSV.map(async (item) => {
          if (!isEmailDuplicate(item.email, dataAllGmail)) {
            const resultAddUserAction = await dispatch(
              addUserAdmin({
                user_code: item.email,
                email: item.email,
                username: item.name,
                password: item.password,
              })
            );
            if (
              addUserAdmin.fulfilled.match(resultAddUserAction) &&
              (resultAddUserAction.payload.added || !resultAddUserAction.payload.exists)
            ) {
              const affiliation_id = dataSelectDepartment.find(
                (i) => i.affiliation_id === item.department_name.split(' ')[0]
              )?.i_id;
              const positions_id = dataSelectPosition.find(
                (i) => i.position_code === item.position_code.split(' ')[0]
              )?.i_id;
              const resultAction2 = await Promise.all([
                dispatch(
                  createAdminUsers({
                    item: {
                      company_id: userInfo?.company_id,
                      login_id: item.email,
                      employee_code: item.employee_code,
                      name: item.name,
                      name_furigana: item.kana,
                      email: item.email,
                      user_type: 'member',
                      role_code: item.i_id_role_code,
                      password: item.password,
                      createdby: userInfo?.login_id,
                      createdat: new Date(),
                    },
                  })
                ),
                dispatch(
                  createAdminAffiliationAssignRole({
                    item: {
                      company_id: userInfo?.company_id,
                      login_id: item.email,
                      affiliation_id: affiliation_id,
                      positions_code: positions_id,
                      sort_order: 1,
                      main_role: 'main',
                    },
                  })
                ),
              ]);
              if (
                createAdminAffiliationAssignRole.fulfilled.match(resultAction2[1]) &&
                createAdminUsers.fulfilled.match(resultAction2[0])
              ) {
                fetchDataAdminUserList();
              }
            }
          }
        });
      },
    });
  };

  const fetchDataSelect = useCallback(() => {
    if (userInfo) {
      (async () => {
        await Promise.all([
          dispatch(
            getSelectAdminDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
      })();
    }
  }, [dispatch, userInfo]);

  useEffect(fetchDataAdminUserList, [fetchDataAdminUserList]);
  useEffect(fetchDataSelect, [fetchDataSelect]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title={headerTitle} />
      <UserMasterStyled collapsedMenu={collapsedMenu} isEmptyData={false}>
        <p className="text-note">管理コンソールを使用するユーザーの作成・管理を行う画面です。</p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="employee_code"
                className="item"
                label={<span className="text-label">社員番号</span>}
              >
                <SelectField
                  name="employee_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectAdminUser?.map(({ employee_code, name }, i) => (
                    <Option value={employee_code} key={i}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectAdminUser?.map(({ name }, i) => (
                    <Option value={name} key={i}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectDepartment?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span
                className="label-reset"
                onClick={() => {
                  formik.resetForm();
                  fetchDataAdminUserList();
                }}
              >
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <Button
            className="btn btn-active"
            icon={<CloudUploadOutlined className="icon" />}
            onClick={() => setVisiblePopupUploadCSV(true)}
          >
            インポート
          </Button>

          <Button
            className="btn btn-active"
            icon={<PlusOutlined className="icon" />}
            onClick={() => {
              dispatch(setHeaderTitle(getHeaderTitle(routes.CreateUserMaster.path)));
              navigate(generatePath(routes.CreateUserMaster.path, { entity: 'receiving' }));
            }}
          >
            新規登録
          </Button>
        </div>
        <Table
          rowKey="index"
          className="table"
          dataSource={dataAdminUserList.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.EmployeeUser.ResponseType[]) =>
              setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: perPage,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <span className="text-count">
                  {page * perPage > dataAdminUserList.length
                    ? dataAdminUserList.length
                    : page * perPage}
                  件表示 / {dataAdminUserList.length} 名
                </span>
                <div>
                  <span className="label-select">表示件数：</span>
                  <Select className="select-option" onChange={setPerpage} value={perPage}>
                    {[100, 50, 20, 10].map((e) => (
                      <Option value={e}>{e}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
          </div>
        </div>
        <UploadCSV
          onSubmit={handleImportCSV}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CompletedDeleteModal
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={handleSubmitDelete}
        />
      </UserMasterStyled>
    </>
  );
};

export default UserMaster;
