import React from 'react';
import { Table } from 'antd';

import TableOfficialCurriculumStyled from './styles';

interface Props {
  visibleProducerExpand: boolean;
  visibleCurriculumDevelopment: boolean;
}

const TableOfficialCurriculum: React.FC<Props> = ({
  visibleProducerExpand,
  visibleCurriculumDevelopment,
}) => {
  const column = [
    {
      title: '企業種類',
      dataIndex: 'type_business',
      key: 'type_business',
      className: 'type-business',
      width: visibleCurriculumDevelopment ? '16%' : '9%',
      render: (text: any, item: any, index: any) => (
        <div className="name">
          <span className="number-label">{index + 1}.</span>
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="title">
          <div>
            <span>公開中</span>
          </div>
          <span>カリキュラム数</span>
        </div>
      ),
      dataIndex: 'curriculum_number',
      key: 'curriculum_number',
      width: '10%',
    },
    ...date,
  ];
  return (
    <TableOfficialCurriculumStyled visibleCurriculumDevelopment={visibleCurriculumDevelopment}>
      <Table
        className="table"
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        rowKey="code"
        columns={column}
        dataSource={
          visibleProducerExpand && visibleCurriculumDevelopment
            ? data2
            : visibleProducerExpand
            ? data1
            : data
        }
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
      />
    </TableOfficialCurriculumStyled>
  );
};

const dataDate = [
  { date: '01/2022' },
  {
    date: '02/2022',
  },
  {
    date: '03/2022',
  },
  {
    date: '04/2022',
  },
  {
    date: '05/2022',
  },
  {
    date: '06/2022',
  },
  {
    date: '07/2022',
  },
  {
    date: '08/2022',
  },
];

const date = dataDate.map((item, index) => ({
  title: item.date,
  dataIndex: `date${index + 1}`,
  key: item.date,
  width: '6%',
  render: (text: string) => <span>{text}</span>,
}));

const data = [
  {
    type_business: '全企業',
    curriculum_number: '10',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
  },
  {
    type_business: 'RSTANDARD',
    curriculum_number: '20',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
  },
  {
    type_business: 'パートナー企業',
    curriculum_number: '30',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
  },
];

const data1 = [
  {
    type_business: '全企業',
    curriculum_number: '10',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
  },
  {
    type_business: 'RSTANDARD',
    curriculum_number: '20',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
    children: [
      {
        type_business: '株式会社 Link At',
        curriculum_number: '5',
        date1: '10,000',
        date2: '11,000',
        date3: '12,000',
        date4: '13,000',
        date5: '14,000',
        date6: '15,000',
        date7: '16,000',
        date8: '16,000',
      },
      {
        type_business: '株式会社 Link At1',
        curriculum_number: '5',
        date1: '20,000',
        date2: '21,000',
        date3: '22,000',
        date4: '23,000',
        date5: '24,000',
        date6: '25,000',
        date7: '26,000',
        date8: '26,000',
      },
      {
        type_business: '株式会社 Link At2',
        curriculum_number: '5',
        date1: '30,000',
        date2: '31,000',
        date3: '32,000',
        date4: '33,000',
        date5: '34,000',
        date6: '35,000',
        date7: '36,000',
        date8: '36,000',
      },
    ],
  },
  {
    type_business: 'パートナー企業',
    curriculum_number: '30',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
  },
];

const data2 = [
  {
    type_business: '全企業',
    curriculum_number: '10',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
  },
  {
    type_business: 'RSTANDARD',
    curriculum_number: '20',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
    children: [
      {
        type_business: '株式会社 Link At',
        curriculum_number: '5',
        date1: '10,000',
        date2: '11,000',
        date3: '12,000',
        date4: '13,000',
        date5: '14,000',
        date6: '15,000',
        date7: '16,000',
        date8: '16,000',
        children: [
          {
            type_business: '経理実務の基本を完全マスター',
            date1: '2,111',
            date2: '1,111',
            date3: '2,111',
            date4: '3,111',
            date5: '4,111',
            date6: '5,111',
            date7: '6,111',
            date8: '6,111',
          },
          {
            type_business: '経理実務の基本を完全マスター',
            date1: '2,222',
            date2: '1,222',
            date3: '2,222',
            date4: '3,222',
            date5: '4,222',
            date6: '5,222',
            date7: '6,222',
            date8: '6,222',
          },
          {
            type_business: '経理実務の基本を完全マスター',
            date1: '2,333',
            date2: '1,333',
            date3: '2,333',
            date4: '3,333',
            date5: '4,333',
            date6: '5,333',
            date7: '6,333',
            date8: '6,333',
          },
        ],
      },
      {
        type_business: '株式会社 Link At1',
        curriculum_number: '5',
        date1: '20,000',
        date2: '21,000',
        date3: '22,000',
        date4: '23,000',
        date5: '24,000',
        date6: '25,000',
        date7: '26,000',
        date8: '26,000',
      },
      {
        type_business: '株式会社 Link At2',
        curriculum_number: '5',
        date1: '30,000',
        date2: '31,000',
        date3: '32,000',
        date4: '33,000',
        date5: '34,000',
        date6: '35,000',
        date7: '36,000',
        date8: '36,000',
      },
    ],
  },
  {
    type_business: 'パートナー企業',
    curriculum_number: '30',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
  },
];

export default TableOfficialCurriculum;
