import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROLES, SELECT_ROLE } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getAdminRoleList = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getAdminRoleList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminRole = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getSelectAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAdminRoleDetail = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getAdminRoleDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/deleteAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/createAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/updateAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ROLES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminRolesExportCSV = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getDataAdminRolesExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
