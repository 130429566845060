import yup from '../yup';

export const createEditCurriculumSchema = yup.object().shape({
  name: yup.string().required(),
});

export const createEditQuestionSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  problems1: yup.string().required(),
  problems2: yup.string().required(),
  problems3: yup.string().required(),
  answer: yup.string().required(),
  time_limit: yup.string().required(),
  comment: yup.string().required(),
  score: yup
    .number()
    .required()
    .test('val', (value) => Number(value) > 0 && Number(value) < 4),
});

export const uploadCSVSchema = yup.array().of(
  yup.object().shape({
    flag: yup.string().required(),
    curriculum_code: yup.string(),
    curriculum_name: yup.string().max(50).required(),
    curriculum_description: yup.string().max(1050),
    level1_name: yup.string().max(50),
    level2_name: yup.string().max(50),
    level3_name: yup.string().max(50),
    level4_name: yup.string().max(50),
    question_name: yup.string().max(60),
    question_description: yup.string().max(480),
    problems1: yup.string().max(100),
    problems2: yup.string().max(100),
    problems3: yup.string().max(100),
    answer: yup.string().max(1).oneOf(['A', 'B', 'C', '']),
    comment: yup.string().max(1050),
    score: yup
      .string()
      .test('val', (value) => (Number(value) > 0 && Number(value) < 4) || value === ''),
  })
);
