import { createSlice } from '@reduxjs/toolkit';

import { getDataInformationUser } from './thunk';
import * as Types from 'types';

export interface InitialState {
  loading: boolean;
  dataInformationUser: Array<Types.InformationUser>;
  total: number;
}

const initialState: InitialState = {
  dataInformationUser: [],
  loading: false,
  total: 0,
};
export const reportSlide = createSlice({
  name: 'report-slice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder.addCase(getDataInformationUser.pending, startLoading);

    builder.addCase(getDataInformationUser.fulfilled, (state, action) => {
      state.dataInformationUser = action.payload.report_results;
      stopLoading(state);
    });

    builder.addCase(getDataInformationUser.rejected, stopLoading);
  },
});

export default reportSlide.reducer;
