import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table, Select as SelectAntd } from 'antd';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { Key } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { find, uniqBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudDownloadOutlined,
  ContainerOutlined,
  SearchOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ArchiveCompleted from 'components/Modal/ArchiveCompleted';
import ConfirmArchive from 'components/Modal/ConfirmArchive';
import { authSelector } from 'containers/Auth/selectors';
import CreateCurriculum from './Modal/CreateCurriculum';
import { useAppDispatch, usePermission } from 'hooks';
import { publicManagerSelector } from './selectors';
import InformationModal from './Modal/Information';
import { Header, SelectField } from 'components';
import UpdateStatus from './Modal/UpdateStatus';
import { loadingRef } from 'components/Loading';
import ArchiveList from './Modal/ArchiveList';
import Index from './Modal/ManageRelease';
import Info from 'components/Modal/Info';
import Changed from './Modal/Changed';
import ReleaseNote from './Modal/ReleaseNote';
import PublicStyled from './styles';
import * as Types from 'types';
import {
  getDataOfficialCurriculumPublishingSettingExportCSV,
  createCurriculumPublishedHistory,
  updateCurriculumPublishedHistory,
  updateOfficialCurriculumMaster,
  getOfficialPublish,
  getDataUsers,
  getDataReleaseNoteID,
  updateReleaseNote,
  getReleaseNoteDetail,
} from './thunk';

const { Option } = Select;

const PublicManagement = () => {
  const [archiveItem, setArchiveItem] =
    useState<Types.OfficialCurriculumPublishingSettings.ResponseType>();
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [status, setStatus] = useState<'publish' | 'editing' | 'publishWarning'>('editing');
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleChanged, setVisibleChanged] = useState<boolean>(false);
  const [visibleArchive, setVisibleArchive] = useState<boolean>(false);
  const [visibleRelease, setVisibleRelease] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [visibleList, setVisibleList] = useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [releaseNoteModalVisible, setReleaseNoteModalVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<
    Array<Types.OfficialCurriculumPublishingSettings.ResponseType>
  >([]);
  const [currentReleaseNote, setCurrentReleaseNote] = useState<
    Types.AdminReleaseNoteManagement.ResponseType | undefined
  >(undefined);

  const { loading, officialPublishData, dataUsers, dataReleaseNoteID } =
    useSelector(publicManagerSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();
  const formik = useFormik<Types.OfficialPublishSearchFormik>({
    initialValues: {
      publish: '',
      official_curriculum_name: '',
      official_curriculum_code: '',
      createdby: '',
    },
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      dispatch(
        getOfficialPublish({
          conditions: [
            {
              id: 'archive_flg',
              search_value: ['0'],
              exact_match: true,
            },
            ...conditions,
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
    },
    onReset: () => {
      fetchOfficialPublish();
    },
  });

  const handleCreateCurriculumPublished = async (version: number | undefined | string) => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          createCurriculumPublishedHistory({
            item: {
              company_id: userInfo.company_id,
              official_curriculum_code: selectedRow[0]?.official_curriculum_code,
              publish_start_date: new Date(),
              version: String(version),
            },
            realtime_auto_link: true,
            return_item_result: true,
            return_display_id: true,
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 2,
                published_version: String(version),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (createCurriculumPublishedHistory.fulfilled.match(resultAction[0])) {
        await dispatch(
          updateReleaseNote({
            id:
              dataReleaseNoteID.length > 1
                ? dataReleaseNoteID.slice(-1)[0].i_id
                : dataReleaseNoteID[0].i_id,
            data: {
              item: {
                curriculum_publish_history_id: resultAction[0].payload.item?.history_id,
              },
              is_force_update: true,
              return_item_result: true,
              realtime_auto_link: true,
            },
          })
        );
      }
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateCurriculumPublishedHistory = async () => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          updateCurriculumPublishedHistory({
            id: selectedRow[0]?.item_ref!.history_id.i_id,
            data: {
              item: {
                company_id: userInfo.company_id,
                official_curriculum_code: selectedRow[0]?.official_curriculum_code,
                publish_end_date: new Date(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 3,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateUnPublish = async () => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          updateCurriculumPublishedHistory({
            id: selectedRow[0]?.item_ref!.history_id.i_id,
            data: {
              item: {
                company_id: userInfo.company_id,
                official_curriculum_code: selectedRow[0]?.official_curriculum_code,
                publish_end_date: new Date(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 0,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataOfficialCurriculumPublishingSettingExportCSV({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataOfficialCurriculumPublishingSettingExportCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          archive_flg: item.archive_flg,
          createdby: item.createdby,
          history_id: item.history_id,
          official_curriculum_code: item.official_curriculum_code,
          official_curriculum_name: item.official_curriculum_name,
          provider_id: item.provider_id,
          publish: item.publish,
          publish_start_date: item.publish_start_date,
          updatedat: item.updatedat,
          version: item.version,
        }));

        const csvString = [
          HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setShowConfirmExportFileModal(false);
    }
  };

  const findUserName = useCallback(
    (loginId?: string) => {
      const user = find(dataUsers, { login_id: loginId });
      return user ? user.name : '';
    },
    [dataUsers]
  );

  const findUserNameByEmail = useCallback(
    (email?: string) => {
      const user = find(dataUsers, { email: email });
      return user ? user.name : '';
    },
    [dataUsers]
  );

  const column: ColumnsType<Types.OfficialCurriculumPublishingSettings.ResponseType> = [
    {
      title: 'ID',
      dataIndex: 'official_curriculum_code',
      key: 'official_curriculum_code',
      width: '7%',
      align: 'left',
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'official_curriculum_name',
      key: 'official_curriculum_name',
      align: 'left',
    },
    {
      title: '公開ステータス',
      dataIndex: 'publish',
      key: 'publish',
      width: '10%',
      align: 'left',
      render: (record: number) => getStatus(record),
    },
    {
      title: '前回公開日時',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (record) =>
        record ? dayjs(record).locale('ja').format('YYYY/MM/DD (ddd)  HH:mm') : '-',
    },
    {
      title: '最終更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '10%',
      render: (record) =>
        record ? dayjs(record).locale('ja').format('YYYY/MM/DD (ddd)  HH:mm') : '-',
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '10%',
      render: (record) => findUserName(record),
    },
    {
      title: '閲覧・編集',
      dataIndex: '',
      key: '',
      width: '10%',
      render: () => <FormOutlined className="icon" onClick={() => setVisibleEdit(true)} />,
    },
    {
      title: 'アーカイブ',
      dataIndex: 'publish',
      width: '7%',
      render: (record, item) =>
        record === 3 && (
          <ContainerOutlined
            onClick={() => {
              setArchiveItem(item);
              setVisibleArchive(true);
            }}
            className="icon"
          />
        ),
    },
  ];
  const handleUpdateOfficialCurriculumMaster = async () => {
    if (!archiveItem) return;

    await dispatch(
      updateOfficialCurriculumMaster({
        id: archiveItem.i_id,
        data: {
          item: {
            archive_flag: 1,
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );
    fetchOfficialPublish();
  };

  const fetchOfficialPublish = () => {
    dispatch(
      getOfficialPublish({
        conditions: [
          {
            id: 'archive_flg',
            search_value: ['0'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        // omit_total_items: false,
        include_item_ref: true,
      })
    );
  };

  const fetchDataUsers = useCallback(() => {
    dispatch(
      getDataUsers({
        conditions: [],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
    onChange: (
      _key: Key[],
      row: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>
    ) => {
      setSelectedRow(row);
      dispatch(
        getDataReleaseNoteID({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'curriculum_code',
              search_value: [row[0]?.official_curriculum_code],
              exact_match: true,
            },
          ],
          sort_fields: [
            {
              id: 'release_version',
              order: 'desc',
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
          omit_total_items: false,
          omit_fields_data: true,
          use_display_id: true,
        })
      );
    },
  };

  const openReleaseNoteModal = async () => {
    setVisible(false);
    dispatch(startLoading());
    const checkCurrentReleaseNote = dataReleaseNoteID.find((item) => {
      return !item.curriculum_publish_history_id;
    });
    if (checkCurrentReleaseNote) {
      const releaseNote = await dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [checkCurrentReleaseNote.release_note_id],
            },
          ],
          omit_fields_data: true,
          omit_total_items: true,
          page: 1,
          per_page: 1,
          use_display_id: true,
        })
      );
      if (getReleaseNoteDetail.fulfilled.match(releaseNote)) {
        setCurrentReleaseNote(releaseNote.payload.report_results[0]);
      }
    }
    dispatch(stopLoading());
    setReleaseNoteModalVisible(true);
  };

  const fetchData = useCallback(() => {
    dispatch(
      getDataReleaseNoteID({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'curriculum_code',
            search_value: [selectedRow[0]?.official_curriculum_code],
            exact_match: true,
          },
        ],
        sort_fields: [
          {
            id: 'release_version',
            order: 'desc',
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        omit_total_items: false,
        omit_fields_data: true,
        use_display_id: true,
      })
    );
  }, [dispatch, selectedRow, userInfo?.company_id]);

  useEffect(fetchOfficialPublish, [dispatch, selectedRow, userInfo]);
  useEffect(fetchDataUsers, [fetchDataUsers]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title={headerTitle} />
      <PublicStyled collapsedMenu={collapsedMenu} isEmptyData={!officialPublishData.length}>
        <div className="wrapper">
          <p className="text-note">作成したOFFICIALカリキュラムの公開管理を行う画面です。</p>
          <Button className="information" onClick={() => setIsOpen(true)}>
            制作者情報
          </Button>
        </div>
        <div className="line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="official_curriculum_code"
                label="OFFICIALカリキュラムID"
                className="item"
              >
                <SelectField name="official_curriculum_code">
                  {uniqBy(officialPublishData, 'official_curriculum_code').map(
                    ({ official_curriculum_code }) => (
                      <Option value={official_curriculum_code} key={official_curriculum_code}>
                        {official_curriculum_code}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="official_curriculum_name"
                label="OFFICIALカリキュラム名"
                className="item"
              >
                <SelectField name="official_curriculum_name">
                  {uniqBy(officialPublishData, 'official_curriculum_name').map(
                    ({ official_curriculum_name }) => (
                      <Option value={official_curriculum_name} key={official_curriculum_name}>
                        {official_curriculum_name}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item name="publish" label="公開ステータス" className="item">
                <SelectField name="publish">
                  {Array.from({
                    length: 4,
                  }).map((_, index) => (
                    <Option value={`${index}`} key={index}>
                      {index === 0
                        ? '編集中'
                        : index === 1
                        ? '未公開'
                        : index === 2
                        ? '公開中'
                        : '公開停止中'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="createdby" label="作成者" className="item">
                <SelectField name="createdby" allowClear>
                  {uniqBy(officialPublishData, 'createdby').map(
                    ({ createdby }) =>
                      createdby && (
                        <Option value={createdby} key={createdby}>
                          {findUserNameByEmail(createdby)}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>

              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                icon={<CloudDownloadOutlined className="icon" />}
                onClick={() => setShowConfirmExportFileModal(true)}
              >
                エクスポート
              </Button>

              <Button className="btn btn-outline" onClick={() => setVisibleList(true)}>
                アーカイブリスト
              </Button>
            </div>
            <Table
              rowKey="i_id"
              columns={column}
              className="table"
              dataSource={officialPublishData.map((item, index) => ({ ...item, index }))}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              onHeaderRow={(columns, index) => {
                const onClickHandler = (
                  event: React.MouseEvent<HTMLTableSectionElement, MouseEvent>
                ) => {
                  const target = event.target as HTMLElement;
                  const thElement = target.closest('th');

                  if (thElement && thElement.cellIndex === 0) {
                    setSelectedRow([]);
                  }
                };
                return {
                  onClick: onClickHandler,
                };
              }}
              pagination={{
                pageSize: perPage,
                position: ['topCenter'],
                total: officialPublishData.length,
                showSizeChanger: false,
                onChange: setPage,
                showTotal: () => (
                  <div className="wrap-select-record">
                    <span className="text-count">
                      {page * perPage > officialPublishData.length
                        ? officialPublishData.length
                        : page * perPage}
                      件表示 /{officialPublishData.length} 名
                    </span>
                    <div>
                      <span className="label-select">表示件数：</span>
                      <SelectAntd className="select-option" onChange={setPerpage} value={perPage}>
                        {[100, 50, 20, 10].map((e) => (
                          <Option value={e}>{e}</Option>
                        ))}
                      </SelectAntd>
                    </div>
                  </div>
                ),
              }}
            />
          </Form>
        </FormikProvider>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したOFFICIALカリキュラムを処理：
            <Button
              className="btn btn-active"
              onClick={() => {
                fetchData();
                selectedRow.length > 0 && setVisible(true);
                setStatus('publish');
              }}
              disabled={selectedRow[0]?.publish === 2}
            >
              公開する
            </Button>
            <Button
              className="btn btn-active"
              onClick={() => {
                selectedRow.length > 0 && setVisibleUpdate(true);
                setStatus('editing');
              }}
              disabled={!selectedRow[0]?.publish}
            >
              編集する
            </Button>
            <Button
              className="btn btn-active"
              onClick={() => {
                selectedRow.length > 0 && setVisibleUpdate(true);
                setStatus('publishWarning');
              }}
              disabled={selectedRow[0]?.publish === 3}
            >
              公開停止にする
            </Button>
            <Button
              className="btn btn-outline"
              onClick={() => selectedRow.length > 0 && setVisibleRelease(true)}
            >
              リリースノートを管理
            </Button>
          </div>
        </div>
        <Index
          visible={visibleRelease}
          selectedRows={selectedRow}
          setVisible={setVisibleRelease}
          permissionNumber={permissionNumber}
        />
        <CreateCurriculum
          title="OFFICIALカリキュラムを公開します"
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          dataReleaseNoteID={dataReleaseNoteID}
          status={status}
          description={getDescription(status)}
          visible={visible}
          setVisible={setVisible}
          selected={selectedRow}
          onSubmit={handleCreateCurriculumPublished}
          openReleaseNoteModal={openReleaseNoteModal}
        />
        <UpdateStatus
          status={status}
          publish={selectedRow[0]?.publish}
          title={getTitle(status)}
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          description={getDescription(status)}
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          noteText={getNoteText(status)}
          textOkButton={getTextOkButton(status)}
          onSubmit={
            status === 'editing' ? handleUpdateUnPublish : handleUpdateCurriculumPublishedHistory
          }
        />
        <ConfirmArchive
          setVisibleComplete={setVisibleComplete}
          visible={visibleArchive}
          setVisible={setVisibleArchive}
          onSubmit={() => handleUpdateOfficialCurriculumMaster()}
        />
        <ArchiveCompleted visible={visibleComplete} setVisible={setVisibleComplete} />
        <ArchiveList visible={visibleList} setVisible={setVisibleList} />
        <Changed visible={visibleChanged} setVisible={setVisibleChanged} status={status} />
        <Info
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          title="OFFICAILカリキュラム閲覧・編集"
          content="OFFICIALカリキュラム閲覧・編集のためにカリキュラムツリーに移動します。"
        />
        <PopupConfirmExportFile
          visible={showConfirmExportFileModal}
          setVisible={setShowConfirmExportFileModal}
          onSubmit={handleExportCSV}
        />
        <InformationModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <ReleaseNote
          visible={releaseNoteModalVisible}
          publish={selectedRow[0]?.publish}
          setVisible={setReleaseNoteModalVisible}
          selected={currentReleaseNote}
        />
      </PublicStyled>
    </>
  );
};

export default PublicManagement;

export const getNoteText = (status: string) => {
  switch (status) {
    case 'editing':
      return (
        <span>
          ※編集中は利用者に影響はありません。
          <br />
          再度公開する際にはリリースノートの公開と利用者側でアップデートが必要になります。
        </span>
      );
    case 'publish':
      return (
        <span>
          ※編集中は利用者に影響はありません。
          <br />
          再度公開する際にはリリースノートの公開と利用者側でアップデートが必要になります。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          ※すでに利用中の一般ユーザー様はそのまま利用継続します。
          <br />
          利用中の一般ユーザー様に利用を停止して頂く場合は、リリースノートにて通知をお願いいたします。
        </span>
      );
    default:
      return '';
  }
};

const getDescription = (status: string) => {
  switch (status) {
    case 'editing':
      return (
        <span>
          OFFICIALカリキュラムを編集にすると、 <br />
          カリキュラムマスタから内容の修正が可能になります。
        </span>
      );
    case 'publish':
      return (
        <span>
          OFFICIALカリキュラムを公開すると、SKILL FAMILIARで
          <br />
          一般のユーザー様が利用できる状態になります。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          OFFICIALカリキュラムを公開停止にすると、
          <br /> 一般ユーザー様の新規の利用は不可になります。
        </span>
      );
    default:
      return '';
  }
};

export const getStatus = (value: number) => {
  switch (value) {
    case 0:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={WhiteEditing} alt="" />
          <span className="violet">編集中</span>
        </div>
      );
    case 1:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={UnPublished} alt="" />
          <span className="blue">未公開</span>
        </div>
      );
    case 2:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={TickComplete} alt="" />
          <span className="green">公開中</span>
        </div>
      );
    case 3:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={StopPublic} alt="" />
          <span className="grey">公開停止中</span>
        </div>
      );
  }
};

export const getTextOkButton = (status: string) => {
  switch (status) {
    case 'editing':
      return '編集中にする';
    case 'publish':
      return '公開する';
    case 'publishWarning':
      return '公開停止にする';
    default:
      return '公開する';
  }
};

export const getTitle = (status: string) => {
  switch (status) {
    case 'editing':
      return '公開中のOFFICIALカリキュラムを編集します';
    case 'publish':
      return '公開中のOFFICIALカリキュラムを公開停止します';
    case 'publishWarning':
      return '公開中のOFFICIALカリキュラムを公開停止します';
    default:
      return '';
  }
};
