import styled from 'styled-components';

type DivProps = { columnIndex: number };

export const DivCustom = styled.div<DivProps>`
  font-weight: 400;
  font-size: 12px;
  border-top: 2px solid
    ${(props) =>
      props.columnIndex === 0
        ? '#2773BA'
        : props.columnIndex === 1
        ? '#F6AC00'
        : props.columnIndex === 2
        ? '#C4C4C4'
        : props.columnIndex === 3
        ? '#71C131'
        : props.columnIndex === 4
        ? '#DD3D63'
        : props.columnIndex === 5
        ? '#00A3A5'
        : 'inherit'};
  .rowWrapper {
    border-top: 1px solid
      ${(props) => (props.columnIndex === 0 || props.columnIndex === 4 ? '#F0F0F0' : 'inherit')};
    padding: 10px;
    .rowContentHead {
      display: flex;
      align-items: center;
      .spanHead {
        display: flex;
        align-items: center;
        color: #777777;
        margin-right: 10px;
        .icon {
          margin-right: 2px;
          width: 14px;
          height: 14px;
        }
        .spanText {
          font-size: 10px;
        }
      }
    }
    .rowContent {
      display: flex;
      justify-content: space-between;
      .icons {
        display: inline;
        .icon {
          color: #c4c4c4;
          font-size: 18px;
          margin-left: 10px;
          &:hover {
            color: #009acb;
          }
        }
      }
      .caretIcon {
        color: #bfbfbf;
      }
      .icons:only-child {
        align-self: flex-end;
        margin-left: auto;
      }
      .ant-switch-checked {
        background-color: ${(props) =>
          props.columnIndex === 0 ? '#eb8b55' : props.columnIndex === 4 ? '#DD3D63' : ''};
      }
      .content {
        margin-bottom: 8px;
      }
    }
  }
  .bgGrey {
    background-color: #f7f8fa;
  }
  .blOrange {
    border-left: 2px solid #eb8b55;
  }
  .blGreen {
    border-left: 2px solid #b57fcf;
    .rowContent {
      cursor: pointer;
    }
  }
  .blViolet {
    border-left: 2px solid #71c131;
    .rowContent {
      cursor: pointer;
    }
  }
  .blRed {
    border-left: 2px solid #dd3d63;
  }
`;

export const Button = styled.button`
  background: #f6ac00;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  padding: 5px 15px;
`;
