import React from 'react';
import { ContainerOutlined } from '@ant-design/icons';

import Styled from './styles';
import Modal from '../index';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleComplete: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void | Promise<void>;
}

const ConfirmArchive: React.FC<Props> = ({ visible, setVisible, setVisibleComplete, onSubmit }) => {
  const handleSubmit = async () => {
    setVisible(false);
    await onSubmit();
    setVisibleComplete(true);
  };
  const handleToggleModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={<span>アーカイブ</span>}
      width={720}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
    >
      <Styled>
        <div className="content">
          <ContainerOutlined className="icon-container" />
          <span>　公開停止中のOFFICIALカリキュラムをアーカイブリストに移動します。</span>
        </div>
        <span className="sub-content">
          アーカイブリストに移動させたOFFICIALカリキュラムは
          <br /> いつでも元に戻すことができます。
        </span>
      </Styled>
    </Modal>
  );
};

export default ConfirmArchive;
