import styled from 'styled-components';
import { Modal } from 'components';

export const ModalStyled = styled(Modal)`
  .ant-modal-body > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1440px) {
    .ant-modal-footer {
      padding: 20px 20px 40px 20px !important;
      .button-secondary,
      .button-primary {
        width: 160px;
        height: 40px;
        padding: 9px 20px !important;
      }
    }
    .ant-modal-body {
      padding: 26px 40px 20px 40px !important;
    }
  }
`;

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #424242;
  padding-top: 20px;
  text-align: center;
  @media (max-width: 1440px) {
    .text-content {
      margin-bottom: 0;
    }
  }
  .sub-title {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
      margin-right: 10px;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: rgba(240, 51, 10, 1);
    text-decoration: underline;
    width: 55%;
  }
`;
