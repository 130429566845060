import styled from 'styled-components';
import { Modal } from 'components';
import { ModalProps } from 'antd';

export const PopoverStyled = styled('div')`
  padding: 24px 12px;
  .main-content {
    margin-top: 24px;
  }
`;

export const ModalStyled = styled(Modal)<ModalProps>`
  @media (max-width: 1440px) {
    .ant-modal-footer {
      padding: 0 20px 40px 20px !important;
      .button-secondary,
      .button-primary {
        width: 160px;
        height: 48px;
      }
    }
  }
  .ant-modal-body {
    padding: 40px;
    background-color: #f9f8f8;
  }
  .ant-modal-footer {
    padding: 0 20px 20px 20px;
    background-color: #f9f8f8;
  }
  .ant-modal-content {
    width: 920px;
  }
`;

export const Styled = styled.div`
  @media (max-width: 1440px) {
    .header-modal .under-contract .under-contract-1 {
      width: 100px;
    }
  }
  .header-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    .sub-text {
      color: rgba(119, 119, 119, 1);
      font-size: 12px;
    }
    .under-contract {
      background: #08a3a5;
      border-radius: 2px;
      border: none;
      font-weight: 700;
      font-size: 13px;
      color: #ffffff;
      height: 28px;
      padding: 0 30px;
      cursor: pointer;
    }
    .under-contract-1 {
      background: #a6abab;
      border-radius: 2px;
      border: none;
      font-weight: 700;
      font-size: 13px;
      color: #ffffff;
      height: 28px;
      padding: 0 30px;
      cursor: pointer;
    }
  }

  .checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #8b62e2;
      border-color: #8b62e2;
    }
    .text-checkbox {
      font-size: 13px;
    }
  }
  .table-above {
    margin-top: 20px;
    .contract-start-date {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      div {
        padding: 11px 0;
      }
      span {
        margin-left: 8px;
      }
      .contract-first,
      .contract-third {
        width: 24%;
        background-color: #ebebeb;
        color: rgba(66, 66, 66, 1);
      }
      .contract-third {
        border-left: 1px solid rgba(204, 204, 204, 1);
      }
      .contract-first > span,
      .contract-third > span {
        font-size: 12px;
      }
      .contract-second,
      .contract-fourth {
        font-size: 14px;
        color: rgba(42, 42, 42, 1);
      }
      .contract-second {
        width: 32%;
      }
    }
    .usage-plan {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;
      div {
        padding: 11px 0;
      }
      span {
        margin-left: 8px;
      }
      .usage-first {
        width: 24%;
        background-color: #ebebeb;
      }
      .usage-first > span {
        font-size: 12px;
      }
      .usage-second {
        width: auto;
      }
      .usage-second > span {
        font-size: 14px;
      }
    }
    .request-method {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;

      .method-second {
        margin-left: 8px;
      }
      .method-first {
        width: 24%;
        background-color: #ebebeb;
        padding: 19px 8px;
      }
      .method-first > span {
        font-size: 12px;
      }
      .method-second {
        width: 12%;
      }
      .method-third {
        width: 22%;
        margin-left: 320px;
      }
      .method-fourth {
        width: auto;
      }
    }
    .unpaid-alert {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;
      height: 85px;
      .unpaid-first {
        width: 24%;
        background-color: #ebebeb;
        padding: 19px 8px;
        height: 100%;
      }
      .unpaid-first > span {
        font-size: 12px;
      }
      .unpaid-second {
        width: 76%;
        display: flex;

        .execution-checkbox {
          width: 35%;
          height: 100%;
          padding: 5px;
          border-right: 1px solid rgba(204, 204, 204, 1);

          .button-disabled {
            background-color: #aeadad;
            box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
            width: 67px;
            height: 27px;
            border-radius: 5px;
            border: none;
            color: white;
            font-size: 13px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
          }
          .button-success {
            background-color: #0c2f87;
            box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
            width: 67px;
            height: 27px;
            border-radius: 5px;
            border: none;
            color: white;
            font-size: 13px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
          }
          .button-error {
            background-color: #f40d0d;
            box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
            width: 67px;
            height: 27px;
            border-radius: 5px;
            border: none;
            color: white;
            font-size: 13px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
          }

          .values-check {
            width: 100%;
            display: flex;
            font-size: 11px;
            line-height: 24px;
            .label {
              width: 35%;
              text-align: right;
            }
            .icon-question {
              color: rgba(8, 163, 165, 1);
              margin-left: 4px;
              font-size: 15px;
              cursor: pointer;
            }
          }
        }
        .datetime-select {
          width: 65%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .key-value {
            width: 100%;
            display: flex;
            font-size: 11px;
            line-height: 24px;
            .label {
              width: 35%;
              text-align: right;
            }
            .values {
              width: 65%;
              .date-input {
                width: 70%;
              }
              .icon-question {
                color: rgba(8, 163, 165, 1);
                margin-left: 4px;
                font-size: 15px;
                cursor: pointer;
              }
              .label {
                margin-left: 10px;
                font-weight: bold;
              }
              .value {
                font-weight: bold;
                color: #fb0c0c;
              }
              .value-date {
                font-size: 11px;
                font-weight: bold;
                line-height: 24px;
                color: #7a67f0;
              }
              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #8b62e2;
                border-color: #8b62e2;
              }
            }
          }
        }
      }
    }
  }
  .table-below {
    margin-top: 24px;
    .address {
      display: flex;
      border: 1px solid rgba(204, 204, 204, 1);
      height: 90px;
      .address-first {
        width: 24%;
        background-color: rgba(235, 235, 235, 1);
        padding: 16px 0 0 8px;
        color: #424242;
        font-size: 12px;
      }
      .address-second {
        display: flex;
        flex-direction: column;
        margin: 16px 0 0 8px;
        .address-text {
          color: rgba(153, 153, 153, 1);
        }
        .address-number {
          color: #2a2a2a;
        }
      }
      .address-second > span {
        color: #2a2a2a;
        font-size: 14px;
      }
    }
    .manager-intelligence {
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;
      display: flex;
      .intelligence-first {
        width: 24%;
        background-color: rgba(235, 235, 235, 1);
        padding: 16px 0 0 8px;
        color: #424242;
        font-size: 12px;
      }
      .intelligence-second {
        display: flex;
        height: 136px;
        flex-direction: column;
        padding: 16px 0 0 8px;
        .intelligence-title {
          font-size: 14px;
        }
        .intelligence-text {
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          .intelligence-sub-text {
            color: rgba(42, 42, 42, 1);
          }
          .intelligence-email {
            color: rgba(8, 163, 165, 1);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .wrap-memo {
    margin-top: 10px;
  }
`;
