import styled from 'styled-components';

export const ModalDetailStyled = styled.div`
  .wrap-submit-search {
    text-align: center;
    padding: 16px;
    @media (max-width: 1440px) {
      .btn-search,
      .label-reset {
        width: 160px !important;
        height: 48px !important;
      }
      .form {
        .card-select-top {
          .status-partner {
            .select-item-right {
              .ant-select-selector {
                width: 180px !important;
              }
            }
          }
        }
      }
    }
    .btn-search {
      border: none;
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      width: 100px;
      height: 40px;
    }
    .label-reset {
      background: #ffffff;
      padding: 4px;
      margin-left: 12px;
      width: 100px;
      cursor: pointer;
      height: 40px;
      border: 1px solid #d9d9d9;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
    }
  }
  .ant-form-item-explain-error {
    display: none;
  }
  .style-errors {
    color: red;
    .icon {
      font-size: 18px;
      margin-right: 10px;
      margin-bottom: 15px;
    }
  }
  .form {
    padding-top: 25px;
    .card-select-top {
      border: 1px solid #cccccc;
      width: 100%;
      margin-bottom: 16px;
      .ant-form-item {
        margin-bottom: 0;
      }
      .status-partner {
        display: flex;
        .style-email {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #08a3a5;
          text-decoration-line: underline;
        }
        .style-text {
          color: #999999;
          font-weight: 400;
          font-size: 12px;
        }
        .style-name {
          font-weight: 400;
          font-size: 14px;
        }
        .select-item-left {
          width: 30%;
          padding: 16px;
          background: #ebebeb;
          border-bottom: 1px solid #cccccc;
        }
        .select-item-right {
          width: 70%;
          padding: 16px;
          border-bottom: 1px solid #cccccc;
          .date_input {
            width: 50%;
          }
          .ant-select-selection-item {
            margin-left: 16px;
          }
          .status-background-green {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #08a3a5;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
          .status-background-orange {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #f6ac00;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
          .status-background-grey {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #c4c4c4;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
        }
        .select-item-left-top {
          width: 30%;
          padding: 16px;
          background: #ebebeb;
        }
        .select-item-right-top {
          width: 70%;
          padding: 16px;
          .date_input {
            width: 50%;
          }
          .ant-select-selection-item {
            margin-left: 16px;
          }
          .status-background-green {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #08a3a5;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
          .status-background-orange {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #f6ac00;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
          .status-background-grey {
            display: flex;
            position: relative;
            width: 70%;
            ::before {
              position: absolute;
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 25px;
              background: #c4c4c4;
              top: 14px;
              left: 10px;
              z-index: 1;
            }
          }
        }
        .select-item-left-bottom {
          width: 30%;
          padding: 16px;
          background: #ebebeb;
        }
        .select-item-right-bottom {
          width: 70%;
          padding: 16px;
        }
      }
    }

    .require {
      color: red;
    }
    .card-detail {
      margin-top: 16px;
      .grid-style-left {
        width: 30%;
        background: #f5f5f5;
      }
      .grid-style-right {
        width: 70%;
        .date-input {
          width: 50%;
        }
      }
    }
  }

  .title-detail-momo {
    font-size: 18px;
    color: #2a2a2a;
    font-style: normal;
    font-weight: 400;
  }
  .title-modal {
    color: #777777;
    font-size: 12px;
    font-family: 'Noto Sans Javanese';
    font-style: normal;
    font-weight: 400;
  }
`;
