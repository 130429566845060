import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, servicesMock } from 'services';
import * as Types from 'types';
import {
  BILLING_DATA_DETAIL,
  BILLING_DATA_MANAGEMENT,
  CURRICULUM_HIERARCHY,
  SELECT_ERROR,
  SELECT_ORDER_ID,
  SELECT_PROVIDER_DATE,
  SELECT_USER_BILLING,
} from 'configs';

export const getDataDetailCorporate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataDetailCorporate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await servicesMock.filter(CURRICULUM_HIERARCHY.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataBillingManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataBillingManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_MANAGEMENT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectProviderDate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataSelectProviderDate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_PROVIDER_DATE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillingDataDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getBillingDataDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_DETAIL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectOrderID = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataSelectOrderID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ORDER_ID.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectUserBilling = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataSelectUserBilling', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_USER_BILLING.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectError = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataSelectError', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ERROR.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillingDataDetailExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getBillingDataDetailExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_DETAIL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
