import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import { getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import * as Types from 'types';
import {
  getSelectCurriculumCreator,
  getSelectCurriculum,
  getSelectQuestion,
  getDataQuestion,
  getSystemmaster,
  getSelectTypes,
  getCompany,
  getDataAllQuestion,
} from './thunk';

export interface InitialState {
  curriculumNameSelect: Array<Types.SelectCurriculums.ResponseSelectType>;
  curriculumTypeSelect: Array<Types.CreateEditSkillCheckSelectType>;
  curriculumCreatorSelect: Array<Types.CurriculumCreatorSelect>;
  scoreSelect: Array<Types.CreateEditSkillCheckSelectType>;
  dataQuestion: Array<Types.QuestionSearch.ResponseType>;
  dataAllQuestion: Array<Types.Questions.ResponseType>;
  questionSelect: Array<Types.QuestionSelect>;
  systemmaster: Types.GetSystemmasterType[];
  timeLimit: Types.TimeLimitType[];
  defaultTimeLimit: number;
  loading: boolean;
  total: number;
}

const initialState: InitialState = {
  curriculumCreatorSelect: [],
  curriculumNameSelect: [],
  curriculumTypeSelect: [],
  dataAllQuestion: [],
  defaultTimeLimit: 0,
  questionSelect: [],
  dataQuestion: [],
  systemmaster: [],
  scoreSelect: [],
  loading: false,
  timeLimit: [],
  total: 0,
};

export const questionMasterSlice = createSlice({
  name: 'questionMaster-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataQuestion.pending, startLoading)
      .addCase(getSelectCurriculum.pending, startLoading)
      .addCase(getSelectCurriculumCreator.pending, startLoading)
      .addCase(getSelectQuestion.pending, startLoading)
      .addCase(getSelectTypes.pending, startLoading)
      .addCase(getOptionLimit.pending, startLoading)
      .addCase(getSystemmaster.pending, startLoading);

    builder.addCase(getDataQuestion.fulfilled, (state, action) => {
      state.dataQuestion = unionBy(action.payload.report_results, 'question_code');
      state.total = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getDataAllQuestion.fulfilled, (state, action) => {
      state.dataAllQuestion = action.payload.items;
      stopLoading(state);
    });

    builder.addCase(getSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectCurriculumCreator.fulfilled, (state, action) => {
      state.curriculumCreatorSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectQuestion.fulfilled, (state, action) => {
      state.questionSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.defaultTimeLimit = Number(action.payload.items[0].time_limit) || 0;
    });
    builder.addCase(getSystemmaster.fulfilled, (state, action) => {
      state.systemmaster = action.payload.items.filter(({ type }) => type === 'initial_time_limit');
      stopLoading(state);
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.type === 'required_curriculum') {
          state.curriculumTypeSelect.push(item);
        } else if (item.type === 'score') {
          state.scoreSelect.push(item);
        }
      });
      stopLoading(state);
    });
    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.timeLimit =
        action.payload.fields['65598664baeaf8d6328c95f3']?.options
          ?.filter((o: Types.TimeLimitType) => o.enabled)
          .sort((a: Types.TimeLimitType, b: Types.TimeLimitType) => a.sort_id - b.sort_id) || [];
      stopLoading(state);
    });

    builder
      .addCase(getDataQuestion.rejected, stopLoading)
      .addCase(getSelectCurriculum.rejected, stopLoading)
      .addCase(getSelectCurriculumCreator.rejected, stopLoading)
      .addCase(getSelectQuestion.rejected, stopLoading)
      .addCase(getSelectTypes.rejected, stopLoading)
      .addCase(getOptionLimit.rejected, stopLoading)
      .addCase(getSystemmaster.rejected, stopLoading);
  },
});

export default questionMasterSlice.reducer;
