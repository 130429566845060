import React from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import {
  DeleteOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { createLinkQuestionAssignLevel, deleteQuestion } from 'containers/CreateEditQuestion/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { getCurriculum, updateCurriculum } from 'pages/Curriculum/thunk';
import { ConditionsType, UnrelatedQuestionSearchFormik } from 'types';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { searchQuestionCurriculumSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { removeNodeLevel4Selected } from '../slice';
import { getUnrelatedQuestions } from '../thunk';
import { Input, SelectField } from 'components';
import QuestionPopup from '../QuestionPopup';
import { useAppDispatch } from 'hooks';
import { Button } from '../styles';
import Item from '../Item';

const { Option } = Select;

type Props = {
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<Array<{ i_id: string; name: string; code: string }>>
  >;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
};

const UnrelatedQuestionSearch: React.FC<Props> = ({
  selectedQuestion,
  setSelectedQuestion,
  setOpenModalCreateQuestion,
}) => {
  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const { nodeLevel4Selected, creatorOption, unrelatedQuestions } = useSelector(
    searchQuestionCurriculumSelector
  );

  const dispatch = useAppDispatch();

  const formik = useFormik<UnrelatedQuestionSearchFormik>({
    initialValues: {
      creator: '',
      name: '',
    },
    onSubmit: async (values) => {
      const conditions: ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: (key as keyof typeof values) !== 'name',
          });
        }
      });
      await dispatch(
        getUnrelatedQuestions({
          conditions,
          page: 1,
          per_page: 0,
        })
      );
    },
  });

  const showModalCreateEditQuestion = () => {
    setOpenModalCreateQuestion({ visible: true, type: 'create', onSubmit: formik.handleSubmit });
  };

  const handleLinkQuestion = async (type?: 'all') => {
    dispatch(startLoading());
    if (type === 'all') {
      const resultActions = await Promise.all(
        unrelatedQuestions.map((item, i) =>
          dispatch(
            createLinkQuestionAssignLevel({
              item: {
                provider_id: userInfo?.company_id,
                level4_code: nodeLevel4Selected?.code,
                code: item.code,
                sort_order: Number(nodeLevel4Selected?.sort_order) + i,
              },
            })
          )
        )
      );
      await dispatch(
        updateCurriculum({
          id: nodeLevel4Selected?.curriculum_id!,
          data: {
            item: {
              probs_count:
                (nodeLevel4Selected?.problems_count || 0) +
                resultActions.filter((r) => createLinkQuestionAssignLevel.fulfilled.match(r))
                  .length,
              updatedat: new Date(),
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
    } else {
      const resultActions = await Promise.all(
        selectedQuestion.map((item, i) =>
          dispatch(
            createLinkQuestionAssignLevel({
              item: {
                provider_id: userInfo?.company_id,
                level4_code: nodeLevel4Selected?.code,
                code: item.code,
                sort_order: Number(nodeLevel4Selected?.sort_order) + i,
              },
            })
          )
        )
      );
      await dispatch(
        updateCurriculum({
          id: nodeLevel4Selected?.curriculum_id!,
          data: {
            item: {
              probs_count:
                (nodeLevel4Selected?.problems_count || 0) +
                resultActions.filter((r) => createLinkQuestionAssignLevel.fulfilled.match(r))
                  .length,
              updatedat: new Date(),
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
    }
    setSelectedQuestion([]);
    Promise.all([
      dispatch(removeNodeLevel4Selected()),
      dispatch(
        getCurriculum({
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
    dispatch(stopLoading());
    formik.handleSubmit();
  };

  const handleDeleteQuestions = async () => {
    if (selectedQuestion.length) {
      await Promise.all(selectedQuestion.map((i) => dispatch(deleteQuestion({ id: i.i_id }))));
      formik.handleSubmit();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{
          flex: '27%',
        }}
        colon={false}
        className="form"
      >
        <p className="title">未設定の設問を検索できます。</p>
        <Form.Item
          name="creator"
          label={<span className="label">作成者</span>}
          className="form-input"
        >
          <SelectField allowClear className="select-input" name="creator">
            {creatorOption.map(({ creator }, index) => (
              <Option key={index} value={creator}>
                {creator}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="name"
          label={<span className="label">フリーワード</span>}
          className="form-input"
        >
          <Input className="select-input" name="name" />
        </Form.Item>
        <div className="wrap-center">
          <SubmitButton className="btn-search">
            <SearchOutlined className="icon-search" />
            検索
          </SubmitButton>
          <ResetButton className="label-reset">リセット</ResetButton>
        </div>
      </Form>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={!!unrelatedQuestions?.length && !!nodeLevel4Selected}
            disabled={!unrelatedQuestions.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion('all')}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!selectedQuestion.length && !!nodeLevel4Selected}
            disabled={!selectedQuestion.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion()}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 300,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {unrelatedQuestions && unrelatedQuestions.length > 0 ? (
              unrelatedQuestions.map((item, index) => (
                <Item
                  key={index}
                  item={{ i_id: item.i_id, code: item.code, name: item.name }}
                  onSubmit={formik.handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <Button active className="btn" onClick={showModalCreateEditQuestion}>
              <PlusOutlined className="icon" />
              設問新規作成
            </Button>
            <Button
              active={!!selectedQuestion.length}
              className="btn"
              onClick={handleDeleteQuestions}
            >
              <DeleteOutlined className="icon" />
              設問削除
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default UnrelatedQuestionSearch;
