import React from 'react';
import { Column } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';

const { Option } = Select;

const GraphNonExpansion: React.FC = () => {
  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">売上推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>売上種類選択：</label>
                  <Select>
                    <Option> ALL</Option>
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">法人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">個人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">全ユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：千円</span>
              <Column
                {...config}
                legend={false}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                  label: {
                    formatter: (text) => `${text},000`,
                  },
                }}
                xAxis={{
                  tickLine: null,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphNonExpansion;

const dataColumn = [
  {
    name: '法人ユーザー',
    month: '2022/01',
    value: 18.9,
  },
  {
    name: '個人ユーザー',
    month: '2022/01',
    value: 135,
  },
  {
    name: '法人ユーザー',
    month: '2022/02',
    value: 28.8,
  },
  {
    name: '個人ユーザー',
    month: '2022/02',
    value: 60.2,
  },
  {
    name: '法人ユーザー',
    month: '2022/03',
    value: 39.3,
  },
  {
    name: '個人ユーザー',
    month: '2022/03',
    value: 17,
  },
  {
    name: '法人ユーザー',
    month: '2022/04.',
    value: 81.4,
  },
  {
    name: '個人ユーザー',
    month: '2022/04.',
    value: 50,
  },
  {
    name: '法人ユーザー',
    month: '2022/05',
    value: 47,
  },
  {
    name: '個人ユーザー',
    month: '2022/05',
    value: 74,
  },
  {
    name: '法人ユーザー',
    month: '2022/06',
    value: 20.3,
  },
  {
    name: '個人ユーザー',
    month: '2022/06',
    value: 76,
  },
  {
    name: '法人ユーザー',
    month: '2022/06',
    value: 24,
  },
  {
    name: '個人ユーザー',
    month: '2022/06',
    value: 80,
  },
  {
    name: '法人ユーザー',
    month: '2022/08',
    value: 35.6,
  },
  {
    name: '個人ユーザー',
    month: '2022/08',
    value: 40,
  },
  {
    name: '全ユーザー',
    month: '2022/01',
    value: 12.4,
  },
  {
    name: '全ユーザー',
    month: '2022/02',
    value: 23.2,
  },
  {
    name: '全ユーザー',
    month: '2022/03',
    value: 34.5,
  },
  {
    name: '全ユーザー',
    month: '2022/04.',
    value: 99.7,
  },
  {
    name: '全ユーザー',
    month: '2022/05',
    value: 52.6,
  },
  {
    name: '全ユーザー',
    month: '2022/06',
    value: 35.5,
  },
  {
    name: '法人ユーザー',
    month: '2022/07',
    value: 50,
  },
  {
    name: '全ユーザー',
    month: '2022/07',
    value: 37.4,
  },
  {
    name: '個人ユーザー',
    month: '2022/07',
    value: 20,
  },
  {
    name: '全ユーザー',
    month: '2022/08',
    value: 42.4,
  },
];
const config = {
  data: dataColumn,
  isGroup: true,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 15,
  dodgePadding: 4,
  color: ['#4365BD', '#E5C82D', '#6DC786'],
};
