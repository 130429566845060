import styled from 'styled-components';

import * as Types from 'types';

type Props = { nodeHeight: number; node?: Types.TreeItem<Types.CurriculumItemType> };

export const Wrapper = styled.div<Props>`
  .ant-popover-inner-content {
    width: auto !important;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.nodeHeight}px;
  .box-hover {
    position: absolute;
    right: 8px;
    .icon {
      color: #c4c4c4;
    }
  }
  .rst__node {
    user-select: none;
  }

  .rst__nodeContent {
    width: ${(props) => (!props.node || !props.node.parentNode ? '100%' : '70%')};
    height: 80%;
  }

  .rst__lineBlock {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .rst__lineHalfHorizontalLeft::before,
  .rst__lineHalfHorizontalLeftVerticalTop::before,
  .rst__lineHalfHorizontalLeftParentNode::before,
  .rst__lineHalfVerticalTopParentNode::after,
  .rst__lineHalfVerticalTop::after {
    position: absolute;
    content: '';
  }

  .rst__lineHalfHorizontalLeftParentNode::before {
    height: 1.5px;
    top: 50%;
    right: 100%;
    width: 100%;
    background: repeating-linear-gradient(
      to right,
      #8b8b8b 0,
      #8b8b8b 3px,
      transparent 0px,
      transparent 5px
    );
  }

  .rst__lineHalfHorizontalLeft::before {
    height: 1.5px;
    top: 50%;
    right: 100%;
    width: 46%;
    background: repeating-linear-gradient(
      to right,
      #8b8b8b 0,
      #8b8b8b 3px,
      transparent 0px,
      transparent 5px
    );
  }

  .rst__lineHalfHorizontalLeftVerticalTop::before {
    height: 1.5px;
    top: 50%;
    right: 100%;
    width: 20%;
    background: repeating-linear-gradient(
      to right,
      #8b8b8b 0,
      #8b8b8b 3px,
      transparent 0px,
      transparent 5px
    );
  }

  .rst__lineHalfVerticalTop::after {
    width: 1px;
    left: -20%;
    bottom: 46%;
    height: ${(props) =>
      props.node ? (props.node.lineIndex! - (props.node.parentNode?.lineIndex || 1)) * 125 : 0}%;
    background: repeating-linear-gradient(
      to bottom,
      #8b8b8b 0,
      #8b8b8b 3px,
      transparent 0px,
      transparent 5px
    );
  }

  .rst__lineHalfVerticalTopParentNode::after {
    width: 1px;
    left: -100%;
    bottom: 50%;
    height: 80%;
    background: repeating-linear-gradient(
      to bottom,
      #8b8b8b 0,
      #8b8b8b 3px,
      transparent 0px,
      transparent 5px
    );
  }

  .rowWrapper {
    height: 100%;
  }

  .rowContentsDragDisabled {
    justify-content: flex-start !important;
  }

  .row {
    width: 100%;
    height: 100%;
    .rowContents {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      background: ${(props) => (props.node?.columnIndex === 5 ? '#F5FDFD' : '#ffffff')};
      border: ${(props) =>
        props.node?.columnIndex === 5 ? '1px solid #7BBEBF' : '1px solid #cccccc'};
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      padding: 0 10px;
      .icon-dragging {
        position: absolute;
        left: 10px;
      }
      .rowTitle {
        display: block;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        color: ${(props) => (props.node?.columnIndex === 5 ? '#159193' : '#2a2a2a')};
        text-overflow: ellipsis;
      }
      .empty {
        color: #999999;
      }
      .icon {
        margin: 0 2px;
      }
      .icon-required {
        width: 16px;
      }
    }
    .node-selected {
      background: #dd3d63;
      border: 1px solid #dd3d63;
      .rowTitle {
        color: #ffffff;
      }
    }
  }
  .moving {
    padding: 1.5px;
    background: linear-gradient(to right, #009acb 4px, transparent 4px) 0 0,
      linear-gradient(to right, #009acb 4px, transparent 4px) 0 100%,
      linear-gradient(to left, #009acb 4px, transparent 4px) 100% 0,
      linear-gradient(to left, #009acb 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, #009acb 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, #009acb 4px, transparent 4px) 100% 0,
      linear-gradient(to top, #009acb 4px, transparent 4px) 0 100%,
      linear-gradient(to top, #009acb 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 12px 12px;
    .rowContents {
      background-color: rgba(0, 154, 203, 0.05);
      border-color: #009acb;
    }
  }
  .select .rowContents {
    background: #009acb;
    border-color: #009acb;
    .rowTitle {
      color: #ffffff;
    }
  }
`;
