import { createSlice } from '@reduxjs/toolkit';

import { getConfirmRegistration, isFirstLogin, signIn } from './thunk';
import * as Types from 'types';

export interface InitialState {
  registrationConfirm?: Types.GetConfirmRegistrationRes;
  userInfo?: Types.Users.ResponseType;
  signedInEmail?: string;
  isFirstLogin: boolean;
  IDconfirming: boolean;
  isLogin: boolean;
  loading: boolean;
  err?: string;
}

const initialState: InitialState = {
  isLogin: !!(localStorage.getItem('email') && localStorage.getItem('remember_me') === 'saved'),
  isFirstLogin: false,
  IDconfirming: false,
  loading: false,
};

export const authSlice = createSlice({
  name: 'auth-container',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.userInfo = undefined;
      state.signedInEmail = undefined;
      localStorage.removeItem('sk_access_token');
      localStorage.removeItem('email');
    },
  },
  extraReducers(builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLogin = true;
      state.signedInEmail = action.payload.signInUrl;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.err = action.error.message;
      state.isLogin = false;
    });
    builder.addCase(isFirstLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(isFirstLogin.fulfilled, (state, action) => {
      state.isFirstLogin = !action.payload.totalItems;
      if (action.payload.totalItems) {
        state.userInfo = action.payload.items[0];
        state.isLogin = true;
      } else {
        if (!state.signedInEmail) {
          state.isLogin = false;
        }
      }
      state.loading = false;
    });
    builder.addCase(isFirstLogin.rejected, (state) => {
      state.isLogin = false;
      state.loading = false;
    });
    builder.addCase(getConfirmRegistration.pending, (state) => {
      state.IDconfirming = true;
    });
    builder.addCase(getConfirmRegistration.fulfilled, (state, action) => {
      state.registrationConfirm = action.payload;
      state.IDconfirming = false;
    });
    builder.addCase(getConfirmRegistration.rejected, (state) => {
      state.IDconfirming = false;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
