import { createSlice } from '@reduxjs/toolkit';
import { groupBy, reduce } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';

import {
  getDataAggregationChurnRate,
  getDataAggregationChurnRateExport,
  getDataChangesInUsageOfOfficialCurriculum,
  getDataChangesInUsageOfOfficialCurriculumChurnRate,
  getDataNumberOfCorporateUsers,
  getDataNumberOfIndividualUsers,
  getDataNumberOfPartnerCompanies,
  getDataNumOfficialCurriculumCountOfStops,
  getDataNumOfficialCurriculumUsers,
  getDataNumOfficialCurriculumUsersActive,
  getDataNumOfNewUsersMonth,
  getDataNumOfSkillChecksPerformed,
  getDataNumOfSkillChecksPerformedMonth,
  getDataSalesTrends,
  getDataSkillCheckTrans,
  getNumberOfInterviewsConducted,
  getDataUsageList,
  getUserCount,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  numOfCorporateUsers: number;
  numOfIndividualUsers: number;
  numOfPartnerCompanies: number;
  numOfImplementations: number;
  numOfInternalImplementations: number;
  numOfInterviewsConducted: number;
  numOfficialCurriculumUsers: number;
  numOfficialCurriculumUsersActive: number;
  numOfNewUsersMonth: number;
  numOfficialCurriculumCountOfStops: number;
  dataAggregationChurnRate: Array<Types.AggregationChurnRate.ResponseType>;
  dataUserTransitionColumnChart: Array<Types.UserTransitionColumnChart>;
  dataEndOfMonthUsersActiveColumnChart: Array<Types.UserTransitionColumnChart>;
  dataEndOfMonthUsersColumnChart: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionColumnChartActive: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionLineChart: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionLineChartActive: Array<Types.UserTransitionLineChart>;
  dataNumOfImplementations: Array<Types.NumOfSkillChecksPerformedMonth>;
  dataChangesInUsageOfOfficialCurriculumColumn: Array<Types.UserTransitionColumnChart>;
  dataChangesInUsageOfOfficialCurriculumLine: Array<Types.UserTransitionColumnChart>;
  dataSalesTrendsDual: Array<Types.DualAxesData>;
  dataSalesTrendsLine: Array<Types.LineData>;
  dataPreviosMonthSale: number;
  dataSaleForSameMonthLastYear: number;
  dataMonthlyUsageFee: number;
  dataStorageUsageFee: number;
  dataSkillCheckUsageFee: number;
  dataUsage: number;
  dataCountUser: number;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  numOfCorporateUsers: 0,
  numOfIndividualUsers: 0,
  numOfPartnerCompanies: 0,
  numOfImplementations: 0,
  numOfInternalImplementations: 0,
  numOfInterviewsConducted: 0,
  numOfficialCurriculumUsers: 0,
  numOfficialCurriculumUsersActive: 0,
  numOfNewUsersMonth: 0,
  numOfficialCurriculumCountOfStops: 0,
  dataAggregationChurnRate: [],
  dataUserTransitionColumnChart: [],
  dataUserTransitionColumnChartActive: [],
  dataUserTransitionLineChart: [],
  dataUserTransitionLineChartActive: [],
  dataNumOfImplementations: [],
  dataEndOfMonthUsersActiveColumnChart: [],
  dataEndOfMonthUsersColumnChart: [],
  dataChangesInUsageOfOfficialCurriculumColumn: [],
  dataChangesInUsageOfOfficialCurriculumLine: [],
  dataSalesTrendsDual: [],
  dataSalesTrendsLine: [],
  dataPreviosMonthSale: 0,
  dataSaleForSameMonthLastYear: 0,
  dataMonthlyUsageFee: 0,
  dataSkillCheckUsageFee: 0,
  dataStorageUsageFee: 0,
  dataUsage: 0,
  dataCountUser: 0,
};

export const dashboardSlice = createSlice({
  name: 'dashboard-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataChangesInUsageOfOfficialCurriculumChurnRate.pending, startLoading)
      .addCase(getDataChangesInUsageOfOfficialCurriculum.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumCountOfStops.pending, startLoading)
      .addCase(getDataNumOfNewUsersMonth.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumUsersActive.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumUsers.pending, startLoading)
      .addCase(getDataNumOfSkillChecksPerformedMonth.pending, startLoading)
      .addCase(getNumberOfInterviewsConducted.pending, startLoading)
      .addCase(getDataNumOfSkillChecksPerformed.pending, startLoading)
      .addCase(getDataSkillCheckTrans.pending, startLoading)
      .addCase(getDataAggregationChurnRate.pending, startLoading)
      .addCase(getDataNumberOfPartnerCompanies.pending, startLoading)
      .addCase(getDataNumberOfCorporateUsers.pending, startLoading)
      .addCase(getDataAggregationChurnRateExport.pending, startLoading)
      .addCase(getDataNumberOfIndividualUsers.pending, startLoading)
      .addCase(getDataSalesTrends.pending, startLoading)
      .addCase(getUserCount.pending, startLoading);

    builder.addCase(getDataNumberOfCorporateUsers.fulfilled, (state, action) => {
      state.numOfCorporateUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumberOfIndividualUsers.fulfilled, (state, action) => {
      state.numOfIndividualUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumberOfPartnerCompanies.fulfilled, (state, action) => {
      state.numOfPartnerCompanies = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataAggregationChurnRate.fulfilled, (state, action) => {
      state.dataAggregationChurnRate = action.payload.report_results;
      const dataMembership = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '入会数',
        value: item.number_of_monthly_enrollments,
      }));
      const dataNumOfWithdrawals = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '退会数',
        value: item.number_of_monthly_withdrawals,
      }));
      const dataMembershipActive = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '入会数',
        value: item.active_number_of_monthly_enrollments,
      }));
      const dataNumOfWithdrawalsActive = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '退会数',
        value: item.active_number_of_monthly_withdrawals,
      }));
      state.dataUserTransitionColumnChart = [...dataMembership, ...dataNumOfWithdrawals];
      state.dataUserTransitionColumnChartActive = [
        ...dataMembershipActive,
        ...dataNumOfWithdrawalsActive,
      ];

      state.dataUserTransitionLineChart = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        value: Number(item.churn_rate),
        name: 'Churn Rate',
      }));

      state.dataUserTransitionLineChartActive = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        value: item.active_churn_rate,
      }));
      state.dataEndOfMonthUsersColumnChart = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '月末ユーザー数',
        value: item.number_of_members_end_of_month,
      }));
      state.dataEndOfMonthUsersActiveColumnChart = action.payload.report_results.map((item) => ({
        target_month: item.target_month,
        name: '月末ユーザー数',
        value: item.active_number_of_members_end_of_month,
      }));
      stopLoading(state);
    });
    builder.addCase(getDataSkillCheckTrans.fulfilled, (state, action) => {
      state.numOfImplementations = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfSkillChecksPerformed.fulfilled, (state, action) => {
      state.numOfInternalImplementations = (action.payload.report_results || []).reduce(
        (acc, curr) => acc + parseInt(curr.number_of_performed),
        0
      );
      stopLoading(state);
    });
    builder.addCase(getNumberOfInterviewsConducted.fulfilled, (state, action) => {
      state.numOfInterviewsConducted = (action.payload.report_results || []).reduce(
        (acc, curr) => acc + parseInt(curr.number_of_performed),
        0
      );
      stopLoading(state);
    });
    builder.addCase(getDataNumOfSkillChecksPerformedMonth.fulfilled, (state, action) => {
      const groupArray = groupBy(action.payload.report_results, (e) => e.implementation);
      const data: Types.NumOfSkillChecksPerformedMonth[] = [];
      Object.keys(groupArray).forEach((key) => {
        const dataInterView = groupArray[key].filter((item) => item.user_type === 'interview');
        const dataNotInterView = groupArray[key].filter((item) => item.user_type !== 'interview');
        ['面接', '社内', '実施合計'].forEach((type) => {
          data.push(
            reduce(
              groupArray[key],
              (res) => ({
                ...res,
                value:
                  type === '面接'
                    ? dataInterView.reduce(
                        (acc, curr) => acc + parseInt(curr.number_of_performed),
                        0
                      )
                    : type === '社内'
                    ? dataNotInterView.reduce(
                        (acc, curr) => acc + parseInt(curr.number_of_performed),
                        0
                      )
                    : dataInterView.length || dataNotInterView.length
                    ? 0
                    : dataInterView.reduce(
                        (acc, curr) => acc + parseInt(curr.number_of_performed),
                        0
                      ) +
                      dataNotInterView.reduce(
                        (acc, curr) => acc + parseInt(curr.number_of_performed),
                        0
                      ),
              }),
              {
                implementation: key,
                value: 0,
                type: type,
              }
            )
          );
        });
      });
      state.dataNumOfImplementations = data;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumUsers.fulfilled, (state, action) => {
      state.numOfficialCurriculumUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumUsersActive.fulfilled, (state, action) => {
      state.numOfficialCurriculumUsersActive = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfNewUsersMonth.fulfilled, (state, action) => {
      state.numOfNewUsersMonth = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumCountOfStops.fulfilled, (state, action) => {
      state.numOfficialCurriculumCountOfStops = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getDataChangesInUsageOfOfficialCurriculum.fulfilled, (state, action) => {
      const groupArray = groupBy(action.payload.items, (e) => e.target_month);
      const dataColumn: Types.UserTransitionColumnChart[] = [];
      const dataLine: Types.UserTransitionColumnChart[] = [];
      Object.keys(groupArray).forEach((key) => {
        ['新規利用', '利用停止', '月間利用数', 'アクティブ'].forEach((name) => {
          dataColumn.push(
            reduce(
              groupArray[key],
              (res, item) => ({
                ...res,
                value:
                  name === '新規利用'
                    ? Number(item.count_of_new_users)
                    : name === '利用停止'
                    ? Number(item.count_of_stops)
                    : name === '月間利用数'
                    ? Number(item.count_of_monthly_users)
                    : Number(item.count_of_monthly_active_users),
              }),
              {
                target_month: key,
                value: 0,
                name: name,
              }
            )
          );
        });
        dataLine.push(
          reduce(
            groupArray[key],
            (res, item) => ({
              ...res,
              value: Number(item.activation_rate.replace('%', '')),
            }),
            {
              target_month: key,
              value: 0,
              name: 'アクティブ比',
            }
          )
        );
      });
      state.dataChangesInUsageOfOfficialCurriculumColumn = dataColumn;
      state.dataChangesInUsageOfOfficialCurriculumLine = dataLine;
      stopLoading(state);
    });

    builder.addCase(getDataSalesTrends.fulfilled, (state, action) => {
      const groupByYearOfYear = action.payload.items.map((e) => ({
        time: dayjs(e.target_month).format('YYYY/MM'),
        count: Number(e.year_on_year),
        name: '月額利用料',
      }));
      const groupByMonthlyUsageFee = action.payload.items.map((e) => ({
        time: dayjs(e.target_month).format('YYYY/MM'),
        value: Number(e.monthly_usage_fee),
        type: '月額利用料',
      }));
      const groupByMonthlySkillCheckFee = action.payload.items.map((e) => ({
        time: dayjs(e.target_month).format('YYYY/MM'),
        value: Number(e.monthly_skill_check_usage_fee),
        type: 'スキルチェック利用料',
      }));
      const groupByTotalSales = action.payload.items.map((e) => ({
        time: dayjs(e.target_month).format('YYYY/MM'),
        value: Number(e.total_sales),
        type: '合計売上',
      }));

      state.dataSalesTrendsDual = [
        ...groupByMonthlyUsageFee,
        ...groupByMonthlySkillCheckFee,
        ...groupByTotalSales,
      ];
      state.dataSalesTrendsLine = groupByYearOfYear;
      state.dataPreviosMonthSale = action.payload.items
        .filter((e) => dayjs(e.target_month).month() === dayjs().month())
        .reduce((prev, next) => prev + Number(next.sales_previous_month) || 0, 0);

      state.dataSaleForSameMonthLastYear = action.payload.items
        .filter((e) => dayjs(e.target_month).month() === dayjs().month())
        .reduce((prev, next) => prev + Number(next.sales_same_month_previous_year) || 0, 0);
    });

    builder.addCase(getDataUsageList.fulfilled, (state, action) => {
      state.dataMonthlyUsageFee = action.payload.report_results.reduce(
        (prev, next) => prev + next.max_users * next.user_monthly_fee,
        0
      );
      state.dataSkillCheckUsageFee = action.payload.report_results.reduce(
        (prev, next) =>
          prev +
          (next.emp_skill_check_count + next.inter_skill_check_count) * next.skill_check_unit_price,
        0
      );
      state.dataUsage = action.payload.report_results.reduce(
        (prev, next) =>
          prev + (next.usage_storage / 1073741824 || 0) * next.usage_storage_unit_price,
        0
      );
    });

    builder.addCase(getUserCount.fulfilled, (state, action) => {
      state.dataCountUser = action.payload.totalItems;
    });

    builder
      .addCase(getDataChangesInUsageOfOfficialCurriculumChurnRate.rejected, stopLoading)
      .addCase(getDataChangesInUsageOfOfficialCurriculum.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumCountOfStops.rejected, stopLoading)
      .addCase(getDataNumOfNewUsersMonth.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumUsersActive.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumUsers.rejected, stopLoading)
      .addCase(getDataNumOfSkillChecksPerformedMonth.rejected, stopLoading)
      .addCase(getNumberOfInterviewsConducted.rejected, stopLoading)
      .addCase(getDataNumOfSkillChecksPerformed.rejected, stopLoading)
      .addCase(getDataSkillCheckTrans.rejected, stopLoading)
      .addCase(getDataAggregationChurnRate.rejected, stopLoading)
      .addCase(getDataNumberOfPartnerCompanies.rejected, stopLoading)
      .addCase(getDataNumberOfCorporateUsers.rejected, stopLoading)
      .addCase(getDataAggregationChurnRateExport.rejected, stopLoading)
      .addCase(getDataNumberOfIndividualUsers.rejected, stopLoading)
      .addCase(getUserCount.rejected, stopLoading);
  },
});

// export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
