import React from 'react';
import { Column, Line } from '@ant-design/plots';

import Styled from './styles';

const Development: React.FC = () => {
  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">月額利用料</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">ユーザー数</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="title">パートナー企業数推移</span>
            <div className="chart">
              <div className="line">
                <span className="unit">単位：千円</span>
                <Line
                  className="line-chart"
                  {...config}
                  label={{
                    position: 'top',
                    style: {
                      fill: '#F6AC00',
                    },
                  }}
                  lineStyle={{
                    stroke: '#F6AC00',
                  }}
                  xAxis={{
                    label: {
                      formatter: (text) => `${text}`,
                    },
                  }}
                  legend={{
                    layout: 'horizontal',
                    position: 'top-right',
                  }}
                  yAxis={{
                    label: {
                      formatter: (text) => `${text}`,
                    },
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                  }}
                />
              </div>
              <span className="title">一般企業数推移</span>
              <div className="column">
                <span className="unit">単位：千円</span>
                <Column
                  className="column-chart"
                  {...configColumn}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Development;

const dataLine = [
  {
    year: '1991',
    value: 87,
  },
  {
    year: '1992',
    value: 44,
  },
  {
    year: '1993',
    value: 35,
  },
  {
    year: '1994',
    value: 55,
  },
  {
    year: '1995',
    value: 49,
  },
  {
    year: '1996',
    value: 136,
  },
  {
    year: '1997',
    value: 78,
  },
  {
    year: '1998',
    value: 39,
  },
  {
    year: '1999',
    value: 13,
  },
];
const config = {
  data: dataLine,
  xField: 'year',
  yField: 'value',
  legend: true,
  point: {
    size: 5,
    shape: 'circle',
    style: {
      fill: '#F6AC00',
      stroke: '#F6AC00',
      lineWidth: 2,
    },
  },
  tooltip: {
    showMarkers: false,
  },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: '#000',
        fill: 'red',
      },
    },
  },
  interactions: [
    {
      type: 'marker-active',
    },
  ],
};

const dataColumn = [
  {
    name: 'London',
    month: '2022/01',
    value: 18.9,
  },
  {
    name: 'London',
    month: '2022/02',
    value: 28.8,
  },
  {
    name: 'London',
    month: '2022/03',
    value: 39.3,
  },
  {
    name: 'London',
    month: '2022/04.',
    value: 81.4,
  },
  {
    name: 'London',
    month: '2022/05',
    value: 47,
  },
  {
    name: 'London',
    month: '2022/06',
    value: 20.3,
  },
  {
    name: 'London',
    month: '2022/06',
    value: 24,
  },
  {
    name: 'London',
    month: '2022/08',
    value: 35.6,
  },
];
const configColumn = {
  data: dataColumn,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: '#08A3A5',
};
