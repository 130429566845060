import { Locale, CurriculumStatus, CurriculumType } from './enum.constant';
import { SelectType } from 'types';
import * as Types from 'types';

export const language: Array<Types.SelectType<keyof typeof Locale>> = [
  { label: 'Japanese', value: 'ja' },
  { label: 'Chinese', value: 'zh' },
  { label: 'English', value: 'en' },
];

export const CURRICULUM_TYPE: Array<SelectType<keyof typeof CurriculumType>> = [
  { label: 'ALL', value: 'all' },
  { label: 'カリキュラム', value: 'curriculum' },
  { label: '必修カリキュラム', value: 'required' },
];

export const CURRICULUM_STATUS: Array<Types.SelectType<keyof typeof CurriculumStatus>> = [
  { label: 'ALL', value: 'all' },
  { label: '公開中', value: 'publish' },
  { label: '編集中', value: 'edit' },
];

export const REPORT_TYPE: Array<
  Types.SelectType<'user' | 'user-analysis' | 'skill-check' | 'skill-check-analysis'>
> = [
  {
    label: 'ユーザーレポート',
    value: 'user',
  },
  {
    label: 'ユーザーレポート【分析グループ】',
    value: 'user-analysis',
  },
  {
    label: 'スキルチェックレポート',
    value: 'skill-check',
  },
  {
    label: 'スキルチェックレポート【分析グループ】',
    value: 'skill-check-analysis',
  },
];

export const ITEM_COMPARISION: Array<
  Types.SelectType<'progress_rate' | 'answer_breakdown' | 'answer_breakdown_percentage'>
> = [
  {
    label: '進捗率',
    value: 'progress_rate',
  },
  {
    label: '解答内訳【解答数】',
    value: 'answer_breakdown',
  },
  {
    label: '解答内訳【解答比率】',
    value: 'answer_breakdown_percentage',
  },
];

export const ITEM_COMPARISION_ANALYSIS: Array<
  Types.SelectType<
    | 'accuracy_rate'
    | 'wrong_rate'
    | 'inexperienced_rate'
    | 'average_answer_time_per_question'
    | 'acquisition_score_rate'
    | 'answer_breakdown'
  >
> = [
  {
    label: '正解率',
    value: 'accuracy_rate',
  },
  {
    label: '不正解率',
    value: 'wrong_rate',
  },
  {
    label: '未実施率',
    value: 'inexperienced_rate',
  },
  {
    label: '設問あたり平均解答時間',
    value: 'average_answer_time_per_question',
  },
  {
    label: '獲得スコア率',
    value: 'acquisition_score_rate',
  },
  {
    label: '解答内訳',
    value: 'answer_breakdown',
  },
];

export const PUBLISH: Array<Types.SelectType<Types.SkillCheckFilterType['publish']>> = [
  { label: '非公開', value: 0 },
  { label: '公開', value: 1 },
];

export const SEARCH_PARTNER = [
  { label: 'ALL', value: 'all' },
  { label: '契約中', value: '契約中' },
  { label: '契約終了', value: '契約終了' },
  { label: '契約終了予定', value: '契約終了予定' },
];

export const SEARCH_PARTNER_OPTION = [
  { label: '契約中', value: '0' },
  { label: '契約終了予定', value: '1' },
  { label: '契約終了', value: '2' },
];

export const SEARCH_PUBLISH_OPTION = [
  { label: '編集中', value: '0' },
  { label: '未公開', value: '1' },
  { label: '公開中', value: '2' },
  { label: '公開停止中', value: '3' },
];
