import React from 'react';
import { Table } from 'antd';

import TableSkillCheckInformationStyled from './styles';

interface Props {
  visibleCorrespondingSale: boolean;
}

const TableSkillCheckInformation: React.FC<Props> = ({ visibleCorrespondingSale }) => {
  const column = [
    {
      title: 'ユーザー種類',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '10%',
    },
    ...(visibleCorrespondingSale
      ? [
          {
            title: '項目名',
            dataIndex: 'name',
            width: '7%',
          },
        ]
      : []),
    ...date,
  ];
  return (
    <TableSkillCheckInformationStyled visibleCorrespondingSale={visibleCorrespondingSale}>
      <Table
        className="table"
        rowKey="code"
        columns={column}
        dataSource={data}
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
      />
    </TableSkillCheckInformationStyled>
  );
};

const dataDate = [
  { date: '01/2022' },
  {
    date: '02/2022',
  },
  {
    date: '03/2022',
  },
  {
    date: '04/2022',
  },
  {
    date: '05/2022',
  },
  {
    date: '06/2022',
  },
  {
    date: '07/2022',
  },
  {
    date: '08/2022',
  },
];

const date = dataDate.map((item, index) => ({
  title: item.date,
  dataIndex: `date${index + 1}`,
  key: item.date,
  width: '6%',
  render: (text: string) => <span>{text}</span>,
}));

const data = [
  {
    user_type: '全企業',
    name: '売上',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
    children: [
      {
        name: '実施回数',
        date1: '1,000,000',
        date2: '1,000,000',
        date3: '1,000,000',
        date4: '1,000,000',
        date5: '1,000,000',
        date6: '1,000,000',
        date7: '1,000,000',
        date8: '1,000,000',
      },
    ],
  },
  {
    user_type: 'RSTANDARD',
    name: '20',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
  },
  {
    user_type: 'パートナー企業',
    name: '30',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
  },
];

export default TableSkillCheckInformation;
