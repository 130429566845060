import React, { useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
// import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';

// import { authSelector } from 'containers/Auth/selectors';
import OfficialCurriculumInformationStyled from './styles';
import { DatePicker, SelectField } from 'components';
import ChartOfficialCurriculum from './Chart';
import TableOfficialCurriculum from './Table';
// import { useAppDispatch } from 'hooks';

const { Option } = Select;
const { RangePicker } = DatePicker;

const OfficialCurriculumInformation: React.FC = () => {
  const [visibleCurriculumDevelopment, setVisibleCurriculumDevelopment] = useState<boolean>(false);
  const [visibleProducerExpand, setVisibleProducerExpand] = useState<boolean>(false);
  const [activeTable, setActiveTable] = useState<boolean>(false);
  const [segmented, setSegmented] = useState<number>(0);

  // const { userInfo } = useSelector(authSelector);

  // const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      aggregation_method: '',
      company_type: '',
      author: '',
    },
    onSubmit: (values) => {},
  });

  const onChange = (e: CheckboxChangeEvent) => {
    if (!visibleProducerExpand) {
      setVisibleCurriculumDevelopment(false);
    }
    setVisibleProducerExpand(e.target.checked);
  };

  const onChangeActive = (e: CheckboxChangeEvent) => {
    setActiveTable(e.target.checked);
  };

  return (
    <OfficialCurriculumInformationStyled>
      <div className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="aggregation_method"
                    className="item"
                    label={<span className="text-label">集計方法選択</span>}
                  >
                    <SelectField
                      name="name"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={0}>OFFICIALカリキュラム利用者数推移</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="aggregation_period"
                    className="item"
                    label={<span className="text-label">集計期間</span>}
                  >
                    <RangePicker
                      allowClear
                      className="date"
                      name="date-item"
                      format="YYYY/MM/DD HH:mm"
                      placeholder={['集計開始日', '集計終了日']}
                      onChange={(dates) => {
                        if (dates) {
                          formik.setFieldValue('start_period', dates[0]?.toISOString());
                          formik.setFieldValue('end_period', dates[1]?.toISOString());
                        } else {
                          formik.setFieldValue('start_period', '');
                          formik.setFieldValue('end_period', '');
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company_type"
                    className="item"
                    label={<span className="text-label">企業種類選択</span>}
                  >
                    <SelectField
                      name="company_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">ALL</Option>
                      <Option value="b">全企業</Option>
                      <Option value="c">RSTANDARD</Option>
                      <Option value="d">パートナー企業</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="author"
                    className="item"
                    label={<span className="text-label">制作者検索</span>}
                  >
                    <SelectField
                      name="author"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">ALL</Option>
                      <Option value="b">個別指定</Option>
                    </SelectField>
                  </Form.Item>
                  <div className="group-btn">
                    <span className="label-reset" onClick={() => formik.resetForm()}>
                      リセット
                    </span>
                    <SubmitButton className="btn-search" loading={false}>
                      検索
                    </SubmitButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  checked={visibleProducerExpand && !segmented}
                  onChange={onChange}
                  disabled={!!segmented}
                >
                  制作者展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  disabled={!visibleProducerExpand || !!segmented}
                  checked={visibleCurriculumDevelopment && visibleProducerExpand && !segmented}
                  onClick={() => setVisibleCurriculumDevelopment(!visibleCurriculumDevelopment)}
                >
                  カリキュラム展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={activeTable} onChange={onChangeActive}>
                  アクティブ
                </Checkbox>
              </div>
            </div>
          </div>
          {segmented === 0 ? (
            <TableOfficialCurriculum
              visibleProducerExpand={visibleProducerExpand}
              visibleCurriculumDevelopment={visibleCurriculumDevelopment}
            />
          ) : (
            <ChartOfficialCurriculum />
          )}
        </div>
      </div>
    </OfficialCurriculumInformationStyled>
  );
};

export default OfficialCurriculumInformation;
