import styled from 'styled-components';

export const SectionStyled = styled.div`
  border-radius: 3px;
  padding: 20px;
  @media (max-width: 1440px) {
    padding: 20px 20px 0 20px;
  }
  .text-content {
    padding: 16px;
    font-size: 13px;
  }
`;
