import { flatMap, groupBy, uniq, values } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { getCorprateUserInfomation, getSelectCompany, getSelectPaymentMethod } from './thunk';
import * as Types from 'types';

export type InitialState = {
  corporateUsers: Array<Types.CorporateUser.CorporateUserInformationType>;
  selectCompany: Array<Types.Selects.SelectCompanyResType>;
  selectPaymentMethod: Array<Types.Selects.SelectPaymentMethodResType>;
};

const initialState: InitialState = {
  corporateUsers: [],
  selectCompany: [],
  selectPaymentMethod: [],
};

export const corporateSlice = createSlice({
  name: 'corporate-page',
  initialState,
  reducers: {
    filterDeleteAt: (state, action) => {
      if (action.payload === '1') {
        state.corporateUsers = state.corporateUsers.filter((item) => item.deletedat);
      } else if (action.payload === '0') {
        state.corporateUsers = state.corporateUsers.filter((item) => !item.deletedat);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getCorprateUserInfomation.fulfilled, (state, action) => {
      const groupedData: Types.CorporateUserInformationType[] = values(
        groupBy(action.payload.report_results, 'company_id')
      ).map((companyData) => {
        const skillcheckCount = uniq(flatMap(companyData, 'skillcheck_tid')).length;

        return {
          ...companyData[0],
          skillcheck_count: skillcheckCount,
        };
      });

      state.corporateUsers = groupedData;
    });

    builder.addCase(getSelectCompany.fulfilled, (state, action) => {
      state.selectCompany = action.payload.report_results;
    });

    builder.addCase(getSelectPaymentMethod.fulfilled, (state, action) => {
      state.selectPaymentMethod = action.payload.report_results;
    });
  },
});

export const { filterDeleteAt } = corporateSlice.actions;

export default corporateSlice.reducer;
