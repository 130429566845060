import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import { Select } from 'antd';
import {
  CloudDownloadOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  FilterOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { getDataOfficialCurriculumExportCSV, getOfficialCurriculumHierarchyList } from './thunk';
import { HEADER_OFFICIAL_CURRICULUM_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import NodeTreeView from 'containers/Curriculum/NodeTreeView';
import { CURRICULUM_TYPE } from 'constant/select.constants';
import { curriculumTreeSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { LIST_LABEL } from 'constant';
import { Header } from 'components';
import { SortByDesc } from 'assets';
import Styled from './styles';

const { Option } = Select;

const CurriculumTree: React.FC = () => {
  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [visibleExportFile, setVisibleExportFile] = useState(false);

  const { curriculumTree, loading } = useSelector(curriculumTreeSelector);
  const { headerTitle } = useSelector(settingSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataOfficialCurriculumExportCSV({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataOfficialCurriculumExportCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          official_curriculum_code: item.official_curriculum_code,
          curriculum_name: item.curriculum_name,
          curriculum_description: item.curriculum_description,
          level1_code: item.level1_code,
          level1_name: item.level1_name,
          level2_code: item.level2_code,
          level2_name: item.level2_name,
          level3_code: item.level3_code,
          level3_name: item.level3_name,
          level4_code: item.level4_code,
          level4_name: item.level4_name,
          question_code: item.question_code,
          question_name: item.question_name,
          question_description: item.question_description,
          question_attach: item.question_attach,
          question2_attach: item.question2_attach,
          question3_attach: item.question3_attach,
          problems1: item.problems1,
          problems2: item.problems2,
          problems3: item.problems3,
          problems1_attach: item.problems1_attach,
          problems2_attach: item.problems2_attach,
          problems3_attach: item.problems3_attach,
          answer: item.answer,
          comment: item.comment,
          problems1_attach_fileID: item.problems1_attach_fileID,
          problems2_attach_fileID: item.problems2_attach_fileID,
          problems3_attach_fileID: item.problems3_attach_fileID,
          time_limit: item.time_limit,
          score: item.score,
        }));

        const csvString = [
          HEADER_OFFICIAL_CURRICULUM_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setVisibleExportFile(false);
    }
  };

  useEffect(() => {
    dispatch(
      getOfficialCurriculumHierarchyList({
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Styled>
      <Header title={headerTitle} className="header">
        <form className="form">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select className="select-input" placeholder="ステータス">
              {CURRICULUM_TYPE.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))}
            </Select>
          </div>
          <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
          <div className="form-input">
            <Select
              showSearch
              className="select-input"
              placeholder="指定のカリキュラムを上位表示"
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {curriculumTree.map((curr, index) => (
                <Option key={index} value={curr.i_id}>
                  {curr.name}
                </Option>
              ))}
            </Select>
          </div>
          <button type="button" className="text-reset">
            クリア
          </button>
        </form>
      </Header>
      <div className="flex">
        <div className="dashboard">
          <div className="wrap-title">
            <div className="wrap-button">
              <span>パートナーが作成したOFFICIALカリキュラムツリーの確認を行う画面です。</span>
              <div className="button-function">
                <button
                  className="btn btn-outline"
                  onClick={() => navigate(routes.PartnerManagement.path)}
                >
                  公開管理
                  <RightOutlined className="size-icon-down-outline" />
                </button>
                <button
                  className="btn btn-outline"
                  onClick={() => navigate(routes.OfficialCurriculumList.path)}
                >
                  階層リスト
                  <RightOutlined className="size-icon-down-outline" />
                </button>
                <button onClick={() => setVisibleExportFile(true)} className="btn btn-active">
                  <CloudDownloadOutlined className="size-icon" />
                  エクスポート
                </button>
              </div>
            </div>

            <div className="flex-label">
              {LIST_LABEL.map((item, index) => (
                <p
                  key={index}
                  className={`label-text${index === columnClosed ? ' active' : ''}`}
                  onClick={() =>
                    setColumnClosed((prevState) =>
                      prevState === index || index > 4 ? undefined : index
                    )
                  }
                >
                  {index < 5 ? (
                    index === columnClosed ? (
                      <CaretUpOutlined className="icon-label" />
                    ) : (
                      <CaretDownOutlined className="icon-label" />
                    )
                  ) : null}
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="wrap-body">
            {curriculumTree.map((c, index) => (
              <div
                key={index}
                className={`wrap-tree ${index < curriculumTree?.length - 1 ? 'bordered' : ''}`}
              >
                <NodeTreeView
                  treeData={c}
                  treeViewIndex={index}
                  tabActive={0}
                  columnClosed={columnClosed}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={visibleExportFile}
        setVisible={setVisibleExportFile}
        onSubmit={handleExportCSV}
      />
    </Styled>
  );
};

export default CurriculumTree;
