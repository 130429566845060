import styled from 'styled-components';

export const SectionStyled = styled.div`
  .wrap-header {
    display: flex;
    justify-content: end;
    align-items: center;
    .button-export {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      padding: 20px;
      border-radius: 5px;
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      color: #ffffff;
      .download-icon {
        font-size: 18px;
      }
    }
  }
  .wrap-body {
    background: #dddddd;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      width: 95%;
      margin: 25px 0 25px 0;
      padding: 45px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
      height: 500px;
      overflow-y: scroll;
      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .image-logo {
          .image {
            width: 90px;
            height: 65px;
          }
        }
        .title {
          margin: 0;
          font-size: 20px;
          color: #222222;
          letter-spacing: 0.7em;
          position: absolute;
          bottom: -15px;
          left: 43%;
        }
        .preview {
          display: flex;
          .label {
            font-size: 11px;
            color: #555555;
          }
          .text-label {
            font-size: 11px;
            text-align: right;
            color: #555555;
          }
        }
      }
      .item-info {
        display: flex;
        justify-content: space-between;
        padding-top: 45px;
        .item-left {
          font-size: 12px;
          color: #222222;
          .name {
            font-size: 14px;
          }
        }
        .item-right {
          text-align: right;
          font-size: 12px;
          color: #222222;
          position: relative;
          .label-name {
            font-size: 8px;
            color: #777777;
          }
          .name-company {
            font-size: 14px;
          }
          .image-logo {
            width: 85px;
            height: 85px;
            position: absolute;
            bottom: -10px;
            right: -10px;
          }
        }
      }
      .total-header {
        margin-top: 20px;
        width: 45%;
        border: 1px solid #08a3a5;
        padding: 1px 6px 0 12px;
        display: flex;
        flex-direction: column;
        .total-header-title {
          font-size: 8px;
          color: #159193;
        }
        .total-number {
          display: flex;
          justify-content: flex-end;
          color: #222222;
          font-size: 22px;
          align-items: baseline;
          .currency-unit {
            font-size: 12px;
            color: #000000;
            margin-left: 8px;
          }
        }
      }
      .content-body {
        margin-top: 20px;
        table {
          width: 100%;
          border-bottom: 1px solid #08a3a5;
          tr {
            height: 36px;
            th {
              background-color: #08a3a5;
              font-weight: 700;
              font-size: 11px;
              line-height: 22px;
              color: #ffffff;
              text-align: center;
            }
            td {
              text-align: right;
              position: relative;
            }
            td:first-child {
              text-align: left;
              padding: 0 0 0 8px;
            }
            td:last-child {
              padding: 0 16px 0 0;
            }
            td:last-child::after {
              content: '';
              width: 0;
              height: 0;
              right: 0;
            }
            td::after {
              content: '';
              width: 1px;
              height: 24px;
              background-color: #eeeeee;
              position: absolute;
              right: 0;
            }
            td:nth-child(2)::after,
            td:nth-child(3)::after {
              content: '';
              width: 1px;
              height: 24px;
              background-color: #eeeeee;
              position: absolute;
              right: -12px;
            }
          }
          tr:nth-child(even) {
            background-color: #ffffff;
          }
          tr:nth-child(odd) {
            background-color: #fafafa;
          }
          tr:first-child {
            height: auto;
          }
        }
        .sub-table {
          display: flex;
          justify-content: flex-end;
          .wrap-sub-item {
            width: 40%;
            .sub-item {
              border-bottom: 1px solid #08a3a5;
              display: flex;
              justify-content: space-between;
              padding: 7px 0;
              align-items: center;
              .sub-title {
                padding-left: 8px;
                color: #555555;
                font-size: 12px;
              }
              .sub-price {
                padding-right: 8px;
                color: #222222;
                font-size: 16px;
              }
            }
          }
        }
      }
      .content-footer {
        margin-top: 76px;
        background: #08a3a5;
        background-color: rgba(8, 163, 165, 0.06);
        padding: 16px 0 16px 20px;
      }
    }
  }
`;
