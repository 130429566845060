import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { createInvoiceCorrespondence, updateInvoiceCorrespondence } from '../../thunk';
import { createEditInvoiceCorrespondenceSchema } from 'libs/validations';
import { invoiceCorrespondenceSelector } from '../../selector';
import { Input, SpinLoading, TextArea } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { Styled, ModalStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleComplete: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'create' | 'edit';
}

const ModalCreateEditInfoInvoice: React.FC<Props> = ({
  visible,
  setVisible,
  type,
  setVisibleComplete,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { invoiceCorrespondenceDetail } = useSelector(invoiceCorrespondenceSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.InvoiceCorrespondenceFormEditFormik>({
    initialValues:
      type === 'edit'
        ? invoiceCorrespondenceDetail!
        : {
            company_name: '',
            manager_name: '',
            email: '',
            memo: '',
          },
    validationSchema: createEditInvoiceCorrespondenceSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!userInfo) return;
      setLoading(true);
      if (type === 'create') {
        const actionResult = await dispatch(
          createInvoiceCorrespondence({
            item: {
              company_id: userInfo.company_id,
              company_name: values.company_name,
              manager_name: values.manager_name,
              email: values.email,
              memo: values.memo,
              url_issue_date: new Date(),
              account_registration_status: 0,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
          })
        );
        if (createInvoiceCorrespondence.fulfilled.match(actionResult)) {
          setVisibleComplete(true);
        }
      } else {
        const actionResult = await dispatch(
          updateInvoiceCorrespondence({
            id: invoiceCorrespondenceDetail?.item_ref?.email.i_id!,
            data: {
              item: {
                company_name: values.company_name,
                manager_name: values.manager_name,
                email: values.email,
                memo: values.memo,
                url_issue_date: new Date(),
                account_registration_status: 0,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        if (updateInvoiceCorrespondence.fulfilled.match(actionResult)) {
          setVisibleComplete(true);
        }
      }
      setLoading(false);
    },
  });

  return (
    <ModalStyled
      open={visible}
      okButton={{
        text: type === 'create' ? 'URL発行' : '更新',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setVisible(false);
        },
      }}
      onCancel={() => setVisible(false)}
      title={type === 'create' ? '請求書対応用URL発行' : '請求書対管理情報 編集'}
    >
      <SpinLoading loading={loading}>
        <Styled>
          <FormikProvider value={formik}>
            <Form layout="horizontal" colon={false}>
              <div className="form-edit">
                <Form.Item
                  name="company_name"
                  className="item"
                  label={
                    <span className="text-label">
                      法人名 <span className="sub-text-label">*</span>
                    </span>
                  }
                >
                  <Input name="company_name" showCount maxLength={100} placeholder="最大100文字" />
                </Form.Item>
                <Form.Item
                  name="manager_name"
                  className="item"
                  label={
                    <span className="text-label">
                      ご担当者氏名 <span className="sub-text-label">*</span>
                    </span>
                  }
                >
                  <Input
                    name="manager_name"
                    showCount
                    maxLength={100}
                    placeholder="英数字,記号：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  className="item"
                  label={
                    <span className="text-label">
                      メールアドレス <span className="sub-text-label">*</span>
                    </span>
                  }
                >
                  {
                    <Input
                      name="email"
                      showCount
                      maxLength={100}
                      placeholder="最大100文字"
                      disabled={type === 'edit'}
                    />
                  }
                </Form.Item>
                <Form.Item
                  name="memo"
                  className="item"
                  label={<span className="text-label">memo</span>}
                >
                  <TextArea
                    name="memo"
                    showCount
                    maxLength={1080}
                    style={{ height: 120, marginBottom: 24 }}
                    placeholder="最大1080文字"
                  />
                </Form.Item>
              </div>
            </Form>
          </FormikProvider>
        </Styled>
      </SpinLoading>
    </ModalStyled>
  );
};

export default ModalCreateEditInfoInvoice;
