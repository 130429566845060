import React from 'react';
import { Table } from 'antd';

import TableDevelopmentStyled from './styles';

interface Props {
  visibleExpansionOfSale: boolean;
}

const dataDate = [
  { date: '01/2022' },
  {
    date: '02/2022',
  },
  {
    date: '03/2022',
  },
  {
    date: '04/2022',
  },
  {
    date: '05/2022',
  },
  {
    date: '06/2022',
  },
  {
    date: '07/2022',
  },
  {
    date: '08/2022',
  },
];

const date = dataDate.map((item, index) => ({
  title: item.date,
  dataIndex: `date${index + 1}`,
  key: `date${index + 1}`,
  width: '8%',
  render: (text: string) => (text ? <span>{text}</span> : <span>-</span>),
  children: [
    {
      title: <span>全ユーザー</span>,
      dataIndex: `date${index + 1}`,
      key: `date${index + 1}`,
    },
  ],
}));

const column = [
  {
    title: 'ユーザー種類　/　ユーザー名・ID',
    dataIndex: 'name',
    key: 'name',
    className: 'name',
    width: '20%',
    render: (text: string, record: any) => (
      <div className="item-name">
        <span className="label-name">{record.name}</span>
        <span className="label-code">{record.code}</span>
      </div>
    ),
    children: [
      {
        title: <span>全ユーザー</span>,
        dataIndex: 'name',
        key: 'name',
      },
    ],
  },
  ...date,
];

const TableMonthlyByUser: React.FC<Props> = ({ visibleExpansionOfSale }) => {
  return (
    <TableDevelopmentStyled>
      <Table
        className="table"
        columns={column}
        dataSource={data.slice(1)}
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
      />
    </TableDevelopmentStyled>
  );
};

const data = [
  {
    key: '0',
    name: 'abc',
    code: '999',
    date1: '100,000,000',
    date2: '100,000,001',
    date3: '100,000,002',
    date4: '100,000,003',
    date5: '100,000,004',
    date6: '100,000,005',
    date7: '100,000,006',
    date8: '100,000,007',
  },
  {
    key: '1',
    name: '法人ユーザー',
    code: '999',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
    monthly: [
      {
        key: '111',
        name: '月額利用料',
        code: '20001',
        date1: '60,000,000',
        date2: '60,000,000',
        date3: '60,000,000',
        date4: '60,000,000',
        date5: '60,000,000',
        date6: '60,000,000',
        date7: '60,000,000',
        date8: '60,000,000',
      },
      {
        key: '112',
        name: 'スキルチェック利用料',
        code: '20002',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
    children: [
      {
        key: '12',
        name: '株式会社ボーダー',
        code: '10001',
        date1: '88,000,000',
        date2: '88,000,000',
        date3: '88,000,000',
        date4: '88,000,000',
        date5: '88,000,000',
        date6: '88,000,000',
        date7: '88,000,000',
        date8: '88,000,000',
      },
      {
        key: '11',
        name: 'YOKOMIZO設計所',
        code: '10002',
        date1: '48,000,000',
        date2: '48,000,000',
        date3: '48,000,000',
        date4: '48,000,000',
        date5: '48,000,000',
        date6: '48,000,000',
        date7: '48,000,000',
        date8: '48,000,000',
      },
    ],
  },
];

export default TableMonthlyByUser;
