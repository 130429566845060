import React from 'react';
import { CalendarOutlined, MailOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';

import { startLoading, stopLoading } from '../../../containers/AppSettings/slice';
import { DatePicker, Input, Modal } from 'components';
import { signUpUser } from 'containers/Auth/thunk';
import { registerSchema } from 'libs/validations';
import { SignUpUserRequest } from 'types';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { LogoPartner } from 'assets';

interface Props {
  visibleDetail?: boolean;
  detailSubmit: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModalComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalRegister: React.FC<Props> = ({
  setVisible,
  detailSubmit,
  visibleDetail,
  setOpenModalComplete,
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { email: '', date: '' },
    validationSchema: registerSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(startLoading());
      setVisible(false);
      const data: SignUpUserRequest = {
        workspace_id: 'skillfamiliar',
        url: '/api/v0/applications/skillfamiliarpartner/datastores/signup/items/new',
        method: 'POST',
        params: {
          action_id: 'signup',
          item: {
            name: values.email,
            start_at: new Date(),
          },
          as_params: {
            email: values.email,
            username: values.email,
          },
          is_force_update: true,
        },
      };

      const resultActionAddUser = await dispatch(signUpUser(data));
      if (signUpUser.fulfilled.match(resultActionAddUser)) {
        setOpenModalComplete(true);
      }
      dispatch(stopLoading());
    },
  });

  const handleCancelModalDetail = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={560}
      visible={visibleDetail}
      onCancel={handleCancelModalDetail}
      bodyStyle={{
        backgroundColor: '#FFF',
        borderRadius: '5px',
        padding: 5,
      }}
    >
      <SectionStyled>
        <div className="border-style-top" />
        <div className="content-register">
          <img src={LogoPartner} className="icon" alt="required-icon" />
          <p className="des-image">For Partners</p>
          <div className="partners-text">パートナー企業アカウント登録</div>
          <div className="partners-description">
            アカウント登録を行うパートナー企業の
            <br />
            メールアドレスと契約位開始日を入力し、送信してください。
          </div>
          <FormikProvider value={formik}>
            <Form className="form">
              <Form.Item name="email">
                <Input
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  className="input"
                  autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                  prefix={<MailOutlined className="icon" />}
                />
              </Form.Item>
              <Form.Item name="date">
                <DatePicker
                  className="input"
                  name="date"
                  placeholder="契約開始日"
                  autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                  prevIcon={<CalendarOutlined className="icon" />}
                />
              </Form.Item>
              <SubmitButton className="btn-submit" loading={false}>
                送信
              </SubmitButton>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ModalRegister;
