import styled from 'styled-components';

export const SectionStyled = styled.div`
  margin: 8px 16px;
  .text-content {
    font-size: 11px;
    color: #777777;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
    justify-content: center;
  }
  .ant-upload-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .form-upload {
    .form-upload-border {
      border: 2px solid #ffffff;
    }
    .ant-upload-list {
      display: none;
    }
    .file-upload {
      display: flex;
      flex-direction: column;
      min-height: 160px;
      border: 2px dashed #d9d9d9;
      background-color: #efefef;
      justify-content: center;
      padding: 20px 0;
      .info-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 30px;
        padding: 10px;
        position: relative;
        background-color: #ffffff;
        .file-name {
          font-size: 13px;
          text-align: center;
          margin-bottom: 10px;
          color: #424242;
          margin: 0;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .label-delete {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .text-download-template {
    font-size: 13px;
    text-align: right;
    margin: 10px 0;
    text-decoration: underline;
    color: #00a3a5;
    cursor: pointer;
    .icon {
      color: #00a3a5;
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .text-successful {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #424242;
    margin: 0;
    .icon {
      font-size: 28px;
      color: #08a3a5;
      margin-right: 10px;
    }
  }
`;
