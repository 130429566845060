import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  background: #f9f8f8;
  .access-denied {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px 30px;
    .icon-lock {
      color: #c4c4c4;
      font-size: 30px;
      margin: 0 10px;
    }
    .content {
      color: #424242;
      font-size: 14px;
    }
  }
`;
