import React from 'react';
import { SelectOutlined, FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { FormikProvider } from 'formik';
import { Form, Table } from 'antd';

import useInformation, { Props, ReceivedProps } from './hook';
import CurriculumContentModal from '../CurriculumContent';
import { Input, Modal, TextArea } from 'components';
import EditBasicInfoModal from '../EditBasicInfo';
import * as Types from 'types';
import Styled from './styles';
import { Dot } from 'assets';

const InformationModalLayout: React.FC<Props> = (props) => {
  const {
    isOpen,
    handleClose,
    formik,
    count,
    setCount,
    officialCurriculumMaster,
    setIsOpenEdit,
    isOpenEdit,
    isOpenDetail,
    setIsOpenDetail,
    setSelected,
    selected,
  } = props;

  const column: ColumnsType<Types.OfficialCurriculumMaster.ResponseType> = [
    {
      title: 'No.',
      dataIndex: '',
      key: '',
      width: '7%',
      align: 'left',
      render: (_value, _record, index) => index + 1,
    },
    {
      title: 'OFFICAILカリキュラム名',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: '説明',
      dataIndex: '',
      key: '',
      width: '10%',
      align: 'center',
      render: (_value, item) => (
        <FileTextOutlined
          onClick={() => {
            setSelected(item);
            setIsOpenDetail(true);
          }}
          style={{
            color: '#c4c4c4',
          }}
        />
      ),
    },
    {
      title: '設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      align: 'left',
      width: '10%',
      render: (item) => `${item || 0}問`,
    },
  ];

  const CustomLabel = () => {
    return (
      <div className="label-area">
        <span>基本情報：</span>
        <div className="wrap-enlarge" onClick={() => setIsOpenEdit(true)}>
          <SelectOutlined className="icon-enlarge" />
          <span className="text-enlarge">入力欄を拡大</span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="制作者情報"
      width={860}
      open={isOpen}
      okButton={{
        text: '更新',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
    >
      <Styled>
        <p className="description">ユーザーが閲覧する制作者情報の確認・編集ページです</p>
        <FormikProvider value={formik}>
          <Form layout="vertical" className="form">
            <Form.Item label="制作者：" name="creator">
              <Input
                name="creator"
                maxLength={100}
                onChange={(e) => setCount(e.target.value.length)}
              />
              <p className="count">{count}/100</p>
            </Form.Item>
            <Form.Item label={<CustomLabel />} name="basic_info">
              <TextArea name="basic_info" className="text-area" maxLength={1050} showCount />
              <p className="count"></p>
            </Form.Item>
          </Form>
        </FormikProvider>
        <div className="wrap-title-table">
          <img src={Dot} alt="" className="dot" />
          <span className="text-table">制作OFFICIALカリキュラム</span>
        </div>
        <Table
          dataSource={officialCurriculumMaster}
          columns={column}
          pagination={false}
          className="table"
        />
        <EditBasicInfoModal isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} formik={formik} />
        <CurriculumContentModal
          isOpen={isOpenDetail}
          setIsOpen={setIsOpenDetail}
          detail={selected!}
          creator={formik.values.creator || ''}
        />
      </Styled>
    </Modal>
  );
};

const InformationModal: React.FC<ReceivedProps> = (props) => {
  return <InformationModalLayout {...useInformation(props)} />;
};

export default InformationModal;
