import React, { useMemo, useRef, useEffect, useState } from 'react';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { HEADER_AGGREGATION_CHURN_RATE } from 'constant/header.export.constant';
import { getDataAggregationChurnRateExport } from './thunk';
import SkillCheckImplement from './SkillCheckImplement';
import OfficialCurriculum from './OfficialCurriculum';
import { pdfGenerator } from 'libs/utils/exportPDF';
import { loadingRef } from 'components/Loading';
import UserInfomation from './UserInfomation';
import SaleInfomation from './SaleInfomation';
import SkillCheckImplementPDF from './PDF';
import { ConfirmExport } from './Modal';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';
import Styled from './styles';
import { useSelector } from 'react-redux';
import { settingSelector } from 'containers/AppSettings/selectors';

const Dashboard: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const { headerTitle, loading } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAggregationChurnRateExport({
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getDataAggregationChurnRateExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          target_month: item.target_month,
          number_of_members_beginning_of_month: item.number_of_members_beginning_of_month,
          number_of_members_end_of_month: item.number_of_members_end_of_month,
          churn_rate: item.churn_rate,
          number_of_monthly_enrollments: item.number_of_monthly_enrollments,
          number_of_monthly_withdrawals: item.number_of_monthly_withdrawals,
          active_number_of_members_beginning_of_month:
            item.active_number_of_members_beginning_of_month,
          active_number_of_members_end_of_month: item.active_number_of_members_end_of_month,
          active_churn_rate: item.active_churn_rate,
          active_number_of_monthly_enrollments: item.active_number_of_monthly_enrollments,
          active_number_of_monthly_withdrawals: item.active_number_of_monthly_withdrawals,
          aggregate_user_type: item.aggregate_user_type,
        }));

        const csvString = [
          HEADER_AGGREGATION_CHURN_RATE.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, 'ダッシュボード.csv');
      }
    }
    if (value === 'pdf') {
      pdfGenerator(content, 'test');
    }
    setVisible(false);
  };

  const content = ref.current
    ? Array.from(ref.current!.children || '').map((child) => {
        const node = child as HTMLElement;
        const height = node.offsetHeight;

        return { node, height };
      })
    : [];

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <div>
          <Header title="HOME / Dashboard" exportPDF />
          <div
            style={{
              padding: '94px 30px 0px 30px',
            }}
          >
            <UserInfomation />
          </div>
        </div>
        <div
          style={{
            padding: '94px 30px 0px 30px',
          }}
        >
          <SkillCheckImplementPDF />
        </div>
        <div
          style={{
            padding: '94px 30px 0px 30px',
          }}
        >
          <OfficialCurriculum />
        </div>
        <div
          style={{
            padding: '94px 30px 0px 30px',
          }}
        >
          <SaleInfomation />
        </div>
      </div>
    );
  }, [UserInfomation, SkillCheckImplement, OfficialCurriculum, SaleInfomation]);
  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      {component}
      <Header title={headerTitle} />
      <Styled>
        <div className="wrap-button">
          <Button onClick={() => setVisible(true)} className="btn-submit">
            エクスポート
          </Button>
        </div>
        <UserInfomation />
        <SkillCheckImplement />
        <OfficialCurriculum />
        <SaleInfomation />
        <ConfirmExport visible={visible} setVisible={setVisible} onSubmit={handleExportCSV} />
      </Styled>
    </>
  );
};

export default Dashboard;
