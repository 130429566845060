import React, { useState } from 'react';
import { CheckCircleOutlined, CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadFile as UploadFileAntd, UploadChangeParam } from 'antd/lib/upload/interface';
import { Upload } from 'antd';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  onSubmit: (
    file: File,
    setUploadSuccessfully: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadPDF: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const onClose = () => {
    setVisible(false);
    setFile(undefined);
    setUploadSuccessfully(false);
  };

  const handleDelete = () => {
    setFile(undefined);
  };

  const handleSubmit = async () => {
    if (!file) return;

    setLoading(true);
    await onSubmit(file, setUploadSuccessfully);
    setUploadSuccessfully(true);
    setFile(undefined);
    setLoading(false);
  };

  return (
    <Modal
      title="カリキュラムマスタ インポート"
      width={720}
      visible={visible}
      okButton={
        file || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully ? 'OK' : 'インポート',
              onClick: isUploadSuccessfully ? onClose : handleSubmit,
              loading,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {!isUploadSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <div className="file-upload">
                {file ? (
                  <div className="info-file">
                    <p className="file-name">{file.name} </p>
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      インポートするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="flex">
                  <Upload
                    accept=".pdf"
                    beforeUpload={() => false}
                    onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                      setFile(info.file as unknown as File);
                    }}
                  >
                    <button type="button" className="btn-upload">
                      ファイルを選択
                    </button>
                  </Upload>
                  {file && (
                    <button className="btn-delete" type="button" onClick={handleDelete}>
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="label-delete">ファイルを削除</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadPDF;
