import { createAsyncThunk } from '@reduxjs/toolkit';

import { servicesMock } from 'services';
import * as Types from 'types';

export const getDataInformationUser = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataInformationUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await servicesMock.filter('reportUserInformation', req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
