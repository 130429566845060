import React, { useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
// import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';

// import { authSelector } from 'containers/Auth/selectors';
import { DatePicker, SelectField } from 'components';
import SkillCheckInformationStyled from './styles';
import TableSkillCheckInformation from './Table';
// import { useAppDispatch } from 'hooks';
import Chart from './Chart';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SkillCheckInformation: React.FC = () => {
  const [visibleCorrespondingSale, setVisibleCorrespondingSale] = useState<boolean>(false);
  const [segmented, setSegmented] = useState<number>(0);

  // const { userInfo } = useSelector(authSelector);

  // const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      aggregation_method: '',
      user_type: '',
    },
    onSubmit: (values) => {},
  });

  const onChange = (e: CheckboxChangeEvent) => {
    setVisibleCorrespondingSale(e.target.checked);
  };

  return (
    <SkillCheckInformationStyled>
      <div className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="aggregation_method"
                    className="item"
                    label={<span className="text-label">集計方法選択</span>}
                  >
                    <SelectField
                      name="aggregation_method"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={0}>スキルチェック実施回数【単月推移】</Option>
                      <Option value={1}>スキルチェック実施回数【累計推移】</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="aggregation_period"
                    className="item"
                    label={<span className="text-label">集計期間</span>}
                  >
                    <RangePicker
                      allowClear
                      className="date"
                      name="date-item"
                      format="YYYY/MM/DD HH:mm"
                      placeholder={['集計開始日', '集計終了日']}
                      onChange={(dates) => {
                        if (dates) {
                          formik.setFieldValue('start_period', dates[0]?.toISOString());
                          formik.setFieldValue('end_period', dates[1]?.toISOString());
                        } else {
                          formik.setFieldValue('start_period', '');
                          formik.setFieldValue('end_period', '');
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="user_type"
                    className="item"
                    label={<span className="text-label">ユーザー種類選択</span>}
                  >
                    <SelectField
                      name="user_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="a">ALL</Option>
                      <Option value="b">全ユーザー</Option>
                      <Option value="c">社内ユーザー</Option>
                      <Option value="d">面接ユーザー</Option>
                    </SelectField>
                  </Form.Item>
                  <div className="item" />
                  <div className="group-btn">
                    <span className="label-reset" onClick={() => formik.resetForm()}>
                      リセット
                    </span>
                    <SubmitButton className="btn-search" loading={false}>
                      検索
                    </SubmitButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={visibleCorrespondingSale} onChange={onChange}>
                  対応売上表示
                </Checkbox>
              </div>
            </div>
          </div>
          {segmented === 0 ? (
            <TableSkillCheckInformation visibleCorrespondingSale={visibleCorrespondingSale} />
          ) : (
            <Chart visibleCorrespondingSale={visibleCorrespondingSale} />
          )}
        </div>
      </div>
    </SkillCheckInformationStyled>
  );
};

export default SkillCheckInformation;
