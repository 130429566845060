import { routes } from 'navigations/routes';

export const getHeaderTitle = (path: string) => {
  const routeKey = Object.keys(routes).find(
    (key) => routes[key as keyof typeof routes].path === path
  );

  if (!routeKey) return '';

  return routes[routeKey as keyof typeof routes].title;
};
