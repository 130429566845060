import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  margin-bottom: 120px;
  .form-setting {
    .block {
      margin-top: 12px;
      .ant-form-item {
        margin-bottom: 0;
      }
      .input-textarea-group {
        font-size: 14px;
        color: #2a2a2a;
        ::placeholder {
          font-size: 14px;
          color: #cccccc;
        }
      }
      .text-label {
        font-size: 13px;
        color: #222222;
        display: block;
        margin: 8px 0;
        .require {
          color: #f0330a;
          font-size: 20px;
        }
      }
      .waper-setting {
        padding: 12px;
        background-color: #ffffff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        .text-label {
          font-size: 12px;
          color: #777777;
          display: block;
          margin: 5px 0;
        }
        .ant-row + .ant-row {
          margin-top: 8px;
        }
        .ant-form-item-with-help .ant-form-item-explain {
          display: none;
        }
      }
    }
    .wrap-submit {
      height: 95px;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .error-message {
        font-weight: 400;
        font-size: 13px;
        color: #f0330a;
        height: 24px;
        margin: 0;
      }
      .wrap-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .btn {
          border-radius: 5px;
          border: none;
          width: 16%;
          height: 40px;
          cursor: pointer;
          &_submit {
            margin-right: 12px;
            color: #ffffff;
            background-color: #f6ac00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          }
          &_close {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            color: #777777;
          }
        }
      }
    }
  }
`;

export const ItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ebebeb;
  height: 55px;
  padding: 4px;
  margin: 4px 0;
  .label {
    width: 20%;
    padding: 12px;
  }
  .radio-group {
    display: flex;
    width: 80%;
    height: 100%;
    background: #ffffff;
    .radio-text {
      justify-content: space-between;
      align-items: center;
      width: 33.33%;
      height: 100%;
      padding: 0 20px;
      margin: 0;
    }
    .middle {
      border-left: 1px solid #e2e2e2;
      border-right: 1px solid #e2e2e2;
    }
  }
  .ant-radio-wrapper-checked {
    color: #424242;
    font-weight: 700;
  }
`;
