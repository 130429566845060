import yup from '../yup';

export const loginSchema = yup.object().shape({
  email: yup.string().required().email().max(300),
  password: yup.string().required().max(30),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().required().email().max(300),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email('メールアドレスは＠を含む半角英数字で入力してください。')
    .max(300, 'メールアドレスは300文字以内で入力してください。')
    .required('メールアドレスは必須入力項目です。'),
});

export const registerFormSchema = yup.object().shape({
  password: yup.string().required().max(30),
  rePassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30),
});

export const changeEmailSchema = yup.object().shape({
  email: yup.string().required().email().max(300),
  confirm_email: yup
    .string()
    .required()
    .oneOf([yup.ref('email'), null], 'パスワードが一致しません')
    .max(30),
});

export const changePasswordSchema = yup.object().shape({
  current_password: yup.string().required().max(30),
  new_password: yup.string().required().max(30),
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref('new_password'), null], 'パスワードが一致しません')
    .max(30),
});
