import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ModalStyled, SectionStyled } from './styles';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => Promise<void> | void;
};

const ResendRegistration: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  const toggleModal = () => {
    setVisible(false);
  };

  return (
    <ModalStyled
      title="URL再送信"
      width={720}
      open={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: toggleModal,
      }}
      footerStyle={{
        background: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          請求書対応のアカウント登録URLの再送信です。
        </p>
        <p className="text-content">
          ※メールアドレスの登録間違いや、ご担当者様がメール削除
          <br />
          してしまった等の場合に限り使用してください。
        </p>
      </SectionStyled>
    </ModalStyled>
  );
};

export default ResendRegistration;
