import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, Input, SelectField, TextArea } from 'components';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditJobTitleSchema } from 'libs/validations';
import AffiliationMasterPDF from './AffiliationMasterPDF';
import { authSelector } from 'containers/Auth/selectors';
import { affiliationMasterSelector } from './selectors';
import CompletedModal from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import AffiliationMasterStyled from './styles';
import exportPDF from 'libs/utils/exportPDF';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getSelectAdminAffiliationHierarchy,
  getDataAdminAffiliationHierarchy,
  deleteAdminAffiliationLevel,
  createAdminAffiliationLevel,
  updateAdminAffiliationLevel,
  getDataAdminAffiliationHierarchyExportCSV,
} from './thunk';

const { Option } = Select;

const AffiliationMaster: React.FC = () => {
  const [itemSelected, setItemSelected] = useState<Types.AdminAffiliationHierarchy.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.AdminAffiliationHierarchy.ResponseType>();
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const ref = useRef(null);

  const { dataSelectAdminAffiliation, dataAdminAffiliationHierarchy, loading } =
    useSelector(affiliationMasterSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formikSearch = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async ({ name }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する役職を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        await dispatch(
          getDataAdminAffiliationHierarchy({
            conditions: [
              {
                id: 'name',
                search_value: [name],
                exact_match: true,
              },
            ],
            include_lookups: true,
            use_display_id: true,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
      }
    },
    onReset: async () => {
      await dispatch(
        getDataAdminAffiliationHierarchy({
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
    },
  });

  const isEditing = (record: Types.AdminAffiliationHierarchy.ResponseType) =>
    record.i_id === itemSelected?.i_id;

  const columns: any = [
    {
      key: 'affiliation_id',
      title: 'コード',
      dataIndex: 'affiliation_id',
      width: '13%',
      align: 'center',
      sorter: (
        a: Types.AdminAffiliationHierarchy.ResponseType,
        b: Types.AdminAffiliationHierarchy.ResponseType
      ) => Number(a.affiliation_id) - Number(b.affiliation_id),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.AdminAffiliationHierarchy.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => setItemSelected(record)} />
        );
      },
    },
    {
      title: '削除',
      width: '7%',
      dataIndex: 'i_id',
      align: 'left',
      render: (_: string, record: Types.AdminAffiliationHierarchy.ResponseType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined
            className="icon"
            onClick={() => {
              setItemDeleted(record);
              setOpenModalConfirmDeleteItem(true);
            }}
          />
        ) : null,
    },
  ];

  const fetchDataAdminAffiliationHierarchy = useCallback(async () => {
    if (userInfo) {
      const resultAction = await dispatch(
        getDataAdminAffiliationHierarchy({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      if (getDataAdminAffiliationHierarchy.fulfilled.match(resultAction)) {
        dispatch(
          getSelectAdminAffiliationHierarchy({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            include_lookups: true,
            use_display_id: true,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
      }
    }
  }, [dispatch, userInfo]);

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      enableReinitialize
      validationSchema={CreateEditJobTitleSchema}
      onSubmit={async (values) => {
        if (!itemSelected) return;
        const resultAction = await dispatch(
          updateAdminAffiliationLevel({
            id: itemSelected.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        setItemSelected(undefined);
        if (updateAdminAffiliationLevel.fulfilled.match(resultAction)) {
          fetchDataAdminAffiliationHierarchy();
          setShowCompleteModal(true);
        } else {
          setActionModalState({
            subTitle: '役職の更新に失敗しました',
            description: (
              <p className="text-content">
                役職の更新に失敗しました。
                <br />
                もう一度情報を入力し、再度お試しください。
              </p>
            ),
          });
          setShowActionErrorModal(true);
        }
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell<Types.AdminAffiliationHierarchy.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: '※役職名称が未入力です',
                },
              ]}
            >
              <Input showCount maxLength={30} name="name" />
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map(
    (col: Types.TypeCell<Types.AdminAffiliationHierarchy.ResponseType>) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: Types.AdminAffiliationHierarchy.ResponseType) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  );

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAdminAffiliationHierarchyExportCSV({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataAdminAffiliationHierarchyExportCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          company_id: item.company_id,
          affiliation_id: item.affiliation_id,
          code: item.code,
          name: item.name,
          user_icon_fileID: item.user_icon_fileID,
          user_name: item.user_name,
          user_departments: item.user_departments,
          user_positions: item.user_positions,
          login_id: item.login_id,
        }));

        const csvString = [
          HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
    }
    if (value === 'pdf') {
      exportPDF(ref, '所属マスタ');
    }
    setShowConfirmExportFileModal(false);
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <AffiliationMasterPDF
          dataAdminAffiliationHierarchy={dataAdminAffiliationHierarchy}
          page={page}
        />
      </div>
    );
  }, [dataAdminAffiliationHierarchy, page]);

  useEffect(() => {
    (async () => {
      await fetchDataAdminAffiliationHierarchy();
    })();
  }, [fetchDataAdminAffiliationHierarchy]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <AffiliationMasterStyled
      collapsedMenu={collapsedMenu}
      isEmptyData={!dataAdminAffiliationHierarchy.length}
    >
      {component}
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            所属マスタの作成・管理を行う画面です。
            <br />
            作成した所属マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label">所属検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="name"
                >
                  {dataSelectAdminAffiliation?.map(
                    ({ name }, i) =>
                      name && (
                        <Option value={name} key={i}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>

          <div className="wrap-table">
            <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>

            <Table
              dataSource={dataAdminAffiliationHierarchy.map((item, index) => ({ ...item, index }))}
              columns={mergedColumns}
              // loading={loading}
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell,
                },
              }}
              className="table"
              pagination={{
                showSizeChanger: false,
                // total: total,
                current: page,
                pageSize: perPage,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    <div className="total-record-table">
                      <span className="text-static">
                        {page * perPage > dataAdminAffiliationHierarchy.length
                          ? dataAdminAffiliationHierarchy.length
                          : page * perPage}
                        <small> 件表示</small> / {dataAdminAffiliationHierarchy.length}
                        <small> 名</small>
                      </span>
                    </div>
                    <div>
                      <span className="label-select">表示件数：</span>
                      <Select className="select-option" onChange={setPerpage} value={perPage}>
                        {[100, 50, 20, 10].map((e) => (
                          <Option value={e}>{e}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ),
              }}
              rowKey="index"
            />
          </div>
        </div>
        <div className="wrap-create">
          <p className="title">所属新規登録</p>
          <Formik<Types.CreateEditJobTitleFormik>
            initialValues={{
              name: '',
            }}
            enableReinitialize
            validationSchema={CreateEditJobTitleSchema}
            onSubmit={async (values, { resetForm }) => {
              // dispatch(startLoading());
              const resultAction = await dispatch(
                createAdminAffiliationLevel({
                  item: {
                    company_id: userInfo?.company_id,
                    name: values.name,
                  },
                  return_item_result: true,
                })
              );
              if (createAdminAffiliationLevel.fulfilled.match(resultAction)) {
                setShowCompleteModal(true);
              } else {
                setActionModalState({
                  subTitle: '新規役職の登録に失敗しました',
                  description: (
                    <p className="text-content">
                      新規役職の登録に失敗しました。
                      <br />
                      もう一度情報を入力し、再度お試しください。
                    </p>
                  ),
                });
                setShowActionErrorModal(true);
              }

              // dispatch(stopLoading());
              fetchDataAdminAffiliationHierarchy();
              resetForm();
            }}
          >
            <Form colon={false} layout="vertical" className="flex">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    所属名称
                    <span className="require">*</span>
                  </span>
                }
                className="form-text-area"
              >
                <TextArea name="name" showCount maxLength={120} placeholder="最大120文字" />
              </Form.Item>
              <SubmitButton className="btn btn_submit">
                <PlusOutlined className="size-icon" />
                新規登録
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        title="登録が完了しました"
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={async () => {
          setActionModalState({
            subTitle: '削除に失敗しました',
            description: (
              <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
            ),
          });
          if (!itemDeleted) return;
          // dispatch(startLoading());
          const resultAction = await dispatch(
            deleteAdminAffiliationLevel({ id: itemDeleted.i_id })
          );
          if (deleteAdminAffiliationLevel.fulfilled.match(resultAction)) {
            setShowCompleteModal(true);
          } else {
            setShowActionErrorModal(true);
          }
          fetchDataAdminAffiliationHierarchy();
          setItemDeleted(undefined);
          // dispatch(stopLoading());
        }}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </AffiliationMasterStyled>
  );
};

export default AffiliationMaster;
