import styled from 'styled-components';

export default styled.section`
  padding: 20px 30px;
  .wrap-button {
    text-align: right;
    .btn-submit {
      background: #f6ac00;
      color: #ffffff;
      height: 40px;
      width: 200px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      &:hover {
        border: none;
      }
    }
  }
`;
