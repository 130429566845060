import { createAsyncThunk } from '@reduxjs/toolkit';

import { ADMIN_USER_DETAIL, COMPANIES, PROVIDER_INFORMATION, USERS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataAdminDetails = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getDataAdminDetails', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_USER_DETAIL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCompanies = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createProviderInformation = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(PROVIDER_INFORMATION.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUser = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getDataSearchUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
