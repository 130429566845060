import { createAsyncThunk } from '@reduxjs/toolkit';

import { COMPANIES, COMPANY_USER, SELECT_COMPANY, SELECT_PAYMENT_METHOD } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getCorprateUserInfomation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'corporateUserInformation/thunk/createCorprateUserInfomation',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(COMPANY_USER.id, req);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataCompanyUserExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'corporateUserInformation/thunk/getDataCompanyUserExportCSV',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(COMPANY_USER.id, req);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectCompany = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/getSelectCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_COMPANY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectPaymentMethod = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/getSelectPaymentMethod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_PAYMENT_METHOD.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCompanyUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/updateCompanyUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeFunctionCreateBatchID = createAsyncThunk<
  Types.ExecuteFunctionResponseType,
  Types.ExecuteFunctionRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeFunctionCreateBatchID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeFunction('createBatchId', req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeFunctionDeleteMailBatch = createAsyncThunk<
  Types.ExecuteFunctionResponseType,
  Types.ExecuteFunctionRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeFunctionDeleteMailBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeFunction('deleteMailBatch', req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType & { actionId: string },
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction(req.actionId, COMPANIES.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
