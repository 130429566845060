import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  SELECT_PROVIDER,
  OFFICIAL_CURRICULUM_LIST2,
  SELECT_OFFICIAL_CURRICULUM_NAME,
  PARTNER_CURRICULUM_NUM_OF_USER_USING,
  PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING,
} from 'configs';

export const getOfficialCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OfficialCurriculumType>(
      OFFICIAL_CURRICULUM_LIST2.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerCompanies = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PartnerCurriculumCompaniesType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getPartnerCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PartnerCurriculumCompaniesType>(
      PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PartnerCurriculumUserType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getPartnerUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PartnerCurriculumUserType>(
      PARTNER_CURRICULUM_NUM_OF_USER_USING.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectProvider = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectProviderType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/selectProvider', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectProviderType>(SELECT_PROVIDER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialCurriculumName = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumNameType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/selectOfficialCurriculumName', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OfficialCurriculumNameType>(
      SELECT_OFFICIAL_CURRICULUM_NAME.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
