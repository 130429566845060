import yup from '../yup';

const regex = {
  halfwidth: /^[a-zA-Z0-9]+$/,
};

export const publicManagement = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  version: yup.string().required().matches(regex.halfwidth, '半角英数字のみ入力してください。'),
  folder_id: yup.string(),
  sections: yup.array().of(
    yup.object().shape({
      section_name: yup.string().trim().required(),
      text: yup.string(),
    })
  ),
});
