import styled from 'styled-components';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

type Props = {
  collapsedMenu: boolean;
  isEmptyData: boolean;
};
export default styled.section<Props>`
  position: relative;
  background: #f9f8f8;
  padding: 30px;
  min-height: 100vh;
  .wrap-select-record {
    display: flex;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 66px;
    height: 28px;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .information {
    background: #ffffff;
    border: 1px solid #f6ac00;
    color: #f6ac00;
    width: 160px;
    height: 40px;
    border-radius: 5px;
  }
  @media (max-width: 1440px) {
    .wrap-nav {
      button {
        width: 180px;
        height: 40px;
      }
    }
    .title-partner {
      .btn-list {
        margin-right: 8px !important;
      }
      .btn-list,
      .btn-tree {
        width: 160px;
        padding: 8px !important;
        height: 40px;
      }
    }
    .form-search {
      justify-content: flex-start !important;
      .btn-search {
        width: 100px;
      }
    }
    .item {
      width: 240px !important;
      margin-right: 8px;
    }
    .item .ant-form-item-label > label {
      font-size: 12px !important;
    }
    .btn-search,
    .label-reset {
      margin-left: 12px;
    }
    .wrap-body {
      .btn-div {
        .btn-control-number {
          width: 160px;
        }
      }
      .wrap-table {
        .table {
          .ant-table-thead {
            .ant-table-cell {
              padding: 8px 10px;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              height: 44px;
            }
          }
        }
      }
    }
    .wrap-button {
      .btn {
        width: 160px;
      }
    }
    .table {
      .ant-spin-container {
        margin-bottom: 100px;
      }
      .ant-table-thead {
        .ant-table-cell {
          font-size: 12px !important;
          padding: 3px 8px !important;
          height: 44px !important;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 3px 8px !important;
          height: 48px !important;
        }
      }
    }
    .wrap-bottom {
      .btn-active {
        width: 160px !important;
      }
      .btn-outline {
        width: 180px !important;
      }
    }
  }
  .text-note {
    font-size: 13px;
    color: #424242;
  }
  .line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .form-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 22%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
    }
    .icon-search {
      font-size: 18px;
      margin-right: 12px;
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 12px 30px;
    z-index: 9;
    .btn {
      padding: 0 30px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 10px;
      border-radius: 5px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      color: #ffffff;
      .icon {
        margin-right: 12px;
        font-size: 18px;
      }
    }
    .btn-outline {
      border: 1px solid #f6ac00;
      background: #ffffff;
      color: #f6ac00;
    }
  }
  .table {
    padding-bottom: 95px;
    margin-top: ${(props) => (props.isEmptyData ? 64 : 0)}px;
    .text-count {
      font-family: 'Lato';
      font-size: 14px;
      color: #424242;
    }
    .ant-table-thead .ant-table-selection-column::after {
      content: '選択\\a解除';
      white-space: pre-line;
      color: #08a3a5;
      font-size: 11px;
      text-decoration: underline;
      cursor: pointer;
    }
    .ant-table-thead .ant-table-selection-column {
      width: 4%;
    }
    .ant-table-thead .ant-checkbox-wrapper {
      padding-top: 10px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-size: 13px;
        text-align: center;
        color: #424242;
        word-wrap: break-word;
      }
      .icon {
        color: #c4c4c4;
        font-size: 20px;
        cursor: pointer;
      }
      .icon-status {
        height: 20px;
        margin-right: 5px;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
    .wrap-status {
      display: inline-flex;
      align-items: center;
    }
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    padding-left: 44px;
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      margin-left: 8px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f6ac00;
    }
    .btn-disable {
      background-color: #eeeeee;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #bbbbbb;
    }
  }
`;
