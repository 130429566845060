import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { HEADER_BILLING_DATA_DETAIL } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import CompletedModal from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Modal, SelectField } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getBillingDataDetail,
  getBillingDataDetailExport,
  getDataDetailCorporate,
  getDataSelectError,
  getDataSelectOrderID,
  getDataSelectUserBilling,
} from 'pages/BillingDataManagement/thunk';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  dataError: Array<string>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const PER_PAGE = 10;

const ErrorAllDetail: React.FC<Props> = ({ visible, setVisible, dataError }) => {
  const [selectedRow, setSelectedRow] = useState<Types.BillingDataDetail.ResponseType[]>([]);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visiblePopupConfirm, setVisiblePopupConfirm] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { dataSelectError, dataSelectUserBilling, dataSelectOrderID, dataBillingDetail, loading } =
    useSelector(billingDataManagementSelector);

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.BillingDataDetail.ResponseType> = [
    {
      title: () => {
        return (
          <div>
            役務提供年月・ 請求データ番号
            <br />
            請求データ名称
          </div>
        );
      },
      dataIndex: 'provided_date',
      key: 'provided_date',
      width: '20%',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{dayjs(text).format('YYYY/MM')}</span>{' '}
            <span className="date">{item.billing_id}</span>
            <br />
            <span>{item.billing_data_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'オーダーID',
      dataIndex: 'order_id',
      key: 'order_id',
      width: '10%',
    },
    {
      title: '会員ID',
      dataIndex: 'company_id',
      key: 'company_id',
      width: '8%',
    },
    {
      title: () => {
        return (
          <div>
            ユーザーID
            <br />
            ユーザー名
          </div>
        );
      },
      dataIndex: 'user_name',
      key: 'user_name',
      width: '12%',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{item.user_id}</span>
            <br />
            <span>{text}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '請求種類',
      dataIndex: 'billing_type',
      key: 'billing_type',
      width: '7%',
      render: (billing_type: number) =>
        billing_type === 1 ? (
          <span>通常請求</span>
        ) : (
          <span className="text-active">エラー請求</span>
        ),
    },
    {
      title: '初回請求日',
      dataIndex: 'billing_date',
      key: 'billing_date',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM/DD')}</span> : <span>-</span>,
    },
    {
      title: 'アカウント数',
      dataIndex: 'num_of_accounts',
      key: 'num_of_accounts',
      width: '12%',
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: '5%',
    },
    {
      title: () => {
        return (
          <div>
            エラーコード
            <br />
            エラー詳細
          </div>
        );
      },
      dataIndex: 'error_title',
      key: 'error_title',
      width: '12%',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{item.error_code}</span>
            <br />
            <span>{text}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'GMO再送信',
      dataIndex: 'gmo_resend',
      key: 'gmo_resend',
      className: 'redelivery',
      width: '8%',
      render: (text: string) => (
        <div onClick={() => setVisiblePopupConfirm(true)} className="item-table active">
          再送信
        </div>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      service_provision_date: '',
      user_type: '',
      means_of_payment: '',
      correspondence_situation: '',
      billing_type: '',
    },
    onSubmit: (values) => {},
  });

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getBillingDataDetailExport({
          conditions: [
            {
              id: 'billing_id',
              search_value: [selectedRow.map((row) => row.billing_id).join('|')],
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getBillingDataDetailExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          billing_detail_id: item.billing_detail_id,
          provided_date: item.provided_date,
          billing_id: item.billing_id,
          billing_data_name: item.billing_data_name,
          order_id: item.order_id,
          company_id: item.company_id,
          company_name: item.company_name,
          user_id: item.user_id,
          user_name: item.user_name,
          billing_type: item.billing_type,
          order_date: item.order_date,
          billing_date: item.billing_date,
          num_of_accounts: item.num_of_accounts,
          amount: item.amount,
          payment_method: item.payment_method,
          error_code: item.error_code,
          error_title: item.error_title,
          os: item.os,
          gmo_resend: item.gmo_resend,
        }));

        const csvString = [
          HEADER_BILLING_DATA_DETAIL.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '請求データ管理画面 ●未送信エラー全明細モーダル.csv');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    Promise.all([
      dispatch(
        getDataDetailCorporate({
          conditions: [
            // {
            //   id: 'company_id',
            //   search_value: [userInfo.company_id],
            //   exact_match: true,
            // },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch]);

  useEffect(() => {
    if (dataError && visible) {
      dispatch(
        getBillingDataDetail({
          conditions: [
            {
              id: 'billing_id',
              search_value: dataError,
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dataError, dispatch, visible]);

  useEffect(() => {
    if (visible) {
      Promise.all([
        dispatch(
          getDataSelectOrderID({
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSelectUserBilling({
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSelectError({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, visible]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title="請求データエラー明細【法人・クレジット】"
      visible={visible}
      width={1300}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="container">
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="order_id"
                  className="item"
                  label={<span className="text-label">オーダーID</span>}
                >
                  <SelectField
                    name="order_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectOrderID?.map(
                      (item, index) =>
                        item.order_id && (
                          <Option key={index} value={item.order_id}>
                            {item.order_id}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="company_id"
                  className="item"
                  label={<span className="text-label">会員ID</span>}
                >
                  <SelectField
                    name="company_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBilling?.map(
                      (item, index) =>
                        item.company_name && (
                          <Option key={index} value={item.company_id}>
                            {item.company_name}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="user_id"
                  className="item"
                  label={<span className="text-label">ユーザーID</span>}
                >
                  <SelectField
                    name="user_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBilling?.map(
                      (item, index) =>
                        item.user_id && (
                          <Option key={index} value={item.user_id}>
                            {item.user_id}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="user_name"
                  className="item"
                  label={<span className="text-label">ユーザー名</span>}
                >
                  <SelectField
                    name="user_name"
                    showSearch
                    placeholder="指定なし"
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBilling?.map(
                      (item, index) =>
                        item.user_name && (
                          <Option key={index} value={item.user_name}>
                            {item.user_name}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="error_code"
                  className="item"
                  label={<span className="text-label">エラーコード</span>}
                >
                  <SelectField
                    name="error_code"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectError?.map(
                      (item, index) =>
                        item.error_code && (
                          <Option key={index} value={item.error_code}>
                            {item.error_code}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="error_title"
                  className="item"
                  label={<span className="text-label">エラー詳細</span>}
                >
                  <SelectField
                    name="error_title"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectError?.map(
                      (item, index) =>
                        item.error_title && (
                          <Option key={index} value={item.error_title}>
                            {item.error_title}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </Form>
          </FormikProvider>
          <div className="text-count">
            {page * PER_PAGE > dataBillingDetail.length
              ? dataBillingDetail.length
              : page * PER_PAGE}
            <span className="text-static">件表示 </span> / {dataBillingDetail.length}
            <span className="text-static">名</span>
          </div>
          <Table
            rowKey="i_id"
            className="table"
            dataSource={dataBillingDetail}
            columns={columns}
            scroll={{ y: 400 }}
            rowSelection={{
              onChange: (_, selectedRows: Types.BillingDataDetail.ResponseType[]) =>
                setSelectedRow(selectedRows),
            }}
            pagination={{
              pageSize: 20,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topRight', 'bottomRight'],
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したデーターを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={handleToggleModal}>
              閉じる
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirm}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirm}
        />
        <CompletedModal
          title="再送信が完了しました"
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => {}}
        />
      </SectionStyled>
    </Modal>
  );
};

export default ErrorAllDetail;
