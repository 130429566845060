import React, { useEffect } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { formatNumberComma } from 'libs/utils/format';
import { loadingRef } from 'components/Loading';
import { LogoAdmin, LogoRStandard } from 'assets';
import { SectionStyled } from './styles';
import { Modal } from 'components';
// import * as Types from 'types';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const dataTableMock = [
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
];

const BillingPreview: React.FC<Props> = ({ visible, setVisible }) => {
  const { loading } = useSelector(billingDataManagementSelector);

  const handleTotalProduct = () => {
    const newArrayTotalProduct = dataTableMock.map((item) => +item.unitPrice * +item.quantity);
    return newArrayTotalProduct.reduce((a, b) => a + b, 0);
  };

  const handleTotalProductFinal = () => {
    return handleTotalProduct() + handleTotalProduct() * 0.1;
  };

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title="請求書"
      width={860}
      visible={visible}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="wrap-header">
          <Button className="button-export">
            <CloudDownloadOutlined className="download-icon" />
            エクスポート
          </Button>
        </div>
        <div className="wrap-body">
          <div className="container">
            <div className="header-content">
              <div className="image-logo">
                <img src={LogoAdmin} alt="logo" className="image" />
              </div>
              <p className="title">請求書</p>
              <div className="preview">
                <div className="label">
                  <span>領収書No.：</span> <br />
                  <span>発行日：</span>
                </div>
                <div className="text-label">
                  <span>100021</span> <br />
                  <span> {dayjs('2023-02-08').format('YYYY年MM月DD日')}</span>
                </div>
              </div>
            </div>
            <div className="item-info">
              <div className="item-left">
                <span className="name">株式会社スタンダード　御中</span>
                <br />
                <br />
                <span>
                  〒 123-1234
                  <br />
                  東京都台東区上野1-1-1スタンダードビル５F
                  <br />
                  TEL：03-1111-1111
                  <br />
                  FAX：03-1111-1112
                  <br />
                  登録番号：T1234567890123
                  <br />
                </span>
              </div>
              <div className="item-right">
                <span className="label-name">アールスタンダード</span>
                <br />
                <span className="name-company">株式会社RSTANDARD</span>
                <br />
                <br />
                <span>
                  〒 123-1234
                  <br />
                  東京都台東区上野3-17-7
                  <br />
                  G-SQUARE上野4F
                  <br />
                  TEL：03-6803-2893
                  <br />
                  FAX：03-6803-2894
                  <br />
                  登録番号：T1234567890123
                  <br />
                </span>
                <img src={LogoRStandard} alt="logoRStandard" className="image-logo" />
              </div>
            </div>
            <div className="total-header">
              <span className="total-header-title">ご請求金額（税込）</span>
              <span className="total-number">
                {formatNumberComma(handleTotalProductFinal())}
                <span className="currency-unit">円</span>
              </span>
            </div>
            <div className="content-body">
              <table>
                <tbody>
                  <tr>
                    <th>内容</th>
                    <th>単価</th>
                    <th>数量</th>
                    <th>合計</th>
                  </tr>
                  {dataTableMock.map((item, index) => (
                    <tr key={index}>
                      <td>{item.content}</td>
                      <td>{item.unitPrice}</td>
                      <td>{item.quantity}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="sub-table">
                <div className="wrap-sub-item">
                  <div className="sub-item">
                    <span className="sub-title">小計</span>
                    <span className="sub-price">{formatNumberComma(handleTotalProduct())}</span>
                  </div>
                  <div className="sub-item">
                    <span className="sub-title">消費税等（10%）</span>
                    <span className="sub-price">
                      {formatNumberComma(handleTotalProduct() * 0.1)}
                    </span>
                  </div>
                  <div className="sub-item">
                    <span className="sub-title">合計</span>
                    <span className="sub-price">
                      {formatNumberComma(handleTotalProductFinal())}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-footer">
              <div>
                <span>【お振込先】</span>
                <div>
                  <span>みずほ銀行（0001）上野支店（107）</span>
                  <span>（普通）2689387</span>
                  <span>株式会社RSTANDARD</span>
                </div>
                <span>※お振込手数料は御社負担にてお願いいたします。</span>
              </div>
            </div>
          </div>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default BillingPreview;
