import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { maxBy, findLast } from 'lodash';

import { Wrapper as WrapperColumnRoot } from './TreeNodeRenderer/styles';
import { searchQuestionCurriculumSelector } from '../Search/selectors';
import { startLoading, stopLoading } from '../../AppSettings/slice';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { CustomDragLayer } from './NodeRenderer/CustomDragLayer';
import { authSelector } from 'containers/Auth/selectors';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import * as Types from 'types';
import {
  memoizedGetChildrenItemIDFromTree,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  createLinkQuestionAssignLevel,
  deleteLinkQuestion,
} from 'containers/CreateEditQuestion/thunk';
import {
  getCurriculum,
  createLevelCurriculum,
  deleteLevelCurriculum,
  updateCurriculum,
} from 'pages/Curriculum/thunk';

const NODE_HEIGHT = 56;

type Props = {
  tabActive?: number;
  columnClosed?: number;
  treeViewIndex: number;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  setItemMoveCopySelected?: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
};

const TreeView: React.FC<Props> = ({
  treeData,
  tabActive = 0,
  columnClosed,
  treeViewIndex,
  itemMoveCopySelected,
  setItemMoveCopySelected,
}) => {
  const [data, setData] = useState<{ [key: string]: Types.TreeItem<Types.CurriculumItemType>[] }>(
    {}
  );

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);

  const dispatch = useAppDispatch();

  const onDrop = async (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    dispatch(startLoading());
    const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
      memoizedGetChildrenItemIDFromTree({
        treeData: currentItem.node,
      });
    for (let index = 0; index < listChildItemID.length; index++) {
      const item = listChildItemID[index];
      if (item.columnIndex < 5) {
        const resultAction = await dispatch(
          createLevelCurriculum({
            level: item.columnIndex,
            item: {
              provider_id: userInfo?.company_id,
              name: item.name,
              sort_order: item.sort_order,
              [`level${item.columnIndex - 1}_code`]:
                index === 0
                  ? targetItem.code
                  : findLast(
                      listChildItemID.slice(0, index),
                      (i) => i.columnIndex === item.columnIndex - 1
                    )?.code,
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createLevelCurriculum.fulfilled.match(resultAction)) {
          switch (item.columnIndex) {
            case 1:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 2:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 3:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 4:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            default:
              break;
          }
          if (type === 'move') {
            await dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
              })
            );
          }
        }
      } else {
        const resultAction = await dispatch(
          createLinkQuestionAssignLevel({
            item: {
              provider_id: userInfo?.company_id,
              level4_code:
                findLast(
                  listChildItemID.slice(0, index),
                  (i) => i.columnIndex === item.columnIndex - 1
                )?.code || targetItem.code,
              code: item.code,
              sort_order: item.sort_order,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        if (createLinkQuestionAssignLevel.fulfilled.match(resultAction) && type === 'move') {
          await dispatch(
            deleteLinkQuestion({
              id: item.question_assign_level_i_id!,
            })
          );
        }
      }
      setItemMoveCopySelected && setItemMoveCopySelected(undefined);
    }

    const question_copy_length = listChildItemID.filter((item) => item.columnIndex === 5).length;
    if (type === 'copy' && question_copy_length) {
      const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
      if (curriculum) {
        await dispatch(
          updateCurriculum({
            id: curriculum.curriculum_id!,
            data: {
              item: {
                probs_count: (curriculum?.problems_count || 0) + question_copy_length,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }
    }

    await dispatch(
      getCurriculum({
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const onDropQuestion = async (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    if (currentItem.type === 'move-question') {
      const resultActions = await dispatch(
        createLinkQuestionAssignLevel({
          item: {
            provider_id: userInfo?.company_id,
            level4_code: targetItem.code,
            code: currentItem.item.code,
            sort_order: targetItem.sort_order,
          },
        })
      );

      if (createLinkQuestionAssignLevel.fulfilled.match(resultActions)) {
        await dispatch(
          updateCurriculum({
            id: nodeLevel4Selected?.curriculum_id!,
            data: {
              item: {
                probs_count:
                  ((nodeLevel4Selected?.children?.length! > nodeLevel4Selected?.problems_count!
                    ? nodeLevel4Selected?.children?.length!
                    : nodeLevel4Selected?.problems_count!) || 0) + 1,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }

      Promise.all([
        currentItem.onSubmit(),
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  };

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    setData(column);
  }, [treeData]);

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      <WrapperColumnRoot nodeHeight={NODE_HEIGHT} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            index={0}
            onDrop={onDrop}
            node={data[0][0]}
            tabActive={tabActive}
            nodeHeight={NODE_HEIGHT}
            treeViewIndex={treeViewIndex}
            accept={data[0][0].node!.i_id!}
            maxSortOrder={(maxBy(data[1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1}
          />
        </div>
      </WrapperColumnRoot>
      <div className="wrap_node_tree">
        {Array.from({
          length: columnClosed !== undefined ? columnClosed + 1 : 6,
        }).map((_, index) =>
          index > 0 ? (
            <ColumnRenderer
              key={index}
              onDrop={onDrop}
              columnIndex={index}
              column={data[index]}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              treeViewIndex={treeViewIndex}
              onDropQuestion={onDropQuestion}
              accept={data[0][0].node!.i_id!}
              isPublish={!!data[0][0].node?.publish}
              itemMoveCopySelected={itemMoveCopySelected}
              setItemMoveCopySelected={setItemMoveCopySelected && setItemMoveCopySelected}
              maxSortOrder={
                (maxBy(data[index + 1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1
              }
            />
          ) : (
            <div className="column" key={index} />
          )
        )}
      </div>
      <CustomDragLayer nodeHeight={NODE_HEIGHT} />
    </Wrapper>
  );
};

export default TreeView;
