import React from 'react';
import { Form, Select, Input } from 'antd';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import EditUserMasterStyled from '../Edit/styles';
import { Header } from 'components';
import * as Types from 'types';

interface Props {
  birthDay: {
    day: string;
    month: string;
    year: string;
  };
  dateJoinedCompany: {
    month: string;
    year: string;
  };
  dataSelectDepartment: Types.Selects.SelectAdminDepartmentResType[];
  dataSelectPosition: Types.Selects.SelectAdminPositionResType[];
  dataSelectAdminRole: Types.Selects.SelectAdminRoleResType[];
  formikValue: Types.EditManagementUserMasterFormFormik;
}

const { Option } = Select;

const UserDetailPDF: React.FC<Props> = ({
  birthDay,
  dateJoinedCompany,
  dataSelectDepartment,
  dataSelectPosition,
  dataSelectAdminRole,
  formikValue,
}) => {
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);

  return (
    <>
      <Header title={headerTitle} exportPDF />
      <EditUserMasterStyled collapsedMenu={collapsedMenu}>
        <p className="text-sub-title">
          社内ユーザーマスタの新規登録を行う画面です。 <br />
          情報を入力後に登録ボタンをクリックしてください。
        </p>
        <Form layout="vertical" autoComplete="off">
          <div className="wrap-basic-information">
            <div className="header">基本情報</div>
            <div className="body">
              <div className="form-left">
                <Form.Item
                  label={
                    <span className="text-label">
                      社員番号<span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    className="text-input"
                    placeholder="半角数字：最大10文字"
                    value={formikValue.employee_code}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-label">
                      氏名<span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    value={formikValue.name}
                    className="text-input"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-label">
                      氏名（フリガナ）<span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    className="text-input"
                    placeholder="全角カナ：最大100文字"
                    value={formikValue.name_furigana}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-label">
                      メールアドレス <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    type="email"
                    className="text-input"
                    placeholder="＠を含む半角英数字：最大300文字"
                    autoComplete="off"
                    readOnly
                    value={formikValue.email}
                  />
                </Form.Item>
              </div>
              <div className="form-right">
                <Form.Item
                  name="dob"
                  label={
                    <span className="text-label">
                      生年月日 <span className="require" />
                    </span>
                  }
                  className="form-input"
                >
                  <Select
                    placeholder="----"
                    className="pull_down input_small"
                    allowClear
                    value={birthDay.year || undefined}
                  ></Select>
                  <span className="text-label-content">年</span>
                  <Select
                    className="pull_down input-month-day"
                    placeholder="--"
                    allowClear
                    value={birthDay.month || undefined}
                  ></Select>
                  <span className="text-label-content">月</span>
                  <Select
                    className="pull_down input-month-day"
                    placeholder="--"
                    allowClear
                    value={birthDay.day || undefined}
                  ></Select>
                  <span className="text-label-content">日</span>
                </Form.Item>
                <Form.Item
                  name="doj"
                  label={
                    <span className="text-label not-require">
                      入社年月 <span className="require" />
                    </span>
                  }
                  className="form-input"
                >
                  <Select
                    className="pull_down input_small"
                    placeholder="----"
                    allowClear
                    value={dateJoinedCompany.year || undefined}
                  ></Select>
                  <span className="text-label-content">年</span>
                  <Select
                    className="pull_down input-month-day"
                    placeholder="--"
                    allowClear
                    value={dateJoinedCompany.month || undefined}
                  ></Select>
                  <span className="text-label-content">月 </span>
                </Form.Item>
                <Form.Item
                  className="pull_down"
                  label={
                    <span className="text-label">
                      権限 <span className="require">*</span>
                    </span>
                  }
                >
                  <Select
                    className="pull_down"
                    placeholder="選択してください"
                    value={formikValue.role_code || ''}
                  >
                    {dataSelectAdminRole?.map(({ i_id, name }) => (
                      <Option value={i_id} key={i_id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="wrap-department">
            <div className="header">所属・役職情報</div>
            <div className="body">
              <p className="description">所属・役職情報を設定・編集することができます。</p>
              <span className="label">メイン所属・役職</span>
              <div className="wrap-main-position">
                <div className="form-row">
                  <div className="item">
                    <Form.Item
                      label={
                        <span className="text-label">
                          所属 <span className="require">*</span>
                        </span>
                      }
                      className="form-input"
                    >
                      <Select
                        className="pull_down"
                        placeholder="選択してください"
                        value={formikValue.main_position.affiliation_id}
                      >
                        {dataSelectDepartment?.map(({ i_id, name }) => (
                          <Option value={i_id} key={i_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="item">
                    <Form.Item
                      label={
                        <span className="text-label">
                          役職 <span className="require">*</span>
                        </span>
                      }
                      className="form-input"
                    >
                      <Select
                        className="pull_down"
                        placeholder="選択してください"
                        value={formikValue.main_position.positions_code}
                      >
                        {dataSelectPosition?.map(({ i_id, name }) => (
                          <Option value={i_id} key={i_id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </EditUserMasterStyled>
    </>
  );
};

export default UserDetailPDF;
