import React, { useCallback, useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { unionBy } from 'lodash';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { HEADER_ADMIN_ROLES_EXPORT_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { authorityMasterSelector } from './selectors';
import { Header, SelectField } from 'components';
import AuthorityMasterStyled from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  deleteAdminRole,
  getAdminRoleList,
  getSelectAdminRole,
  getDataAdminRolesExportCSV,
} from './thunk';
import PDFRolesMasterTableDocument from './PDFRolesMasterTableDocument';

const { Option } = Select;

const AuthorityMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showWarningDeleteModal, setShowWarningDeleteModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>();
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const { listAuthority, userRole, total } = useSelector(authorityMasterSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const columns: ColumnsType<Types.Roles.ResponseType> = [
    {
      key: 'code',
      className: 'column',
      width: '10%',
      align: 'center',
      title: 'コード',
      dataIndex: 'code',
    },
    {
      key: 'name',
      className: 'column',
      width: '74%',
      title: '名称',
      dataIndex: 'name',
    },
    {
      key: 'edit',
      className: 'column',
      width: '8%',
      title: '編集',
      render: (_: string, item: Types.Roles.ResponseType) => (
        <FormOutlined className="icon" onClick={() => handleEditItem(item)} />
      ),
    },
    {
      key: 'delete',
      className: 'column',
      width: '8%',
      title: '削除',
      align: 'center',
      render: (item: Types.UserMasterRole) => (
        <DeleteOutlined className="icon" onClick={() => handleDeleteItem(item)} />
      ),
    },
  ];

  const fetchDataAdminRoleList = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getAdminRoleList({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: page,
        per_page: 0,
      })
    );
  }, [dispatch, page, userInfo]);

  const fetchDataSelectAdminRole = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getSelectAdminRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  const searchFormik = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getAdminRoleList({
          conditions,
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: page,
          per_page: 0,
        })
      );
    },
    onReset: () => {
      fetchDataAdminRoleList();
    },
  });

  const handleDeleteItem = (item: Types.UserMasterRole) => {
    setDeleteItemId(item.i_id);
    setOpenModalConfirmDeleteItem(true);
  };

  const handleEditItem = (item: Types.Roles.ResponseType) => {
    navigate(generatePath(routes.AuthorityMasterEdit.path, { id: item.i_id }), {
      state: item,
    });
  };

  const handleAddItem = () => {
    navigate(generatePath(routes.AuthorityMasterCreate.path));
  };

  const handleSearch = (value: string) => {};

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAdminRolesExportCSV({
          page: 1,
          per_page: 0,
        })
      );
      if (getDataAdminRolesExportCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.items.map((item) => ({
          i_id: item.i_id,
          name: item.name,
          code: item.code,
          createdat: item.createdat,
          createdby: item.createdby,
          updatedby: item.updatedby,
          deletedat: item.deletedat,
          updatedat: item.updatedat,
          company_id: item.company_id,
          user_report_permission: item.user_report_permission,
          users_master_permission: item.users_master_permission,
          sales_report_permission: item.sales_report_permission,
          admin_master_permission: item.admin_master_permission,
          roles_master_permission: item.roles_master_permission,
          corporate_user_permission: item.corporate_user_permission,
          curriculum_tree_permission: item.curriculum_tree_permission,
          admin_dashboard_permission: item.admin_dashboard_permission,
          question_master_permission: item.question_master_permission,
          positions_master_permission: item.positions_master_permission,
          partner_management_permission: item.partner_management_permission,
          billing_management_permission: item.billing_management_permission,
          curricullum_master_permission: item.curricullum_master_permission,
          departments_master_permission: item.departments_master_permission,
          invoice_correspondence_permission: item.invoice_correspondence_permission,
          individual_user_management_permission: item.individual_user_management_permission,
          official_curriculum_report_permission: item.official_curriculum_report_permission,
          official_curriculum_publish_permission: item.official_curriculum_publish_permission,
          skill_check_implementation_report_permission:
            item.skill_check_implementation_report_permission,
        }));

        const csvString = [
          HEADER_ADMIN_ROLES_EXPORT_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, 'admin_role.csv');
      }
      setShowConfirmExportFileModal(false);
    } else {
      const blob = await pdf(<PDFRolesMasterTableDocument roles={listAuthority} />).toBlob();
      saveAs(blob, 'roles_master_table.pdf');
      setShowConfirmExportFileModal(false);
    }
  };

  const handleDeleteAdminRole = useCallback(async () => {
    if (!deleteItemId) return;
    const resultAction = await dispatch(deleteAdminRole({ id: deleteItemId }));
    if (deleteAdminRole.fulfilled.match(resultAction)) {
      setShowCompleteModal(true);
    } else {
      setShowWarningDeleteModal(true);
    }
    fetchDataAdminRoleList();
    fetchDataSelectAdminRole();
    setDeleteItemId(undefined);
  }, [deleteItemId, dispatch, fetchDataAdminRoleList, fetchDataSelectAdminRole]);

  useEffect(() => {
    fetchDataAdminRoleList();
    fetchDataSelectAdminRole();
  }, [fetchDataAdminRoleList, fetchDataSelectAdminRole]);

  return (
    <AuthorityMasterStyled isEmptyData={!listAuthority.length}>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの作成・管理を行う画面です。 権限マスタはSKILL
            FAMILIAR上で社内ユーザーが使用できる機能の範囲を設定します。
            <br />
            作成した権限マスタをユーザーに設定することで、機能ごとの権限がユーザーに設定されます。
          </p>
          <div className="border" />
          <FormikProvider value={searchFormik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label"> 権限</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  onSearch={handleSearch}
                  className="select-input"
                  placeholder="選択してください"
                  name="name"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(userRole, 'name').map(
                    ({ name }, index) =>
                      name && (
                        <Option value={name} key={index}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="label-reset">
                  <span className="label-text"> リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          <div className="wrap-table">
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                disabled={!listAuthority.length}
                icon={<CloudDownloadOutlined />}
                onClick={() => setShowConfirmExportFileModal(true)}
              >
                エクスポート
              </Button>
              <Button
                className="btn btn-active"
                onClick={handleAddItem}
                icon={<PlusOutlined className="size-icon" />}
              >
                新規登録
              </Button>
            </div>

            <Table
              rowKey="code"
              dataSource={listAuthority}
              columns={columns}
              className={total ? 'table' : 'table_mr'}
              pagination={{
                pageSize: perPage,
                showSizeChanger: false,
                total: total,
                current: page,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    {listAuthority.length && (
                      <div className="total-record-table">
                        <span className="text-static">
                          {page * perPage > listAuthority.length
                            ? listAuthority.length
                            : page * perPage}
                          <small> 件表示</small> / {listAuthority.length}
                          <small> 名</small>
                        </span>
                      </div>
                    )}
                    <div>
                      <span className="label-select">表示件数：</span>
                      <Select className="select-option" onChange={setPerpage} value={perPage}>
                        {[100, 50, 20, 10].map((e) => (
                          <Option value={e}>{e}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="検索するマスタが未選択です"
        description={
          <p className="text-content">
            検索する権限を選択後、
            <br />
            「検索」をクリックしてください。
          </p>
        }
      />
      <ActionErrorModal
        visible={showWarningDeleteModal}
        setVisible={setShowWarningDeleteModal}
        subTitle="削除に失敗しました"
        description="マスタの削除に失敗しました。 再度お試しください。"
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="削除が完了しました"
      />
      <ConfirmDeleteModal
        visible={openModalConfirmDeleteItem}
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        onSubmit={handleDeleteAdminRole}
        onCancel={() => setDeleteItemId('')}
        setVisible={setOpenModalConfirmDeleteItem}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </AuthorityMasterStyled>
  );
};

export default AuthorityMaster;
