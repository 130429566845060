import { createAsyncThunk } from '@reduxjs/toolkit';

import { POSITIONS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataAdminPositions = createAsyncThunk<
  Types.GetItemResponseTypeNew<Types.AdminPositions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/getDataAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminPositions = createAsyncThunk<
  Types.GetItemResponseTypeNew<Types.AdminPositions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/getSelectAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminPositions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/thunk/deleteAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(POSITIONS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminPosition = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AdminPositions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/createAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminPosition = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdminPositions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/updateAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
