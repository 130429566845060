import styled from 'styled-components';
import ModalCommonInvoice from '../ModalCommonInvoice';

export const ModalStyled = styled(ModalCommonInvoice)`
  @media (max-width: 1440px) {
    .ant-modal-footer {
      padding: 0 20px 40px 20px !important;
      .button-secondary,
      .button-primary {
        width: 160px;
        height: 40px;
        padding: 9px 20px !important;
      }
    }
    .ant-modal-body {
      padding: 26px 40px 0 40px !important;
    }
  }
  .ant-modal-body {
    border-top: 1px solid rgba(204, 204, 204, 1);
    padding: 36px 40px 0 40px;
    background-color: #f9f8f8;
  }
  .ant-modal-footer {
    padding: 0 20px 20px 20px;
    background-color: #f9f8f8;
  }
  .ant-modal-content {
    width: 720px;
    background-color: rgba(249, 248, 248, 1);
  }
`;

export const Styled = styled.div`
  @media (max-width: 1440px) {
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .ant-input-textarea {
      height: 300px !important;
    }
  }
  .form-edit {
    .ant-row {
      flex-direction: column;
    }
    .ant-form-item-label {
      text-align: left;
      .text-label {
        font-size: 13px;
        color: rgba(34, 34, 34, 1);
        .sub-text-label {
          color: rgba(240, 51, 10, 1);
          font-size: 17px;
        }
      }
    }
    .iJhzlk {
      padding: 8px;
      position: relative;
      .ant-input-show-count-has-suffix {
        position: absolute;
        right: 0;
        top: 38px;
      }
    }
  }
`;
