import { createSlice } from '@reduxjs/toolkit';
import { findIndex, unionBy } from 'lodash';

import * as Types from 'types';
import {
  getOfficialPublish,
  getArchiveList,
  getReleaseNoteDetail,
  updateCurriculumPublishedHistory,
  getDataAdminReleaseNoteManagement,
  getDataReleaseNoteID,
  updateReleaseNoteSection,
  createCurriculumPublishedHistory,
  updateOfficialCurriculumMaster,
  createReleaseNote,
  createReleaseNoteSection,
  removeReleaseSectionNote,
  updateReleaseNote,
  getDataAdminReleaseNoteManagementExport,
  getDataUsers,
  getProviderInformation,
  getOfficialCurriculumMaster,
  updateProviderInformation,
  getCurriculumPublishedHistory,
} from './thunk';

export interface InitialState {
  loading: boolean;
  error?: string;
  officialPublishData: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  dataAdminReleaseNoteManagement: Array<Types.AdminReleaseNoteManagement.ResponseType>;
  archiveList: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  releaseNoteDetail?: Types.RelaseNoteFormik;
  dataReleaseNoteID: Array<Types.ReleaseNote.ResponseType>;
  totalDataReleaseNoteID: number;
  totalReleaseNote: number;
  total: number;
  dataUsers: Array<Types.Users.ResponseType>;
  providerInformation?: Types.ProviderInformation.ResponseType;
  officialCurriculumMaster: Array<Types.OfficialCurriculumMaster.ResponseType>;
  releaseNotes: Array<Types.ReleaseNoteDetail.ResponseType>;
  curriculumPublishedHistory: Array<Types.CurriculumPublishedHistory.ResponseType>;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  officialPublishData: [],
  dataAdminReleaseNoteManagement: [],
  archiveList: [],
  dataReleaseNoteID: [],
  totalDataReleaseNoteID: 0,
  totalReleaseNote: 0,
  total: 0,
  dataUsers: [],
  officialCurriculumMaster: [],
  releaseNotes: [],
  curriculumPublishedHistory: [],
};
export const PublicManagementSlice = createSlice({
  name: 'publicManagement-slice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder
      .addCase(getOfficialPublish.pending, startLoading)
      .addCase(getDataAdminReleaseNoteManagement.pending, startLoading)
      .addCase(getArchiveList.pending, startLoading)
      .addCase(createCurriculumPublishedHistory.pending, startLoading)
      .addCase(updateOfficialCurriculumMaster.pending, startLoading)
      .addCase(updateCurriculumPublishedHistory.pending, startLoading)
      .addCase(getReleaseNoteDetail.pending, startLoading)
      .addCase(createReleaseNote.pending, startLoading)
      .addCase(createReleaseNoteSection.pending, startLoading)
      .addCase(removeReleaseSectionNote.pending, startLoading)
      .addCase(updateReleaseNote.pending, startLoading)
      .addCase(getDataReleaseNoteID.pending, startLoading)
      .addCase(getDataAdminReleaseNoteManagementExport.pending, startLoading)
      .addCase(updateReleaseNoteSection.pending, startLoading)
      .addCase(getDataUsers.pending, startLoading)
      .addCase(getProviderInformation.pending, startLoading)
      .addCase(getOfficialCurriculumMaster.pending, startLoading)
      .addCase(updateProviderInformation.pending, startLoading)
      .addCase(getCurriculumPublishedHistory.pending, startLoading);

    builder.addCase(getOfficialPublish.fulfilled, (state, action) => {
      const newOfficialData: Array<Types.OfficialCurriculumPublishingSettings.ResponseType> = [];
      (action.payload.report_results || []).forEach((e) => {
        const index = findIndex(
          newOfficialData,
          (val) =>
            val.official_curriculum_code === e.official_curriculum_code && val.archive_flg !== 1
        );
        if (index >= 0) {
          newOfficialData[index] =
            newOfficialData[index].history_id < e.history_id ? e : newOfficialData[index];
        } else {
          newOfficialData.push(e);
        }
      });
      state.officialPublishData = newOfficialData;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataAdminReleaseNoteManagement.fulfilled, (state, action) => {
      state.dataAdminReleaseNoteManagement = action.payload.report_results;
      state.totalReleaseNote = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getArchiveList.fulfilled, (state, action) => {
      state.archiveList = unionBy(
        action.payload.report_results,
        (item) => item.official_curriculum_code
      );
      stopLoading(state);
    });
    builder.addCase(getReleaseNoteDetail.fulfilled, (state, action) => {
      state.releaseNoteDetail = {
        i_id: action.payload.report_results[0].i_id,
        title: action.payload.report_results[0].release_note_title,
        release_note_id: action.payload.report_results[0].release_note_id,
        official_curriculum_code: action.payload.report_results[0].official_curriculum_code,
        description: action.payload.report_results[0].description,
        version: action.payload.report_results[0].release_note_version,
        sections: action.payload.report_results.map((manual) => ({
          i_id: manual.item_ref?.section_id?.i_id,
          section_name: manual.section_name,
          text: manual.section_text,
        })),
      };
      state.releaseNotes = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataReleaseNoteID.fulfilled, (state, action) => {
      state.totalDataReleaseNoteID = action.payload.totalItems;
      state.dataReleaseNoteID = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getDataUsers.fulfilled, (state, action) => {
      state.dataUsers = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getProviderInformation.fulfilled, (state, action) => {
      state.providerInformation = action.payload.items[0];
      stopLoading(state);
    });
    builder.addCase(getOfficialCurriculumMaster.fulfilled, (state, action) => {
      state.officialCurriculumMaster = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getCurriculumPublishedHistory.fulfilled, (state, action) => {
      state.curriculumPublishedHistory = action.payload.items;
      stopLoading(state);
    });

    builder
      .addCase(updateReleaseNoteSection.fulfilled, stopLoading)
      .addCase(updateReleaseNote.fulfilled, stopLoading)
      .addCase(removeReleaseSectionNote.fulfilled, stopLoading)
      .addCase(createReleaseNoteSection.fulfilled, stopLoading)
      .addCase(createReleaseNote.fulfilled, stopLoading)
      .addCase(updateOfficialCurriculumMaster.fulfilled, stopLoading)
      .addCase(createCurriculumPublishedHistory.fulfilled, stopLoading)
      .addCase(updateCurriculumPublishedHistory.fulfilled, stopLoading)
      .addCase(updateProviderInformation.fulfilled, stopLoading);

    builder
      .addCase(getOfficialPublish.rejected, stopLoading)
      .addCase(getDataAdminReleaseNoteManagement.rejected, stopLoading)
      .addCase(getArchiveList.rejected, stopLoading)
      .addCase(createCurriculumPublishedHistory.rejected, stopLoading)
      .addCase(updateOfficialCurriculumMaster.rejected, stopLoading)
      .addCase(updateCurriculumPublishedHistory.rejected, stopLoading)
      .addCase(getReleaseNoteDetail.rejected, stopLoading)
      .addCase(createReleaseNote.rejected, stopLoading)
      .addCase(createReleaseNoteSection.rejected, stopLoading)
      .addCase(removeReleaseSectionNote.rejected, stopLoading)
      .addCase(updateReleaseNote.rejected, stopLoading)
      .addCase(getDataReleaseNoteID.rejected, stopLoading)
      .addCase(getDataAdminReleaseNoteManagementExport.rejected, stopLoading)
      .addCase(updateReleaseNoteSection.rejected, stopLoading)
      .addCase(getDataUsers.rejected, stopLoading)
      .addCase(getProviderInformation.rejected, stopLoading)
      .addCase(getOfficialCurriculumMaster.rejected, stopLoading)
      .addCase(updateProviderInformation.rejected, stopLoading)
      .addCase(getCurriculumPublishedHistory.rejected, stopLoading);
  },
});

export default PublicManagementSlice.reducer;
