import styled from 'styled-components';

export default styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-delete {
    color: #bbbbbb;
    font-size: 28px;
  }
  .text {
    font-size: 16px;
    margin-left: 8px;
  }
`;
