import styled from 'styled-components';

export const SectionStyled = styled.div`
  .border-style-top {
    width: 100%;
    height: 4px;
    background: #f6ac00;
    border-radius: 2px;
  }
  .content-register {
    padding: 20px 90px 0 90px;
    text-align: center;
    .des-image {
      color: #f6ac00;
      font-size: 17px;
      font-family: Lato;
      font-weight: 700;
      margin: 0;
      padding-top: 10px;
    }
    .partners-text {
      color: #2a2a2a;
      font-size: 15px;
      font-family: Lato;
      padding: 25px 0;
    }
    .partners-description {
      color: #424242;
      font-size: 13px;
      padding-bottom: 35px;
    }
    .form {
      padding-bottom: 40px;
      .input {
        width: 100%;
        height: 45px;
        border-radius: 3px;
        background: #f5f5f5;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01) inset;
        .ant-input {
          background: #f5f5f5;
        }
        .ant-input-prefix {
          color: #bbbbbb;
        }
      }
      .btn-submit {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background: #f6ac00;
        box-shadow: 1px 1px 4px 0px rgba(68, 68, 68, 0.2);
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
      }
      .ant-btn-primary {
        border-color: unset !important;
      }
      .ant-btn-primary:active {
        border-color: unset !important;
      }
      .ant-btn-primary:hover {
        border-color: unset !important;
      }
      .ant-btn-primary:focus {
        border-color: unset !important;
      }
    }
  }
`;
