import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import styled from 'styled-components';

interface Props {
  collapsedMenu: boolean;
}

export default styled.div<Props>`
  width: calc(100vw - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
  .header {
    .wrap-header {
      width: 75%;
      display: flex;
      justify-content: flex-end;
      .item-storage {
        display: flex;
        align-items: center;
        .btn {
          cursor: pointer;
          font-size: 13px;
          font-weight: 700;
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
        }
        .btn-active {
          background-color: #f6ac00;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          color: #ffffff;
          .size-icon {
            font-size: 16px;
            margin-right: 10px;
          }
        }
        .count {
          padding-left: 20px;
          .text-page {
            padding: 0 5px;
          }
        }
      }
    }
  }

  .sub-child {
    background: #ffffff !important;
  }
  .date-picker {
    width: 100%,
    border-radius: 5px
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    height: 100%;
  }
  .text-table {
    font-size: 13px;
    margin: 0
  }
  .table-chart {
    display: flex;
    padding: 20px 43px;
    background: #fff;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
    .item-left {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .text-note {
        color: #424242;
        font-size: 11px;
        padding-top: 10px;
      }
    }
    .item-right {
      width: 80%;
      align-self: center;
      .table {
        border-radius: 3px;
        border: 1px solid #08a3a5;
        border-collapse: collapse;
        .ant-table-tbody .ant-table-cell {
          font-family: Lato;
          font-size: 14px;
          text-align: right;
          color: #424242;
          border-left: 1px solid rgba(85, 85, 85, 0.18);
        }
        .ant-table-tbody tr td:first-child {
          border-left: none;
        }
        .ant-table-tbody .ant-table-cell.storage-breakdown {
          text-align: left;
        }
        .ant-table-tbody .ant-table-cell {
          padding: 5px 5px;
        }

        .ant-table-thead .ant-table-cell {
          font-weight: 700;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          background-color: #08a3a5 !important;
          // padding: 10px 10px;
          ::before {
            width: 1px !important;
            height: 100% !important;
            background-color: #ffffff !important;
          }
        }
        .small {
          color: #999999;
          font-size: 10px;
        }
      }
    }
  }
  .container {
    padding: 0 35px;
    padding-bottom: 95px;
    .label-content {
      color: #08a3a5;
      font-size: 12px;
      font-weight: 700;
      padding: 20px 0;
      display: flex;
      align-items: center;
    }
    .item-select {
      width: 80%;
      .form-select {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .item {
          width: 19%;
          .select {
            width: 100%;
            .ant-select-selector {
              border-radius: 5px;
            }
          }
          .text-label {
            height: 25px;
          }
          .segmented {
            display: flex;
            align-items: center;
            justify-content: start;
            .segmented-item {
              padding: 5px 10px;
              background: #ffffff;
              border: 2px solid #cccccc;
              cursor: pointer;
              color: #424242;
              font-weight: 400;
              font-size: 13px;
              .icon {
                margin-right: 10px;
                color: #c4c4c4;
              }
              &:nth-child(1) {
                border-radius: 3px 0 0 3px;
                border-right-color: #08a3a5;
              }
              &:nth-child(2) {
                border-radius: 0 3px 3px 0;
                border-left-width: 0;
              }
            }
            .segmented-item-selected {
              border-color: #08a3a5;
              color: #08a3a5;
              font-weight: bold;
              .icon {
                color: #08a3a5;
              }
            }
          }
        }
        .text-label {
          display: block;
          font-size: 13px;
          color: #424242;
          .require {
            color: #f0330a;
          }
        }
      }
    }
    .text-total-record-table {
      position: absolute;
      bottom: 10px;
      right: 0;
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
    }

    .wrap-table {
      margin-top: 25px;
      .table {
        border: 1px solid #dddddd;

        .ant-table-tbody .ant-table-row .ant-table-cell {
          border-left: 1px solid rgba(85, 85, 85, 0.08);
        }
        .ant-table-cell.ant-table-cell-with-append {
          background: #ffffff;
        }
        .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
          display: none;
        }
        .ant-table-tbody > tr.ant-table-row-level-0 > td {
          border-top: 1px solid #eeeeee;
          border-bottom: 1px solid #eeeeee;
        }
        .ant-table-tbody > tr > td {
          border: none;
        }
        .ant-table-cell {
          text-align: center;
          font-size: 13px;
        }
        .ant-table-tbody .ant-table-cell.user-type {
          text-align: left;
        }
        .ant-table-tbody .ant-table-cell {
          padding: 5px 10px;
        }
        .ant-table-thead {
          border-radius: 2px 2px 0 0;
          .ant-table-cell {
            background-color: #ebebeb;
            font-weight: 700;
            font-size: 12px;
            color: #424242;
            margin: 0 3px;
            &:before {
              height: 100% !important;
            }
          }
        }
      }
      .chart-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .wrap-chart {
          border: 1px solid #dddddd;
          padding: 16px 32px;
          background: #ffffff;
          .title-fee {
            margin-bottom: 17px;
          }
          .wrap-title {
            display: flex;
            justify-content: space-between;
            .unit {
              color: #999;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
`;
