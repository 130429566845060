import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

import { getCompany } from './thunk';
import * as Types from 'types';

export type InitialState = {
  dataFilterFreeTrialCompany: Array<Types.Companies.ResponseType>;
  dataFreeTrialCompany: Array<Types.Companies.ResponseType>;
  loading: boolean;
};

const initialState: InitialState = {
  dataFilterFreeTrialCompany: [],
  dataFreeTrialCompany: [],
  loading: false,
};

export const trialManagementSlice = createSlice({
  name: 'free-trial-management-page',
  initialState,
  reducers: {
    filterDataFreeTrial(state, action: PayloadAction<Array<Types.Companies.ResponseType>>) {
      state.dataFilterFreeTrialCompany = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder.addCase(getCompany.pending, startLoading);

    builder.addCase(getCompany.fulfilled, (state, action) => {
      const data = sortBy(
        action.payload.items.filter((item) => item.trial_start_date),
        (obj) => new Date(obj.trial_start_date)
      );
      state.dataFreeTrialCompany = data;
      state.dataFilterFreeTrialCompany = data;
      stopLoading(state);
    });

    builder.addCase(getCompany.rejected, stopLoading);
  },
});

export const { filterDataFreeTrial } = trialManagementSlice.actions;

export default trialManagementSlice.reducer;
