import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Document } from 'react-pdf';

import { initialPDFState } from '../../state/reducer';
import { setNumPages } from '../../state/actions';
import { PDFAllPages } from './PDFAllPages';
import PDFSinglePage from './PDFSinglePage';
import { PDFContext } from '../../state';

const PDFPages: React.FC = () => {
  const {
    state: { mainState, paginated },
    dispatch,
  } = useContext(PDFContext);

  const currentDocument = mainState?.currentDocument || null;

  useEffect(() => {
    dispatch(setNumPages(initialPDFState.numPages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument]);

  if (!currentDocument || currentDocument.fileData === undefined) return null;

  return (
    <DocumentPDF
      file={currentDocument.fileData}
      onLoadSuccess={({ numPages }) => dispatch(setNumPages(numPages))}
    >
      {paginated ? <PDFSinglePage /> : <PDFAllPages />}
    </DocumentPDF>
  );
};

const DocumentPDF = styled(Document)`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  background-color: #aaa;
  width: auto;
  .react-pdf__Document {
    width: fit-content !important;
  }
`;

export default PDFPages;
