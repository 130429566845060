import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
}

export default styled.section<Props>`
  position: relative;
  .text-count {
    position: absolute;
    top: -30px;
    right: 0;
  }
  .title {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 20px;
    .code {
      padding-left: 30px;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-around;
    .code-number {
      font-size: 11px;
      color: #999999;
    }
  }
  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 13px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
    }
    .ant-table-cell {
      text-align: center;
      font-size: 13px;
    }
    .ant-table-thead tr .average {
      background: #f9f9f9;
      font-weight: 400;
      font-size: 14px;
      color: #2a2a2a;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
  }
`;
