import styled from 'styled-components';

interface Props {
  isEmpty: boolean;
}

export const PopoverStyled = styled('div')`
  padding: 24px 12px;
  .main-content {
    margin-top: 24px;
  }
`;

export default styled.section<Props>`
  position: relative;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  @media (max-width: 1440px) {
    .wrap-nav {
      button {
        width: 180px;
        height: 40px;
        padding: 8px 20px !important;
      }
    }
    .border-line {
      margin: 30px 0 20px 0 !important;
    }
    .form-search {
      justify-content: flex-start !important;
      .btn-search {
        width: 100px;
      }
    }
    .item {
      width: 240px !important;
      margin-right: 8px;
    }
    .btn-search,
    .label-reset {
      margin-left: 12px;
    }
    .wrap-body {
      .btn-div {
        .btn-control-number {
          width: 160px;
        }
      }
      .wrap-group-button {
        margin-top: 10px;
        .btn-control-file {
          width: 180px;
          padding: 0 10px !important;
        }
        .btn-control-number {
          width: 160px;
        }
      }
      .wrap-table {
        .table {
          .ant-table-thead {
            .ant-table-cell {
              padding: 4px 10px;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              padding: 4px 10px;
              height: 44px;
            }
          }
        }
      }
    }
  }
  .icon {
    font-size: 18px;
  }
  .wrap-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-note {
      font-size: 12px;
      color: #424242;
    }
    .wrap-button {
      display: flex;
      gap: 10px;
      button {
        position: relative;
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: rgba(246, 172, 0, 1);
        padding: 8px 40px;
        max-height: 40px;
        font-size: 13px;
        cursor: pointer;
        .icon-right-outline {
          position: absolute;
          right: 8px;
          top: 12px;
        }
      }
    }
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
    background-color: red;
    max-height: 40px;
  }
  .form-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 22%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .wrap-body {
    width: 100%;
    background: #f9f8f8;
    .text-count {
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: 22px;
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
      .select-perpage {
        display: flex;
        align-items: center;
        padding-left: 50px;
        .label {
          font-size: 12px;
          color: #424242;
          margin: 0 5px;
        }
      }
    }
    .wrap-group-button {
      display: flex;
      position: absolute;
      right: 30px;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      z-index: 9;
      .btn-control-number {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 172, 0, 1);
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: rgba(255, 255, 255, 1);
        height: 40px;
        padding: 0 37px;
        border: none;
        cursor: pointer;
        z-index: 100;
        .text {
          padding: 0 0;
          font-size: 13px;
          font-weight: 700;
        }
      }
      .btn-control-file {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 172, 0, 1);
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: rgba(255, 255, 255, 1);
        height: 40px;
        padding: 0 17px;
        border: none;
        cursor: pointer;
        z-index: 100;
        margin-left: 8px;
        .text {
          padding: 0 0;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
    .wrap-table {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .table {
        margin-top: ${(props) => (props.isEmpty ? '64px' : '0')};
        .ant-table-thead {
          border-radius: 2px 2px 0 0;
          .ant-table-cell {
            background-color: #ebebeb;
            font-weight: 700;
            font-size: 12px;
            color: #424242;
            margin: 0 3px;
            &:before {
              display: none;
            }
          }
        }
        .ant-table-cell {
          text-align: center;
          font-size: 12px;
        }
        .ant-table-cell.name,
        .issue-date,
        .memo {
          text-align: left;
          font-size: 13px;
        }
        .ant-table-tbody .ant-table-cell.date {
          font-size: 11px;
          color: #777777;
        }
      }
      .ant-table-wrapper {
        width: 100%;
      }
      .icon-question {
        color: rgba(8, 163, 165, 1);
        margin-left: 4px;
        cursor: pointer;
      }
      .wrap-icon-row {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(196, 196, 196, 1);
        font-size: 16px;
        cursor: pointer;
      }
      .wrap-check-box {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        .check-invoice-exception {
          color: rgba(75, 131, 215, 0.4);
        }
        .check-forced-withdrawal {
          color: rgba(139, 98, 226, 0.4);
        }
      }
      .wrap-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .wrap-contract-status {
        position: relative;
        .text-status {
          font-size: 13px;
          color: rgba(42, 42, 42, 1);
        }
        .text-status-change {
          font-size: 13px;
          color: rgba(240, 51, 10, 1);
        }
        .wrap-icon-memo {
          display: flex;
          align-items: center;
          .wrap-icon-file {
            white-space: nowrap;
            overflow: hidden;
            margin-right: 8px;
            font-size: 15px;
            color: rgba(196, 196, 196, 1);
            cursor: pointer;
            &:hover {
              color: rgba(8, 163, 165, 1);
            }
          }
          .text-memo {
            font-size: 11px;
          }
        }
      }
      .wrap-mail-address {
        .text-mail-address {
          color: rgba(0, 152, 154, 1);
          font-size: 12px;
          text-decoration: underline;
        }
      }
      .wrap-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: rgba(196, 196, 196, 1);
        cursor: pointer;
        &:hover {
          color: rgba(8, 163, 165, 1);
        }
      }
    }
  }
`;
