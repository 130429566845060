import React, { useContext } from 'react';
import styled from 'styled-components';

import { nextDocument, previousDocument } from '../store/actions';
import { DocViewerContext } from '../store/DocViewerProvider';
import { DocumentNav } from './DocumentNav';

export const HeaderBar: React.FC = () => {
  const { state, dispatch } = useContext(DocViewerContext);
  const { config } = state;

  if (config?.header?.disableHeader) return null;

  const override = config?.header?.overrideComponent?.(
    state,
    () => dispatch(previousDocument()),
    () => dispatch(nextDocument())
  );

  if (override) {
    return override;
  } else {
    return (
      <Container>
        <DocumentNav />
      </Container>
    );
  }
};

const Container = styled.div`
  @media (max-width: 768px) {
    min-height: 30px;
    padding: 5px;
    font-size: 10px;
  }
`;
