import React, { useEffect, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Form } from 'formik-antd';
import saveAs from 'file-saver';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { getReleaseNoteDetail, getReleaseNoteDetailExport } from '../../thunk';
import { HEADER_RELEASE_NOTE_DETAIL } from 'constant/header.export.constant';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import ReleaseNoteStyled from './styles';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import * as Types from 'types';

interface Props {
  selected?: Types.AdminReleaseNoteManagement.ResponseType;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReleaseNote: React.FC<Props> = ({ visible, setVisible, publish, selected }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);

  const { loading, releaseNoteDetail } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: releaseNoteDetail ?? {
      title: '',
      description: '',
      version: 1.0,
      official_curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getReleaseNoteDetailExport({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [selected?.release_note_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getReleaseNoteDetailExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          description: item.description,
          official_curriculum_code: item.official_curriculum_code,
          release_note_id: item.release_note_id,
          release_note_title: item.release_note_title,
          release_note_version: item.release_note_version,
          section_name: item.section_name,
          section_text: item.section_text,
        }));

        const csvString = [
          ...listCsv.map((item) => Object.values(item)),
          HEADER_RELEASE_NOTE_DETAIL.map(({ label }) => label),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setShowConfirmExportFileModal(false);
    }
  };

  useEffect(() => {
    if (userInfo && visible) {
      dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [selected?.release_note_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, visible, userInfo, selected]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <ReleaseNoteStyled
      isEmptyData={!releaseNoteDetail?.sections.length}
      title={<span className="title">リリースノート</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      visible={visible}
      onCancel={handleToggleModal}
    >
      <FormikProvider value={formik}>
        <Form>
          <div className="information">
            <div>
              <span className="content">{selected?.official_curriculum_name}</span>
              <span className="id">
                ここから始めるプログラミングの基礎（ID：{selected?.official_curriculum_code})
              </span>
            </div>
            <div className="version">
              <div className="wrap-label">
                <label className="label">
                  OFFICIALカリキュラム＆リリースノート公開バージョン
                  <span className="required"> *</span> ：
                </label>
                <Form.Item name="version" className="version-required">
                  <Input name="version" readOnly={true} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="while-editing">
            <div>
              <img
                src={
                  publish === 0 || !publish
                    ? WhiteEditing
                    : publish === 2
                    ? TickComplete
                    : publish === 1
                    ? UnPublished
                    : StopPublic
                }
                alt=""
              />
              <span className="content">{formik.values.title}</span>
            </div>
            <Button className=" btn-outline" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined
                style={{
                  fontSize: 18,
                }}
              />
              <span> エクスポート</span>
            </Button>
          </div>
          <div className="wrap-editor">
            <div className="left-side">
              <div className="title-editor">
                <div className="no-editor">{indexSection + 1}</div>
                <Form.Item name="section_name" className="item input-title">
                  <Input
                    disabled
                    value={formik.values.sections[indexSection].section_name}
                    name={`sections.${indexSection}.section_name`}
                    placeholder="セクション名を入力..."
                  />
                </Form.Item>
              </div>
              <JoditEditor
                config={defaultConfig}
                value={formik.values.sections[indexSection]?.text}
              />
              <div className="wrap-bottom-editor">
                <div className="wrap-button-editor">
                  <Button
                    className="btn button-prev"
                    disabled={indexSection === 0}
                    onClick={() => setIndexSection((prevState) => prevState - 1)}
                  >
                    <CaretLeftOutlined />
                    <span>前のセクション</span>
                  </Button>
                  <Button
                    className="btn button-next"
                    disabled={indexSection === formik.values.sections.length - 1}
                    onClick={() => setIndexSection(indexSection + 1)}
                  >
                    <span>次のセクション</span>
                    <CaretRightOutlined />
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-side">
              <Form.Item name="section_name" label="概要：" className="text-area">
                <TextArea
                  disabled
                  value={formik.values.description}
                  name="section_name"
                  rows={5}
                  placeholder="最大480文字"
                />
              </Form.Item>
              <span className="title-right">セクション</span>
              <div className="dashed-line"></div>
              <div className="wrap-radio">
                {formik.values.sections.map(({ section_name }, index) => (
                  <div
                    key={index}
                    onClick={() => setIndexSection(index)}
                    className={`section ${index === indexSection && 'section-checked'}`}
                  >
                    <span className="no-number">{index + 1}</span>
                    <span className="section-name">{section_name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="wrap-submit">
            <div className="wrap-submit">
              <div className="wrap-button">
                <Button className="btn btn_close" onClick={() => setVisible(false)}>
                  閉じる
                </Button>
              </div>
            </div>
          </div>
          <Completed
            title="リリースノートを保存しました。"
            visible={visibleComplete}
            setVisible={setVisibleComplete}
            onSubmit={() => navigate(routes.PublicManagement.path)}
          />
          <UpdateStatus
            publish={publish}
            status="unPublished"
            title="リリースノートを公開します"
            from={UnPublished}
            to={TickComplete}
            description={
              <span>
                リリースノートを公開すると、
                <br /> SKILL FAMILIARで一般のユーザー様が閲覧できる状態になります。
              </span>
            }
            visible={visibleUpdate}
            setVisible={setVisibleUpdate}
            onSubmit={() => setVisiblePublished(true)}
          />
          <Published
            visible={visiblePublished}
            setVisible={setVisiblePublished}
            onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          />
          <PopupConfirmExportFile
            visible={showConfirmExportFileModal}
            setVisible={setShowConfirmExportFileModal}
            onSubmit={handleExportCSV}
          />
        </Form>
      </FormikProvider>
    </ReleaseNoteStyled>
  );
};

export default ReleaseNote;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  placeholder: '本文を入力...',
  readonly: true,
  enableDragAndDropFileToEditor: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
};
