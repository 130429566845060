import styled from 'styled-components';

export default styled.section`
  display: flex;
  justify-content: center;
  .content {
    text-align: center;
    font-size: 15px;
    color: #424242;
  }
`;
