import styled from 'styled-components';

export default styled('div')`
  text-align: center;
  margin-top: 12px;
  .title {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .description {
    font-size: 13px;
    color: #424242;
    margin-top: 24px;
  }
  img {
    height: 28px;
  }
  .text {
    font-size: 18px;
    margin-left: 12px;
  }
`;
