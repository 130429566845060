import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import AuthorityMasterFrom from 'containers/AuthorityMasterForm';
import { CreateAuthorityMasterSchema } from 'libs/validations';
import { getAdminRoleDetail, updateAdminRole } from '../thunk';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { authorityMasterSelector } from '../selectors';
import EditAuthorityMasterStyled from './styles';
import { routes } from 'navigations/routes';
import PDFDocument from '../PDFDocument';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';
import * as Types from 'types';

const EditAuthorityMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { itemEdit } = useSelector(authorityMasterSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formikEdit = useFormik<Types.CreateRolesMasterFormik>({
    initialValues: {
      name: itemEdit?.name || '',
      admin_dashboard_permission: Number(itemEdit?.admin_dashboard_permission) ?? 1,
      sales_report_permission: Number(itemEdit?.sales_report_permission) ?? 1,
      user_report_permission: Number(itemEdit?.user_report_permission) ?? 1,
      official_curriculum_report_permission:
        Number(itemEdit?.official_curriculum_report_permission) ?? 1,
      skill_check_implementation_report_permission:
        Number(itemEdit?.skill_check_implementation_report_permission) ?? 1,
      billing_management_permission: Number(itemEdit?.billing_management_permission) ?? 1,
      official_curriculum_publish_permission:
        Number(itemEdit?.official_curriculum_publish_permission) ?? 1,
      curricullum_master_permission: Number(itemEdit?.curricullum_master_permission) ?? 1,
      question_master_permission: Number(itemEdit?.question_master_permission) ?? 1,
      users_master_permission: Number(itemEdit?.users_master_permission) ?? 1,
      roles_master_permission: Number(itemEdit?.roles_master_permission) ?? 1,
      departments_master_permission: Number(itemEdit?.departments_master_permission) ?? 1,
      positions_master_permission: Number(itemEdit?.positions_master_permission) ?? 1,
      admin_master_permission: Number(itemEdit?.admin_master_permission) ?? 1,
      partner_management_permission: Number(itemEdit?.partner_management_permission) ?? 1,
      curriculum_tree_permission: Number(itemEdit?.curriculum_tree_permission) ?? 1,
      corporate_user_permission: Number(itemEdit?.corporate_user_permission) ?? 1,
      invoice_correspondence_permission: Number(itemEdit?.invoice_correspondence_permission) ?? 1,
      individual_user_management_permission:
        Number(itemEdit?.individual_user_management_permission) ?? 1,
    },
    validationSchema: CreateAuthorityMasterSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!id) return;

      const resultAction = await dispatch(
        updateAdminRole({
          id,
          data: {
            item: { ...values, updatedby: userInfo?.login_id, updatedat: new Date() },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );

      updateAdminRole.fulfilled.match(resultAction)
        ? setShowCompleteModal(true)
        : setShowActionErrorModal(true);
    },
  });

  const handleExportPDF = async (value: string) => {
    if (value !== 'pdf' || !itemEdit) return;

    const blob = await pdf(<PDFDocument role={itemEdit} />).toBlob();
    saveAs(blob, `${itemEdit.code}_${itemEdit.name}.pdf`);
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    id &&
      dispatch(
        getAdminRoleDetail({
          conditions: [
            {
              id: 'i_id',
              search_value: [id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 1,
        })
      );
  }, [dispatch, id]);

  return (
    <EditAuthorityMasterStyled>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの編集を行う画面です。
            <br />
            編集完了後は更新ボタンをクリックしてください。
          </p>
          <div className="btn-div">
            <Button
              className="btn btn-active"
              icon={<CloudDownloadOutlined className="icon-cloud" />}
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              エクスポート
            </Button>
          </div>
        </div>
        <AuthorityMasterFrom formik={formikEdit} />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
        onSubmit={() =>
          navigate(generatePath(routes.AuthorityMaster.path, { entity: 'receiving' }))
        }
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        onSubmit={() => setShowCompleteModal(true)}
        subTitle="権限の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の更新に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
      <PopupConfirmExportFile
        disableCsv
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportPDF}
      />
    </EditAuthorityMasterStyled>
  );
};

export default EditAuthorityMaster;
