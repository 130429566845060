import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import Styled from './styles';
import Modal from '../index';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void | Promise<void>;
}

const DeleteCompleted: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      footerStyle={{
        background: '#F9f8f8',
      }}
      onCancel={handleToggleModal}
    >
      <Styled>
        <DeleteOutlined className="icon-delete" />
        <span className="text">削除は完了しました</span>
      </Styled>
    </Modal>
  );
};

export default DeleteCompleted;
