import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
// import zipcodeJa from 'zipcode-ja';

import { administratorMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { administratorMasterSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { useAppDispatch } from 'hooks';
import { Input } from 'components';
import {
  createCompanies,
  createProviderInformation,
  createUser,
  getDataAdminDetails,
  getDataSearchUsers,
  updateInformation,
  updateProviderInformation,
  updateUser,
} from './thunk';

interface Props {
  goBack: () => void;
  checkData: boolean;
}

const ConfirmAdministratorMaster: React.FC<Props> = ({ goBack, checkData }) => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { information, dataAdminDetail, dataUser } = useSelector(administratorMasterSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!checkData && !information.i_id) {
        const resultActionRegisterInformation = await dispatch(
          createCompanies({
            item: {
              ...values,
              postal_code: values.postal_code?.toString(),
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createCompanies.fulfilled.match(resultActionRegisterInformation)) {
          await Promise.all([
            dispatch(
              createProviderInformation({
                item: {
                  company_id: resultActionRegisterInformation.payload.item.id,
                  basic_info: resultActionRegisterInformation.payload.item.name,
                  contract_end_date: '9999-12-31T11:44:37Z',
                },
                return_item_result: true,
                return_display_id: true,
              })
            ),
            dispatch(
              createUser({
                item: {
                  company_id: resultActionRegisterInformation.payload.item.id,
                  name: values.admin_name,
                  email: values.admin_email,
                  login_id: userInfo?.login_id,
                  user_type: 'admin',
                  phone: values.admin_phone,
                },
                return_item_result: true,
                return_display_id: true,
              })
            ),
          ]);
        }
        if (createCompanies.fulfilled.match(resultActionRegisterInformation)) {
          dispatch(setInformationRegister(values));
          setShowCompleteModal(true);
        } else {
          setShowActionErrorModal(true);
        }
      }
      if (information.i_id) {
        const resultAction = await dispatch(
          updateInformation({
            id: information.i_id,
            data: {
              item: {
                ...values,
                postal_code: values.postal_code?.toString(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
        if (updateInformation.fulfilled.match(resultAction)) {
          const resultUpdateProverInformation = await Promise.all([
            dispatch(
              updateProviderInformation({
                id: dataAdminDetail[0].item_ref.provider_id.i_id,
                data: {
                  item: {
                    basic_info: values.name,
                  },
                  return_item_result: true,
                  is_force_update: true,
                },
              })
            ),
            dispatch(
              updateUser({
                id: dataUser[0].i_id,
                data: {
                  item: {
                    name: values.admin_name,
                    email: values.admin_email,
                    login_id: dataUser[0].login_id,
                    user_type: 'admin',
                    phone: values.admin_phone,
                  },
                  return_item_result: true,
                  is_force_update: true,
                },
              })
            ),
          ]);
          if (updateProviderInformation.fulfilled.match(resultUpdateProverInformation[0])) {
            dispatch(setInformationRegister(values));
            setShowCompleteModal(true);
          } else {
            setShowActionErrorModal(true);
          }
        }
      }
    },
  });

  useEffect(() => {
    dispatch(
      getDataSearchUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: ['000000001'],
          },
          {
            id: 'login_id',
            search_value: [information.admin_email],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataAdminDetails({
        conditions: [
          {
            id: 'company_id',
            search_value: ['000000001'],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );
  }, [dispatch, userInfo]);

  return (
    <div className="container confirm">
      <div className="description">
        <p>編集内容に問題がなければ、更新ボタンをクリックしてください。</p>
      </div>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  法人名 <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input name="name" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  法人名 （フリガナ） <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input name="name_furigana" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  郵便番号
                  <span className="require">*</span>
                </span>
              }
              className={`item wrap-input-zip-code ${
                formik.touched.postal_code && formik.errors.postal_code
                  ? 'error-input-zip-code'
                  : ''
              }`}
            >
              <Input
                name="postal_code"
                className="input__disabled w-half"
                type="text"
                disabled
                placeholder="数字：７文字（ハイフンなし）"
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.postal_code!.toString().length > 6) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="prefectures"
              label={
                <span className="text-label">
                  都道府県
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="prefectures"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="municipalities"
              label={
                <span className="text-label">
                  市区町村
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="municipalities"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>

            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  番地
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="address"
                type="text"
                placeholder="全角：最大100文字"
                className="input__disabled"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">建物名・部屋番号</span>}
              className="item"
            >
              <Input
                name="building_name"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  管理者氏名
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="admin_name"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  管理者氏名 （フリガナ）<span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="admin_name_furigana"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="affiliation"
              label={
                <span className="text-label">
                  所属
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="affiliation"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="director"
              label={
                <span className="text-label">
                  役職
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="director"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  管理者電話番号
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="admin_phone"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={
                <span className="text-label">
                  管理者メールアドレス
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="admin_email"
                className="input__disabled"
                type="text"
                disabled
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_email_confirm"
              label={
                <span className="text-label">
                  管理者メールアドレス（確認）
                  <span className="require">*</span>
                </span>
              }
              className="item"
            >
              <Input
                name="admin_email_confirm"
                className="input__disabled"
                type="text"
                disabled
                placeholder="数字：最大11文字（ハイフン無し）"
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.admin_phone!.toString().length > 10) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">更新</SubmitButton>
            <button disabled={formik.isSubmitting} className="cancel-btn" onClick={goBack}>
              戻る
            </button>
          </div>
        </Form>
      </FormikProvider>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        onSubmit={goBack}
        title="更新が完了しました"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        onSubmit={() => setShowCompleteModal(true)}
        subTitle="カード情報の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </div>
  );
};

export default ConfirmAdministratorMaster;
