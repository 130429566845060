import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Button, Select } from 'antd';
import dayjs from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, Input, SelectField } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import ConfirmExportPDFModal from '../ModalExportPDF';
import { getAdminUser } from 'containers/Auth/thunk';
import { updateUserSchema } from 'libs/validations';
import { userMasterSelector } from '../selectors';
import { loadingRef } from 'components/Loading';
import UserDetailPDF from '../UserDetailPDF';
import exportPDF from 'libs/utils/exportPDF';
import { CREDIT_CARD_MONTH } from 'constant';
import EditUserMasterStyled from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  updateAdminAffiliationAssignRole,
  getSelectAdminDepartment,
  getSelectAdminPosition,
  getSelectAdminRole,
  updateAdminUsers,
  getSubPosition,
} from '../thunk';

const { Option } = Select;

const EditUserMaster: React.FC = () => {
  const [listOldPosition, setListOldPosition] = useState<Array<Types.PositionType>>([]);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const ref = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { dataSelectDepartment, dataSelectPosition, dataSelectAdminRole, loading } =
    useSelector(userMasterSelector);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<Types.EditManagementUserMasterFormFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      name_furigana: '',
      email: '',
      role_code: '',
      dob: '',
      doj: '',
      main_position: {
        position_id: '',
        affiliation_id: '',
        positions_code: '',
        sort_order: 1,
      },
    },
    validationSchema: updateUserSchema,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (!id) return;
      const resultAction = await Promise.all([
        dispatch(
          updateAdminUsers({
            id,
            data: {
              item: {
                dob: dayjs(values.dob).toDate(),
                doj: dayjs(values.doj).toDate(),
                name: values.name,
                role_code: values.role_code,
                name_furigana: values.name_furigana,
                employee_code: values.employee_code,
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        ),

        listOldPosition[0].sort_order === 1 &&
          (listOldPosition[0].affiliation_id !== values.main_position.affiliation_id ||
            listOldPosition[0].positions_code !== values.main_position.positions_code) &&
          dispatch(
            updateAdminAffiliationAssignRole({
              id: values.main_position.position_id,
              data: {
                item: {
                  affiliation_id: values.main_position.affiliation_id,
                  positions_code: values.main_position.positions_code,
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          ),
      ]);
      if (updateAdminUsers.fulfilled.match(resultAction[0])) {
        setVisibleCompleted(true);
      }
    },
  });

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (value: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${value}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('doj', `${value}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${value}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const handleExportPDF = () => {
    if (!ref.current) return;

    exportPDF(ref, 'ユーザーマスタ編集画面', 'p');
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <UserDetailPDF
          birthDay={birthDay}
          dataSelectDepartment={dataSelectDepartment}
          dataSelectPosition={dataSelectPosition}
          dateJoinedCompany={dateJoinedCompany}
          formikValue={formik.values}
          dataSelectAdminRole={dataSelectAdminRole}
        />
      </div>
    );
  }, [
    birthDay,
    dataSelectAdminRole,
    dataSelectDepartment,
    dataSelectPosition,
    dateJoinedCompany,
    formik.values,
  ]);

  useEffect(() => {
    if (id && userInfo) {
      (async () => {
        const resultAction = await Promise.all([
          dispatch(
            getAdminUser({
              id,
            })
          ),
          dispatch(
            getSelectAdminDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);

        if (getAdminUser.fulfilled.match(resultAction[0])) {
          const user_detail: Types.Users.ResponseDetailType =
            resultAction[0].payload.field_values.reduce(
              (a, v) => ({ ...a, [v.field_id]: v.value }),
              {}
            );

          const resultAction2 = await Promise.all([
            dispatch(
              getSubPosition({
                conditions: [
                  {
                    id: 'company_id',
                    search_value: [user_detail.company_id],
                  },
                  {
                    id: 'login_id',
                    search_value: [user_detail.login_id],
                  },
                ],
                sort_fields: [
                  {
                    id: 'sort_order',
                    order: 'asc',
                  },
                ],
                page: 1,
                per_page: 0,
                include_lookups: true,
                include_item_ref: true,
              })
            ),
          ]);
          if (getSubPosition.fulfilled.match(resultAction2[0])) {
            if (user_detail.dob) {
              setBirthDay({
                year: dayjs(user_detail.dob).year().toString(),
                month: (dayjs(user_detail.dob).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
                day: dayjs(user_detail.dob).date().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }
            if (user_detail.doj) {
              setDateJoinedCompany({
                year: dayjs(user_detail.doj).year().toString(),
                month: (dayjs(user_detail.doj).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }
            const position = resultAction2[0].payload.items.map((item) => ({
              position_id: item.i_id,
              affiliation_id: item.lookup_items?.affiliation_id?.i_id || '',
              positions_code: item.lookup_items?.positions_code?.i_id || '',
              sort_order: Number(item.sort_order),
            }));
            setListOldPosition(position);
            formik.setValues({
              ...formik.values,
              employee_code: user_detail.employee_code,
              name: user_detail.name,
              email: user_detail.email,
              name_furigana: user_detail.name_furigana,
              role_code: user_detail.role_code?.item_id,
              dob: user_detail.dob ? dayjs(user_detail.dob).format('YYYYMMDD') : '',
              doj: user_detail.doj ? dayjs(user_detail.doj).format('YYYYMMDD') : '',
              main_position: {
                position_id: Number(position[0]?.sort_order) === 1 ? position[0]?.position_id : '',
                affiliation_id:
                  Number(position[0]?.sort_order) === 1 ? position[0]?.affiliation_id : '',
                positions_code:
                  Number(position[0]?.sort_order) === 1 ? position[0]?.positions_code : '',
                sort_order: 1,
              },
            });
          }
        } else {
          navigate(generatePath(routes.UserMaster.path, { entity: 'receiving' }));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title={headerTitle} />
      <EditUserMasterStyled collapsedMenu={collapsedMenu}>
        {component}
        <div className="wrap-intro">
          <p className="text-sub-title">
            社内ユーザーマスタの新規登録を行う画面です。 <br />
            情報を入力後に登録ボタンをクリックしてください。
          </p>
          <Button className="button-export" onClick={() => setOpen(true)}>
            エクスポート
          </Button>
        </div>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input name="name" className="text-input" placeholder="全角：最大100文字" />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      autoComplete="off"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectBirthDay('year')}
                      allowClear
                      value={birthDay.year || undefined}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('month')}
                      placeholder="--"
                      allowClear
                      value={birthDay.month || undefined}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('day')}
                      placeholder="--"
                      allowClear
                      value={birthDay.day || undefined}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onChange={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      placeholder="----"
                      allowClear
                      value={dateJoinedCompany.year || undefined}
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onChange={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      placeholder="--"
                      allowClear
                      value={dateJoinedCompany.month || undefined}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                    >
                      {dataSelectAdminRole?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">所属・役職情報を設定・編集することができます。</p>
                <span className="label">メイン所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.affiliation_id"
                          className="pull_down"
                          placeholder="選択してください"
                        >
                          {dataSelectDepartment?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div className="item">
                      <Form.Item
                        name="main_position.positions_code"
                        label={
                          <span className="text-label">
                            役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.positions_code"
                          className="pull_down"
                          placeholder="選択してください"
                        >
                          {dataSelectPosition?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit" disabled={!formik.dirty}>
                  更新
                </SubmitButton>
                <button className="btn btn_close" onClick={() => navigate(routes.UserMaster.path)}>
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          title="登録が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => navigate(generatePath(routes.UserMaster.path, { entity: 'receiving' }))}
        />
        <ConfirmExportPDFModal isOpen={open} setIsOpen={setOpen} onSubmit={handleExportPDF} />
      </EditUserMasterStyled>
    </>
  );
};

export default EditUserMaster;
