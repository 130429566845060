import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getBillingDataDetail,
  getBillingDataDetailExport,
  getDataBillingManagement,
  getDataDetailCorporate,
  getDataSelectError,
  getDataSelectOrderID,
  getDataSelectProviderDate,
  getDataSelectUserBilling,
} from './thunk';

export type InitialState = {
  dataBillingManagement: Array<Types.BillingDataManagement.ResponseType>;
  dataSelectProviderDate: Array<Types.Selects.SelectProviderDate>;
  dataBillingDetail: Array<Types.BillingDataDetail.ResponseType>;
  dataSelectUserBilling: Array<Types.Selects.SelectUserBilling>;
  dataSelectOrderID: Array<Types.Selects.SelectOrderID>;
  dataSelectError: Array<Types.Selects.SelectError>;
  dataDetailCorporate: Array<Types.DetailCorporate>;
  dataError: Array<string>;
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  dataSelectProviderDate: [],
  dataBillingManagement: [],
  dataDetailCorporate: [],
  dataSelectOrderID: [],
  dataSelectUserBilling: [],
  dataBillingDetail: [],
  dataSelectError: [],
  dataError: [],
  loading: false,
  total: 0,
};

export const billingDataManagementSlide = createSlice({
  name: 'billingDataManagement-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataBillingManagement.pending, startLoading)
      .addCase(getDataSelectProviderDate.pending, startLoading)
      .addCase(getBillingDataDetail.pending, startLoading)
      .addCase(getDataSelectOrderID.pending, startLoading)
      .addCase(getDataSelectUserBilling.pending, startLoading)
      .addCase(getDataSelectError.pending, startLoading)
      .addCase(getBillingDataDetailExport.pending, startLoading)
      .addCase(getDataDetailCorporate.pending, startLoading);

    builder.addCase(getDataBillingManagement.fulfilled, (state, action) => {
      state.dataBillingManagement = action.payload.report_results;

      const dataNumError = action.payload.report_results
        .filter((data) => data.num_of_errors > 0)
        .map((id) => id.billing_id);
      state.dataError = dataNumError;

      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataDetailCorporate.fulfilled, (state, action) => {
      state.dataDetailCorporate = [
        {
          company_id: '000000021',
          i_id: '1',
          service_provision_date: '2022-07-12T07:30:22Z',
          billing_data_number: 'C2011010600',
          billing_data_name: '分法人請求データ【クレジット】',
          order_ID: 'ORDC10023200',
          member_ID: 'SK1200112',
          user_name: '株式会社ROOM',
          user_id: 'C10023200',
          billing_type: '0',
          billing_date: '2022-07-12T07:30:22Z',
          number_of_accounts: '200',
          amount_of_money: '300,000',
          redelivery: '0',
        },
        {
          company_id: '000000021',
          i_id: '2',
          service_provision_date: '2022-07-12T07:30:22Z',
          billing_data_number: 'C2011010600',
          billing_data_name: '分法人請求データ【クレジット】',
          order_ID: 'ORDC10023200',
          member_ID: 'SK1200112',
          user_name: '株式会社ROOM',
          user_id: 'C10023200',
          billing_type: '1',
          billing_date: '2022-07-12T07:30:22Z',
          number_of_accounts: '200',
          amount_of_money: '300,000',
          redelivery: '1',
        },
        {
          company_id: '000000021',
          i_id: '2',
          service_provision_date: '2022-07-12T07:30:22Z',
          billing_data_number: 'C2011010600',
          billing_data_name: '分法人請求データ【クレジット】',
          order_ID: '',
          member_ID: 'SK1200112',
          user_name: '株式会社ROOM',
          user_id: 'C10023200',
          billing_type: '1',
          billing_date: '2022-07-12T07:30:22Z',
          number_of_accounts: '200',
          amount_of_money: '300,000',
          redelivery: '1',
        },
      ];
      state.total = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getDataSelectProviderDate.fulfilled, (state, action) => {
      state.dataSelectProviderDate = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getBillingDataDetail.fulfilled, (state, action) => {
      state.dataBillingDetail = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataSelectOrderID.fulfilled, (state, action) => {
      state.dataSelectOrderID = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataSelectUserBilling.fulfilled, (state, action) => {
      state.dataSelectUserBilling = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataSelectError.fulfilled, (state, action) => {
      state.dataSelectError = action.payload.report_results;
      stopLoading(state);
    });

    builder
      .addCase(getDataBillingManagement.rejected, stopLoading)
      .addCase(getDataSelectProviderDate.rejected, stopLoading)
      .addCase(getBillingDataDetail.rejected, stopLoading)
      .addCase(getDataSelectOrderID.rejected, stopLoading)
      .addCase(getDataSelectUserBilling.rejected, stopLoading)
      .addCase(getDataSelectError.rejected, stopLoading)
      .addCase(getBillingDataDetailExport.rejected, stopLoading)
      .addCase(getDataDetailCorporate.rejected, stopLoading);
  },
});

// export const {  } = billingDataManagementSlide.actions;

export default billingDataManagementSlide.reducer;
