import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import zipcodeJa from 'zipcode-ja';

import { settingSelector } from 'containers/AppSettings/selectors';
import { administratorMasterSchema } from 'libs/validations';
import { administratorMasterSelector } from './selectors';
import ConfirmAdministratorMaster from './Confirm';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { getDataAdminDetails } from './thunk';
import { Header, Input } from 'components';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';

const AdministratorMaster: React.FC = () => {
  const [nextScreen, setNextScreen] = useState<boolean>(false);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { information } = useSelector(administratorMasterSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setInformationRegister(values));
      toggleScreen();
      setSubmitting(false);
    },
  });

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as number]?.address;
      formik.setFieldValue('prefectures', address ? address[0] : '');
      formik.setFieldValue('municipalities', address ? `${address[1]} ${address[2]}` : '');
      if (address) {
        formik.setErrors({
          prefectures: undefined,
          municipalities: undefined,
        });
        formik.setFieldTouched('prefectures', false);
        formik.setFieldTouched('municipalities', false);
      }
    }
  };

  const toggleScreen = () => setNextScreen((prevState) => !prevState);

  useEffect(() => {
    dispatch(
      getDataAdminDetails({
        conditions: [
          {
            id: 'company_id',
            search_value: ['000000001'],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );
  }, [dispatch]);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      {nextScreen ? (
        <ConfirmAdministratorMaster goBack={toggleScreen} checkData={!information} />
      ) : (
        <div className="container">
          <div className="description">
            <p>
              管理者情報の確認・編集を行う画面です。
              <br />
              編集完了後に確認画面へボタンをクリックしてください。
            </p>
          </div>
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '25%',
              }}
              colon={false}
            >
              <div className="form">
                <Form.Item
                  name="name"
                  label={
                    <span className="text-label">
                      法人名 <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input name="name" className="input" type="text" />
                </Form.Item>
                <Form.Item
                  name="name_furigana"
                  label={
                    <span className="text-label">
                      法人名 （フリガナ） <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input name="name_furigana" className="input" type="text" />
                </Form.Item>
                <Form.Item
                  name="postal_code"
                  label={
                    <span className="text-label">
                      郵便番号
                      <span className="require">*</span>
                    </span>
                  }
                  className={`item wrap-input-zip-code ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'error-input-zip-code'
                      : ''
                  }`}
                >
                  <Input
                    name="postal_code"
                    className="input"
                    type="text"
                    placeholder="数字：７文字（ハイフンなし）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.postal_code!.toString().length > 6
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="btn-check-zipCode"
                    onClick={convertZipCodeToAddress}
                  >
                    郵便番号から住所検索
                  </button>
                </Form.Item>
                <Form.Item
                  name="prefectures"
                  label={
                    <span className="text-label">
                      都道府県
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="prefectures"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="municipalities"
                  label={
                    <span className="text-label">
                      市区町村
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="municipalities"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <span className="text-label">
                      番地
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="address"
                    type="text"
                    placeholder="全角：最大100文字"
                    className="input"
                  />
                </Form.Item>

                <Form.Item
                  name="building_name"
                  label={<span className="text-label">建物名・部屋番号</span>}
                  className="item"
                >
                  <Input
                    name="building_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name"
                  label={
                    <span className="text-label">
                      管理者氏名
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name_furigana"
                  label={
                    <span className="text-label">
                      管理者氏名 （フリガナ）<span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_name_furigana"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_department"
                  label={
                    <span className="text-label">
                      所属
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_department"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_position"
                  label={
                    <span className="text-label">
                      役職
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_position"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_phone"
                  label={
                    <span className="text-label">
                      管理者電話番号
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_phone"
                    className="input"
                    type="text"
                    placeholder="数字：最大11文字（ハイフン無し）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.admin_phone!.toString().length > 10
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email"
                  label={
                    <span className="text-label">
                      管理者メールアドレス
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_email"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email_confirm"
                  label={
                    <span className="text-label">
                      管理者メールアドレス（確認）
                      <span className="require">*</span>
                    </span>
                  }
                  className="item"
                >
                  <Input
                    name="admin_email_confirm"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
              </div>
              <div className="wrap-button">
                <SubmitButton className="btn-submit">確認画面へ</SubmitButton>
              </div>
            </Form>
          </FormikProvider>
        </div>
      )}
    </Wrapper>
  );
};

export default AdministratorMaster;
