export enum Locale {
  en = 'en',
  ja = 'ja',
  zh = 'zh',
}

export enum CurriculumType {
  all = 'ALL',
  curriculum = 'カリキュラム',
  required = '必修カリキュラム',
}

export enum CurriculumStatus {
  all = 'ALL',
  publish = 'カリキュラム',
  edit = '必修カリキュラム',
}

export enum FileType {
  MP4 = 'video/mp4',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
}

export enum FileUploadStatus {
  active = 0,
  exception = 1,
}

export enum HomeCardColour {
  green = 0,
  blue = 1,
  purple = 2,
}
