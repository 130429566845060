import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { getDataDetailCorporate } from 'pages/BillingDataManagement/thunk';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
// import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Modal, SelectField } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const PER_PAGE = 10;

const CorporationInvoice: React.FC<Props> = ({ visible, setVisible }) => {
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visiblePopupConfirm, setVisiblePopupConfirm] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.DetailCorporate[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { dataDetailCorporate, loading, total } = useSelector(billingDataManagementSelector);
  // const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  // console.log('chưa có link');

  const columns = [
    {
      title: () => {
        return (
          <div>
            役務提供年月・ 請求データ番号
            <br />
            請求データ名称
          </div>
        );
      },
      dataIndex: 'service_provision_date',
      key: 'service_provision_date',
      width: '20%',
      render: (text: string, item: Types.DetailCorporate) =>
        text ? (
          <div className="item-date">
            <span className="date">{dayjs(text).format('YYYY/MM')}</span>{' '}
            <span className="date">{item.billing_data_number}</span>
            <br />
            <span>{item.billing_data_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'オーダーID',
      dataIndex: 'order_ID',
      key: 'order_ID',
      width: '10%',
    },
    {
      title: '会員ID',
      dataIndex: 'member_ID',
      key: 'member_ID',
      width: '8%',
    },
    {
      title: () => {
        return (
          <div>
            ユーザーID
            <br />
            ユーザー名
          </div>
        );
      },
      dataIndex: 'user_name',
      key: 'user_name',
      width: '10%',
      render: (text: string, item: Types.DetailCorporate) =>
        text ? (
          <div className="item-date">
            <span className="date">{item.user_id}</span>
            <br />
            <span>{text}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '請求種類',
      dataIndex: 'billing_type',
      key: 'billing_type',
      width: '7%',
      render: (billing_type: number) =>
        billing_type === 1 ? (
          <span>通常請求</span>
        ) : (
          <span className="text-active">エラー請求</span>
        ),
    },
    {
      title: '初回請求日',
      dataIndex: 'billing_date',
      key: 'billing_date',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM/DD')}</span> : <span>-</span>,
    },
    {
      title: '請求日',
      dataIndex: 'billing_date',
      key: 'billing_date',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM/DD')}</span> : <span>-</span>,
    },
    {
      title: 'アカウント数',
      dataIndex: 'number_of_accounts',
      key: 'number_of_accounts',
      width: '12%',
      className: 'number',
    },
    {
      title: '金額',
      dataIndex: 'amount_of_money',
      key: 'amount_of_money',
      width: '8%',
    },
    {
      title: () => {
        return (
          <div>
            エラーコード
            <br />
            エラー詳細
          </div>
        );
      },
      dataIndex: 'user_name',
      key: 'user_name',
      width: '9%',
      render: (text: string, item: Types.DetailCorporate) =>
        text ? (
          <div className="item-date">
            <span className="date">{item.user_id}</span>
            <br />
            <span>{text}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      service_provision_date: '',
      user_type: '',
      means_of_payment: '',
      correspondence_situation: '',
      billing_type: '',
    },
    onSubmit: (values) => {
      console.log(values, 'values');
    },
  });

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    Promise.all([
      dispatch(
        getDataDetailCorporate({
          conditions: [
            // {
            //   id: 'company_id',
            //   search_value: [userInfo.company_id],
            //   exact_match: true,
            // },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title="請求データ明細【法人・クレジット・エラー】"
      visible={visible}
      width={1300}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="container">
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="user_type"
                  className="item"
                  label={<span className="text-label">役務提供年月</span>}
                >
                  <SelectField
                    name="user_type"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="user_type"
                  className="item"
                  label={<span className="text-label">オーダーID</span>}
                >
                  <SelectField
                    name="user_type"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="user_type"
                  className="item"
                  label={<span className="text-label">会員ID</span>}
                >
                  <SelectField
                    name="user_type"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="user_type"
                  className="item"
                  label={<span className="text-label">ユーザーID</span>}
                >
                  <SelectField
                    name="user_type"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="means_of_payment"
                  className="item"
                  label={<span className="text-label">ユーザー名</span>}
                >
                  <SelectField
                    name="means_of_payment"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="correspondence_situation"
                  className="item"
                  label={<span className="text-label">エラーコード</span>}
                >
                  <SelectField
                    name="correspondence_situation"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="billing_type"
                  className="item"
                  label={<span className="text-label">エラー詳細</span>}
                >
                  <SelectField
                    name="billing_type"
                    showSearch
                    placeholder="指定なし"
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="a">a</Option>
                  </SelectField>
                </Form.Item>
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </Form>
          </FormikProvider>
          <div className="text-count">
            {page * PER_PAGE > total ? total : page * PER_PAGE}
            <span className="text-static">件表示 </span> / {total}
            <span className="text-static">名</span>
          </div>
          <Table
            rowKey="i_id"
            className="table"
            dataSource={dataDetailCorporate}
            columns={columns}
            scroll={{ y: 400 }}
            rowSelection={{
              onChange: (_, selectedRows: Types.DetailCorporate[]) => setSelectedRow(selectedRows),
            }}
            pagination={{
              pageSize: 20,
              total,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topRight', 'bottomRight'],
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したデーターを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={handleToggleModal}>
              閉じる
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={() => {}}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirm}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirm}
        />
        <CompletedModal
          title="再送信が完了しました"
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => {}}
        />
      </SectionStyled>
    </Modal>
  );
};

export default CorporationInvoice;
