import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { services, userService } from 'services';
import { config, USERS } from 'configs';
import * as Types from 'types';

export const signIn = createAsyncThunk<
  Types.SignInRes,
  Types.SignInReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signIn', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signIn(req);
    localStorage.setItem('sk_access_token', data.token);

    const cookieName = 'auth-token';
    const currentCookie = Cookies.get(cookieName);

    if (!currentCookie || currentCookie !== data.token) {
      Cookies.set(cookieName, data.token, { domain: '.hexabase.com' });
    }

    return { ...data, signInUrl: req.user_code };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signUp = createAsyncThunk<
  Types.SignUpRes,
  Types.SignUpReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signUp', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signUp(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const signUpUser = createAsyncThunk<
  Types.SignUpUserResponse,
  Types.SignUpUserRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/signUpUser', async (req, { rejectWithValue }) => {
  try {
    const { data, status } = await userService.signUpUser(req);
    if (status === 200) {
      const dataInviteUser: any = {
        workspace_id: 'skillfamiliar',
        url: `/api/v0/applications/skillfamiliarpartner/datastores/signup/items/action/${data.item_id}/userinvite`,
        method: 'POST',
        params: {
          item: req.params.item,
          as_params: {
            users: [
              {
                email: req.params.item.name,
                exclusive_w_id: '655983b2942ff9ef0f4a0081',
              },
            ],
            email_templates_id: config.TEMPLATES_ID,
            domain: 'stg-rsweb-partner.hexabase.com',
            invitation_path: 'confirm_email',
          },
          is_force_update: true,
        },
      };
      await userService.inviteUser(dataInviteUser, data.item_id);
    }

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk<
  Types.ResetPasswordRes,
  Types.ResetPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/resetPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.resetPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isFirstLogin = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/isFirstLogin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setNewPassword = createAsyncThunk<
  undefined,
  Types.SetNewPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/setNewPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.setNewPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getConfirmRegistration = createAsyncThunk<
  Types.GetConfirmRegistrationRes,
  Types.GetConfirmIdReq,
  Types.ThunkAPI<Types.requestError>
>('auth/getConfirmRegistration', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.confirmRegistration(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLoginTime = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/updateLoginTime', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserDetail = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getUserDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAdminUser = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getAdminUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk<Types.ChangePasswordRes, Types.ChangePasswordReq>(
  'auth/thunk/changePassword',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await userService.changePassword(req);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeEmail = createAsyncThunk<
  Types.ChangeEmailRes,
  Types.ChangeEmailReq,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/changeEmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.changeEmail(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
