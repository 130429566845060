import { useCallback } from 'react';

export interface ReceivedProps {
  isOpen: boolean;
  onSubmit: () => void;
  setIsOpen: (open: boolean) => void;
}

const useConfrimExportModal = (props: ReceivedProps) => {
  const { onSubmit, setIsOpen } = props;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(() => {
    onSubmit();
    handleClose();
  }, [onSubmit, handleClose]);

  return { ...props, handleSubmit, handleClose };
};

export type Props = ReturnType<typeof useConfrimExportModal>;

export default useConfrimExportModal;
