import styled from 'styled-components';

interface Props {
  visibleCorrespondingSale: boolean;
}

export default styled.section<Props>`
  .name {
    text-align: left;
    .number-label {
      padding-right: 15px;
      font-size: 11px;
      color: #aaaaaa;
    }
  }
  .table {
    border: 1px solid #dddddd;
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
    .ant-table-row-expand-icon-spaced {
      display: none;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
      .ant-table-cell {
        &:nth-child(1) {
          &:before {
            display: ${(props) => (!props.visibleCorrespondingSale ? '' : 'none')};
          }
        }
      }
    }
    .ant-table-tbody .ant-table-row .ant-table-cell {
      border-left: 1px solid rgba(85, 85, 85, 0.08);
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      border-bottom: ${(props) => (props.visibleCorrespondingSale ? 'none' : '')};
    }
    .ant-table-tbody > tr.ant-table-row-level-1 > td.ant-table-cell {
      display: ${(props) => (props.visibleCorrespondingSale ? '' : 'none')};
      border-top: ${(props) =>
        !props.visibleCorrespondingSale ? '1px solid #eeeeee' : '1px dashed #eeeeee'};
    }
    .ant-table-tbody > tr.ant-table-row-level-1 > td.ant-table-cell {
      &:nth-child(1) {
        border-top: ${(props) => (props.visibleCorrespondingSale ? 'none' : '')};
      }
    }
    .ant-table-tbody > tr > td {
      border: none;
    }
    .ant-table-cell {
      text-align: center;
      font-size: 13px;
    }
    .ant-table-tbody {
      .ant-table-cell {
        &:nth-child(2) {
          border-left: ${(props) =>
            !props.visibleCorrespondingSale ? '1px solid #eeeeee' : 'none'};
        }
      }
    }
  }
`;
