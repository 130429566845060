import styled from 'styled-components';

export default styled('div')`
  text-align: center;
  .icon-container {
    font-size: 28px;
    color: #bbbbbb;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-content {
    color: #777777;
    font-size: 13px;
  }
`;
