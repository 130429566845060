import { createAsyncThunk } from '@reduxjs/toolkit';

import { PARTNER_DETAIL, PARTNER_MANAGEMENT, PROVIDER_INFORMATION, SELECT_COMPANY } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getPartnerManagement = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DataPartnerManagementType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.DataPartnerManagementType>(
      PARTNER_MANAGEMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getPartnerDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(PARTNER_DETAIL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCompany = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getSelectCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_COMPANY.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProviderInformation = createAsyncThunk<
  Types.GetItemResponseType<Types.InformationType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(PROVIDER_INFORMATION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.DataPartnerManagementType>,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/UpdateProviderInformationDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileProvider = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/uploadFileProvider', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeActionUploadFile = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/executeActionUploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction(
      '63884a903f959f2d1b8b4c7e',
      PROVIDER_INFORMATION.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFilePartnerManagement = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getFilePartnerManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
