import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import { getOfficialCurriculumHierarchyList } from './thunk';
import * as Types from 'types';

export type InitialState = {
  curriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>>;
  curricullumMaster: Array<Types.CurriculumMasterType>;
  data_user_setting: Array<Types.UserTreeviewType>;
  data_curricullum_export: Array<Types.ItemExport>;
  filter_conditions: {
    conditions: Array<Types.ConditionsType>;
    status?: keyof typeof CurriculumStatus;
    type?: keyof typeof CurriculumType;
    name?: string;
  };
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  data_curricullum_export: [],
  curricullumMaster: [],
  data_user_setting: [],
  curriculumTree: [],
  filter_conditions: {
    conditions: [],
  },
  loading: false,
  total: 0,
};

export const curriculumTreeSlice = createSlice({
  name: 'curriculumTree-page',
  initialState,
  reducers: {
    setFilterConditions(
      state,
      action: PayloadAction<{
        type?: keyof typeof CurriculumType;
        status?: keyof typeof CurriculumStatus;
        name?: string;
      }>
    ) {
      if (action.payload.type) {
        state.filter_conditions.type = action.payload.type;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'required_curriculum'
        );
        switch (action.payload.type) {
          case 'curriculum':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curriculum',
                search_value: ['0'],
                exact_match: true,
              });
            }
            break;
          case 'required':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curriculum',
                search_value: ['1'],
                exact_match: true,
              });
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.status) {
        state.filter_conditions.status = action.payload.status;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'publish'
        );
        switch (action.payload.status) {
          case 'publish':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              });
            }
            break;
          case 'edit':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['0'],
                exact_match: true,
              });
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.name) {
        state.filter_conditions.name = action.payload.name;
        const data = [...state.curriculumTree];
        state.curriculumTree = data.sort((x, y) =>
          x.i_id === action.payload.name ? -1 : y.i_id === action.payload.name ? 1 : 0
        );
        const new_data_user_setting = [...state.data_user_setting];
        state.data_user_setting = new_data_user_setting.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      }
    },
    clearFilterConditions(state) {
      state.filter_conditions = {
        conditions: [],
        name: undefined,
        status: undefined,
        type: undefined,
      };
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder.addCase(getOfficialCurriculumHierarchyList.pending, startLoading);

    builder.addCase(getOfficialCurriculumHierarchyList.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newCurriculum.findIndex((c) => c.i_id === item.i_id);
        if (itemIndex >= 0) {
          newCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newCurriculum[itemIndex],
            item,
          });
        } else {
          newCurriculum.push({
            i_id: item.i_id,
            code: item.official_curriculum_code,
            name: item.curriculum_name,
            description: item.description,
            sort_order: item.sort_order,
            publish: item.publish,
            required_curriculum: item.required_curriculum,
            columnIndex: 0,
            children: item.item_ref.level1_code?.i_id
              ? [
                  {
                    i_id: item.item_ref.level1_code.i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    sort_order: item.level1_sort_order,
                    publish: item.publish,
                    required_curriculum: item.required_curriculum,
                    columnIndex: 1,
                    children: item.item_ref.level2_code?.i_id
                      ? [
                          {
                            i_id: item.item_ref.level2_code.i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            sort_order: item.level2_sort_order,
                            publish: item.publish,
                            required_curriculum: item.required_curriculum,
                            columnIndex: 2,
                            children: item.item_ref.level3_code?.i_id
                              ? [
                                  {
                                    i_id: item.item_ref.level3_code.i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    sort_order: item.level3_sort_order,
                                    publish: item.publish,
                                    required_curriculum: item.required_curriculum,
                                    columnIndex: 3,
                                    children: item.item_ref.level4_code?.i_id
                                      ? [
                                          {
                                            i_id: item.item_ref.level4_code?.i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            sort_order: item.level4_sort_order,
                                            publish: item.publish,
                                            required_curriculum: item.required_curriculum,
                                            columnIndex: 4,
                                            children: item.item_ref.question_code
                                              ? [
                                                  {
                                                    i_id: item.item_ref.question_name?.i_id,
                                                    name: item.question_name,
                                                    code: item.question_code,
                                                    sort_order: item.question_sort_order,
                                                    question_assign_level_i_id:
                                                      item.item_ref.question_sort_order?.i_id,
                                                    publish: item.publish,
                                                    required_curriculum: item.required_curriculum,
                                                    columnIndex: 5,
                                                  },
                                                ]
                                              : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });
      if (state.filter_conditions.name) {
        state.curriculumTree = newCurriculum.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      } else {
        state.curriculumTree = newCurriculum;
      }
      stopLoading(state);
    });

    builder.addCase(getOfficialCurriculumHierarchyList.rejected, stopLoading);
  },
});
export const { setFilterConditions, clearFilterConditions } = curriculumTreeSlice.actions;

export default curriculumTreeSlice.reducer;
