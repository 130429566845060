import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  AGGREGATION_CHURN_RATE,
  CHANGES_IN_USAGE_OF_OFFICIAL_CURRICULUM,
  COMPANIES,
  NUMBER_OF_SKILL_CHECKS_PERFORMED,
  NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH,
  OFFICIAL_CURRICULUM_COUNT_OF_STOPS,
  OFFICIAL_CURRICULUM_USERS,
  SALES_TRENDS,
  SKILL_CHECK_TRANS2,
  USAGE_LIST,
  USERS,
} from 'configs';

export const getDataNumberOfCorporateUsers = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfCorporateUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumberOfIndividualUsers = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfIndividualUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumberOfPartnerCompanies = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfPartnerCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(COMPANIES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAggregationChurnRate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataAggregationChurnRate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, {
      ...req,
      conditions: [
        {
          id: 'aggregate_user_type',
          search_value: ['65598573baeaf8d6328c9188'],
        },
      ],
      sort_fields: [{ id: 'target_month', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckTrans = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SKILL_CHECK_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfSkillChecksPerformed = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfSkillChecksPerformed', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getNumberOfInterviewsConducted = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getNumberOfInterviewsConducted', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfSkillChecksPerformedMonth = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfSkillChecksPerformedMonth', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumUsers = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumUsersActive = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumUsersActive', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfNewUsersMonth = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfNewUsersMonth', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumCountOfStops = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumCountOfStops', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(OFFICIAL_CURRICULUM_COUNT_OF_STOPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataChangesInUsageOfOfficialCurriculum = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(CHANGES_IN_USAGE_OF_OFFICIAL_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataChangesInUsageOfOfficialCurriculumChurnRate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculumChurnRate',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, req);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAggregationChurnRateExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataAggregationChurnRateExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSalesTrends = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataSalesTrends', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SALES_TRENDS.name, {
      ...req,
      sort_fields: [{ id: 'target_month', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUsageList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataUsageList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(USAGE_LIST.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserCount = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getUserCount', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
