import styled from 'styled-components';

interface Props {
  isEmptyData: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  height: 100%;
  .header .title-page {
    font-weight: normal;
  }
  .wrap-select-record {
    display: flex;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 66px;
    height: 28px;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .container {
    padding: 30px;
    .description .content {
      font-size: 12px;
      color: #424242;
    }
    .border {
      width: 40px;
      margin: 30px 0;
      border-top: 1px solid #dddddd;
    }
    .form-search {
      margin: 20px 0;
      display: flex;
      align-items: center;
      .form-input {
        .label {
          font-family: Lato;
          font-size: 12px;
          color: #424242;
        }
        .select-input {
          width: 400px;
          font-family: Lato;
        }
      }
      .wrap-btn {
        margin-left: 30px;
        margin-top: 5px;
        .btn-search {
          background-color: #ffffff;
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-size: 14px;
          color: #00a3a5;
          padding-right: 25px;
          cursor: pointer;
          .icon-search {
            font-size: 18px;
            margin-right: 5px;
          }
        }
        .label-reset {
          cursor: pointer;
          margin-right: 12px;
          border: none;
          background: none;
          .label-text {
            font-size: 12px;
            text-decoration: underline;
            color: #00a3a5;
          }
        }
      }
    }

    .wrap-table {
      position: relative;
      .wrap-button {
        position: absolute;
        right: 0;
        z-index: 9;
        top: ${(props) => (props.isEmptyData ? '-55px' : '0px')};
        .btn {
          width: 160px;
          height: 40px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 700;
          margin-left: 10px;
        }
        .btn-active {
          background-color: #f6ac00;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          color: #ffffff;
          .icon {
            margin-right: 12px;
            font-size: 18px;
          }
        }
      }
      .total-record-table {
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          padding-left: 3px;
        }
      }
      .table {
        &_mr {
          margin-top: 85px;
        }
        .ant-table-tbody .ant-table-cell.column {
          font-size: 14px;
          color: #424242;
        }
        .ant-table-pagination.ant-pagination {
          justify-content: center;
        }
        .ant-table-thead .ant-table-cell {
          font-weight: 700;
          font-size: 12px;
          color: #2a2a2a;
          ::before {
            display: none;
          }
        }
        .icon {
          font-size: 20px;
          color: #c4c4c4;
        }
      }
    }
  }
`;
