import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '..';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  okButtonDisabled?: boolean;
  description?: React.ReactNode;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmDeleteModal: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  onCancel,
  onSubmit,
  setVisible,
  description,
  okButtonDisabled,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = async () => {
    setLoading(true);
    onCancel && (await onCancel());
    setLoading(false);
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    setLoading(true);
    onSubmit && (await onSubmit());
    setLoading(false);
    setVisible(false);
  };

  return (
    <Modal
      title={<h3 className="title">{title}</h3>}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
        loading,
        disabled: okButtonDisabled,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};

export default ConfirmDeleteModal;
