import React, { useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider } from 'react-intl';
import { DndProvider } from 'react-dnd';
import jaJP from 'antd/es/locale/ja_JP';
import { ConfigProvider } from 'antd';

import dayjs from 'dayjs';

import { isFirstLogin, updateLoginTime } from 'containers/Auth/thunk';

import setupInterceptors from './libs/axios';
import { useAppDispatch } from 'hooks';
import { store } from 'store';

setupInterceptors(store);

const AppProvider: React.FC = ({ children }) => {
  // const { locale } = useSelector(settingSelector);
  // const [_, setLocaleDefault] = useState<keyof typeof Locale>(locale);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   setLocaleDefault(locale);
  // }, [locale]);

  useEffect(() => {
    (async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('sk_access_token');
      if (email && token) {
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            include_links: true,
            page: 1,
            per_page: 1,
          })
        );
        if (
          isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
          resultActionIsFirstLogin.payload.totalItems
        ) {
          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
            })
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider
      locale={
        {
          antd: jaJP,
          locale: 'ja',
        } as any
      }
    >
      <IntlProvider
        locale={
          {
            antd: jaJP,
            locale: 'ja',
          } as any
        }
      >
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default AppProvider;
