import styled from 'styled-components';

export default styled.section`
  .table {
    border: 1px solid #dddddd;
    .ant-table-row.ant-table-row-level-2 {
      background: #f9f9f9;
      border-bottom: 1px dashed #eeeeee;
    }
    .ant-table-tbody .ant-table-row .ant-table-cell {
      border-left: 1px solid rgba(85, 85, 85, 0.08);
    }
    .ant-table-cell.ant-table-cell-with-append {
      background: #ffffff;
    }
    .ant-table-tbody > tr.ant-table-row-level-2 > td {
      border-bottom: 1px dashed #eeeeee;
    }
    .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
    }
    .ant-table-tbody > tr > td {
      border: none;
    }
    .ant-table-cell {
      text-align: center;
      font-size: 13px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
    }
  }
`;
