import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Button, Table } from 'antd';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  RightOutlined,
  PlusOutlined,
  MailOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { HEADER_CURRICULUM_MASTER_TABLE_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { setHeaderTitle } from 'containers/AppSettings/slice';
import { Header, SelectField, SpinLoading } from 'components';
import ActionErrorModal from 'components/Modal/ActionError';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import { SEARCH_PARTNER } from 'constant/select.constants';
import LoginSuccess from 'components/Modal/LoginSuccess';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { DataPartnerManagementType } from 'types';
import { managementSelector } from './selectors';
import ModalContract from './ModalViewerPdf';
import ModalRegister from './ModalRegister';
import { routes } from 'navigations/routes';
import UploadPDF from './ModalImportPdf';
import { PartnerStyled } from './styles';
import ModalDetail from './ModalDetail';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getPartnerManagement,
  getPartnerDetail,
  getSelectCompany,
  uploadFileProvider,
  executeActionUploadFile,
  updateProviderInformation,
  getProviderInformation,
} from './thunk';

const { Option } = Select;

const PartnerManagement = () => {
  const [selectedRow, setSelectedRow] = useState<Array<DataPartnerManagementType>>([]);
  const [visibleModalRegister, setVisibleModalRegister] = useState<boolean>(false);
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);
  const [visibleNoPartner, setVisibleNoPartner] = useState<boolean>(false);
  const [visibleContract, setVisibleContract] = useState<boolean>(false);
  const [visibleModalCsv, setVisibleModalCsv] = useState<boolean>(false);
  const [statusSelect, setStatusSelect] = useState<Types.StatusType>();
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [visibleDetail, setVisibleDetail] = useState<boolean>(false);
  const [contractFileId, setContractFileId] = useState<string>('');
  const [visibleCsv, setVisibleCsv] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const { management, searchSelect } = useSelector(managementSelector);
  const { collapsedMenu, headerTitle, loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { search_status: '', provider_id: '', provider_name: '' },
    validateOnBlur: false,
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      fetchManagement(conditions);
    },
    onReset: () => {
      fetchManagement();
    },
  });

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisibleNoPartner(true);
    } else {
      setVisibleCsv(true);
    }
  };

  const detailSubmit = () => {
    setVisibleDetail(false);
    setVisibleSuccess(true);
  };

  const handleUploadCSV = async (
    file: File,
    setUploadSuccessfully: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!userInfo) return;
    const formData = new FormData();
    formData.append('filename', file?.name);
    formData.append('file', new Blob([file as Blob]));
    const uploadFile = await dispatch(
      uploadFileProvider({
        formData,
      })
    );

    if (uploadFileProvider.fulfilled.match(uploadFile)) {
      const resultAction = await Promise.all([
        dispatch(
          executeActionUploadFile({
            itemId: selectedRow[0]?.items[0]?.i_id!,
            data: {
              changes: [
                {
                  id: 'contract_file',
                  value: [uploadFile.payload.file_id],
                },
              ],
              is_force_update: true,
            },
          })
        ),
        dispatch(
          updateProviderInformation({
            id: selectedRow[0]?.items[0]?.i_id!,
            data: {
              item: {
                contract_file_id: uploadFile.payload.file_id,
                contract_file_name: file?.name,
                url_issue_date: new Date(),
                account_registration_status: 0,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        ),
      ]);
      if (updateProviderInformation.fulfilled.match(resultAction[1])) {
        setUploadSuccessfully(true);
        fetchManagement();
        fetchDataInformation();
      }
    }
  };

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = management?.map((item) => ({
        provider_id: item.provider_id,
        provider_name: item.provider_name,
        contract_start_date: item.contract_start_date,
        contract_end_date: item.contract_end_date,
        deletedat: item.deletedat,
        produced: item.produced,
        admin_department: item.admin_department,
        admin_position: item.admin_position,
        admin_name: item.admin_name,
      }));

      const csvString = [
        HEADER_CURRICULUM_MASTER_TABLE_CSV.map(({ label }) => label),
        ...listCsv.map((item: { [s: string]: unknown } | ArrayLike<unknown>) =>
          Object.values(item)
        ),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'Curriculum_Master_Table_export.csv');
    }
    setVisibleModalCsv(false);
  };

  const columns = [
    {
      title: <div className="title-table">契約状況</div>,
      dataIndex: 'name',
      key: 'name',
      className: 'name',
      width: '10%',
      render: (_: string, item: DataPartnerManagementType) => (
        <span
          className={`${
            !item.contract_end_date && !item.deletedat
              ? 'star-button-green'
              : item.contract_end_date && dayjs().isBefore(dayjs(item.contract_end_date))
              ? 'star-button-orange'
              : item.deletedat &&
                dayjs().isAfter(dayjs(item.contract_end_date)) &&
                'star-button-grey'
          }`}
        >
          {!item.contract_end_date && !item.deletedat
            ? '契約中'
            : item.contract_end_date && dayjs().isBefore(dayjs(item.contract_end_date))
            ? '契約終了予定'
            : item.deletedat && dayjs().isAfter(dayjs(item.contract_end_date)) && '契約終了'}
        </span>
      ),
    },
    {
      title: () => (
        <div>
          <span>ID</span>
          <br />
          <span>パートナー名</span>
        </div>
      ),
      dataIndex: 'question_code',
      className: 'code',
      key: 'question_code',
      width: '12%',
      render: (_: string, item: DataPartnerManagementType) => (
        <div>
          <div className="question_code_table">{item.provider_id}</div>
          <div>{item.provider_name}</div>
        </div>
      ),
    },
    {
      title: '契約開始日',
      dataIndex: 'contract_start_date',
      key: 'contract_start_date',
      width: '10%',
      render: (contract_start_date: string) => (
        <div>{contract_start_date ? dayjs(contract_start_date).format('YYYY/MM/DD') : '-'}</div>
      ),
    },
    {
      title: () => (
        <div>
          <span>契約終了日</span>
          <br />
          <span>（予定）</span>
        </div>
      ),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      width: '13%',
      render: (contract_end_date: string) => (
        <div>{contract_end_date ? dayjs(contract_end_date).format('YYYY/MM/DD') : '-'}</div>
      ),
    },
    {
      title: () => (
        <div>
          <span>カリキュラム</span>
          <br />
          <span>制作数</span>
        </div>
      ),
      dataIndex: 'produced',
      key: 'produced',
      width: '10%',
      render: (produced: string) => <div className="score-style">{produced}</div>,
    },
    {
      title: () => (
        <div>
          <span>所属 / 役職 </span>
          <br />
          <span>管理者氏名</span>
        </div>
      ),
      dataIndex: 'admin_name',
      key: 'admin_name',
      className: 'admin_name',
      width: '16%',
      render: (_: string, item: DataPartnerManagementType) => (
        <div>
          <div className="minister-style">
            {item.admin_department} / {item.admin_position}
          </div>
          <div>{item.admin_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div>
          <span>メール </span>
          <br />
          <span>送信</span>
        </div>
      ),
      dataIndex: 'operation',
      width: '7%',
      render: (_: string) => (
        <div className="background-color-mail">
          <MailOutlined />
        </div>
      ),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      width: '10%',
      render: (last_login_date: string) => (
        <div>{last_login_date ? dayjs(last_login_date).format('YYYY/MM/DD') : '-'}</div>
      ),
    },
    {
      title: '詳細',
      dataIndex: 'question_description',
      width: '5%',
      render: (_: string, item: DataPartnerManagementType) => (
        <div className="background-color-mail">
          <FileTextOutlined
            onClick={() => {
              setVisibleDetail(true);
              fetchPartnerDetail(item.provider_id);
              if (!item.contract_end_date && !item.deletedat) {
                setStatusSelect({
                  status: '0',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item.contract_end_date,
                });
              }
              if (item.contract_end_date && dayjs().isBefore(dayjs(item.contract_end_date))) {
                setStatusSelect({
                  status: '1',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item.contract_end_date,
                });
              }
              if (item.deletedat && dayjs().isAfter(dayjs(item.contract_end_date))) {
                setStatusSelect({
                  status: '2',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item.contract_end_date,
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      title: '契約書',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, item: DataPartnerManagementType) => (
        <div className="background-color-mail">
          <FileDoneOutlined
            onClick={() => {
              setContractFileId(item.contract_file_id);
              setVisibleContract(true);
            }}
          />
        </div>
      ),
    },
  ];

  const fetchManagement = useCallback(
    (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        dispatch(
          getPartnerManagement({
            conditions,
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            omit_total_items: false,
          })
        );
      }
    },
    [dispatch, userInfo]
  );

  const fetchPartnerDetail = useCallback(
    (id) => {
      if (!id) return;
      dispatch(
        getPartnerDetail({
          conditions: [
            {
              id: 'provider_id',
              search_value: [id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
        })
      );
    },
    [dispatch]
  );

  const fetchDataInformation = useCallback(() => {
    dispatch(
      getProviderInformation({
        page: page,
        per_page: 10,
        include_lookups: true,
        include_item_ref: true,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(
      getSelectCompany({
        page,
        per_page: 0,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    fetchDataInformation();
  }, [fetchManagement, fetchDataInformation]);

  useEffect(fetchManagement, [fetchManagement]);

  return (
    <PartnerStyled collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} className="header" />
      <div className="partner-styled">
        <div className="title-partner">
          <p>パートナーの新規登録・登録情報の確認・管理を行う画面です。</p>
          <div>
            <button
              className="btn-list"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.OfficialCurriculumList.path)));
                navigate(generatePath(routes.OfficialCurriculumList.path));
              }}
            >
              カリキュラム一覧 <RightOutlined />
            </button>
            <button
              className="btn-tree"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.CurriculumTree.path)));
                navigate(generatePath(routes.CurriculumTree.path));
              }}
            >
              カリキュラムツリー <RightOutlined />
            </button>
          </div>
        </div>
        <div className="content" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="search_status"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  name="search_status"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {SEARCH_PARTNER.map((item, index) => (
                    <Option value={item.label} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="provider_id"
                className="item"
                label={<span className="text-label">ID</span>}
              >
                <SelectField
                  name="provider_id"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {searchSelect.map((item, index) => (
                    <Option value={item.company_id} key={index}>
                      {item.company_id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="
                provider_name"
                className="item"
                label={<span className="text-label">パートナー名</span>}
              >
                <SelectField
                  name="provider_name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {searchSelect.map((item, index) => (
                    <Option value={item.company_name} key={index}>
                      {item.company_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <SpinLoading loading={loading}>
          <div className={`${management.length > 0 ? 'wrap-button' : 'wrap-button-no-data'}`}>
            <Button className="btn btn-active" onClick={() => setVisibleModalCsv(true)}>
              エクスポート
            </Button>
            <Button
              className="btn btn-active"
              icon={<PlusOutlined className="icon" />}
              onClick={() => setVisibleModalRegister(true)}
            >
              新規登録
            </Button>
          </div>
          <Table
            rowKey="provider_id"
            className="table"
            dataSource={management}
            columns={columns}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedRow.map(({ provider_id }) => provider_id),
              onChange: (_, data) => setSelectedRow(data),
            }}
            pagination={{
              pageSize: perPage,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topCenter'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <div className="text-count">
                    {page * perPage > management.length ? management.length : page * perPage}
                    件表示 / {management.length} 名
                  </div>
                  <div>
                    <span className="label-select">表示件数：</span>
                    <Select className="select-option" onChange={setPerpage} value={perPage}>
                      {[100, 50, 20, 10].map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ),
            }}
          />
        </SpinLoading>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したパートナーを処理：
              <Button className="btn btn-active" onClick={() => handleButtonExport()}>
                契約書インポート
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalDetail
        visibleDetail={visibleDetail}
        setVisible={setVisibleDetail}
        detailSubmit={detailSubmit}
        statusSelect={statusSelect}
      />
      <ModalContract
        visibleContract={visibleContract}
        setVisibleContract={setVisibleContract}
        contractFileId={contractFileId}
      />
      <PopupConfirmExportFile
        visible={visibleModalCsv}
        setVisible={setVisibleModalCsv}
        onSubmit={handleExportCSV}
      />
      <LoginSuccess
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        subTitle="送信が完了しました"
        handleResetForm={() => formik.resetForm()}
      />
      <ActionErrorModal
        setVisible={setVisibleNoPartner}
        visible={visibleNoPartner}
        subTitle="パートナーが選択されていません"
        description="インポートを実行するパートナーを選択して、再度実行してください。"
      />
      <UploadPDF setVisible={setVisibleCsv} visible={visibleCsv} onSubmit={handleUploadCSV} />
      <ModalRegister
        visibleDetail={visibleModalRegister}
        setVisible={setVisibleModalRegister}
        setOpenModalComplete={setOpenModalComplete}
        detailSubmit={() => {}}
      />
      <CompletedModal
        title="送信が完了しました"
        visible={openModalComplete}
        setVisible={setOpenModalComplete}
        onSubmit={() => setOpenModalComplete(!openModalComplete)}
      />
    </PartnerStyled>
  );
};

export default PartnerManagement;
