import React from 'react';
import { Table } from 'antd';

import TableDevelopmentStyled from './styles';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
}

const TableDevelopment: React.FC<Props> = ({ visibleExpansionOfSale, visibleCountUsage }) => {
  return (
    <TableDevelopmentStyled>
      <Table
        className="table"
        rowKey="code"
        columns={column}
        dataSource={
          visibleExpansionOfSale && visibleCountUsage
            ? data
            : visibleExpansionOfSale
            ? data1
            : data2
        }
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
      />
    </TableDevelopmentStyled>
  );
};

const dataDate = [
  { date: '01/2022' },
  {
    date: '02/2022',
  },
  {
    date: '03/2022',
  },
  {
    date: '04/2022',
  },
  {
    date: '05/2022',
  },
  {
    date: '06/2022',
  },
  {
    date: '07/2022',
  },
  {
    date: '08/2022',
  },
];

const date = dataDate.map((item, index) => ({
  title: item.date,
  dataIndex: `date${index + 1}`,
  key: item.date,
  width: '8%',
  render: (text: string) => (text ? <span>{text}</span> : <span>-</span>),
}));

const column = [
  {
    title: 'ユーザー種類',
    dataIndex: 'user_type',
    key: 'user_type',
  },
  {
    title: '売上種類',
    dataIndex: 'sales_type',
    key: 'sales_type',
  },
  ...date,
];

const data = [
  {
    user_type: '全ユーザー',
    sales_type: 'ALL',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '60,000,000',
        date2: '60,000,000',
        date3: '60,000,000',
        date4: '60,000,000',
        date5: '60,000,000',
        date6: '60,000,000',
        date7: '60,000,000',
        date8: '60,000,000',
        children: [
          {
            sales_type: 'ユーザー数',
            date1: '60,000,000',
            date2: '60,000,000',
            date3: '60,000,000',
            date4: '60,000,000',
            date5: '60,000,000',
            date6: '60,000,000',
            date7: '60,000,000',
            date8: '60,000,000',
          },
        ],
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
        children: [
          {
            sales_type: 'ユーザー数',
            date1: '60,000,000',
            date2: '60,000,000',
            date3: '60,000,000',
            date4: '60,000,000',
            date5: '60,000,000',
            date6: '60,000,000',
            date7: '60,000,000',
            date8: '60,000,000',
          },
        ],
      },
    ],
  },
  {
    user_type: '法人ユーザー',
    sales_type: 'ALL',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '48,000,000',
        date2: '48,000,000',
        date3: '48,000,000',
        date4: '48,000,000',
        date5: '48,000,000',
        date6: '48,000,000',
        date7: '48,000,000',
        date8: '48,000,000',
        children: [
          {
            sales_type: 'ユーザー数',
            date1: '60,000,000',
            date2: '60,000,000',
            date3: '60,000,000',
            date4: '60,000,000',
            date5: '60,000,000',
            date6: '60,000,000',
            date7: '60,000,000',
            date8: '60,000,000',
          },
        ],
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
  },
  {
    user_type: '個人ユーザー',
    sales_type: 'ALL',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '12,000,000',
        date2: '12,000,000',
        date3: '12,000,000',
        date4: '12,000,000',
        date5: '12,000,000',
        date6: '12,000,000',
        date7: '12,000,000',
        date8: '12,000,000',
      },
      {},
    ],
  },
];
const data1 = [
  {
    user_type: '全ユーザー',
    sales_type: 'ALL',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '60,000,000',
        date2: '60,000,000',
        date3: '60,000,000',
        date4: '60,000,000',
        date5: '60,000,000',
        date6: '60,000,000',
        date7: '60,000,000',
        date8: '60,000,000',
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
  },
  {
    user_type: '法人ユーザー',
    sales_type: 'ALL',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '48,000,000',
        date2: '48,000,000',
        date3: '48,000,000',
        date4: '48,000,000',
        date5: '48,000,000',
        date6: '48,000,000',
        date7: '48,000,000',
        date8: '48,000,000',
      },
      {
        sales_type: 'スキルチェック利用料',
        date1: '40,000,000',
        date2: '40,000,000',
        date3: '40,000,000',
        date4: '40,000,000',
        date5: '40,000,000',
        date6: '40,000,000',
        date7: '40,000,000',
        date8: '40,000,000',
      },
    ],
  },
  {
    user_type: '個人ユーザー',
    sales_type: 'ALL',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
    children: [
      {
        sales_type: '月額利用料',
        date1: '12,000,000',
        date2: '12,000,000',
        date3: '12,000,000',
        date4: '12,000,000',
        date5: '12,000,000',
        date6: '12,000,000',
        date7: '12,000,000',
        date8: '12,000,000',
      },
      {},
    ],
  },
];

const data2 = [
  {
    user_type: '全ユーザー',
    sales_type: 'ALL',
    date1: '100,000,000',
    date2: '100,000,000',
    date3: '100,000,000',
    date4: '100,000,000',
    date5: '100,000,000',
    date6: '100,000,000',
    date7: '100,000,000',
    date8: '100,000,000',
  },
  {
    user_type: '法人ユーザー',
    sales_type: 'ALL',
    date1: '88,000,000',
    date2: '88,000,000',
    date3: '88,000,000',
    date4: '88,000,000',
    date5: '88,000,000',
    date6: '88,000,000',
    date7: '88,000,000',
    date8: '88,000,000',
  },
  {
    user_type: '個人ユーザー',
    sales_type: 'ALL',
    date1: '12,000,000',
    date2: '12,000,000',
    date3: '12,000,000',
    date4: '12,000,000',
    date5: '12,000,000',
    date6: '12,000,000',
    date7: '12,000,000',
    date8: '12,000,000',
  },
];

export default TableDevelopment;
