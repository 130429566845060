import React, { useEffect, useState } from 'react';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { HEADER_BILLING_DATA_DETAIL, HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import CompletedDeleteModal from 'components/Modal/CompletedDelete';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { billingDataManagementSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import BillingDataManagementStyled from './styles';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getBillingDataDetailExport,
  getDataBillingManagement,
  getDataSelectProviderDate,
} from './thunk';
import {
  CorporationCreditBilling,
  CorporationInvoice,
  ModalAppBilling,
  ModalDetailCorporate,
  ModalErrorAllDetail,
  ModalErrorDetail,
} from './Modal';

const { Option } = Select;

const BillingDataManagement: React.FC = () => {
  const [openModalCorporationInvoice, setOpenModalCorporationInvoice] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.BillingDataManagement.ResponseType[]>([]);
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [openModalDetailCorporate, setOpenModalDetailCorporate] = useState<boolean>(false);
  const [dataRecord, setDataRecord] = useState<Types.BillingDataManagement.ResponseType>();
  const [openModalErrorAllDetail, setOpenModalErrorAllDetail] = useState<boolean>(false);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [openModalErrorDetail, setOpenModalErrorDetail] = useState<boolean>(false);
  const [openModalAppBilling, setOpenModalAppBilling] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [openModalCorporationCreditBilling, setOpenModalCorporationCreditBilling] =
    useState<boolean>(false);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [dataRecordError, setDataRecordError] =
    useState<Types.BillingDataManagement.ResponseType>();

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { total } = useSelector(billingDataManagementSelector);
  const { userInfo } = useSelector(authSelector);
  const { dataBillingManagement, dataError, dataSelectProviderDate, loading } = useSelector(
    billingDataManagementSelector
  );

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.BillingDataManagement.ResponseType> = [
    {
      title: '役務提供年月',
      dataIndex: 'provided_date',
      key: 'provided_date',
      width: '5%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM')}</span> : <span>-</span>,
    },
    {
      title: '請求データ番号',
      dataIndex: 'billing_id',
      key: 'billing_id',
      width: '10%',
      render: (text: string) => (text ? <span>{text}</span> : <span>-</span>),
    },
    {
      title: '名称',
      dataIndex: 'billing_data_name',
      key: 'billing_data_name',
      width: '18%',
    },
    {
      title: 'ユーザー種類',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '6%',
      render: (user_type: number) => (user_type === 1 ? <span>法人</span> : <span>個人</span>),
    },
    {
      title: '決済手段',
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '8%',
      render: (payment_method: number) =>
        payment_method === 1 ? (
          <span>クレジット</span>
        ) : payment_method === 2 ? (
          <span>請求書</span>
        ) : (
          <span>App内課金</span>
        ),
    },
    {
      title: '対応状況',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (status: number) =>
        status ? (
          <span>請求済</span>
        ) : (
          <div className="not-requested">
            <span>未請求</span>
          </div>
        ),
    },
    {
      title: '請求種類',
      dataIndex: 'billing_type',
      key: 'billing_type',
      width: '8%',
      render: (billing_type: number) =>
        billing_type === 1 ? (
          <span>通常請求</span>
        ) : (
          <span className="text-active">エラー請求</span>
        ),
    },
    {
      title: '請求月',
      dataIndex: 'billing_month',
      key: 'billing_month',
      width: '7%',
      render: (text: string) =>
        text ? <span>{dayjs(text).format('YYYY/MM')}</span> : <span>-</span>,
    },
    {
      title: '請求件数',
      dataIndex: 'num_of_billings',
      key: 'num_of_billings',
      width: '6%',
      render: (text: string) => (text ? <span>{text}件</span> : <span>-</span>),
    },
    {
      title: '請求金額',
      dataIndex: 'amount_total',
      key: 'amount_total',
      width: '8%',
      render: (text: string) => (text ? <span>{text}円</span> : <span>-</span>),
    },
    {
      title: 'エラー件数',
      dataIndex: 'num_of_errors',
      key: 'num_of_errors',
      width: '8%',
      render: (text: number, record) =>
        text || text === 0 ? (
          <span
            onClick={() => {
              setOpenModalErrorDetail(true);
              setDataRecordError(record);
            }}
            className="number-active"
          >
            {text}件
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '再送信',
      dataIndex: 'resend',
      key: 'resend',
      width: '8%',
      render: (text: string) => (text ? <span>{text}件</span> : <span>-</span>),
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '4%',
      render: (_: string, record) => (
        <FileTextOutlined
          onClick={() => {
            setDataRecord(record);
            if (record.payment_method === 1) {
              setOpenModalDetailCorporate(true);
            }
            if (record.payment_method === 2) {
              setOpenModalCorporationInvoice(true);
            }
            if (record.payment_method === 3) {
              setOpenModalAppBilling(true);
            }
          }}
          className="icon"
        />
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      provided_date: '',
      status: undefined,
      user_type: undefined,
      payment_method: undefined,
      billing_type: undefined,
    },
    onSubmit: async (values) => {
      if (userInfo) {
        const conditions: Array<Types.ConditionsType> = [];
        Object.keys(values).forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [String(values[key as keyof typeof values])],
              exact_match: true,
            });
          }
        });
        await dispatch(
          getDataBillingManagement({
            conditions,
            page,
            per_page: perPage,
            use_display_id: true,
            include_item_ref: true,
          })
        );
      }
    },
    onReset: () => {
      dispatch(
        getDataBillingManagement({
          include_lookups: true,
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
    },
  });

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getBillingDataDetailExport({
          conditions: [
            {
              id: 'billing_id',
              search_value: [selectedRow.map((row) => row.billing_id).join('|')],
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getBillingDataDetailExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          billing_detail_id: item.billing_detail_id,
          provided_date: item.provided_date,
          billing_id: item.billing_id,
          billing_data_name: item.billing_data_name,
          order_id: item.order_id,
          company_id: item.company_id,
          company_name: item.company_name,
          user_id: item.user_id,
          user_name: item.user_name,
          billing_type: item.billing_type,
          order_date: item.order_date,
          billing_date: item.billing_date,
          num_of_accounts: item.num_of_accounts,
          amount: item.amount,
          payment_method: item.payment_method,
          error_code: item.error_code,
          error_title: item.error_title,
          os: item.os,
          gmo_resend: item.gmo_resend,
        }));

        const csvString = [
          HEADER_BILLING_DATA_DETAIL.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '請求データ管理画面.csv');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  useEffect(() => {
    dispatch(
      getDataBillingManagement({
        include_lookups: true,
        page: 1,
        per_page: 0,
        use_display_id: true,
        include_item_ref: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataSelectProviderDate({
        include_lookups: true,
        page: 1,
        per_page: 0,
        use_display_id: true,
        include_item_ref: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title={headerTitle} />
      <BillingDataManagementStyled
        collapsedMenu={collapsedMenu}
        isEmptyData={!dataBillingManagement.length}
      >
        <p className="text-note">
          請求データの管理画面です。請求データの確認・ダウンロードが可能です。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="provided_date"
                className="item"
                label={<span className="text-label">役務提供年月</span>}
              >
                <SelectField
                  name="provided_date"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectProviderDate?.map((item, index) => (
                    <Option key={index} value={item.provided_date}>
                      {item.provided_date}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="user_type"
                className="item"
                label={<span className="text-label">ユーザー種類</span>}
              >
                <SelectField
                  name="user_type"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>法人</Option>
                  <Option value={2}>個人</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="payment_method"
                className="item"
                label={<span className="text-label">決済手段</span>}
              >
                <SelectField
                  name="payment_method"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>クレジット</Option>
                  <Option value={2}>請求書</Option>
                  <Option value={3}>App内課金</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="status"
                className="item"
                label={<span className="text-label">対応状況</span>}
              >
                <SelectField
                  name="status"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0}>未請求</Option>
                  <Option value={1}>請求済</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="billing_type"
                className="item"
                label={<span className="text-label">請求種類</span>}
              >
                <SelectField
                  name="billing_type"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>通常請求</Option>
                  <Option value={9}>エラー請求</Option>
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <p onClick={() => setOpenModalErrorAllDetail(true)} className="count-error">
            未送信エラー件数：<span className="number-active">{dataError.length}件</span>
          </p>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={dataBillingManagement}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.BillingDataManagement.ResponseType[]) =>
              setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: perPage,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <span className="text-count">
                  {page * perPage > dataBillingManagement.length
                    ? dataBillingManagement.length
                    : page * perPage}
                  件表示 / {dataBillingManagement.length} 名
                </span>
                <div>
                  <span className="label-select">表示件数：</span>
                  <Select className="select-option" onChange={setPerpage} value={perPage}>
                    {[100, 50, 20, 10].map((e) => (
                      <Option value={e}>{e}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したデーターを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
          </div>
        </div>
        <UploadCSV
          onSubmit={() => {}}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CompletedDeleteModal
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => {}}
        />
        <ModalDetailCorporate
          visible={openModalDetailCorporate}
          setVisible={setOpenModalDetailCorporate}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
        <ModalErrorDetail
          visible={openModalErrorDetail}
          setVisible={setOpenModalErrorDetail}
          dataRecord={dataRecordError}
          onSubmit={() => {}}
        />
        <ModalErrorAllDetail
          visible={openModalErrorAllDetail}
          setVisible={setOpenModalErrorAllDetail}
          dataError={dataError}
          onSubmit={() => {}}
        />

        <CorporationCreditBilling
          visible={openModalCorporationCreditBilling}
          setVisible={setOpenModalCorporationCreditBilling}
          onSubmit={() => {}}
        />
        <CorporationInvoice
          visible={openModalCorporationInvoice}
          setVisible={setOpenModalCorporationInvoice}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
        <ModalAppBilling
          visible={openModalAppBilling}
          setVisible={setOpenModalAppBilling}
          dataRecord={dataRecord}
          onSubmit={() => {}}
        />
      </BillingDataManagementStyled>
    </>
  );
};

export default BillingDataManagement;
