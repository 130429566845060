import styled from 'styled-components';

export const SectionStyled = styled.div`
  margin: 8px 16px;
  .text-content {
    font-size: 11px;
    color: #777777;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
  }
  .ant-upload-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .form-upload {
    position: relative;
    border: 3px solid #ffffff;
    .icon-prev,
    .icon-next {
      color: #c4c4c4;
      font-size: 25px;
      cursor: pointer;
    }
    .icon-prev {
      position: absolute;
      left: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
    .icon-next {
      position: absolute;
      right: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
  }
  .flex-image-small {
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0;
    .image {
      width: 100px;
      height: 56px;
      margin-right: 4px;
      margin-bottom: 4px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      .no-image {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #efefef;
        position: relative;
        .icon {
          width: 60px;
          height: 38px;
        }
        .text-no-image {
          font-family: Lato;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 13px;
          color: #cccccc;
          position: absolute;
          top: 19px;
          z-index: 9;
        }
      }
      .image-small {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &_active {
        border: 2px solid #00a3a5;
      }
    }
  }
  .file-upload {
    display: flex;
    flex-direction: column;
    min-height: 160px;
    border: 2px dashed #d9d9d9;
    background-color: #efefef;
    justify-content: center;
    padding: 20px 0;
    .info-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px 0;
      position: relative;
      .name-image {
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
        color: #424242;
      }
      .image-file {
        height: 338px;
      }
      .ant-image-mask {
        .zoom-image {
          font-size: 18px;
          color: #c4c4c4;
          position: absolute;
          right: 20px;
          bottom: 20px;
          z-index: 99;
          cursor: pointer;
        }
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .text-delete-outlined {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
`;
