import React, { CSSProperties } from 'react';
import { MessageFormatElement } from 'react-intl';
import { ModalProps } from 'antd';

import ModalStyled, { ButtonStyled } from './styles';
import SpinLoading from 'components/SpinLoading';

type ButtonProps = {
  text?: string | MessageFormatElement[] | React.ReactNode;
  onClick?: () => Promise<void> | void;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
};

type Props = ModalProps & {
  cancelButton?: ButtonProps;
  okButton?: ButtonProps;
  othersButton?: ButtonProps[];
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
};

const Modal: React.FC<Props> = ({
  cancelButton,
  okButton,
  headerStyle,
  contentStyle,
  bodyStyle,
  footerStyle,
  othersButton,
  children,
  ...props
}) => {
  return (
    <ModalStyled
      centered
      contentStyle={contentStyle}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      footerStyle={footerStyle}
      footer={
        okButton || cancelButton
          ? [
              okButton ? (
                <SpinLoading key="button-ok" loading={okButton.loading} size="default">
                  <ButtonStyled
                    type="submit"
                    style={okButton.style}
                    onClick={okButton.onClick}
                    disabled={okButton.disabled}
                    className={`button-primary${okButton.disabled ? ' disabled' : ''}`}
                  >
                    <span className="button-label">{okButton.text}</span>
                  </ButtonStyled>
                </SpinLoading>
              ) : null,
              othersButton
                ? othersButton.map((button, index) => (
                    <SpinLoading key={index} loading={button.loading} size="default">
                      <ButtonStyled
                        type="submit"
                        style={button.style}
                        onClick={button.onClick}
                        disabled={button.disabled}
                        className={`button-other${button.disabled ? ' disabled' : ''}`}
                      >
                        <span className="button-label">{button.text}</span>
                      </ButtonStyled>
                    </SpinLoading>
                  ))
                : null,
              cancelButton ? (
                <ButtonStyled
                  type="button"
                  key="button-cancel"
                  style={cancelButton.style}
                  className="button-secondary"
                  onClick={cancelButton.onClick}
                >
                  <span className="button-label">{cancelButton.text}</span>
                </ButtonStyled>
              ) : null,
            ]
          : null
      }
      closable={false}
      {...props}
    >
      {children}
    </ModalStyled>
  );
};

export default Modal;
