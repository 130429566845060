import { createAsyncThunk } from '@reduxjs/toolkit';

import { AFFILIATION_LEVEL, AFFILIATION_HIERARCHY } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getSelectAdminAffiliationHierarchy = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'AffiliationMaster/thunk/getSelectAdminAffiliationHierarchy',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(AFFILIATION_HIERARCHY.id, {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAdminAffiliationHierarchy = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/getDataAdminAffiliationHierarchy', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AFFILIATION_HIERARCHY.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminAffiliationLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/createAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/thunk/deleteAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminAffiliationLevel = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/updateAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminAffiliationHierarchyExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'AffiliationMaster/thunk/getDataAdminAffiliationHierarchyExportCSV',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(AFFILIATION_HIERARCHY.id, req);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
