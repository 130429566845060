import React from 'react';

import { IconLocked, StopPublic, TickComplete } from 'assets';
import { Modal } from 'components';
import Styled from './styles';

interface Props {
  status: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const Changed: React.FC<Props> = ({ visible, setVisible, status }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: handleToggleModal,
      }}
      width={420}
    >
      <Styled>
        <img
          className="icon-changed"
          src={status === 'editing' ? IconLocked : status === 'publish' ? TickComplete : StopPublic}
          alt=""
        />
        {status === 'editing' ? (
          <span className="changed-content">
            OFFICIALカリキュラムを
            <br />
            編集中に変更しました。
          </span>
        ) : status === 'publish' ? (
          <span className="changed-content">OFFICIALカリキュラムを公開しました。</span>
        ) : (
          <span className="changed-content">
            OFFICIALカリキュラムを
            <br />
            公開停止しました。
          </span>
        )}
      </Styled>
    </Modal>
  );
};

export default Changed;
