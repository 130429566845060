import styled from 'styled-components';

export default styled.section`
  height: 100%;
  margin: 0 40px;
  .wrap-label {
    display: flex;
    justify-content: space-between;
    .version-label {
      color: #777777;
    }
    .version {
      color: #424242;
      font-size: 12px;
    }
  }
  .wrap-image {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    .name-status {
      font-size: 13px;
      font-weight: 400;
      margin: 0 7px;
    }
    .icon-update {
      height: 30px;
    }
    .large {
      height: 40px;
    }
    .large-text {
      font-weight: 700;
    }
    .violet {
      color: #b57fcf;
    }
    .green {
      color: #71c131;
    }
    .grey {
      color: #989898;
    }
    .blue {
      color: #6a6ecd;
    }
    .arrow-right {
      height: 15px;
      margin: 0 20px;
    }
  }
  .description {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .note {
    text-align: center;
    color: #777777;
    font-size: 13px;
  }
  .input {
    padding: 10px 17px;
    margin-top: 7px;
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
  }
  .ant-color {
    color: rgba(251, 45, 45, 1) !important;
  }
  .sub-title {
    margin-top: 8px;
    font-size: 10px;
  }
  .label-link {
    text-decoration-line: underline;
    color: rgba(99, 106, 252, 1);
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
  }
  .sub-title-active {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
  }
  .label-link-active {
    text-decoration-line: underline;
    color: rgba(99, 106, 252, 1);
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    margin: 8px 0 0 24px;
  }
  .required {
    color: red;
    font-size: 20px;
    vertical-align: middle;
    line-height: 1;
  }
  .wrap-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .label {
    font-size: 12px;
  }
`;
