import React, { useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { find, unionBy } from 'lodash';
import { Button, Select } from 'antd';
import { Form } from 'formik-antd';

import { publicManagerSelector } from '../../selectors';
import { getReleaseNoteDetail } from '../../thunk';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import ReleaseNoteStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  selected?: Types.OfficialCurriculumMaster.ResponseType | undefined;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataOfficialCurriculum: Array<Types.OfficialCurriculumMaster.ResponseType>;
  providerInformation: Types.ProviderInformation.ResponseType | undefined;
}

const { Option } = Select;

const InformationReleaseNote: React.FC<Props> = ({
  visible,
  setVisible,
  selected,
  providerInformation,
}) => {
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [version, setVersion] = useState<string>();
  const [indexSection, setIndexSection] = useState<number>(0);

  const { loading, releaseNoteDetail, releaseNotes } = useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dataReleaseNote = useMemo(() => {
    if (version) {
      const data = find(releaseNotes, (val) => val.release_note_version === Number(version));
      return {
        i_id: data?.i_id,
        title: data?.release_note_title || '',
        release_note_id: data?.release_note_id || '',
        official_curriculum_code: data?.official_curriculum_code || '',
        description: data?.description || '',
        version: Number(data?.release_note_version),
        sections: releaseNotes
          .filter((e) => e.release_note_version === Number(version))
          .map((manual) => ({
            i_id: manual.item_ref?.section_id?.i_id,
            section_name: manual?.section_name || '',
            text: manual?.section_text || '',
          })),
      };
    }
  }, [releaseNotes, version]);

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: dataReleaseNote ??
      releaseNoteDetail ?? {
        title: '',
        description: '',
        version: 1.0,
        official_curriculum_code: '',
        release_note_id: '',
        sections: [
          {
            section_name: '',
            text: '',
          },
        ],
      },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'official_curriculum_code',
              search_value: ['A00000052'],
            },
          ],
          page: 1,
          per_page: 0,
          sort_fields: [
            {
              id: 'release_note_id',
              order: 'desc',
            },
          ],
        })
      );
    }
  }, [dispatch, visible, selected?.official_curriculum_code]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      {visible && (
        <ReleaseNoteStyled
          isEmptyData={!releaseNoteDetail?.sections.length}
          title={<span className="title">リリースノート</span>}
          headerStyle={{
            borderBottom: '1px solid #CCCCCC',
          }}
          width={1400}
          visible={visible}
          onCancel={handleToggleModal}
        >
          <div className="information">
            <div>
              <span className="content">
                {(dataReleaseNote?.title ?? releaseNoteDetail?.title) || ''}
              </span>
            </div>
            <div className="wrap-right-title">
              <p className="creator">制作者：{providerInformation?.creator || ''}</p>
              <div className="version">
                <span className="label">バージョン</span>
                <Select
                  className="select-version"
                  defaultValue={releaseNoteDetail?.version}
                  onChange={(e) => setVersion(e.toString())}
                >
                  {unionBy(releaseNotes, 'release_note_id').map((e) => (
                    <Option value={e.release_note_version}>{e.release_note_version}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <FormikProvider value={formik}>
            <p className="title-infor">
              {(dataReleaseNote?.title ?? releaseNoteDetail?.title) || ''}
            </p>
            <Form>
              <div className="wrap-editor">
                <div className="left-side">
                  <div className="title-editor">
                    <div className="no-editor">{indexSection + 1}</div>
                    <Form.Item name="section_name" className="item input-title">
                      <Input
                        disabled
                        value={formik.values.sections[indexSection]?.section_name}
                        name={`sections.${indexSection}.section_name`}
                        placeholder="セクション名を入力..."
                      />
                    </Form.Item>
                  </div>
                  <JoditEditor
                    config={defaultConfig}
                    value={formik.values.sections[indexSection]?.text}
                  />
                  <div className="wrap-bottom-editor">
                    <div className="wrap-button-editor">
                      <Button
                        className="btn button-prev"
                        disabled={indexSection === 0}
                        onClick={() => setIndexSection((prevState) => prevState - 1)}
                      >
                        <CaretLeftOutlined />
                        <span>前のセクション</span>
                      </Button>
                      <Button
                        className="btn button-next"
                        disabled={indexSection === formik.values.sections.length - 1}
                        onClick={() => setIndexSection(indexSection + 1)}
                      >
                        <span>次のセクション</span>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <Form.Item name="section_name" label="概要：" className="text-area">
                    <TextArea
                      disabled
                      value={formik.values?.description}
                      name="section_name"
                      rows={5}
                      placeholder="最大480文字"
                    />
                  </Form.Item>
                  <span className="title-right">セクション</span>
                  <div className="dashed-line"></div>
                  <div className="wrap-radio">
                    {formik.values.sections.map(({ section_name }, index) => (
                      <div
                        key={index}
                        onClick={() => setIndexSection(index)}
                        className={`section ${index === indexSection && 'section-checked'}`}
                      >
                        <span className="no-number">{index + 1}</span>
                        <span className="section-name">{section_name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="wrap-submit">
                <div className="wrap-submit">
                  <div className="wrap-button">
                    <Button className="btn btn_close" onClick={() => setVisible(false)}>
                      閉じる
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
            <Completed
              title="リリースノートを保存しました。"
              visible={visibleComplete}
              setVisible={setVisibleComplete}
              onSubmit={() => navigate(routes.PublicManagement.path)}
            />
          </FormikProvider>
        </ReleaseNoteStyled>
      )}
    </>
  );
};

export default InformationReleaseNote;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  placeholder: '本文を入力...',
  readonly: true,
  enableDragAndDropFileToEditor: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
};
