import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, FileTextOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Select, Table, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import saveAs from 'file-saver';

import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { HEADER_CURRICULUM_MASTER_TABLE_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { Header, Input, SelectField } from 'components';
import { PUBLISH } from 'constant/select.constants';
import { curriculumSelector } from '../selectors';
import { IconLocked, IconPublish } from 'assets';
import { loadingRef } from 'components/Loading';
import { getCurriculumMaster } from '../thunk';
import { useAppDispatch } from 'hooks';
import EditModal from './Edit';
import * as Types from 'types';
import Wrapper from './styles';
import {
  getCurriculumQuestionOption,
  getCurriculumLevelOption,
} from 'containers/Curriculum/Search/thunk';

const { Option } = Select;

type Props = {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
};

export type VisiblePopupEditType = {
  visible: boolean;
  publish?: boolean;
  index: number;
};

const CurriculumMaster: React.FC<Props> = ({ setOpenCurriculumMaster }) => {
  const [selectedRows, setSelectedRows] = useState<Types.CurriculumMasterType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [visible, setVisible] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [visiblePopupEdit, setVisiblePopupEdit] = useState<VisiblePopupEditType>({
    visible: false,
    index: -1,
  });

  const { loading, curricullumMaster, total } = useSelector(curriculumSelector);
  const { curriculumOption, level1Option, level2Option, level3Option, level4Option } = useSelector(
    searchQuestionCurriculumSelector
  );

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const newDataCurriculum = useMemo(() => {
    const result: Array<Types.CurriculumMasterType> = Object.values(
      curricullumMaster.reduce((acc: any, curr) => {
        const key = curr.official_curriculum_code;
        if (!acc[key]) {
          acc[key] = {
            i_id: curr.i_id,
            official_curriculum_code: curr.official_curriculum_code,
            curriculum_name: curr.curriculum_name,
            description: curr.description,
            publish: curr.publish,
            level1_code: '',
            level1_name: '',
            level2_code: '',
            level2_name: '',
            level3_code: '',
            level3_name: '',
            level4_code: '',
            level4_name: '',
            question_code: '',
            question_name: '',
            sort_order: curr.sort_order,
          };
        }
        return acc;
      }, {})
    );
    return result;
  }, [curricullumMaster]);

  const columns: ColumnsType<Types.CurriculumMasterType> = [
    {
      title: 'ステータス',
      dataIndex: 'publish',
      key: 'publish',
      className: 'publish',
      width: '6%',
      render: (_text, item) => (
        <>
          {!item.publish ? (
            <img src={IconLocked} className="icon-small" alt="edit-icon" />
          ) : (
            <img src={IconPublish} className="icon-small" alt="publish-icon" />
          )}
        </>
      ),
    },
    {
      title: (
        <>
          <p className="title-table">カリキュラムコード</p>
          <span>カリキュラム名</span>
        </>
      ),
      dataIndex: 'operation',
      width: '24%',
      render: (_text, item) => (
        <>
          <p className="text-code">{item.official_curriculum_code}</p>
          <span className="text-title">{item.curriculum_name}</span>
        </>
      ),
    },
    {
      title: '説明',
      dataIndex: 'description',
      key: 'description',
      width: '5%',
      render: (text, _item, index) => (
        <Tooltip
          trigger={['click']}
          title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
          color="#ffffff"
          onVisibleChange={(v) =>
            setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
          }
        >
          <FileTextOutlined
            className="icon"
            style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </Tooltip>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第１階層コード</p>
          <span>第１階層名</span>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '12%',
      render: (_text, { level1_code, level1_name }) => (
        <>
          {!visible ? (
            <>
              <p className="text-code">{level1_code}</p>
              <p className="text-title">{level1_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第２階層コード</p>
          <span>第２階層名</span>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '12%',
      render: (_text, { level2_name, level2_code }) => (
        <>
          {!visible ? (
            <>
              <p className="text-code">{level2_code}</p>
              <span className="text-title">{level2_name}</span>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第３階層コード</p>
          <span>第３階層名</span>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '12%',
      render: (_text: string, { level3_code, level3_name }) => (
        <>
          {!visible ? (
            <>
              <p className="text-code">{level3_code}</p>
              <span className="text-title">{level3_name}</span>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第４階層コード</p>
          <span>第４階層名</span>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '12%',
      render: (_text, { level4_code, level4_name }) => (
        <>
          {!visible ? (
            <>
              <p className="text-code">{level4_code}</p>
              <span className="text-title">{level4_name}</span>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">設問コード</p>
          <span>設問名</span>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '12%',
      render: (_text, { question_code, question_name }) => (
        <>
          {!visible ? (
            <>
              <p className="text-code">{question_code}</p>
              <span className="text-title">{question_name}</span>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? '編集' : '',
      dataIndex: 'operation',
      width: '5%',
      render: (_text, item, index) => (
        <>
          {!visible ? (
            <FormOutlined
              className="icon"
              onClick={() =>
                setVisiblePopupEdit({
                  visible: true,
                  publish: +item.publish !== 0,
                  index,
                })
              }
              style={{ color: visiblePopupEdit.index === index ? '#00a3a5' : '#c4c4c4' }}
            />
          ) : null}
        </>
      ),
    },
  ];

  const fetchDataCurriculumHierarchy = useCallback(() => {
    if (userInfo) {
      dispatch(
        getCurriculumMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: page,
          per_page: 0,
        })
      );
    }
  }, [dispatch, page, userInfo]);

  // TODO: data has not been converted - not resolved
  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv: Array<Types.CurriculumMasterTableCheckType> =
        selectedRows.length > 0
          ? selectedRows.map((item: Types.CurriculumMasterTableCheckType) => ({
              publish: item.publish,
              curriculum_description: item.curriculum_description,
              creator: item.creator,
              curriculum_code: item.curriculum_code,
              curriculum_name: item.curriculum_name,
              curriculum_sort_order: item.curriculum_sort_order,
              official_curriculum_code: item.official_curriculum_code,
              problems_count: item.problems_count,
              required_curricullum: item.required_curricullum,
            }))
          : [];
      const csvString = [
        HEADER_CURRICULUM_MASTER_TABLE_CSV.map(({ label }) => label),
        ...listCsv.map((item: any) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'Curriculum_Master_Table_export.csv');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRows.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const formik = useFormik<Types.CurriculumMasterSearchFormik>({
    initialValues: {
      publish: 'none',
      curriculum_name: null,
      level1_name: null,
      level2_name: null,
      level3_name: null,
      level4_name: null,
      question_name: null,
    },
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (key === 'publish' && value !== null && value !== 'none') {
          conditions.push({
            id: key,
            search_value: [(value ? 2 : 0).toString()],
            exact_match: true,
          });
        } else if (value !== null && value !== 'none') {
          conditions.push({
            id: key,
            search_value: [value!.toString()],
            exact_match: true,
          });
        }
      });
      dispatch(
        getCurriculumMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            ...conditions,
          ],
          page: page,
          per_page: 0,
          omit_total_items: false,
          omit_fields_data: true,
        })
      );
    },
  });

  useEffect(fetchDataCurriculumHierarchy, [fetchDataCurriculumHierarchy]);

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getCurriculumQuestionOption({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            provider_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 2,
            provider_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 3,
            provider_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 4,
            provider_id: userInfo.company_id,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      <div className="container">
        <p className="text-note">
          カリキュラムマスタの階層リストです。階層の確認と編集が可能です。
          <br />
          編集は編集マークの行に設定されている各階層を編集できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="publish"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField name="publish" defaultValue="none">
                  <Option value="none">指定なし</Option>
                  {PUBLISH.map(({ value, label }, index) => (
                    <Option key={index} value={value}>
                      {Number(value) === 1 ? (
                        <>
                          <img src={IconPublish} className="icon" alt="publish-icon" />
                          <span>{label}</span>
                        </>
                      ) : (
                        <>
                          <img src={IconLocked} className="icon" alt="edit-icon" />
                          <span>{label}</span>
                        </>
                      )}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="curriculum_name"
                className="item"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="curriculum_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumOption.map((value, index) => (
                    <Option key={index} value={value.official_curriculum_name}>
                      {value.official_curriculum_name}
                    </Option>
                  ))}
                  <Option value={'test'}>curriculum_name</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level1_name"
                className="item"
                label={<span className="text-label">第１階層名</span>}
              >
                <SelectField
                  name="level1_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(level1Option, 'name').map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name || '(空白)'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level2_name"
                className="item"
                label={<span className="text-label">第２階層名</span>}
              >
                <SelectField
                  name="level2_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(level2Option, 'name').map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level3_name"
                className="item"
                label={<span className="text-label">第３階層名</span>}
              >
                <SelectField
                  name="level3_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(level3Option, 'name').map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level4_name"
                className="item"
                label={<span className="text-label">第４階層名</span>}
              >
                <SelectField
                  name="level4_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(level4Option, 'name').map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_name"
                className="item"
                label={<span className="text-label">設問名</span>}
              >
                <Input name="question_name" className="input-question-name" />
              </Form.Item>
              <div className="wrap-submit-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="item-table">
          <div className="item-checkbox">
            <Checkbox checked={visible} onClick={() => setVisible(!visible)}>
              階層を折りたたむ
            </Checkbox>
          </div>
          <Table
            rowKey="index"
            className="table"
            dataSource={
              visible
                ? newDataCurriculum.map((item, index) => ({ ...item, index: `${index}_${page}` }))
                : curricullumMaster.map((item, index) => ({ ...item, index: `${index}_${page}` }))
            }
            columns={columns}
            rowSelection={{
              onChange: (_, selected) => setSelectedRows(selected),
            }}
            pagination={{
              pageSize: perPage,
              total: visible ? newDataCurriculum.length : total,
              current: page,
              onChange: (pageChange) => {
                setPage(pageChange);
                setSelectedRows([]);
              },
              showSizeChanger: false,
              position: ['topCenter'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <div className="text-count">
                    {(page - 1) * perPage + 1} ~
                    {page * perPage >
                    (visible ? newDataCurriculum.length : curricullumMaster.length)
                      ? visible
                        ? newDataCurriculum.length
                        : curricullumMaster.length
                      : page * perPage}
                    <span className="text-static"> 件表示</span> /
                    {visible ? newDataCurriculum.length : curricullumMaster.length}
                    <span className="text-static"> 件中</span>
                  </div>
                  <div>
                    <span className="label-select">表示件数：</span>
                    <Select className="select-option" onChange={setPerpage} value={perPage}>
                      {[100, 50, 20, 10].map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した階層リストを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(false)}>
              <span>カリキュラムマスタ</span>
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <EditModal
          page={page}
          per_page={perPage}
          visible={visiblePopupEdit}
          setVisible={setVisiblePopupEdit}
        />
      </div>
    </Wrapper>
  );
};

export default CurriculumMaster;
