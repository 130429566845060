import React, { useEffect, useState } from 'react';
import type { SubMenuType } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PayCircleOutlined,
  ReadOutlined,
  UserOutlined,
  GlobalOutlined,
  BarChartOutlined,
} from '@ant-design/icons';

import { setCollapsed, setHeaderTitle } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import AlertCustom, { alertCustomRef } from 'components/Alert';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import Loading, { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import {
  CoporateIcon,
  IconStorage,
  LogoHexa,
  LogoNavSmall,
  PartnerIcon,
  PersonalIcon,
} from 'assets';

const { Sider } = Layout;

type Props = {
  accessDenied?: boolean;
  title: string;
};

const SideBar: React.FC<Props> = ({ children, accessDenied, title }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { collapsedMenu, hiddenSidebar } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const toggle = () => dispatch(setCollapsed(!collapsedMenu));

  const items: ItemType[] = [
    {
      key: routes.Dashboard.path,
      icon: <HomeOutlined />,
      label: (
        <span className="title-submenu">
          HOME{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      onClick: () => navigate(routes.Dashboard.path),
    },
    {
      key: routes.Report.path,
      icon: <BarChartOutlined />,
      label: (
        <span className="title-submenu">
          レポート{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      onClick: () => navigate(routes.Report.path),
    },
    {
      key: routes.BillingDataManagement.path,
      icon: <PayCircleOutlined />,
      label: (
        <span className="title-submenu">
          請求データ管理{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      onClick: () => navigate(routes.BillingDataManagement.path),
    },
    {
      key: routes.PublicManagement.path,
      icon: <GlobalOutlined />,
      label: (
        <span className="title-submenu">
          公開管理{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      onClick: () => navigate(routes.PublicManagement.path),
    },
    {
      key: 'TrainingCurriculum',
      icon: <ReadOutlined />,
      label: <span className="title-submenu">カリキュラム管理</span>,
      children: [
        {
          key: routes.CurriculumMaster.path,
          label: '- 　カリキュラムマスタ',
          onClick: () => navigate(routes.CurriculumMaster.path),
        },
        {
          key: routes.QuestionMasterPage.path,
          label: '- 　設問マスタ',
          onClick: () => navigate(routes.QuestionMasterPage.path),
        },
      ],
    },
    {
      key: 'User',
      icon: <UserOutlined />,
      label: 'ユーザー管理',
      children: [
        {
          key: routes.UserMaster.path,
          label: '-   ユーザーマスタ',
          onClick: () => navigate(routes.UserMaster.path),
        },
        {
          key: routes.AuthorityMaster.path,
          label: '-   権限マスタ',
          onClick: () => navigate(routes.AuthorityMaster.path),
        },
        {
          key: routes.AffiliationMaster.path,
          label: '-   所属マスタ',
          onClick: () => navigate(routes.AffiliationMaster.path),
        },
        {
          key: routes.JobTitleMaster.path,
          label: '-   役職マスタ',
          onClick: () => navigate(routes.JobTitleMaster.path),
        },
        {
          key: routes.AdministratorMaster.path,
          label: '-   管理者マスタ',
          onClick: () => navigate(routes.AdministratorMaster.path),
        },
      ],
    },
    {
      key: routes.StorageManagement.path,
      icon: (
        <div className="item-icon">
          <img src={IconStorage} className="icon" alt="storage" />
        </div>
      ),
      label: (
        <span className="title-submenu">
          ストレージ管理{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      onClick: () => navigate(routes.StorageManagement.path),
    },

    {
      type: 'divider',
      style: {
        marginTop: 50,
        marginBottom: collapsedMenu ? 50 : 20,
        borderWidth: '1px 0 0',
        borderColor: 'rgba(255, 255, 255, 0.25)',
        transition: 'margin-bottom 0.3s',
      },
    },
    {
      key: 'training',
      label: 'パートナー管理',
      icon: (
        <div className="item-icon">
          <img src={PartnerIcon} className="icon" alt="storage" />
        </div>
      ),
      children: [
        {
          label: '-   　パートナー管理',
          key: routes.PartnerManagement.path,
          onClick: () => navigate(routes.PartnerManagement.path),
        },
        {
          label: '-   　カリキュラムマスタ',
          key: routes.OfficialCurriculumList.path,
          onClick: () => navigate(routes.OfficialCurriculumList.path),
        },
        {
          label: '-   　カリキュラムツリー',
          key: routes.CurriculumTree.path,
          onClick: () => navigate(routes.CurriculumTree.path),
        },
      ],
    },
    {
      key: 'skill-check',
      label: '法人ユーザー管理',
      icon: (
        <div className="item-icon">
          <img src={CoporateIcon} className="icon" alt="personal" />
        </div>
      ),
      children: [
        {
          label: '- 法人ユーザー情報一覧',
          key: routes.CorporateUserInformationList.path,
          onClick: () => navigate(routes.CorporateUserInformationList.path),
        },
        {
          label: '- 請求書対応管理',
          key: routes.InvoiceCorrespondence.path,
          onClick: () => navigate(routes.InvoiceCorrespondence.path),
        },
        {
          label: '- 無料トライアル管理',
          key: routes.FreeTrialManagement.path,
          onClick: () => navigate(routes.FreeTrialManagement.path),
        },
      ],
    },
    {
      key: routes.IndividualUserManagement.path,
      label: (
        <span className="title-submenu">
          個人ユーザー管理{!collapsedMenu && <i className="ant-menu-submenu-arrow" />}
        </span>
      ),
      icon: (
        <div className="item-icon">
          <img src={PersonalIcon} className="icon" alt="personal" />
        </div>
      ),
      onClick: () => navigate(routes.IndividualUserManagement.path),
    },
  ];

  useEffect(() => {
    const openKey = items.find((item) =>
      (item as SubMenuType).children?.some(
        (c) => (c as SubMenuType).key === `/${window.location.pathname.split('/')[1]}`
      )
    )?.key as string;
    setOpenKeys(openKey ? [openKey] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedMenu]);

  useEffect(() => {
    dispatch(setHeaderTitle(title));
  }, [dispatch, title]);

  useEffect(() => {
    if (accessDenied) {
      loadingRef.current?.isLoading(false);
    }
  }, [accessDenied]);

  return (
    <Wrapper collapseMenu={collapsedMenu}>
      <Sider
        width={MENU_WIDTH}
        className="sider"
        trigger={null}
        collapsedWidth={MENU_COLLAPSED_WIDTH}
        collapsible
        collapsed={collapsedMenu}
        hidden={hiddenSidebar}
      >
        <div className="site-layout-background">
          {collapsedMenu ? (
            <MenuUnfoldOutlined className="trigger" onClick={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </div>
        <div className="wrap-logo">
          <img
            src={collapsedMenu ? LogoNavSmall : LogoHexa}
            className={collapsedMenu ? 'image-logo' : 'image-logo-large'}
            onClick={() => navigate(routes.Dashboard.path)}
            alt="skill-familiar"
          />
        </div>
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[window.location.pathname, `/${window.location.pathname.split('/')[1]}`]}
          onOpenChange={onOpenChange}
          items={items}
          inlineIndent={18}
          className="menu"
        />
      </Sider>
      <Layout>
        {children}
        <Loading ref={loadingRef} />
        <AlertCustom ref={alertCustomRef} />
      </Layout>
    </Wrapper>
  );
};

export default SideBar;
