import React, { useState } from 'react';

import { IconLocked, IconPublish, IconRequired } from 'assets';
import CurriculumDetail from '../CurriculumDetail';
import { DivCustom, Button } from './styles';
import * as Types from 'types';

type Props = {
  node: Types.TreeItem<Types.CurriculumItemType>;
};

const TooltipRowContent = ({ node }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [code, setCode] = useState<string>();

  return node.columnIndex === 0 ? (
    <DivCustom columnIndex={0}>
      <div className="rowWrapper">
        <div className="rowContentHead">
          <div className="spanHead">
            {node.node?.publish ? (
              <>
                <img src={IconPublish} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : (
              <>
                <img src={IconLocked} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          {!!node.node?.required_curriculum && (
            <div className="spanHead">
              <img src={IconRequired} className="icon" alt="required-icon" />
              <span className="spanText">必修カリキュラム</span>
            </div>
          )}
        </div>
      </div>
      <div className="rowWrapper">
        <p>{node?.node?.description}</p>
      </div>
    </DivCustom>
  ) : node.columnIndex === 5 ? (
    <DivCustom columnIndex={5}>
      <div className="rowWrapper">
        <p className="content">{node.node?.name}</p>
        <Button
          onClick={() => {
            setVisible(true);
            setCode(node.node?.i_id);
          }}
        >
          設問を確認
        </Button>
      </div>
      <CurriculumDetail visible={visible} setVisible={setVisible} code={code} />
    </DivCustom>
  ) : (
    <DivCustom columnIndex={2}>
      <div className="rowWrapper">
        <p className="content">{node.node?.name}</p>
      </div>
    </DivCustom>
  );
};

export default TooltipRowContent;
