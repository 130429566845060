import styled from 'styled-components';

type Props = {
  hasImage: boolean;
};
export const SectionStyled = styled.div<Props>`
  @media (max-width: 1440px) {
    .form {
      .form-input {
        .input {
          height: 40px !important;
        }
      }
    }
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .form {
    .form-input {
      .ant-input-suffix {
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 99;
      }
    }
    .require {
      color: #f0330a;
    }
    .text-label {
      font-size: 13px;
      font-weight: 400;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .wrap-upload {
      background: #efefef;
      border: 3px dashed #d9d9d9;
      text-align: center;
      padding: ${({ hasImage }) => (hasImage ? '24px 20px' : '72px 20px')};
      display: block;
      flex-direction: column;
      justify-content: center;
      max-block-size: fit-content;
      margin-bottom: 15px;
      .icon {
        font-size: 40px;
        color: #ffffff;
      }
      .ant-upload {
        background: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: none !important;
      }
      .ant-image-img {
        max-height: 55vh;
        max-width: 87vh;
      }
      .btn {
        border: 1px solid #00a3a5;
        background: #ffffff;
        color: #00a3a5;
        font-weight: 700;
        border-radius: 5px;
        margin-top: 20px;
      }
      .btn-delete {
        border: none;
        background: transparent;
        margin-left: 10px;
        span {
          text-decoration: underline;
          font-weight: 400;
        }
      }
    }
    .note {
      color: #777777;
      font-size: 11px;
    }
  }
`;
