import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 2px solid #eb8b55;
  .rowWrapper {
    border-top: 1px solid #f0f0f0;
    padding: 10px;
    .rowContentHead {
      display: flex;
      align-items: center;
      .spanHead {
        display: flex;
        align-items: center;
        color: #777777;
        margin-right: 10px;
        .icon {
          margin-right: 2px;
          width: 14px;
          height: 14px;
        }
        .spanText {
          font-size: 10px;
        }
      }
    }
    .text-content {
      font-size: 12px;
      color: #555555;
    }
    .rowContent {
      display: flex;
      justify-content: space-between;
      .icons {
        display: inline;
        .icon {
          color: #c4c4c4;
          font-size: 18px;
          margin-left: 10px;
          &:hover {
            color: #009acb;
          }
        }
      }
      .ant-switch-checked {
        background-color: #eb8b55;
      }
    }
  }
`;

export const Button = styled.button`
  background-color: #eb8b55;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  padding: 5px 15px;
`;
