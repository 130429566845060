import React, { HTMLAttributes, useState } from 'react';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';

import SendNoticeComplete from '../Modal/SendNoticeComplete';
import { authSelector } from 'containers/Auth/selectors';
import ChangePassword from 'containers/ChangePassword';
import { HeaderStyled, ModalContent } from './styles';
import ChangeEmail from 'containers/ChangeEmail';
import { logout } from 'containers/Auth/slice';
import { useAppDispatch } from 'hooks';
import { adminAvatar } from 'assets';
import Modal from 'components/Modal';

interface Props {
  title: string;
  exportPDF?: boolean;
}

const HeaderDashboard: React.FC<HTMLAttributes<HTMLDivElement> & Props> = ({
  title,
  children,
  className,
  exportPDF,
}) => {
  const [visibleChangePassword, setVisibleChangePassword] = useState<boolean>(false);
  const [visibleChangeEmail, setVisibleChangeEmail] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [changedEmail, setChangedEmail] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  return (
    <HeaderStyled className={className}>
      <div className="header__left">
        <p className="page__title">{title}</p>
      </div>
      {children}
      {!exportPDF && (
        <div className="header__right">
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: '0',
                    label: 'ヘルプセンター',
                  },
                ]}
              />
            }
            placement="bottomRight"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Dropdown>
          <div className="border" />
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: 'profile',
                    label: 'プロフィール',
                    onClick: () => {},
                  },
                  {
                    key: 'reset-email',
                    label: 'メールアドレス変更',
                    onClick: () => {
                      setVisibleChangeEmail(true);
                      setChangedEmail(true);
                    },
                  },
                  {
                    key: 'reset-password',
                    label: 'パスワード変更',
                    onClick: () => {
                      setVisibleChangePassword(true);
                      setChangedEmail(false);
                    },
                  },
                  {
                    type: 'divider',
                  },

                  {
                    key: 'logout',
                    label: 'ログアウト',
                    onClick: () => setVisible(true),
                  },
                ]}
              />
            }
            placement="bottomRight"
            className="drop-down"
          >
            <div className="flex">
              <Avatar size={36} gap={0} src={adminAvatar} alt="avatar" />
              <p className="drop-down">
                {userInfo?.name}
                <small>さん</small>
                <DownOutlined />
              </p>
            </div>
          </Dropdown>
        </div>
      )}
      <Modal
        title="ログアウト"
        open={visible}
        width={416}
        closable
        okButton={{
          text: 'OK',
          onClick: () => {
            dispatch(logout());
            setVisible(false);
          },
        }}
        onCancel={() => setVisible(false)}
      >
        <ModalContent>
          <p className="text-content">ログアウトを実行します。よろしいですか？</p>
        </ModalContent>
      </Modal>
      <ChangeEmail
        visible={visibleChangeEmail}
        setVisible={setVisibleChangeEmail}
        setVisiblelComplete={setVisibleComplete}
      />
      <ChangePassword
        visible={visibleChangePassword}
        setVisible={setVisibleChangePassword}
        setVisiblelComplete={setVisibleComplete}
      />
      <SendNoticeComplete
        title={changedEmail ? 'メールアドレス変更' : 'パスワード変更'}
        changedEmail={changedEmail}
        content={
          changedEmail ? (
            <div className="content">
              <span> sample@mail.address 宛にメールアドレス変更のご案内を送信しました。</span>
              <br />
              メールに記載されているURLにアクセスして、
              <br /> メールアドレス変更を完了してください。
            </div>
          ) : (
            <span className="content">
              パスワードを変更しました。自動的にログアウトしますので、
              <br />
              新しいパスワードで再度ログインしてください。
            </span>
          )
        }
        visible={visibleComplete}
        setVisible={setVisibleComplete}
        onSubmit={() => {
          if (!changedEmail) {
            dispatch(logout());
          }
        }}
      />
    </HeaderStyled>
  );
};

export default HeaderDashboard;
