import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getOfficialCurriculumName,
  getOfficialCurriculum,
  getPartnerCompanies,
  getPartnerUser,
  getSelectProvider,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  searchProvider: Array<Types.SelectProviderType>;
  curriculumList: Array<Types.OfficialCurriculumType>;
  curriculumUser: Array<Types.PartnerCurriculumUserType>;
  searchCurriculumName: Array<Types.OfficialCurriculumNameType>;
  curriculumCompanies: Array<Types.PartnerCurriculumCompaniesType>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  curriculumList: [],
  curriculumUser: [],
  searchProvider: [],
  curriculumCompanies: [],
  searchCurriculumName: [],
};

export const publicCurriculumListSlice = createSlice({
  name: 'publicCurriculumListSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getOfficialCurriculumName.pending, startLoading)
      .addCase(getOfficialCurriculum.pending, startLoading)
      .addCase(getPartnerCompanies.pending, startLoading)
      .addCase(getPartnerUser.pending, startLoading)
      .addCase(getSelectProvider.pending, startLoading);

    builder.addCase(getOfficialCurriculum.fulfilled, (state, action) => {
      state.curriculumList = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getPartnerCompanies.fulfilled, (state, action) => {
      state.curriculumCompanies = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getPartnerUser.fulfilled, (state, action) => {
      state.curriculumUser = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectProvider.fulfilled, (state, action) => {
      state.searchProvider = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getOfficialCurriculumName.fulfilled, (state, action) => {
      state.searchCurriculumName = action.payload.report_results;
      stopLoading(state);
    });

    builder
      .addCase(getOfficialCurriculumName.rejected, stopLoading)
      .addCase(getOfficialCurriculum.rejected, stopLoading)
      .addCase(getPartnerCompanies.rejected, stopLoading)
      .addCase(getPartnerUser.rejected, stopLoading)
      .addCase(getSelectProvider.rejected, stopLoading);
  },
});

export default publicCurriculumListSlice.reducer;
