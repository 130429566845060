import { createAsyncThunk } from '@reduxjs/toolkit';

import { INDIVIDUAL_USER_MANAGERMENT, USERS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getIndividualUserManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/getIndividualUserManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INDIVIDUAL_USER_MANAGERMENT.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getIndividualUserManagementExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/getIndividualUserManagementExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INDIVIDUAL_USER_MANAGERMENT.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateIndividualUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.IndividualUserManagement>,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/updateIndividualUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
