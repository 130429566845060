import React from 'react';
import { Document, Page, View, Text, Font } from '@react-pdf/renderer';

import HanSanskThin from 'assets/fonts/NotoSansJP-Light.otf';
import HanSansk from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'HanSansk', src: HanSansk as string });
Font.register({ family: 'HanSanskThin', src: HanSanskThin as string });

interface Props {
  data: Types.AdminPositions.ResponseType[];
}

const FileExportInvoicePDF: React.FC<Props> = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap orientation="landscape">
        <View style={styles.header}>
          <Text style={[styles.title]}>役職マスタ</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.des}>役職マスタの作成・管理を行う画面です。</Text>
          <Text style={styles.des}>作成した役職マスタはユーザーに設定します。</Text>
          <View style={styles.line} />
          <Text style={styles.length}>
            {data.length} 件表示 / {data.length} 件中
          </Text>
          <View style={styles.table}>
            <View style={[styles.headerTable, styles.row]} fixed>
              <View style={styles.column}>
                <Text style={[styles.textTable, styles.bold]}>コード</Text>
              </View>
              <View style={[styles.column, styles.lastColumn]}>
                <Text style={[styles.textTable, styles.bold]}>名称</Text>
              </View>
            </View>
            {data.length &&
              data.map((e, index) => (
                <View style={[styles.row]} break={(index + 1) % 18 === 0}>
                  <View style={styles.column}>
                    <Text style={styles.textTable}>{e?.code}</Text>
                  </View>
                  <View style={[styles.column, styles.lastColumn]}>
                    <Text style={styles.textTable}>{e?.name}</Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FileExportInvoicePDF;
