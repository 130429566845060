export const HEADER_CURRICULUM_CSV = [
  { label: '同一カリキュラムフラグ', key: 'flag' },
  { label: 'カリキュラム名', key: 'curriculum_name' },
  { label: 'カリキュラムコード', key: 'curriculum_code' },
  { label: 'カリキュラム説明', key: 'curriculum_description' },
  { label: '第１階層名', key: 'level1_name' },
  { label: '第１階層コード', key: 'level1_code' },
  { label: '第２階層名', key: 'level2_name' },
  { label: '第2階層コード', key: 'level2_code' },
  { label: '第３階層名', key: 'level3_name' },
  { label: '第3階層コード', key: 'level3_code' },
  { label: '第４階層名', key: 'level4_name' },
  { label: '第4階層コード', key: 'level4_code' },
  { label: '設問名', key: 'question_name' },
  { label: '設問コード', key: 'question_code' },
  { label: '設問内容', key: 'question_description' },
  { label: '設問添付ファイルID', key: 'question_attach' },
  { label: '設問添付ファイルID2', key: 'question2_attach' },
  { label: '設問添付ファイルID3', key: 'question3_attach' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A添付ファイルID', key: 'problems1_attach' },
  { label: '選択肢B添付ファイルID', key: 'problems2_attach' },
  { label: '選択肢C添付ファイルID', key: 'problems3_attach' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説添付ファイルID1', key: 'problems1_attach_fileID' },
  { label: '解説添付ファイルID2', key: 'problems2_attach_fileID' },
  { label: '解説添付ファイルID3', key: 'problems3_attach_fileID' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア', key: 'score' },
];

export const HEADER_JOB_TITLE_MASTER_CSV = [
  { label: 'a_id', key: 'a_id' },
  { label: '役職コード', key: 'code' },
  { label: '会社ID', key: 'company_id' },
  { label: '登録者', key: 'created_by' },
  { label: 'd_id', key: 'd_id' },
  { label: 'name', key: 'name' },
  { label: 'p_id', key: 'p_id' },
  { label: '序列順序', key: 'rank_order' },
  { label: 'status_id', key: 'status_id' },
  { label: 'title', key: 'title' },
  { label: 'unread', key: 'unread' },
  { label: '更新者', key: 'updated_by' },
];
export const HEADER_SKILL_CHECK_MASTER_CSV = [
  { label: 'company_id', key: 'company_id' },
  { label: 'createdby', key: 'createdby' },
  { label: 'description', key: 'description' },
  { label: 'end_period', key: 'end_period' },
  { label: 'grouping_code', key: 'grouping_code' },
  { label: 'implementation_settings', key: 'implementation_settings' },
  { label: 'problems4', key: 'problems4' },
  { label: 'publish', key: 'publish' },
  { label: 'question_count', key: 'question_count' },
  { label: 'question_time_limit', key: 'question_time_limit' },
  { label: 'skill_check_code', key: 'skill_check_code' },
  { label: 'skill_check_code_assign', key: 'skill_check_code_assign' },
  { label: 'skill_check_name', key: 'skill_check_name' },
  { label: 'start_period', key: 'start_period' },
  { label: 'user_type', key: 'user_type' },
];

export const HEADER_CURRICULUM_MASTER_TABLE_CSV = [
  { label: 'ステータス', key: 'publish' },
  { label: 'カリキュラムコード カリキュラム名', key: 'curriculum_description' },
  { label: '第１階層コード 第１階層名', key: 'creator' },
  { label: '第２階層コード 第２階層名', key: 'curriculum_code' },
  { label: '第３階層コード 第３階層名', key: 'curriculum_name' },
  { label: '第４階層コード 第４階層名', key: 'curriculum_sort_order' },
  { label: '設問コード 設問名', key: 'official_curriculum_code' },
  { label: 'problems_count', key: 'problems_count' },
  { label: 'required_curricullum', key: 'required_curricullum' },
];

export const HEADER_AFFILIATION_MASTER_CSV = [
  { label: 'affiliation_id', key: 'affiliation_id' },
  { label: 'affiliation_parent_id', key: 'affiliation_parent_id' },
  { label: 'code', key: 'code' },
  { label: 'company_id', key: 'company_id' },
  { label: 'level', key: 'level' },
  { label: 'login_id', key: 'login_id' },
  { label: 'name', key: 'name' },
  { label: 'sort_order', key: 'sort_order' },
  { label: 'user_icon_fileID', key: 'user_icon_fileID' },
  { label: 'user_name', key: 'user_name' },
  { label: 'user_sort_order', key: 'user_sort_order' },
];

export const HEADER_AUTHORITY_MASTER_CSV = [
  { label: '権限コード', key: 'code' },
  { label: '会社ID', key: 'company_id' },
  { label: '権限名', key: 'name' },
  { label: '管理者ダッシュボード権限', key: 'admin_dashboard_permission' },
  { label: '部署マスタ権限', key: 'departments_master_permission' },
  { label: 'ユーザーマスタ権限', key: 'users_master_permission' },
  { label: '管理者マスタ権限', key: 'admin_master_permission' },
  { label: 'カリキュラムマスタ権限', key: 'curricullum_master_permission' },
  { label: '分析グループマスタ権限', key: 'groups_master_permission' },
  { label: 'スキルチェックマスタ権限', key: 'skill_check_master_permission' },
  { label: 'バンドルマスタ権限', key: 'bundle_master_permission' },
  { label: 'レポート閲覧権限', key: 'report_view_permission' },
  { label: '権限マスタ権限', key: 'roles_master_permission' },
  { label: '役職マスタ権限', key: 'positions_master_permission' },
  { label: '支払いマスタ権限', key: 'payment_master_permission' },
  { label: 'タイマーマスタ権限', key: 'timer_master_permission' },
  { label: '設問マスタ権限', key: 'question_master_permission' },
  { label: 'スキルチェックツリー権限', key: 'skill_check_tree_permission' },
  { label: '面接ユーザーマスタ権限', key: 'inter_users_master_permission' },
  { label: '更新者', key: 'updatedby' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日', key: 'createdat' },
  { label: '更新日', key: 'updatedat' },
  { label: '削除日', key: 'deletedat' },
];

export const HEADER_EMPLOYEE_CSV = [
  { label: '社員番号', key: 'employee_code' },
  { label: 'ユーザ名', key: 'name' },
  { label: 'ユーザ名（カナ）', key: 'kana' },
  { label: 'メールアドレス', key: 'email' },
  { label: 'パスワード', key: 'password' },
  { label: '所属ID', key: 'department_name' },
  { label: '役職コード', key: 'position_code' },
  { label: '誕生日', key: 'birthday' },
  { label: '入社日', key: 'hire_date' },
  { label: '権限コード', key: 'role_code' },
  { label: 'インポート用権限コード', key: 'i_id_role_code' },
];

export const HEADER_INTERVIEW_CSV = [
  {
    label: '管理番号',
    key: 'manage_code',
  },
  {
    label: '氏名',
    key: 'name',
  },
  {
    label: 'フリガナ',
    key: 'kana',
  },
  {
    label: '募集所属',
    key: 'department_name',
  },
  {
    label: '募集役職',
    key: 'position_code',
  },
  {
    label: '年齢',
    key: 'birthday',
  },
  {
    label: '職歴',
    key: 'work_history',
  },
  {
    label: '採用予定年月',
    key: 'hire_date',
  },
  {
    label: 'メールアドレス',
    key: 'email',
  },
  {
    label: 'スキルチェック',
    key: 'implementation_date',
  },
];

export const HEADER_QUESTION_MASTER_CSV = [
  { label: '設問名称', key: 'name' },
  { label: '設問ID', key: 'i_id' },
  { label: '設問内容', key: 'description' },
  { label: '設問画像・動画ID①', key: 'attach_fileID1' },
  { label: '設問画像・動画ID②', key: 'attach_fileID2' },
  { label: '設問画像・動画ID③', key: 'attach_fileID3' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A画像・動画ID', key: 'problems1_attach_fileID' },
  { label: '選択肢B画像・動画ID', key: 'problems2_attach_fileID' },
  { label: '選択肢C画像・動画ID', key: 'problems3_attach_fileID' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説画像・動画ID①', key: 'comment_attach_fileID1' },
  { label: '解説画像・動画ID②', key: 'comment_attach_fileID2' },
  { label: '解説画像・動画ID③', key: 'comment_attach_fileID3' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア（設問難易度）', key: 'score' },
  { label: '設問画像・動画名①', key: 'attach_file_name1' },
  { label: '設問画像・動画名②', key: 'attach_file_name2' },
  { label: '設問画像・動画名③', key: 'attach_file_name3' },
  { label: '選択肢A画像・動画名', key: 'problems1_attach_file_name' },
  { label: '選択肢B画像・動画名', key: 'problems2_attach_file_name' },
  { label: '選択肢C画像・動画名', key: 'problems3_attach_file_name' },
  { label: '解説画像・動画名①', key: 'comment_attach_file_name1' },
  { label: '解説画像・動画名②', key: 'comment_attach_file_name2' },
  { label: '解説画像・動画名③', key: 'comment_attach_file_name3' },
];

export const HEADER_COMPANY_USER_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'company_id', key: 'company_id' },
  { label: 'company_name', key: 'company_name' },
  { label: 'payment_method_cd', key: 'payment_method_cd' },
  { label: 'payment_method', key: 'payment_method' },
  { label: 'admin_department', key: 'admin_department' },
  { label: 'admin_position', key: 'admin_position' },
  { label: 'admin_name', key: 'admin_name' },
  { label: 'last_login_at', key: 'last_login_at' },
  { label: 'invoice_exception', key: 'invoice_exception' },
  { label: 'skillcheck_tid', key: 'skillcheck_tid' },
  { label: 'max_users', key: 'max_users' },
  { label: 'contract_date', key: 'contract_date' },
  { label: 'paid_flg', key: 'paid_flg' },
  { label: 'deletedat', key: 'deletedat' },
  { label: 'forced_withdrawal_flg', key: 'forced_withdrawal_flg' },
];

export const HEADER_INVOICE_CORRESPONDENCE_LIST_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'company_id', key: 'company_id' },
  { label: 'company_name', key: 'company_name' },
  { label: 'manager_name', key: 'manager_name' },
  { label: 'email', key: 'email' },
  { label: 'account_registration_status', key: 'account_registration_status' },
  { label: 'url_issue_date', key: 'url_issue_date' },
  { label: 'memo', key: 'memo' },
];

export const HEADER_OFFICIAL_CURRICULUM_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'curriculum_name', key: 'curriculum_name' },
  { label: 'curriculum_description', key: 'curriculum_description' },
  { label: 'level1_code', key: 'level1_code' },
  { label: 'level1_name', key: 'level1_name' },
  { label: 'level2_code', key: 'level2_code' },
  { label: 'level2_name', key: 'level2_name' },
  { label: 'level3_code', key: 'level3_code' },
  { label: 'level3_name', key: 'level3_name' },
  { label: 'level4_code', key: 'level4_code' },
  { label: 'level4_name', key: 'level4_name' },
  { label: 'question_name', key: 'question_name' },
  { label: 'question_description', key: 'question_description' },
  { label: 'question_attach', key: 'question_attach' },
  { label: 'question2_attach', key: 'question2_attach' },
  { label: 'question3_attach', key: 'question3_attach' },
  { label: 'problems1', key: 'problems1' },
  { label: 'problems2', key: 'problems2' },
  { label: 'problems3', key: 'problems3' },
  { label: 'problems1_attach', key: 'problems1_attach' },
  { label: 'problems2_attach', key: 'problems2_attach' },
  { label: 'problems3_attach', key: 'problems3_attach' },
  { label: 'answer', key: 'answer' },
  { label: 'comment', key: 'comment' },
  { label: 'problems1_attach_fileID', key: 'problems1_attach_fileID' },
  { label: 'problems2_attach_fileID', key: 'problems2_attach_fileID' },
  { label: 'problems3_attach_fileID', key: 'problems3_attach_fileID' },
  { label: 'time_limit', key: 'time_limit' },
  { label: 'score', key: 'score' },
];

export const HEADER_ADMIN_USER_EXPORT_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'employee_code', key: 'employee_code' },
  { label: 'name', key: 'name' },
  { label: 'kana', key: 'kana' },
  { label: 'email', key: 'email' },
  { label: 'password', key: 'password' },
  { label: 'department_name', key: 'department_name' },
  { label: 'position_code', key: 'position_code' },
  { label: 'birthday', key: 'birthday' },
  { label: 'hire_date', key: 'hire_date' },
  { label: 'role_code', key: 'role_code' },
];

export const HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'company_id', key: 'company_id' },
  { label: 'affiliation_id', key: 'affiliation_id' },
  { label: 'code', key: 'code' },
  { label: 'name', key: 'name' },
  { label: 'user_icon_fileID', key: 'user_icon_fileID' },
  { label: 'user_name', key: 'user_name' },
  { label: 'user_departments', key: 'user_departments' },
  { label: 'user_positions', key: 'user_positions' },
  { label: 'login_id', key: 'login_id' },
];

export const HEADER_ADMIN_ROLES_EXPORT_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'name', key: 'name' },
  { label: 'code', key: 'code' },
  { label: 'createdat', key: 'createdat' },
  { label: 'createdby', key: 'createdby' },
  { label: 'updatedby', key: 'updatedby' },
  { label: 'deletedat', key: 'deletedat' },
  { label: 'updatedat', key: 'updatedat' },
  { label: 'company_id', key: 'company_id' },
  { label: 'users_master_permission', key: 'users_master_permission' },
  { label: 'sales_report_permission', key: 'sales_report_permission' },
  { label: 'admin_master_permission', key: 'admin_master_permission' },
  { label: 'roles_master_permission', key: 'roles_master_permission' },
  { label: 'corporate_user_permission', key: 'corporate_user_permission' },
  { label: 'curriculum_tree_permission', key: 'curriculum_tree_permission' },
  { label: 'admin_dashboard_permission', key: 'admin_dashboard_permission' },
  { label: 'question_master_permission', key: 'question_master_permission' },
  { label: 'positions_master_permission', key: 'positions_master_permission' },
  { label: 'partner_management_permission', key: 'partner_management_permission' },
  { label: 'billing_management_permission', key: 'billing_management_permission' },
  { label: 'curricullum_master_permission', key: 'curricullum_master_permission' },
  { label: 'departments_master_permission', key: 'departments_master_permission' },
  { label: 'invoice_correspondence_permission', key: 'invoice_correspondence_permission' },
  { label: 'individual_user_management_permission', key: 'individual_user_management_permission' },
  { label: 'official_curriculum_report_permission', key: 'official_curriculum_report_permission' },
  {
    label: 'official_curriculum_publish_permission',
    key: 'official_curriculum_publish_permission',
  },
  {
    label: 'skill_check_implementation_report_permission',
    key: 'skill_check_implementation_report_permission',
  },
];

export const HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV = [
  { label: 'i_id', key: 'i_id' },
  { label: 'archive_flg', key: 'archive_flg' },
  { label: 'createdby', key: 'createdby' },
  { label: 'history_id', key: 'history_id' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'official_curriculum_name', key: 'official_curriculum_name' },
  { label: 'provider_id', key: 'provider_id' },
  { label: 'publish', key: 'publish' },
  { label: 'publish_start_date', key: 'publish_start_date' },
  { label: 'updatedat', key: 'updatedat' },
  { label: 'version', key: 'version' },
];

export const HEADER_BILLING_DATA_DETAIL = [
  { label: 'i_id', key: 'i_id' },
  { label: 'billing_detail_id', key: 'billing_detail_id' },
  { label: 'provided_date', key: 'provided_date' },
  { label: 'billing_id', key: 'billing_id' },
  { label: 'billing_data_name', key: 'billing_data_name' },
  { label: 'order_id', key: 'order_id' },
  { label: 'company_id', key: 'company_id' },
  { label: 'company_name', key: 'company_name' },
  { label: 'user_id', key: 'user_id' },
  { label: 'user_name', key: 'user_name' },
  { label: 'billing_type', key: 'billing_type' },
  { label: 'order_date', key: 'order_date' },
  { label: 'billing_date', key: 'billing_date' },
  { label: 'num_of_accounts', key: 'num_of_accounts' },
  { label: 'amount', key: 'amount' },
  { label: 'payment_method', key: 'payment_method' },
  { label: 'error_code', key: 'error_code' },
  { label: 'error_title', key: 'error_title' },
  { label: 'os', key: 'os' },
  { label: 'gmo_resend', key: 'gmo_resend' },
];

export const HEADER_AGGREGATION_CHURN_RATE = [
  { label: 'i_id', key: 'i_id' },
  { label: 'target_month', key: 'target_month' },
  { label: 'number_of_members_beginning_of_month', key: 'number_of_members_beginning_of_month' },
  { label: 'number_of_members_end_of_month', key: 'number_of_members_end_of_month' },
  { label: 'churn_rate', key: 'churn_rate' },
  { label: 'number_of_monthly_enrollments', key: 'number_of_monthly_enrollments' },
  { label: 'number_of_monthly_withdrawals', key: 'number_of_monthly_withdrawals' },
  {
    label: 'active_number_of_members_beginning_of_month',
    key: 'active_number_of_members_beginning_of_month',
  },
  { label: 'active_number_of_members_end_of_month', key: 'active_number_of_members_end_of_month' },
  { label: 'active_churn_rate', key: 'active_churn_rate' },
  { label: 'active_number_of_monthly_enrollments', key: 'active_number_of_monthly_enrollments' },
  { label: 'active_number_of_monthly_withdrawals', key: 'active_number_of_monthly_withdrawals' },
  { label: 'aggregate_user_type', key: 'aggregate_user_type' },
];

export const HEADER_INDIVIDUAL_USER_MANAGEMENT = [
  { label: 'i_id', key: 'i_id' },
  { label: 'id', key: 'id' },
  { label: 'company_id', key: 'company_id' },
  { label: 'login_id', key: 'login_id' },
  { label: 'user_name', key: 'user_name' },
  { label: 'period', key: 'period' },
  { label: 'createdat', key: 'createdat' },
  { label: 'deletedat', key: 'deletedat' },
  { label: 'comment', key: 'comment' },
  { label: 'status', key: 'status' },
  { label: 'os', key: 'os' },
];

export const HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT = [
  { label: 'i_id', key: 'i_id' },
  { label: 'provider_id', key: 'provider_id' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'history_id', key: 'history_id' },
  { label: 'publish_start_date', key: 'publish_start_date' },
  { label: 'publish_type', key: 'publish_type' },
  { label: 'release_note_id', key: 'release_note_id' },
  { label: 'updatedat', key: 'updatedat' },
  { label: 'release_note_title', key: 'release_note_title' },
  { label: 'release_version', key: 'release_version' },
  { label: 'release_note_version', key: 'release_note_version' },
  { label: 'release_note_publish_status', key: 'release_note_publish_status' },
  { label: 'createdby', key: 'createdby' },
  { label: 'official_curriculum_name', key: 'official_curriculum_name' },
];

export const HEADER_RELEASE_NOTE_DETAIL = [
  { label: 'i_id', key: 'i_id' },
  { label: 'description', key: 'description' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'release_note_id', key: 'release_note_id' },
  { label: 'release_note_version', key: 'release_note_version' },
  { label: 'release_note_title', key: 'release_note_title' },
  { label: 'section_name', key: 'section_name' },
  { label: 'section_text', key: 'section_text' },
];
