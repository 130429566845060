import React, { useEffect, useState } from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { DualAxes } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import Styled from './styles';
import {
  getDataAggregationChurnRate,
  getDataChangesInUsageOfOfficialCurriculum,
  getDataNumOfficialCurriculumCountOfStops,
  getDataNumOfficialCurriculumUsers,
  getDataNumOfficialCurriculumUsersActive,
  getDataNumOfNewUsersMonth,
} from '../thunk';

const { Option } = Select;

const OfficialCurriculum: React.FC = () => {
  const [selected, setSelected] = useState<string>('all');

  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    numOfficialCurriculumUsers,
    numOfficialCurriculumUsersActive,
    numOfNewUsersMonth,
    numOfficialCurriculumCountOfStops,
    dataChangesInUsageOfOfficialCurriculumColumn,
    dataChangesInUsageOfOfficialCurriculumLine,
    dataUserTransitionLineChart,
  } = useSelector(dashboardSelector);

  const config = {
    data: [
      dataChangesInUsageOfOfficialCurriculumColumn,
      [...dataChangesInUsageOfOfficialCurriculumLine, ...dataUserTransitionLineChart],
    ],
    xField: 'target_month',
    yField: ['value', 'value'],
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        seriesField: 'name',
      },
      {
        geometry: 'line',
        seriesField: 'name',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };

  const getDateThreeMonthsAgo = (dateString: string) => {
    const date = dayjs(dateString);
    const dateThreeMonthsAgo = date.subtract(3, 'month');
    return dateThreeMonthsAgo.toISOString();
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isUserInfoChanged && userInfo) {
      dispatch(
        getDataNumOfficialCurriculumUsers({
          page: 1,
          per_page: 0,
          return_count_only: true,
          omit_total_items: false,
        })
      );
    }
  }, [isUserInfoChanged, dispatch, userInfo]);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataNumOfficialCurriculumUsersActive({
            conditions: [
              {
                id: 'last_login_date',
                search_value: [getDateThreeMonthsAgo(dayjs().toISOString()), null],
              },
              {
                id: 'last_login_date',
                search_value: [null, dayjs().toISOString()],
              },
            ],
            page: 1,
            per_page: 0,
            return_count_only: true,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataNumOfNewUsersMonth({
            conditions: [
              {
                id: 'implementation_date',
                search_value: [dayjs().startOf('month').toISOString(), null],
              },
              {
                id: 'implementation_date',
                search_value: [null, dayjs().endOf('month').toISOString()],
              },
            ],
            page: 1,
            per_page: 0,
            return_count_only: true,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataNumOfficialCurriculumCountOfStops({
            conditions: [
              {
                id: 'target_month',
                search_value: [dayjs().format('YYYYMM'), null],
              },
            ],
            page: 1,
            per_page: 0,
            return_count_only: true,
          })
        ),
        dispatch(
          getDataChangesInUsageOfOfficialCurriculum({
            page: 1,
            per_page: 0,
            sort_fields: [
              {
                id: 'target_month',
                order: 'asc',
              },
            ],
          })
        ),
        dispatch(
          getDataAggregationChurnRate({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <ReadOutlined className="icon" /> OFFICIALカリキュラム情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイム利用ユーザー数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">利用ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">{numOfficialCurriculumUsers}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">アクティブ利用ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">{numOfficialCurriculumUsersActive}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <div className="detail-child">
                  <span className="title-info">当月新規利用ユーザー数</span>
                  <div className="wrap-number">
                    <span className="number">{numOfNewUsersMonth}</span>
                    <span className="unit"> 人</span>
                  </div>
                </div>
                <div className="line" />
                <div className="detail-child">
                  <span className="title-info">当月新規利用ユーザー数</span>
                  <div className="wrap-number">
                    <span className="number">{numOfficialCurriculumCountOfStops}</span>
                    <span className="unit"> 人</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">利用ユーザー数推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>データ選択：</label>
                  <Select defaultValue={selected} onSelect={(e) => setSelected(e)}>
                    <Option value="all">ALL</Option>
                    <Option value="count_of_new_users">新規利用</Option>
                    <Option value="count_of_stops">利用停止</Option>
                    <Option value="count_of_monthly_users">月間利用数</Option>
                    <Option value="count_of_monthly_active_users">アクティブ</Option>
                    <Option value="activation_rate">アクティブ比</Option>
                    <Option value="churn_rate">Churn Rate</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：人</span>
              <DualAxes
                className="dual-chart"
                {...config}
                legend={{
                  position: 'top-right',
                }}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                }}
                xAxis={{
                  tickLine: null,
                  label: {
                    formatter: (text) => dayjs(text).format('YYYY/MM'),
                  },
                }}
                label={{
                  position: 'top',
                }}
                height={550}
                padding={[50, 0]}
                geometryOptions={[
                  {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'name',
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: ['#EC4D7C', '#C38BC2', '#4E83D3', '#1AC199'],
                    label: {
                      position: 'top',
                    },
                  },
                  {
                    geometry: 'line',
                    seriesField: 'name',
                    lineStyle: {
                      lineWidth: 2,
                    },
                    isStack: true,
                    point: {},
                    color: ['#5EDFDF', '#F0CA45'],
                  },
                ]}
                animation={!loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default OfficialCurriculum;
