import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import {
  getFilePartnerManagement,
  getPartnerDetail,
  getPartnerManagement,
  getProviderInformation,
  getSelectCompany,
} from './thunk';
import * as Types from 'types';

export type InitialState = {
  total: number;
  loading: boolean;
  signedInEmail?: string;
  userInfo?: Types.Users.ResponseType;
  management: Array<Types.DataPartnerManagementType>;
  dataDetail?: Types.DataPartnerDetailType;
  searchSelect: Array<Types.SearchSelectOptionType>;
  dataInformation: Array<Types.InformationType>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  management: [],
  searchSelect: [],
  dataInformation: [],
};

export const publicManagementSlice = createSlice({
  name: 'PublicManagementSlice-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getPartnerManagement.pending, startLoading)
      .addCase(getPartnerDetail.pending, startLoading)
      .addCase(getSelectCompany.pending, startLoading)
      .addCase(getFilePartnerManagement.pending, startLoading)
      .addCase(getProviderInformation.pending, startLoading);

    builder.addCase(getPartnerManagement.fulfilled, (state, action) => {
      state.management = unionBy(action.payload.report_results, 'provider_id');
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getProviderInformation.fulfilled, (state, action) => {
      state.dataInformation = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getPartnerDetail.fulfilled, (state, action) => {
      state.dataDetail = action.payload.report_results.map((item) => ({
        ...item,
        addressError:
          !item.admin_name &&
          !item.admin_name_furigana &&
          !item.admin_department &&
          !item.admin_position &&
          !item.admin_phone &&
          !item.admin_email &&
          '企業情報・管理者情報未入力。',
      }))[0];
      stopLoading(state);
    });
    builder.addCase(getSelectCompany.fulfilled, (state, action) => {
      state.searchSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getFilePartnerManagement.fulfilled, stopLoading);

    builder
      .addCase(getPartnerManagement.rejected, stopLoading)
      .addCase(getPartnerDetail.rejected, stopLoading)
      .addCase(getProviderInformation.rejected, startLoading)
      .addCase(getFilePartnerManagement.rejected, startLoading)
      .addCase(getSelectCompany.rejected, stopLoading);
  },
});

export default publicManagementSlice.reducer;
