import styled from 'styled-components';

export const SectionStyled = styled.div`
  .title {
    background-color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    align-items: center;
    color: #424242;
    padding: 20px 0;
    margin: 0;
  }
  .content {
    background-color: #f9f8f8;
    font-size: 13px;
    color: #424242;
    text-align: center;
    .sub-title {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #424242;
      .icon {
        color: #bbbbbb;
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .radio-group {
      height: 40px;
      width: 400px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #ffffff;
      margin: auto;
      border: 1px solid #d9d9d9;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
    }
    .text-content {
      font-size: 13px;
      text-align: center;
      color: #777777;
    }
    .group-btn {
      padding: 40px 0 20px 0;
      .btn {
        height: 40px;
        border-radius: 5px;
        font-size: 13px;
        margin: 0 4px;
        cursor: pointer;
      }
      .active {
        width: 140px;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border: none;
        font-weight: 700;
        color: #ffffff;
      }
      .gmo {
        width: 180px;
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        font-weight: 700;
        color: #00a3a5;
      }
      .outline {
        width: 100px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        color: #777777;
      }
    }
  }
`;
