import React, { useCallback, useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, Input, SelectField, TextArea } from 'components';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditJobTitleSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { jobTitleMasterSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import JobTitleMasterStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getSelectAdminPositions,
  getDataAdminPositions,
  deleteAdminPositions,
  updateAdminPosition,
  createAdminPosition,
} from './thunk';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import FileExportInvoicePDF from './FileExportPDF';

const { Option } = Select;

const JobTitleMaster: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<Types.AdminPositions.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.AdminPositions.ResponseType>();
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { dataSelectAdminPositions, dataAdminPositions, loading } =
    useSelector(jobTitleMasterSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const fetchDataAdminPositions = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getDataAdminPositions({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: page,
        per_page: 0,
      })
    );
  }, [dispatch, page, userInfo]);

  const formikSearch = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async ({ name }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する役職を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        await dispatch(
          getDataAdminPositions({
            conditions: [
              {
                id: 'name',
                search_value: [name],
                exact_match: true,
              },
            ],
            include_lookups: true,
            use_display_id: true,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
      }
    },
    onReset: async () => {
      await dispatch(
        getDataAdminPositions({
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
    },
  });

  const isEditing = (record: Types.AdminPositions.ResponseType) =>
    record.i_id === itemSelected?.i_id;

  const columns: any = [
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: '13%',
      align: 'center',
      sorter: (a: Types.AdminPositions.ResponseType, b: Types.AdminPositions.ResponseType) =>
        Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.AdminPositions.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => setItemSelected(record)} />
        );
      },
    },
    {
      title: '削除',
      width: '7%',
      dataIndex: 'i_id',
      align: 'left',
      render: (_: string, record: Types.AdminPositions.ResponseType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined
            className="icon"
            onClick={() => {
              setItemDeleted(record);
              setOpenModalConfirmDeleteItem(true);
            }}
          />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      enableReinitialize
      validationSchema={CreateEditJobTitleSchema}
      onSubmit={async (values) => {
        if (!itemSelected) return;
        const resultAction = await dispatch(
          updateAdminPosition({
            id: itemSelected.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        setItemSelected(undefined);
        if (updateAdminPosition.fulfilled.match(resultAction)) {
          fetchDataAdminPositions();
          fetchDataSelectAdminPositions();
          setShowCompleteModal(true);
        } else {
          setActionModalState({
            subTitle: '役職の更新に失敗しました',
            description: (
              <p className="text-content">
                役職の更新に失敗しました。
                <br />
                もう一度情報を入力し、再度お試しください。
              </p>
            ),
          });
          setShowActionErrorModal(true);
        }
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell<Types.AdminPositions.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: '※役職名称が未入力です',
                },
              ]}
            >
              <Input showCount maxLength={30} name="name" />
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell<Types.AdminPositions.ResponseType>) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Types.AdminPositions.ResponseType) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSubmitDelete = async () => {
    const resultAction = await dispatch(
      deleteAdminPositions({
        id: itemDeleted!.i_id,
      })
    );
    if (deleteAdminPositions.fulfilled.match(resultAction)) {
      fetchDataSelectAdminPositions();
      fetchDataAdminPositions();
      setShowCompleteModal(true);
    }
  };

  const handleExportCSV = async (value: string) => {
    setShowConfirmExportFileModal(false);
    if (value === 'pdf') {
      const blob = await pdf(<FileExportInvoicePDF data={dataAdminPositions} />).toBlob();
      saveAs(blob, '役職マスタ.pdf');
    }
  };

  const fetchDataSelectAdminPositions = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getSelectAdminPositions({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  useEffect(fetchDataAdminPositions, [fetchDataAdminPositions]);
  useEffect(fetchDataSelectAdminPositions, [fetchDataSelectAdminPositions]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <JobTitleMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataAdminPositions.length}>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            役職マスタの作成・管理を行う画面です。
            <br />
            作成した役職マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label">役職検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="name"
                >
                  {dataSelectAdminPositions?.map(
                    ({ name }, i) =>
                      name && (
                        <Option value={name} key={i}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>

          <div className="wrap-table">
            <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>

            <Table
              dataSource={dataAdminPositions.map((item, index) => ({ ...item, index }))}
              columns={mergedColumns}
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell,
                },
              }}
              className="table"
              pagination={{
                showSizeChanger: false,
                current: page,
                pageSize: perPage,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    {dataAdminPositions.length && (
                      <div className="total-record-table">
                        <span className="text-static">
                          {page * perPage > dataAdminPositions.length
                            ? dataAdminPositions.length
                            : page * perPage}
                          <small> 件表示</small> / {dataAdminPositions.length}
                          <small> 名</small>
                        </span>
                      </div>
                    )}
                    <div>
                      <span className="label-select">表示件数：</span>
                      <Select className="select-option" onChange={setPerpage} value={perPage}>
                        {[100, 50, 20, 10].map((e) => (
                          <Option value={e}>{e}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ),
              }}
              rowKey="index"
            />
          </div>
        </div>
        <div className="wrap-create">
          <p className="title">役職新規登録</p>
          <Formik<Types.CreateEditJobTitleFormik>
            initialValues={{
              name: '',
            }}
            enableReinitialize
            validationSchema={CreateEditJobTitleSchema}
            onSubmit={async (values, { resetForm }) => {
              const resultAction = await dispatch(
                createAdminPosition({
                  item: {
                    company_id: userInfo?.company_id,
                    name: values.name,
                  },
                })
              );
              if (createAdminPosition.fulfilled.match(resultAction)) {
                setShowCompleteModal(true);
              } else {
                setActionModalState({
                  subTitle: '新規役職の登録に失敗しました',
                  description: (
                    <p className="text-content">
                      新規役職の登録に失敗しました。
                      <br />
                      もう一度情報を入力し、再度お試しください。
                    </p>
                  ),
                });
                setShowActionErrorModal(true);
              }

              fetchDataSelectAdminPositions();
              fetchDataAdminPositions();
              resetForm();
            }}
          >
            <Form colon={false} layout="vertical" className="flex">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    役職名称
                    <span className="require">*</span>
                  </span>
                }
                className="form-text-area"
              >
                <TextArea name="name" showCount maxLength={120} placeholder="最大120文字" />
              </Form.Item>
              <SubmitButton className="btn btn_submit">
                <PlusOutlined className="size-icon" />
                新規登録
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        title="登録が完了しました"
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={handleSubmitDelete}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </JobTitleMasterStyled>
  );
};

export default JobTitleMaster;
