import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getSelectUser,
  getPositionUser,
  getDepartmentUser,
  getDataSearchUsers,
  createUserAssignCurriculum,
} from './thunk';

type InitialState = {
  loading: boolean;
  list_users: Types.Users.ResponseType[];
  data_departments: Types.Departments.ResponseType[];
  position_code: Types.Departments.ResponseType[];
  data_user: Types.Selects.SelectUserResponseType[];
};

const initialState: InitialState = {
  loading: false,
  list_users: [],
  data_user: [],
  data_departments: [],
  position_code: [],
};

const menuRightSlice = createSlice({
  name: 'menuRight-Slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getSelectUser.pending, startLoading)
      .addCase(getPositionUser.pending, startLoading)
      .addCase(getDepartmentUser.pending, startLoading)
      .addCase(getDataSearchUsers.pending, startLoading)
      .addCase(createUserAssignCurriculum.pending, startLoading);

    builder.addCase(getSelectUser.fulfilled, (state, action) => {
      state.data_user = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getPositionUser.fulfilled, (state, action) => {
      state.position_code = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getDepartmentUser.fulfilled, (state, action) => {
      state.data_departments = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getDataSearchUsers.fulfilled, (state, action) => {
      state.list_users = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(createUserAssignCurriculum.fulfilled, stopLoading);

    builder
      .addCase(getSelectUser.rejected, stopLoading)
      .addCase(getPositionUser.rejected, stopLoading)
      .addCase(getDepartmentUser.rejected, stopLoading)
      .addCase(getDataSearchUsers.rejected, stopLoading)
      .addCase(createUserAssignCurriculum.rejected, stopLoading);
  },
});

export default menuRightSlice.reducer;
