import React from 'react';
import { Form, Radio } from 'formik-antd';

import { ItemStyled } from './styles';

interface Prop {
  name: string;
  label: string;
  disabled?: boolean;
}

const Item: React.FC<Prop> = ({ name, label, disabled }) => {
  return (
    <Form.Item name={name}>
      <ItemStyled>
        <div className="label">{label}</div>
        <Radio.Group name={name} className="radio-group">
          <Radio name={name} disabled={disabled} value={2} className="radio-text">
            編集権限
          </Radio>
          <Radio name={name} value={1} className="radio-text middle">
            閲覧権限
          </Radio>
          <Radio name={name} value={0} className="radio-text">
            非表示
          </Radio>
        </Radio.Group>
      </ItemStyled>
    </Form.Item>
  );
};

export default Item;
