import React, { useCallback, useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, MinusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';

import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { removeNodeLevel4Selected, selectNodeLevel4 } from 'containers/Curriculum/Search/slice';
import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { CopyWhite, IconLocked, IconPublish, IconRequired, MoveWhite } from 'assets';
import { deleteLinkQuestion } from 'containers/CreateEditQuestion/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CreateQuestion from 'pages/QuestionMaster/Modal/CreateQuestion';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { Button, DivCustom, DivCustomCanDrag } from './styles';
import { sharedFileInMinIO } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { UploadFile } from 'antd/lib/upload/interface';
import { ErrorBoundary } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  EditLevelCurriculum,
  CreateEditCurriculum,
  CreateLevelCurriculum,
  PopupCurriculumConfirmPublish,
} from 'pages/Curriculum/Modal';
import {
  getCurriculum,
  getDataQuestion,
  deleteCurriculum,
  getDataUserSetting,
  setPublishCurriculum,
  deleteLevelCurriculum,
  deleteLinkUserAssignCurriculum,
  updateCurriculum,
} from 'pages/Curriculum/thunk';

type Props = {
  tabActive: number;
  maxSortOrder?: number;
  visible: boolean;
  node: Types.TreeItem<Types.CurriculumItemType>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectModeDrag: (n: Types.TreeItem<Types.CurriculumItemType>, m: 'move' | 'copy') => void;
  onDrop?: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
};

const TooltipRowContent = ({
  node,
  onDrop,
  tabActive,
  setVisible,
  maxSortOrder,
  itemMoveCopySelected,
  handleSelectModeDrag,
  visible,
}: Props) => {
  const [openModalEditLevelCurriculum, setOpenModalEditLevelCurriculum] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [openModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<File>>();
  const [openModalCreateLevelCurriculum, setOpenModalCreateLevelCurriculum] =
    useState<boolean>(false);
  const [openPopupCurriculumConfirmPublish, setOpenPopupCurriculumConfirmPublish] =
    useState<boolean>(false);
  const [openModalCreateEditQuestion, setOpenModalCreateEditQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  }>({ visible: false, type: 'create' });

  const { filter_conditions, data_user_setting } = useSelector(curriculumSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handlePublishItem = useCallback(async () => {
    if (node.node) {
      await dispatch(
        setPublishCurriculum({
          id: node.node.i_id!,
          data: {
            item: {
              publish: node.node.publish ? 0 : 1,
            },
            is_force_update: true,
            return_item_result: true,
          },
        })
      );
      dispatch(removeNodeLevel4Selected());
      setVisible(false);
      setOpenPopupCurriculumConfirmPublish(false);
      await dispatch(
        getCurriculum({
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [node.node, dispatch, setVisible, filter_conditions.conditions, userInfo?.company_id]);

  const handleGetFileCurriculum = async () => {
    if (!node?.node) return;

    const fileQuestion: UploadFile<File> = { uid: '', name: '' };
    dispatch(startLoading());
    if (node?.node?.fileID) {
      const fileName = extractFileName(node?.node?.fileID);
      const nodeFileUrl = await sharedFileInMinIO(node?.node?.fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
      Object.assign(
        fileQuestion,
        convertFileResponse({
          file: fileFromUrl,
          fileID: node?.node?.fileID,
          fileName: fileName,
        })
      );
      setFiles(fileQuestion);
    }
    dispatch(stopLoading());
  };

  const handleSelectNodeLevel4 = useCallback(
    (checked: boolean) => {
      if (checked && node.node) {
        dispatch(selectNodeLevel4({ ...node.node, maxSortOrder }));
      } else {
        dispatch(removeNodeLevel4Selected());
      }
      setVisible(false);
    },
    [node, setVisible, dispatch, maxSortOrder]
  );

  const handleDeleteCurriculum = useCallback(async () => {
    if (node) {
      const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
        memoizedGetChildrenItemIDFromTree({
          treeData: node.node!,
        });

      const resultActions = await Promise.all([
        ...listChildItemID.map((item) => {
          if (item.columnIndex === 0) {
            return dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
        ...(data_user_setting
          .find((curr) => curr.i_id === node.node?.i_id)
          ?.children?.flatMap((department) =>
            department.children?.map((user) =>
              dispatch(
                deleteLinkUserAssignCurriculum({
                  id: user.i_id!,
                })
              )
            )
          ) ?? []),
      ]);
      const questions_delete_length = resultActions.filter(
        (r) => r && deleteLinkQuestion.fulfilled.match(r)
      ).length;
      if (questions_delete_length) {
        const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
        if (curriculum && !listChildItemID.some((item) => item.columnIndex === 0)) {
          await dispatch(
            updateCurriculum({
              id: curriculum.curriculum_id!,
              data: {
                item: {
                  probs_count:
                    curriculum?.problems_count && curriculum?.problems_count > 0
                      ? curriculum?.problems_count - questions_delete_length
                      : 0,
                  updatedat: new Date(),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }
      }
      setVisible(false);
      setOpenModalConfirmDeleteItem(false);
      await Promise.all([
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUserSetting({
            include_lookups: true,
            include_item_ref: true,
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [
    node,
    setVisible,
    data_user_setting,
    dispatch,
    filter_conditions?.conditions,
    userInfo?.company_id,
  ]);

  useEffect(() => {
    handleGetFileCurriculum();
  }, [visible]);

  return tabActive === 0 ? (
    <DivCustom columnIndex={node.columnIndex!}>
      {node.columnIndex === 0 && (
        <div className="rowWrapper bgGrey">
          <div className="rowContentHead">
            <div className="spanHead">
              {node.node?.publish ? (
                <>
                  <img src={IconPublish} className="icon" alt="publish-icon" />
                  <span className="spanText">公開中</span>
                </>
              ) : (
                <>
                  <img src={IconLocked} className="icon" alt="edit-icon" />
                  <span className="spanText">編集中</span>
                </>
              )}
            </div>
            {!!node.node?.required_curriculum && (
              <div className="spanHead">
                <img src={IconRequired} className="icon" alt="required-icon" />
                <span className="spanText">必修カリキュラム</span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="rowWrapper">
        {files && node?.node?.fileID ? (
          <div className="item-image">
            <img
              src={URL.createObjectURL(new Blob([files.originFileObj as Blob]))}
              className="image"
              alt={files.name}
            />
          </div>
        ) : null}
        {node.columnIndex === 0 ? (
          <p>{node.node?.description}</p>
        ) : (
          <p>{node.node?.name || '（空白）'}</p>
        )}
        {!node.node?.publish && (
          <div className="rowContent">
            {node.columnIndex! < 4 && (
              <Button onClick={() => setOpenModalCreateLevelCurriculum(true)}>
                +第{node.columnIndex! + 1}階層追加
              </Button>
            )}
            <div className="icons">
              <EditOutlined
                className="icon"
                onClick={() => {
                  if (node.columnIndex === 0) {
                    setOpenModalEditCurriculum(true);
                    handleGetFileCurriculum();
                  } else if (node.columnIndex === 5) {
                    setOpenModalCreateEditQuestion({
                      question_id: node.node?.i_id,
                      visible: true,
                      type: 'edit',
                    });
                  } else {
                    setOpenModalEditLevelCurriculum(true);
                  }
                }}
              />
              {node.columnIndex! < 5 ? (
                <DeleteOutlined
                  className="icon"
                  onClick={() => setOpenModalConfirmDeleteItem(true)}
                />
              ) : (
                <MinusOutlined
                  className="icon"
                  onClick={() => setOpenModalConfirmDeleteItem(true)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {node.columnIndex === 0 ? (
        <>
          {/*<div className="rowWrapper blOrange"></div>*/}
          {/*<div className={`rowWrapper ${node.node?.publish ? 'blGreen' : 'blViolet'}`}>*/}
          {/*  <div className="rowContent" onClick={() => setOpenPopupCurriculumConfirmPublish(true)}>*/}
          {/*    <span>*/}
          {/*      {node.node?.publish ? 'カリキュラムを編集する' : 'カリキュラムを公開する'}*/}
          {/*    </span>*/}
          {/*    <div>*/}
          {/*      {node.node?.publish ? (*/}
          {/*        <img src={IconPublish} className="icon" alt="publish-icon" />*/}
          {/*      ) : (*/}
          {/*        <img src={IconLocked} className="icon" alt="edit-icon" />*/}
          {/*      )}*/}
          {/*      <CaretRightOutlined className="caretIcon" />*/}
          {/*      {node.node?.publish ? (*/}
          {/*        <img src={IconLocked} className="icon" alt="edit-icon" />*/}
          {/*      ) : (*/}
          {/*        <img src={IconPublish} className="icon" alt="publish-icon" />*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </>
      ) : (
        node.columnIndex === 4 &&
        !node.node?.publish && (
          <div className="rowWrapper blRed">
            <div className="rowContent">
              <span>設問追加先に設定</span>
              <Switch
                size="small"
                checked={
                  nodeLevel4Selected && node.node && nodeLevel4Selected?.i_id === node.node?.i_id
                }
                onChange={handleSelectNodeLevel4}
              />
            </div>
          </div>
        )
      )}
      <CreateLevelCurriculum
        node={node}
        maxSortOrder={maxSortOrder}
        visible={openModalCreateLevelCurriculum}
        setVisible={setOpenModalCreateLevelCurriculum}
        setVisibleTooltip={setVisible}
      />
      <EditLevelCurriculum
        node={node}
        visible={openModalEditLevelCurriculum}
        setVisible={setOpenModalEditLevelCurriculum}
      />
      <ErrorBoundary>
        <CreateQuestion
          page={1}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={openModalCreateEditQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateEditQuestion}
          fetchData={() => {
            dispatch(
              getDataQuestion({
                conditions: [{ id: 'company_id', search_value: [userInfo?.company_id] }],
                page: 1,
                per_page: 0,
                include_item_ref: true,
              })
            );
          }}
        />
      </ErrorBoundary>
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
      <PopupCurriculumConfirmPublish
        onSubmit={handlePublishItem}
        nodeLocked={!!node.node?.publish}
        visible={openPopupCurriculumConfirmPublish}
        setVisible={setOpenPopupCurriculumConfirmPublish}
      />
      <CreateEditCurriculum
        type="edit"
        textSubmit="更新"
        id={node.node?.i_id}
        title="カリキュラム編集"
        name={node.node?.name}
        visible={openModalEditCurriculum}
        description={node.node?.description}
        setVisible={setOpenModalEditCurriculum}
        subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
        fileCurriculum={files}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します。"
        onSubmit={handleDeleteCurriculum}
        visible={openModalConfirmDeleteItem}
        setVisible={setOpenModalConfirmDeleteItem}
        description={
          <>
            データの削除を実行すると、復元できませんのでご注意ください。
            <br />
            <span style={{ color: 'red' }}>※ただし、設問は削除されません"</span>
          </>
        }
      />
    </DivCustom>
  ) : node.columnIndex === 0 ? (
    <DivCustom columnIndex={0}>
      <div className="rowWrapper bgGrey">
        <div className="rowContentHead">
          <div className="spanHead">
            {node.node?.publish ? (
              <>
                <img src={IconPublish} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : (
              <>
                <img src={IconLocked} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          {!!node.node?.required_curriculum && (
            <div className="spanHead">
              <img src={IconRequired} className="icon" alt="required-icon" />
              <span className="spanText">必修カリキュラム</span>
            </div>
          )}
        </div>
      </div>
      <div className="rowWrapper">
        <p>{node.node?.description}</p>
      </div>
    </DivCustom>
  ) : node.parentNode ? (
    itemMoveCopySelected && itemMoveCopySelected.node.columnIndex! - 1 === node.columnIndex ? (
      <DivCustomCanDrag isPublish={node.node?.publish}>
        <div className="wrap-content">
          <p>
            「{itemMoveCopySelected.node.node?.name || '（空白）'}
            」を選択した階層の下にペーストします。
          </p>
        </div>
        <div className="wrap-button-drop">
          <button
            className="button ok button-ok"
            onClick={() => {
              if (node.node && onDrop && !node.node?.publish) {
                setVisible(false);
                onDrop(itemMoveCopySelected.type, itemMoveCopySelected.node, node.node);
              }
            }}
          >
            OK
          </button>
          <button className="button cancel" onClick={() => setVisible(false)}>
            キャンセル
          </button>
        </div>
      </DivCustomCanDrag>
    ) : (
      <DivCustomCanDrag>
        <div className="wrap-content">
          <p>アクションを選択してください</p>
        </div>
        <div className="wrap-button">
          <button
            disabled={!!node.node!.publish}
            className={node.node?.publish ? 'disabled' : 'button'}
            onClick={() => handleSelectModeDrag(node, 'move')}
          >
            <img src={MoveWhite} alt="move-icon" />
            移動
          </button>
          <button className="button" onClick={() => handleSelectModeDrag(node, 'copy')}>
            <img src={CopyWhite} alt="copy-icon" />
            コピー
          </button>
        </div>
      </DivCustomCanDrag>
    )
  ) : null;
};

export default TooltipRowContent;
