import yup from '../yup';

export const CreateAuthorityMasterSchema = yup.object().shape({
  name: yup.string().required().max(120),
});

export const CreateEditJobTitleSchema = yup.object().shape({
  name: yup.string().required('※役職名称が未入力です').max(120),
});

export const createUserMasterSchema = yup.object().shape({
  employee_code: yup.string().required(),
  name: yup.string().required(),
  name_furigana: yup.string().required(),
  email: yup.string().email().required(),
  email_confirm: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email'), null], 'Email confirmation is not the same as email'),
  password: yup.string().required().max(30).min(4),
  password_confirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30)
    .min(4),
  role_code: yup.string().required(),
  main_position: yup.object({
    affiliation_id: yup.string().required(),
    positions_id: yup.string().required(),
  }),
  sub_position: yup.array().of(
    yup.object().shape({
      affiliation_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.positions_id && value) ||
            (!value && !ctx.parent.positions_id) ||
            (value && !ctx.parent.positions_id)
        ),
      positions_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.affiliation_id && value) ||
            (!ctx.parent.affiliation_id && !value) ||
            (value && !ctx.parent.affiliation_id)
        ),
    })
  ),
});

export const updateUserSchema = yup.object().shape({
  employee_code: yup.string().required(),
  name: yup.string().required(),
  name_furigana: yup.string().required(),
  role_code: yup.string().required(),
  dob: yup.string(),
  doj: yup.string(),
  main_position: yup.object().shape({
    position_id: yup.string().required(),
    affiliation_id: yup.string().required(),
    positions_code: yup.string().required(),
  }),
});

export const administratorMasterSchema = yup.object().shape({
  name: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '法人名100以内で入力してください。'),
  name_furigana: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '法人名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  postal_code: yup
    .string()
    .required('※正しく入力してください')
    .min(7, '郵便番号7以内で入力してください。')
    .max(7, '郵便番号7以内で入力してください。'),
  prefectures: yup
    .string()
    .required('※正しく入力してください')
    .max(4, '都道府県4以内で入力してください。'),
  municipalities: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '市区町村100以内で入力してください。'),
  address: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '番地100以内で入力してください。'),
  building_name: yup.string().max(100, '建物名・部屋番号100以内で入力してください。'),
  admin_name: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '管理者氏名100以内で入力してください。'),
  admin_name_furigana: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '管理者氏名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  admin_department: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '部署100以内で入力してください。'),
  admin_position: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '役職100以内で入力してください。'),
  admin_phone: yup
    .string()
    .required('※正しく入力してください')
    .min(10, '管理者電話番号11以内で入力してください。')
    .max(11, '管理者電話番号11以内で入力してください。'),
  admin_email: yup
    .string()
    .required('※メールアドレスを正しく入力してください（半角英数字のみ）')
    .max(100, '役職100以内で入力してください。'),
  admin_email_confirm: yup
    .string()
    .required('※メールアドレスを正しく入力してください（半角英数字のみ）')
    .oneOf([yup.ref('admin_email'), null], 'パスワードが一致しません')
    .max(100, '役職100以内で入力してください。'),
});
