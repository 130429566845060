import React from 'react';
import { DualAxes } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';

const { Option } = Select;

const GraphExpansion: React.FC = () => {
  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">売上種類別売上推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>売上種類選択：</label>
                  <Select>
                    <Option> ALL</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：千円</span>
              <DualAxes
                {...config}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                }}
                xAxis={{
                  tickLine: null,
                }}
                label={{
                  position: 'top',
                }}
                legend={{
                  layout: 'horizontal',
                  position: 'top-right',
                  reversed: true,
                  itemName: {
                    formatter: (text: string, item: any) => {
                      return item.value === 'NB'
                        ? 'Churn Rate'
                        : item.value === 'aa'
                        ? '月末登録企業数'
                        : item.value === 'bill'
                        ? '退会企業数'
                        : '新規登録企業数';
                    },
                  },
                }}
                height={550}
                geometryOptions={[
                  {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'type',
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: ['#08A3A5', '#F6AC00', '#C3DC7B'],
                    label: {
                      position: 'top',
                    },
                  },
                  {
                    geometry: 'line',
                    seriesField: 'name',
                    lineStyle: {
                      lineWidth: 2,
                    },
                    isStack: true,
                    point: true,
                    color: '#669CCE',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphExpansion;

const uvBillData = [
  {
    time: '2022/01',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/02',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/03',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/04',
    value: 900,
    type: 'uv',
  },
  {
    time: '2022/05',
    value: 300,
    type: 'uv',
  },
  {
    time: '2022/06',
    value: 450,
    type: 'uv',
  },
  {
    time: '2022/07',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/08',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/09',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/10',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/11',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/12',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/01',
    value: 200,
    type: 'bill',
  },
  {
    time: '2022/02',
    value: 178,
    type: 'bill',
  },
  {
    time: '2022/03',
    value: 220,
    type: 'bill',
  },
  {
    time: '2022/04',
    value: 300,
    type: 'bill',
  },
  {
    time: '2022/05',
    value: 250,
    type: 'bill',
  },
  {
    time: '2022/06',
    value: 220,
    type: 'bill',
  },
  {
    time: '2022/07',
    value: 362,
    type: 'bill',
  },
  {
    time: '2022/08',
    value: 378,
    type: 'bill',
  },
  {
    time: '2022/09',
    value: 238,
    type: 'bill',
  },
  {
    time: '2022/10',
    value: 92,
    type: 'bill',
  },
  {
    time: '2022/11',
    value: 394,
    type: 'bill',
  },
  {
    time: '2022/12',
    value: 287,
    type: 'bill',
  },
  {
    time: '2022/01',
    value: 198,
    type: 'aa',
  },
  {
    time: '2022/02',
    value: 128,
    type: 'aa',
  },
  {
    time: '2022/03',
    value: 280,
    type: 'aa',
  },
  {
    time: '2022/04',
    value: 280,
    type: 'aa',
  },
  {
    time: '2022/05',
    value: 280,
    type: 'aa',
  },
  {
    time: '2022/06',
    value: 280,
    type: 'aa',
  },
  {
    time: '2022/07',
    value: 280,
    type: 'aa',
  },
  {
    time: '2022/12',
    value: 378,
    type: 'aa',
  },
  {
    time: '2022/08',
    value: 408,
    type: 'aa',
  },
  {
    time: '2022/09',
    value: 478,
    type: 'aa',
  },
  {
    time: '2022/10',
    value: 489,
    type: 'aa',
  },
  {
    time: '2022/11',
    value: 509,
    type: 'aa',
  },
];
const transformData = [
  {
    time: '2022/01',
    count: 75,
    name: 'NB',
  },
  {
    time: '2022/02',
    count: 78,
    name: 'NB',
  },
  {
    time: '2022/03',
    count: 80,
    name: 'NB',
  },
  {
    time: '2022/04',
    count: 60,
    name: 'NB',
  },
  {
    time: '2022/05',
    count: 40,
    name: 'NB',
  },
  {
    time: '2022/06',
    count: 38,
    name: 'NB',
  },
  {
    time: '2022/07',
    count: 22,
    name: 'NB',
  },
  {
    time: '2022/08',
    count: 38,
    name: 'NB',
  },
  {
    time: '2022/09',
    count: 29,
    name: 'NB',
  },
  {
    time: '2022/10',
    count: 10,
    name: 'NB',
  },
  {
    time: '2022/11',
    count: 56,
    name: 'NB',
  },
  {
    time: '2022/12',
    count: 78,
    name: 'NB',
  },
];
const config = {
  data: [uvBillData, transformData],
  xField: 'time',
  yField: ['value', 'count'],
  appendPadding: [40, 0, 0, 0],
  meta: {
    count: {
      formatter: (v: number) => {
        return `${Number(v.toFixed(1))}%`;
      },
    },
  },
};
