export const LIST_TITLE_HEADER_INFORMATION_REGISTER = [
  'AJ-Step-1',
  'AJ-Step-2',
  'AJ-Step-3',
  'AJ-Step-4',
  'AJ-Step-5',
];

export const CHECK_BOX_MODAL_CONFIRM_WITHDRAWAL = [
  '利用料金が高いため',
  '機能が不十分なため',
  '動作が重い',
  '別のサービスを利用するため',
  'その他',
];

export const CREDIT_CARD_MONTH = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const LIST_LABEL = ['カリキュラム', '第１階層', '第２階層', '第３階層', '第４階層', '設問'];

export const LIST_TAB_BAR = ['通常モード', '移動＆コピーモード'];

export const MENU_WIDTH = 196;

export const MENU_COLLAPSED_WIDTH = 60;

export const HEADER_HEIGHT = 56;
