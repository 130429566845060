import React from 'react';

import RegisteredInformation from './RegisteredInformation';
import BreakdownOfCompanies from './BreakdownOfCompanies';
import InformationUser from './InformationUser';

interface Props {
  visibleExpansionOfSale: boolean;
  valueSelect?: number;
}

const Table: React.FC<Props> = ({ visibleExpansionOfSale, valueSelect }) => {
  return valueSelect === 0 ? (
    <BreakdownOfCompanies visibleExpansionOfSale={visibleExpansionOfSale} />
  ) : valueSelect === 1 ? (
    <RegisteredInformation visibleExpansionOfSale={visibleExpansionOfSale} />
  ) : (
    <InformationUser visibleExpansionOfSale={visibleExpansionOfSale} />
  );
};

export default Table;
