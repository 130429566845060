import { createSlice } from '@reduxjs/toolkit';

import { getAdminRoleDetail, getAdminRoleList, getSelectAdminRole } from './thunk';
import * as Types from 'types';

export interface InitialState {
  listAuthority: Types.Roles.ResponseType[];
  searchResult: Types.Roles.ResponseType[];
  userRole: Types.AdminRoles.ResponseType[];
  itemEdit?: Types.Roles.ResponseType;
  loading: boolean;
  total: number;
}

const initialState: InitialState = {
  listAuthority: [],
  searchResult: [],
  userRole: [],
  loading: false,
  total: 0,
};

export const authorityMasterSlice = createSlice({
  name: 'authorityMaster-slice',
  initialState,
  reducers: {
    resetSearch(state) {
      state.searchResult = [];
    },
  },
  extraReducers(builder) {
    // const startLoading = (state: InitialState) => {
    //   state.loading = true;
    // };
    // const stopLoading = (state: InitialState) => {
    //   state.loading = false;
    // };

    builder.addCase(getAdminRoleList.fulfilled, (state, action) => {
      state.listAuthority = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getAdminRoleDetail.fulfilled, (state, action) => {
      state.itemEdit = action.payload.items[0];
    });

    builder.addCase(getSelectAdminRole.fulfilled, (state, action) => {
      state.userRole = action.payload.report_results;
    });
  },
});

export const { resetSearch } = authorityMasterSlice.actions;

export default authorityMasterSlice.reducer;
