import { createAsyncThunk } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import * as Types from 'types';
import {
  SELECT_CURRICULUM_CREATOR,
  SELECT_CURRICULUMS,
  QUESTION_SEARCH,
  SELECT_QUESTION,
  SELECT_TYPES,
  SYSTEMMASTER,
  COMPANIES,
  QUESTIONS,
  ATTACH,
} from 'configs';

export const getDataQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearch.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.QuestionSearch.ResponseType>(
      QUESTION_SEARCH.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseSelectType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseSelectType>(
      SELECT_CURRICULUMS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.CreateEditSkillCheckSelectType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SELECT_TYPES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculumCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumCreatorSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculumCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumCreatorSelect>(
      SELECT_CURRICULUM_CREATOR.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.QuestionSelect>(SELECT_QUESTION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileQuestion = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFileQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataAllQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSystemmaster = createAsyncThunk<
  Types.GetItemResponseType<Types.GetSystemmasterType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSystemmaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SYSTEMMASTER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSystemmaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseUpdateSystemmasterType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateSystemmaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SYSTEMMASTER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTimeLimitCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateTimeLimitCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getCompany', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search(COMPANIES.id, req);

    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
