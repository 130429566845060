import React, { useEffect, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Image as ImageAntd, Image } from 'antd';
import { Form } from 'formik-antd';

import { handleGetFileQuestionDetail, handleGetQuestionDetail } from 'libs/utils/question';
import { Input, Modal, TextArea } from 'components';
import { useAppDispatch } from 'hooks';
import { NoImage2 } from 'assets';
import * as Types from 'types';
import Styled from './styles';

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  code: string | undefined;
  visible: boolean;
};

const CurriculumDetail: React.FC<Props> = ({ visible, setVisible, code }) => {
  const vidRef = useRef<HTMLVideoElement>(null);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit: '',
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attach_fileID: [],
      comments_attach_fileID: [],
      problems_attach_fileID: [],
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values) => {},
  });

  const closeModal = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (!visible) return;

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      type: 'edit',
      i_id: code,
      formik,
    });

    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      i_id: code,
      formik,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        problems_attach_fileID: [],
        comments_attach_fileID: [],
        attach_fileID: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, visible]);

  return (
    <Modal
      open={visible}
      title="設問詳細"
      cancelButton={{
        text: '閉じる',
        onClick: () => closeModal(),
      }}
      width={860}
      onCancel={() => closeModal()}
      footerStyle={{
        background: '#f9f8f8',
      }}
    >
      <Styled>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <p className="label-note">
              <span className="number">1</span>設問内容
            </p>
            <Form.Item
              name="name"
              label={<span className="text-label">設問名</span>}
              className="form-input"
            >
              <Input
                name="name"
                className="input"
                type="text"
                maxLength={30}
                placeholder="全角：最大30文字"
                readOnly={true}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className="text-label">設問内容</span>}
              className="form-input"
            >
              <TextArea
                name="description"
                className="input-textarea"
                maxLength={480}
                placeholder="全角：最大100文字"
                disabled={true}
              />
            </Form.Item>
            <Form.Item name="attach">
              <div className="wrap-imgVid">
                {formik.values?.attach_fileID?.map((e, index) =>
                  !!e ? (
                    <div className="imgVid">
                      <p className="title-file">
                        設問画像・動画 <p className="number">{index + 1}</p>
                      </p>

                      {e.includes('mp4') ? (
                        <React.Fragment key={index}>
                          <video ref={vidRef} width="100%" height={338} controls>
                            <source src={e} type="video/mp4" />
                          </video>
                        </React.Fragment>
                      ) : e.includes('jpeg') || e.includes('png') || e.includes('jpg') ? (
                        <ImageAntd className="image-file" src={e} alt="" />
                      ) : null}
                    </div>
                  ) : (
                    <div className="imgVid">
                      <p className="title-file">
                        設問画像・動画 <p className="number">1</p>
                      </p>
                      <ImageAntd className="image-file" src={NoImage2} alt="" />
                    </div>
                  )
                )}
              </div>
            </Form.Item>
            <p className="label-note">
              <span className="number">2</span>解答選択肢
            </p>
            <Form.Item name="problems1" label="選択肢A">
              <div className="wrap-option">
                <TextArea
                  name="problems1"
                  className="input-textarea-small"
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                  disabled={true}
                />
                {formik.values.problems_attach_fileID?.length &&
                formik.values.problems_attach_fileID[0] ? (
                  <div className="imgVid">
                    {formik.values.problems_attach_fileID[0].includes('mp4') ? (
                      <React.Fragment key={1}>
                        <video ref={vidRef} width="100%" height={338} controls>
                          <source src={formik.values.problems_attach_fileID[0]} type="video/mp4" />
                        </video>
                      </React.Fragment>
                    ) : formik.values.problems_attach_fileID[0].includes('jpeg') ||
                      formik.values.problems_attach_fileID[0].includes('png') ? (
                      <ImageAntd
                        className="image-file"
                        src={formik.values.problems_attach_fileID[0]}
                        alt=""
                      />
                    ) : null}
                  </div>
                ) : (
                  <Image src={NoImage2} className="no-image" />
                )}
              </div>
            </Form.Item>
            <Form.Item name="problems2" label="選択肢B">
              <div className="wrap-option">
                <TextArea
                  name="problems2"
                  className="input-textarea-small"
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                  disabled={true}
                />
                {formik.values.problems_attach_fileID?.length &&
                formik.values.problems_attach_fileID[1] ? (
                  <div className="imgVid">
                    {formik.values.problems_attach_fileID[1].includes('mp4') ? (
                      <React.Fragment key={2}>
                        <video ref={vidRef} width="100%" height={338} controls>
                          <source src={formik.values.problems_attach_fileID[1]} type="video/mp4" />
                        </video>
                      </React.Fragment>
                    ) : formik.values.problems_attach_fileID[1].includes('jpeg') ||
                      formik.values.problems_attach_fileID[1].includes('png') ? (
                      <ImageAntd
                        className="image-file"
                        src={formik.values.problems_attach_fileID[1]}
                        alt=""
                      />
                    ) : null}
                  </div>
                ) : (
                  <Image src={NoImage2} className="no-image" />
                )}
              </div>
            </Form.Item>
            <Form.Item name="problems3" label="選択肢C">
              <div className="wrap-option">
                <TextArea
                  name="problems3"
                  className="input-textarea-small"
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                  disabled={true}
                />
                {formik.values.problems_attach_fileID?.length &&
                formik.values.problems_attach_fileID[2] ? (
                  <div className="imgVid">
                    {formik.values.problems_attach_fileID[2].includes('mp4') ? (
                      <React.Fragment key={3}>
                        <video ref={vidRef} width="100%" height={338} controls>
                          <source src={formik.values.problems_attach_fileID[2]} type="video/mp4" />
                        </video>
                      </React.Fragment>
                    ) : formik.values.problems_attach_fileID[2].includes('jpeg') ||
                      formik.values.problems_attach_fileID[2].includes('png') ? (
                      <ImageAntd
                        className="image-file"
                        src={formik.values.problems_attach_fileID[2]}
                        alt=""
                      />
                    ) : null}
                  </div>
                ) : (
                  <Image src={NoImage2} className="no-image" />
                )}
              </div>
            </Form.Item>
            <Form.Item name="answer" label="解答">
              <Input
                name="answer"
                className="input-answer"
                type="text"
                maxLength={30}
                placeholder="全角：最大30文字"
                readOnly={true}
              />
            </Form.Item>
            <p className="label-note">
              <span className="number">3</span>解説・その他設定
            </p>
            <Form.Item name="comment" label="設問内容">
              <TextArea
                name="comment"
                className="input-textarea comment"
                disabled={true}
                maxLength={1050}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item name="images">
              <div className="wrap-imgVid">
                {formik.values.comments_attach_fileID?.map((e, index) =>
                  !!e ? (
                    <div className="imgVid">
                      <p className="title-file">
                        設問画像・動画 <p className="number">{index + 1}</p>
                      </p>

                      {e.includes('mp4') ? (
                        <React.Fragment key={index}>
                          <video ref={vidRef} width="100%" height={338} controls>
                            <source src={e} type="video/mp4" />
                          </video>
                        </React.Fragment>
                      ) : e.includes('jpeg') || e.includes('png') || e.includes('jpg') ? (
                        <ImageAntd className="image-file" src={e} alt="" />
                      ) : null}
                    </div>
                  ) : (
                    <div className="imgVid">
                      <p className="title-file">
                        設問画像・動画 <p className="number">1222</p>
                      </p>
                      <ImageAntd className="image-file" src={NoImage2} alt="" />
                    </div>
                  )
                )}
              </div>
            </Form.Item>
            <Form.Item name="time_limit" label="制限時間">
              <div className="time-limit">
                <Input
                  name="time_limit"
                  className="input-answer"
                  type="text"
                  maxLength={30}
                  placeholder="全角：最大30文字"
                  readOnly={true}
                />
                <span className="description">
                  ※トレーニング用に設定している、設問の制限時間です。
                </span>
              </div>
            </Form.Item>
            <Form.Item name="score" label="スコア設定（設問難易度の設定）">
              <Input
                name="score"
                className="input-answer"
                type="text"
                maxLength={30}
                placeholder="全角：最大30文字"
                readOnly={true}
              />
            </Form.Item>
          </Form>
        </FormikProvider>
      </Styled>
    </Modal>
  );
};

export default CurriculumDetail;
