import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import CurriculumService from './curriculum.service';
import { instance, instanceMock } from 'libs';
import UserService from './users.service';
import { config } from 'configs';
import * as Types from 'types';

class Service {
  protected _axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this._axios = axios;
  }

  filter<T>(
    dataStoreId: string,
    data: Types.ReportsItemRequestType
  ): AxiosPromise<Types.ReportsItemResponseType<T>> {
    const request: AxiosRequestConfig<Types.ReportsItemRequestType> = {
      url: `applications/${config.APP_ID}/reports/${dataStoreId}/filter`,
      method: 'POST',
      data: {
        ...data,
        omit_total_items: data.omit_total_items ?? true,
        omit_fields_data: data.omit_fields_data ?? true,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }

  search(
    dataStoreId: string,
    data: Types.GetItemRequestType
  ): AxiosPromise<Types.GetItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/search`,
      method: 'POST',
      data: {
        ...data,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }

  create<T>(
    dataStoreId: string,
    data: Types.CreateItemRequestType<T>
  ): AxiosPromise<Types.CreateItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/new`,
      method: 'POST',
      data: {
        ...data,
        realtime_auto_link: true,
      },
    };

    return this._axios(request);
  }

  update<T>(
    dataStoreId: string,
    { id, data }: Types.UpdateItemRequestType<T>
  ): AxiosPromise<Types.UpdateItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/edit/${id}`,
      method: 'POST',
      data: {
        ...data,
        realtime_auto_link: true,
      },
    };

    return this._axios(request);
  }

  delete(
    dataStoreId: string,
    { id, data = {} }: Types.DeleteItemRequestType
  ): AxiosPromise<Types.DeleteItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/delete/${id}`,
      method: 'DELETE',
      data,
    };

    return this._axios(request);
  }

  filterConditions(
    dataStoreId: string,
    data: Types.ReportsItemRequestType
  ): AxiosPromise<Types.ReportsItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/reports/${dataStoreId}/conditions`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  addUserAdmin(data: Types.AddUserAdminRequest): AxiosPromise<Types.AddUserAdminResponse> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/functions/users`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  fields({
    dataStoreId,
  }: Types.GetDatastoreFieldsRequestType): AxiosPromise<Types.GetDatastoreFieldsResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/fields`,
      method: 'GET',
    };

    return this._axios(request);
  }

  detail(
    dataStoreId: string,
    {
      id,
      format,
      include_linked_items,
      include_lookups,
      return_number_value,
      use_display_id,
    }: Types.GetItemDetailRequestType
  ): AxiosPromise {
    const params = [];
    if (format) {
      params.push('format=map');
    }
    if (include_linked_items) {
      params.push('include_linked_items=true');
    }
    if (include_lookups) {
      params.push('include_lookups=true');
    }
    if (return_number_value) {
      params.push('return_number_value=true');
    }
    if (use_display_id) {
      params.push('use_display_id=true');
    }
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/details/${id}${
        params.length > 0 ? `?${params.join('&')}` : ''
      }`,
      method: 'GET',
    };
    return this._axios(request);
  }

  uploadFile({
    itemId,
    fieldId,
    data,
    onProgress,
  }: Types.UploadFileRequestType): AxiosPromise<Types.UploadFileResponseType> {
    const request: AxiosRequestConfig = {
      url: `items/${itemId}/fields/${fieldId}/attachments`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    };

    return this._axios(request);
  }

  executeFunction(
    functionId: string,
    { data }: Types.ExecuteFunctionRequestType
  ): AxiosPromise<Types.ExecuteFunctionResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/functions/${functionId}`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  executeAction(
    actionId: string,
    dataStoreId: string,
    { data, itemId }: Types.ExecuteActionRequestType
  ): AxiosPromise<Types.ExecuteActionResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/action/${itemId}/${actionId}`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  updateAttachmentTable(
    actionId: string,
    dataStoreId: string,
    { data, itemId }: Types.updateAttachTableRequestType
  ): AxiosPromise<any> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/action/${itemId}/${actionId}`,
      method: 'POST',
      data,
    };
    return this._axios(request);
  }

  uploadFileToS3({
    formData,
  }: Types.UploadFileToS3RequestType): AxiosPromise<Types.UploadFileToS3ResponseType> {
    const request: AxiosRequestConfig = {
      url: 'files',
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
    };

    return this._axios(request);
  }

  getFile({ file_id }: Types.GetFileRequestType): AxiosPromise<ArrayBuffer> {
    const request: AxiosRequestConfig = {
      url: `files/${file_id}`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'content-type': 'text/plain',
      },
    };

    return this._axios(request);
  }

  deleteFile({ file_id }: Types.DeleteFileRequestType): AxiosPromise<null> {
    const request: AxiosRequestConfig = {
      url: `files/${file_id}`,
      method: 'DELETE',
    };

    return this._axios(request);
  }

  createNewFile(
    data: Types.createNewFileRequestType
  ): AxiosPromise<Types.createNewFileResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/attach/items/new`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }
  deleteItemByConditions(
    dataStoreId: string,
    data: Types.DeleteItemByConditionsRequestType
  ): AxiosPromise<Types.DeleteItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/${dataStoreId}/items/delete`,
      method: 'DELETE',
      data,
    };

    return this._axios(request);
  }
  filterPartner<T>(
    dataStoreId: string,
    data: Types.ReportsItemRequestType
  ): AxiosPromise<Types.ReportsItemResponseType<T>> {
    const request: AxiosRequestConfig<Types.ReportsItemRequestType> = {
      url: `applications/${config.APP_ID_PARTNER}/reports/${dataStoreId}/filter`,
      method: 'POST',
      data: {
        ...data,
        omit_total_items: data.omit_total_items ?? true,
        omit_fields_data: data.omit_fields_data ?? true,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }
}

export const services = new Service(instance);
export const servicesMock = new Service(instanceMock);
export const userService = new UserService(instance);
export const curriculumService = new CurriculumService(instance);
