import styled from 'styled-components';

export default styled.section`
  padding: 0 60px 0 60px;
  font-family: Noto Sans Javanese;
  label {
    font-size: 13px;
  }
  .box {
    background: #ffffff;
    border: 1px solid rgba(204, 204, 204, 0.4);
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    color: #424242;
    font-weight: 400;
  }
  .box-textarea {
    height: 90px;
    padding: 8px 17px;
  }
  .question-name {
    padding: 12px;
  }
  .time-limit {
    display: flex;
    align-items: center;
    .description {
      padding-left: 20px;
      font-size: 12px;
      color: #777777;
    }
  }
  .label-note {
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 60px;
    .number {
      width: 19px;
      height: 19px;
      background-color: #424242;
      font-family: 'Lato';
      font-size: 11px;
      color: #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 5px;
    }
    &:nth-of-type(1) {
      margin-top: 21px;
    }
  }
  .other-setting {
    margin-bottom: 0 !important;
  }
  .wrap-imgVid {
    background: #ffffff;
    border: 1px solid rgba(204, 204, 204, 0.4);
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    padding: 13px 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 41px;
    .imgVid {
      width: 32.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title-file {
      color: #777777;
      font-size: 12px;
      display: flex;
      align-items: center;
      .number {
        border: 1px solid #777777;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
  .image-file {
    height: 100px !important;
  }
  .wrap-option {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid rgba(204, 204, 204, 0.4);
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    padding: 8px 8px 8px 12px;
    .no-image {
      width: 160px;
    }
    .input-textarea-small {
      border: none;
      background: #ffffff;
    }
    .input-answer {
      width: 40%;
    }
  }
  .answer-content {
    background: #ffffff;
    border: 1px solid rgba(204, 204, 204, 0.4);
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    width: 30%;
    padding: 10px 0;
    display: grid;
    place-items: center;
    margin-right: 8px;
  }
  .ant-input-disabled {
    background: #ffffff;
    border-color: #eaeaea;
    color: #2a2a2a;
  }
  .ant-form .ant-input-affix-wrapper-readonly {
    background: #ffffff;
  }
  .ant-form .ant-input-affix-wrapper-readonly.input-answer {
    width: 40%;
  }
  .input-textarea.comment {
    height: 90px;
  }
`;
