import styled from 'styled-components';

export default styled.div`
  padding: 0 20px;
  .description {
    font-size: 14px;
    color: #424242;
    text-align: center;
  }
  .text-area {
    height: 120px;
  }
  .wrap-text-area {
    display: flex;
    justify-content: space-between;
  }
  .label-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .wrap-enlarge {
    display: flex;
  }
  .text-enlarge {
    color: #00989a;
    font-size: 12px;
    text-decoration: underline;
  }
  .form {
    label {
      width: 100%;
      color: #777777;
      font-size: 12px;
    }
  }
  .icon-enlarge {
    color: #00989a;
    font-size: 13px;
    margin-right: 4px;
  }
  .form {
    margin-bottom: 32px;
  }
  .wrap-title-table {
    display: flex;
    margin-top: 32px;
    margin-bottom: 9px;
  }
  .dot {
    margin-right: 4px;
  }
  .text-table {
    font-size: 15px;
    color: #424242;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
  .ant-table-wrapper .ant-table-thead .ant-table-cell {
    font-size: 12px;
    font-weight: 700;
  }
  .count {
    text-align: right;
    color: #8c8c8c;
    margin: 0;
  }
  .table {
    max-height: 200px;
    overflow: scroll;
    .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
`;
