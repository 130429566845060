import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import * as Types from 'types';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import {
  ADMIN_REPORT_STORAGE_MANAGEMENT,
  OFFICIAL_CURRICULUM_HIERARCHY_LIST,
  STORAGE_MANAGEMENT,
} from 'configs';

export const getDataTableStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.TotalStorage>,
  Types.ReportsItemRequestType & { type: number; corporateUser?: string },
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataTableStorage', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    dispatch(startLoading());
    let data = [] as unknown as Types.ReportsItemResponseType<Types.StorageManagement.ResponseType>;
    if (req.corporateUser !== 'partner') {
      const dataAdmin = await services.filter<Types.StorageManagement.ResponseType>(
        STORAGE_MANAGEMENT.name,
        req
      );
      data = dataAdmin.data;
    }

    const dataPartner = await services.filterPartner<Types.StorageManagement.ResponseType>(
      ADMIN_REPORT_STORAGE_MANAGEMENT.id,
      {
        ...req,
      }
    );

    const groupByExtension = groupBy(
      [...(data.report_results ?? []), ...dataPartner.data.report_results],
      ({ file_extension, file_location }) => {
        if (req.type === 1) {
          if (file_extension?.includes('image')) {
            return '画像';
          }
          if (file_extension?.includes('mp4')) {
            return '動画';
          }
          if (file_extension?.includes('sheet') || file_extension?.includes('excel')) {
            return 'Excel';
          }
          if (
            file_extension?.includes('ms-doc') ||
            file_extension?.includes('doc') ||
            file_extension?.includes('document')
          ) {
            return 'Word';
          }
          if (file_extension?.includes('ms-powerpoint')) {
            return 'PowerPoint';
          }
        }

        if (req.type === 2) {
          return file_location === '1'
            ? 'カリキュラムマスタ'
            : file_location === '2'
            ? '設問マスタ'
            : file_location === '3'
            ? 'スキルチェックマスタ'
            : file_location === '4'
            ? 'マニュアル'
            : file_location === '5'
            ? 'ナレッジ'
            : 'プロフィール';
        }
      }
    );

    const totalByExtension = Object.keys(groupByExtension).map((key) => {
      const groupBydate = groupBy(groupByExtension[key], (e) =>
        dayjs(e?.createdAt).format('YYYY/MM')
      );

      const total = Object.keys(groupBydate).map((date) => {
        return groupBydate[date].reduce(
          (result, item) => {
            return {
              date: date,
              total: result.total + Number(item.file_size),
              ...(req.type === 1 ? { file_extension: key } : { file_location: key }),
            };
          },
          {
            date: '',
            total: 0,
            ...(req.type === 1 ? { file_extension: '' } : { file_location: '' }),
          }
        );
      });

      return {
        ...(req.type === 1 ? { file_extension: key } : { file_location: key }),
        ...Object.fromEntries(total.map((item) => [item.date, item.total])),
      };
    });

    const filterData = totalByExtension.filter((e) => {
      if (req.type === 1 && 'file_extension' in e) return e.file_extension !== 'undefined';
      if (req.type === 2 && 'file_location' in e) return e.file_location !== 'undefined';
    });

    const groupByDate = groupBy(
      [...(data.report_results ?? []), ...dataPartner.data.report_results],
      ({ createdAt }) => dayjs(createdAt).format('YYYY/MM')
    );

    const dataAll = Object.keys(groupByDate).map((key) => {
      return groupByDate[key].reduce(
        (result, item) => {
          return { ...result, total: result.total + Number(item.file_size) || 0 };
        },
        {
          date: key,
          total: 0,
        }
      );
    });

    dispatch(stopLoading());
    return {
      ...data,
      report_results: [
        {
          ...Object.fromEntries(dataAll.map((item) => [item.date, item.total * 10])),
          ...(req.type === 1 ? { file_extension: 'storage' } : { file_location: 'storage' }),
        },
        {
          ...Object.fromEntries(dataAll.map((item) => [item.date, item.total])),
          ...(req.type === 1 ? { file_extension: 'ALL' } : { file_location: 'ALL' }),
        },
        ...filterData,
      ],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTableFile = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('storageManagement/thunk/getDataTableFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_HIERARCHY_LIST.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.SelectStorage>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getSelectStorage', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.filter<Types.StorageManagement.ResponseType>(
      STORAGE_MANAGEMENT.name,
      req
    );

    const dataPartner = await services.filterPartner<Types.StorageManagement.ResponseType>(
      ADMIN_REPORT_STORAGE_MANAGEMENT.id,
      req
    );

    const groupByLocation = groupBy(
      [...data.report_results, ...dataPartner.data.report_results].filter(
        (e) => e.file_extension && e.file_size
      ),
      'file_location'
    );
    console.log('location: ', groupByLocation);
    const dataByLocation = Object.keys(groupByLocation).map((key) => {
      return groupByLocation[key].reduce(
        (result, item) => {
          return {
            location:
              key === '1'
                ? 'カリキュラムマスタ'
                : key === '2'
                ? '設問マスタ'
                : key === '3'
                ? 'スキルチェックマスタ'
                : key === '4'
                ? 'マニュアル'
                : key === '5'
                ? 'ナレッジ'
                : 'プロフィール',
            image: item.file_extension.includes('image')
              ? Number((result.image + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.image) || 0) / 1073741824).toFixed(4)),
            video: item.file_extension.includes('video')
              ? Number((result.video + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.video) || 0) / 1073741824).toFixed(4)),
            excel: item.file_extension.includes('excel')
              ? Number((result.excel + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.excel) || 0) / 1073741824).toFixed(4)),
            word: item.file_extension.includes('word')
              ? Number((result.word + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.word) || 0) / 1073741824).toFixed(4)),
            pdf: item.file_extension.includes('pdf')
              ? Number((result.pdf + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.pdf) || 0) / 1073741824).toFixed(4)),
            power_point: item.file_extension.includes('power')
              ? Number((result.power_point + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number((Number(result.power_point || 0) / 1073741824).toFixed(4)),
            other: !['image', 'video', 'excel', 'word', 'pdf', 'power'].includes(
              item.file_extension
            )
              ? Number((result.other + (Number(item.file_size) || 0) / 1073741824).toFixed(4))
              : Number(((Number(result.other) || 0) / 1073741824).toFixed(4)),
          };
        },
        {
          location: '',
          image: 0,
          video: 0,
          excel: 0,
          word: 0,
          pdf: 0,
          power_point: 0,
          other: 0,
        }
      );
    });

    console.log('location 1312312: ', dataByLocation);

    const totalByLocation = dataByLocation.map((e) => ({
      ...e,
      total: e.image + e.video + e.excel + e.word + e.pdf + e.power_point + e.other,
    }));
    const total = totalByLocation.reduce(
      (result, item) => {
        return {
          location: result.location,
          image: Number((result.image + item.image).toFixed(4)),
          video: Number((result.video + item.video).toFixed(4)),
          excel: Number((result.excel + item.excel).toFixed(4)),
          word: Number((result.word + item.word).toFixed(4)),
          pdf: Number((result.pdf + item.pdf).toFixed(4)),
          power_point: Number((result.power_point + item.power_point).toFixed(4)),
          other: Number((result.other + item.other).toFixed(4)),
        };
      },
      {
        location: 'Total',
        image: 0,
        video: 0,
        excel: 0,
        word: 0,
        pdf: 0,
        power_point: 0,
        other: 0,
      }
    );

    const dataSelect = {
      filename: [...data.report_results, ...dataPartner.data.report_results].map((e) => e.filename),
      file_extension: [...data.report_results, ...dataPartner.data.report_results].map(
        (e) => e.file_extension
      ),
      file_location: [...data.report_results, ...dataPartner.data.report_results].map(
        (e) => e.file_location
      ),
      dataTableFile: [
        ...totalByLocation,
        {
          ...total,
          total:
            total.image +
            total.video +
            total.excel +
            total.word +
            total.pdf +
            total.power_point +
            total.other,
        },
      ],
    };
    dispatch(stopLoading());
    return { ...data, report_results: [dataSelect] };
  } catch (error) {
    return rejectWithValue(error);
  }
});
