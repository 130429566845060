import React from 'react';

import CorrespondingUnChecked from './CorrespondingUnChecked';
import Corresponding from './Corresponding';

interface Props {
  visibleCorrespondingSale: boolean;
}

const Chart: React.FC<Props> = ({ visibleCorrespondingSale }) => {
  return visibleCorrespondingSale ? <Corresponding /> : <CorrespondingUnChecked />;
};

export default Chart;
