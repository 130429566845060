import yup from '../yup';

export const modalPartnerSchemaStart = yup.object().shape({
  start_date: yup.string().required('企業情報・管理者情報未入力。').nullable(),
  end_date: yup.string().required('企業情報・管理者情報未入力。').nullable(),
});

export const modalPartnerSchemaEnd = yup.object().shape({
  start_date: yup.string().required('企業情報・管理者情報未入力。').nullable(),
});
