import React, { useEffect, useState } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Column, Line } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';
import dayjs from 'dayjs';

import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import Styled from './styles';
import {
  getDataAggregationChurnRate,
  getDataNumberOfCorporateUsers,
  getDataNumberOfIndividualUsers,
  getDataNumberOfPartnerCompanies,
} from '../thunk';

const { Option } = Select;

const UserInfomation: React.FC = () => {
  const [visibleChart, setVisibleChart] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const {
    numOfCorporateUsers,
    numOfIndividualUsers,
    numOfPartnerCompanies,
    dataUserTransitionColumnChart,
    dataUserTransitionLineChart,
    dataUserTransitionColumnChartActive,
    dataUserTransitionLineChartActive,
    dataEndOfMonthUsersColumnChart,
    dataEndOfMonthUsersActiveColumnChart,
  } = useSelector(dashboardSelector);

  const dispatch = useAppDispatch();

  const configColumnBlue = {
    data: checked ? dataEndOfMonthUsersActiveColumnChart : dataEndOfMonthUsersColumnChart,
    isGroup: true,
    xField: 'target_month',
    yField: 'value',
    legend: {
      position: 'top-left',
      offsetY: -5,
    },
  };

  const configColumn = {
    data: !checked ? dataUserTransitionColumnChart : dataUserTransitionColumnChartActive,
    isGroup: true,
    legend: true,
    xField: 'target_month',
    yField: 'value',
    seriesField: 'name',
  };
  const config = {
    data: !checked ? dataUserTransitionLineChart : dataUserTransitionLineChartActive,
    xField: 'target_month',
    yField: 'value',
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#BAC831',
        stroke: '#BAC831',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataNumberOfCorporateUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                  not_match: true,
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
          dispatch(
            getDataNumberOfIndividualUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
          dispatch(
            getDataNumberOfPartnerCompanies({
              conditions: [
                {
                  id: 'id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'forced_withdrawal_flg',
                  search_value: ['1'],
                  not_match: true,
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(
      getDataAggregationChurnRate({
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <TeamOutlined className="icon" /> ユーザー情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイムユーザー数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">法人ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">{numOfCorporateUsers}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">個人ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">{numOfIndividualUsers}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">パートナー企業数</span>
                <div className="wrap-number">
                  <span className="number">{numOfPartnerCompanies}</span>
                  <span className="unit"> 社</span>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">ユーザー推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>グラフ種類：</label>
                  <Select
                    defaultValue={false}
                    onChange={(value: boolean) => setVisibleChart(value)}
                    className="select-user"
                  >
                    <Option value={false}> 退会率＆入退会者数推移</Option>
                    <Option value={true}>月末ユーザー数推移</Option>
                  </Select>
                </div>
                <div className="checkbox">
                  <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                  <span className="text">アクティブユーザーのみ</span>
                </div>
              </div>
            </div>
            {!visibleChart ? (
              <div className="chart">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">Churn Rate</span>
                  </div>
                </div>
                <Line
                  className="line-chart"
                  {...config}
                  lineStyle={{
                    stroke: '#BAC831',
                  }}
                  xAxis={{
                    label: null,
                  }}
                  legend={{
                    position: 'top-right',
                  }}
                  yAxis={{
                    label: {
                      formatter: (text) => `${text} %`,
                    },
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                  }}
                  animation={!loading}
                />
                <Column
                  className="column-chart"
                  {...configColumn}
                  legend={{
                    position: 'top-right',
                  }}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                    label: {
                      formatter: (text) => dayjs(text).format('YYYY/MM'),
                    },
                  }}
                  padding={[50, 0]}
                  maxColumnWidth={25}
                  dodgePadding={4}
                  color={['#08A3A5', '#F6AC00']}
                  label={{
                    position: 'top',
                  }}
                  animation={!loading}
                />
              </div>
            ) : (
              <div className="chart">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">月末ユーザー数</span>
                  </div>
                </div>
                <div className="item-chart">
                  <span className="unit">単位：人</span>
                  <Column
                    className="column-chart"
                    {...configColumnBlue}
                    legend={{
                      position: 'top-right',
                    }}
                    yAxis={{
                      grid: {
                        line: {
                          style: {
                            lineDash: [2, 2],
                            stroke: '#ddd',
                          },
                        },
                      },
                      label: {
                        formatter: (text) => `${text}`,
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                      label: {
                        formatter: (text) => dayjs(text).format('YYYY/MM'),
                      },
                    }}
                    padding={[50, 0]}
                    maxColumnWidth={25}
                    dodgePadding={4}
                    color="#1D89C6"
                    label={{
                      position: 'top',
                    }}
                    animation={!loading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default UserInfomation;
