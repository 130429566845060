import styled from 'styled-components';

import * as Types from 'types';

type Props = { nodeHeight: number; node?: Types.TreeItem<Types.CurriculumItemType> };

export const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.nodeHeight}px;

  .rst__nodeContent {
    width: ${(props) => (!props.node || !props.node.parentNode ? '100%' : '70%')};
  }
`;
