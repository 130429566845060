import { omit } from 'lodash';

import * as Types from 'types';

const walkDescendants = ({
  callback,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  currentLineIndex,
  columnIndex = 0,
}: Types.WalkDescendantsType<Types.CurriculumItemType>): {
  childIndex: number | false;
  childLineIndex: number | false;
} => {
  const selfPath = isPseudoRoot ? [] : [...path, Number(currentIndex)];
  const selfInfo = isPseudoRoot
    ? null
    : {
        node,
        parentNode,
        path: selfPath,
        lineIndex: currentLineIndex,
        columnIndex,
      };

  if (!isPseudoRoot) {
    const callbackResult = callback(selfInfo);

    // Cut walk short if the callback returned false
    if (callbackResult === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  // Return self on nodes with no children or hidden children
  if (!node.children) {
    return { childIndex: currentIndex, childLineIndex: currentLineIndex };
  }

  // Get all descendants
  let childIndex = currentIndex;
  let childLineIndex = currentLineIndex;

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    const result = walkDescendants({
      callback,
      node: node.children[i],
      parentNode: isPseudoRoot
        ? null
        : ({
            ...node,
            lineIndex: currentLineIndex,
          } as Types.TreeItem<Types.CurriculumItemType>),
      currentIndex: +childIndex + 1,
      path: selfPath,
      columnIndex: isPseudoRoot ? 0 : columnIndex + 1,
      currentLineIndex:
        selfPath[selfPath.length - 1] !== +childIndex || i > 0 || isPseudoRoot
          ? +childLineIndex + 1
          : childLineIndex,
    });

    childIndex = result.childIndex;
    childLineIndex = result.childLineIndex;
    // Cut walk short if the callback returned false
    if (childIndex === false || childLineIndex === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  return { childIndex, childLineIndex };
};

export const walk = ({ treeData, callback }: Types.WalkType<Types.CurriculumItemType>) => {
  if (!treeData) {
    return;
  }

  walkDescendants({
    callback,
    isPseudoRoot: true,
    node: { children: [treeData] },
    currentIndex: -1,
    currentLineIndex: 0,
    path: [],
    columnIndex: 0,
  });
};

export const getFlatDataFromTree = ({
  treeData,
}: Types.FlatDataFromTreeType<Types.CurriculumItemType>): Array<
  Types.FlatDataItem<Types.CurriculumItemType>
> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatDataItem<Types.CurriculumItemType>> = [];
  walk({
    treeData,
    callback: (nodeInfo: Types.FlatDataItem<Types.CurriculumItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

const convertData = ({
  node,
  item,
}: {
  node: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
}) => {
  if (!node.children || node.columnIndex === 5) {
    return;
  }

  const currentItem = node.children.find((i) =>
    Object.keys(item).some((key) => item[key] === i.code)
  );
  if (!currentItem && node.columnIndex !== undefined) {
    if (
      node.columnIndex < 4 &&
      item.item_ref[`level${node.columnIndex + 1}_code`]?.i_id &&
      (item[`level${node.columnIndex}_code`] === node.code || item.curriculum_code === node.code)
    ) {
      node.children.push({
        i_id: item.item_ref[`level${node.columnIndex + 1}_code`]?.i_id,
        code: item[`level${node.columnIndex + 1}_code`],
        columnIndex: node.columnIndex + 1,
        sort_order: item[`level${node.columnIndex + 1}_sort_order`],
        name: item[`level${node.columnIndex + 1}_name`],
        publish: node.publish,
        required_curriculum: node.required_curriculum,
        children: [],
      });
    } else if (item.level4_code === node.code && item.item_ref.question_name?.i_id) {
      node.children.push({
        i_id: item.item_ref.question_name?.i_id,
        code: item.question_code,
        columnIndex: 5,
        question_assign_level_i_id: item.item_ref.question_sort_order.i_id,
        sort_order: item.question_sort_order,
        publish: node.publish,
        required_curriculum: node.required_curriculum,
        name: item.question_name,
      });
    }
  }

  // Get all descendants
  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertData({
      node: node.children[i],
      item,
    });
  }

  return;
};

export const convertDataFromTree = ({
  treeData,
  item,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
}) => {
  if (!treeData) {
    return {};
  }

  convertData({
    item,
    node: { children: [treeData] },
  });

  return treeData;
};

const childrenItemID = ({
  node,
  callback,
}: {
  node: Types.TreeItem<Types.CurriculumItemType>;
  callback: Function;
}) => {
  if (node.i_id) {
    callback(omit(node, ['children']));
  }

  if (!node.children || node.columnIndex === 5) {
    return;
  }

  // Get all descendants
  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    childrenItemID({
      node: node.children[i],
      callback,
    });
  }

  return;
};

export const getChildrenItemID = ({
  treeData,
  callback,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  callback: Function;
}) => {
  if (!treeData) {
    return;
  }

  childrenItemID({
    callback,
    node: { children: [treeData] },
  });
};

export const getChildrenItemIDFromTree = ({
  treeData,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
}): Array<Types.FlatChildrenItemID<Types.CurriculumItemType>> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatChildrenItemID<Types.CurriculumItemType>> = [];
  getChildrenItemID({
    treeData,
    callback: (nodeInfo: Types.FlatChildrenItemID<Types.CurriculumItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

export const convertDataFromImportFile = ({
  treeData,
  item,
}: {
  treeData: Types.ImportCurriculumType;
  item: any;
}) => {
  if (!treeData) {
    return {};
  }

  convertDataImportFile({
    item,
    node: { children: [treeData] },
  });

  return treeData;
};

const convertDataImportFile = ({ node, item }: { node: Types.ImportCurriculumType; item: any }) => {
  if (!node.children || node.level === 5) {
    return;
  }

  const currentItem = node.children.find((i) =>
    Object.keys(item).some((key) => item[key] === i.code)
  );
  if (!currentItem && node.level !== undefined && node.code) {
    if (
      node.level < 4 &&
      item[`level${node.level + 1}_code`] &&
      (item[`level${node.level}_code`] === node.code || item.curriculum_code === node.code)
    ) {
      node.children.push({
        code: item[`level${node.level + 1}_code`],
        level: node.level + 1,
        name: item[`level${node.level + 1}_name`],
        children: [],
      });
    } else if (item.level4_code === node.code && item.question_code) {
      node.children.push({
        code: item.question_code,
        name: item.question_name,
        description: item.question_description,
        problems1: item.problems1,
        problems2: item.problems2,
        problems3: item.problems3,
        answer: item.answer,
        comment: item.comment,
        time_limit: item.time_limit,
        score: Number(item.score),
        problems1_attach_fileID: item.problems1_attach_fileID,
        problems2_attach_fileID: item.problems2_attach_fileID,
        problems3_attach_fileID: item.problems3_attach_fileID,
        problems1_attach: item.problems1_attach,
        problems2_attach: item.problems2_attach,
        problems3_attach: item.problems3_attach,
        question_attach: item.question_attach,
        question2_attach: item.question2_attach,
        question3_attach: item.question3_attach,
        level: 5,
      });
    }
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertDataImportFile({
      node: node.children[i],
      item,
    });
  }

  return;
};
