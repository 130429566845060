import React from 'react';
import { DualAxes } from '@ant-design/plots';

import Styled from './styles';

const RegisteredUsersPerMonth: React.FC = () => {
  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter yellow">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">増加率</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">全ユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="title">全ユーザー月間最大登録ユーザー数推移</span>
            <div className="chart">
              <div className="column">
                <span className="unit">単位：千円</span>
                <DualAxes
                  {...config}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                  }}
                  legend={false}
                  height={550}
                  geometryOptions={[
                    {
                      geometry: 'column',
                      seriesField: 'type',
                      columnWidthRatio: 0.2,
                      marginRatio: 0.2,
                      color: '#08A3A5',
                      label: {
                        position: 'top',
                        style: {
                          fill: '#08A3A5',
                        },
                      },
                    },
                    {
                      geometry: 'line',
                      seriesField: 'name',
                      lineStyle: {
                        lineWidth: 2,
                      },
                      isStack: true,
                      point: true,
                      color: '#F6AC00',
                      label: {
                        position: 'top',
                        style: {
                          fill: '#F6AC00',
                          style: {
                            fill: '#F6AC00',
                          },
                        },
                      },
                    },
                  ]}
                />
              </div>
              <div className="wrap-header">
                <div className="wrap-action">
                  <div className="legend">
                    <div className="sort-column">
                      <div className="legend-filter green">
                        <div className="circle" />
                        <div className="line" />
                      </div>
                      <span className="legend-name">増加率</span>
                    </div>
                    <div className="sort-column">
                      <div className="legend-filter skyblue" />
                      <span className="legend-name">全ユーザー</span>
                    </div>
                  </div>
                </div>
              </div>
              <span className="title">ユーザー別月間最大登録ユーザー数推移</span>
              <div className="chart">
                <div className="column">
                  <span className="unit">単位：千円</span>
                  <DualAxes
                    {...config}
                    yAxis={{
                      grid: {
                        line: {
                          style: {
                            lineDash: [2, 2],
                            stroke: '#ddd',
                          },
                        },
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                    }}
                    label={{
                      position: 'top',
                    }}
                    legend={false}
                    height={550}
                    geometryOptions={[
                      {
                        geometry: 'column',
                        seriesField: 'type',
                        columnWidthRatio: 0.2,
                        marginRatio: 0.2,
                        color: '#20A7D1',
                        label: {
                          position: 'top',
                          style: {
                            fill: '#20A7D1',
                          },
                        },
                      },
                      {
                        geometry: 'line',
                        seriesField: 'name',
                        lineStyle: {
                          lineWidth: 2,
                        },
                        isStack: true,
                        point: true,
                        color: '#8DC968',
                        label: {
                          position: 'top',
                          style: {
                            fill: '#8DC968',
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default RegisteredUsersPerMonth;

const uvBillData = [
  {
    time: '2022/01',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/02',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/03',
    value: 350,
    type: 'uv',
  },
  {
    time: '2022/04',
    value: 900,
    type: 'uv',
  },
  {
    time: '2022/05',
    value: 300,
    type: 'uv',
  },
  {
    time: '2022/06',
    value: 450,
    type: 'uv',
  },
  {
    time: '2022/07',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/08',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/09',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/10',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/11',
    value: 470,
    type: 'uv',
  },
  {
    time: '2022/12',
    value: 470,
    type: 'uv',
  },
];
const transformData = [
  {
    time: '2022/01',
    count: 75,
    name: 'NB',
  },
  {
    time: '2022/02',
    count: 78,
    name: 'NB',
  },
  {
    time: '2022/03',
    count: 80,
    name: 'NB',
  },
  {
    time: '2022/04',
    count: 60,
    name: 'NB',
  },
  {
    time: '2022/05',
    count: 40,
    name: 'NB',
  },
  {
    time: '2022/06',
    count: 38,
    name: 'NB',
  },
  {
    time: '2022/07',
    count: 22,
    name: 'NB',
  },
  {
    time: '2022/08',
    count: 38,
    name: 'NB',
  },
  {
    time: '2022/09',
    count: 29,
    name: 'NB',
  },
  {
    time: '2022/10',
    count: 10,
    name: 'NB',
  },
  {
    time: '2022/11',
    count: 56,
    name: 'NB',
  },
  {
    time: '2022/12',
    count: 78,
    name: 'NB',
  },
];
const config = {
  data: [uvBillData, transformData],
  xField: 'time',
  yField: ['value', 'count'],
  appendPadding: [40, 0, 0, 0],
  meta: {
    count: {
      formatter: (v: number) => {
        return `${Number(v.toFixed(1))}%`;
      },
    },
  },
  geometryOptions: [
    {
      geometry: 'line',
      seriesField: 'name',
      lineStyle: {
        lineWidth: 2,
      },
    },
    {
      geometry: 'column',
    },
  ],
};
