import styled from 'styled-components';

type Props = { treeActive: boolean };

export const Wrapper = styled.div<Props>`
  .rst__node {
    background-color: ${(props) => (props.treeActive ? '#EB8B55' : '#ffffff')} !important;
    border: 1px solid ${(props) => (props.treeActive ? '#eb8b55' : '#dddddd')} !important;
    height: 40px;
    cursor: pointer;
    .rowTitle {
      color: ${(props) => (props.treeActive ? '#ffffff' : '#2a2a2a')} !important;
      font-size: 13px;
      white-space: nowrap;
    }
    .box_icon {
      background-color: ${(props) => (props.treeActive ? '#ffffff' : null)} !important;
    }
  }
  .tree {
    background-color: #f1f1f1;
    border: 1px solid ${(props) => (props.treeActive ? 'rgba(234, 139, 85, 0.6)' : '#dddddd')};
    border-radius: 4px;
    padding: 16px;
    margin-left: 30px;
    margin-top: 26px;
    position: relative;
    .text-name {
      font-weight: 700;
      font-size: 13px;
      color: #aaaaaa;
      margin: 4px;
    }
    .tree-text {
      font-weight: 700;
      font-size: 13px;
      color: #aaaaaa;
      margin: 0 0 0 5px;
    }
    .wrap_node_tree {
      display: flex;
      flex-wrap: wrap;
      .node-item {
        background: #ffffff;
        border: 1px solid #ffffff;
        width: 24.25%;
        height: 36px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 2px;
        font-size: 11px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        color: #999999;
        margin: 4px 3px;
        cursor: pointer;
        .text-bold {
          margin-left: 5px;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .text-not-set {
      font-size: 13px;
      color: #999999;
      margin: 0;
    }
    .column {
      width: 16.67%;
    }
    .column-active {
      background: #f8f8f8;
      border-radius: 4px;
    }
    &:before {
      position: absolute;
      content: '';
      top: -25px;
      left: -1%;
      width: 1px;
      height: 35px;
      background: repeating-linear-gradient(
        to bottom,
        #8b8b8b 0,
        #8b8b8b 3px,
        transparent 0px,
        transparent 5px
      );
    }
    &:after {
      position: absolute;
      content: '';
      top: 10px;
      left: -1%;
      width: 1%;
      height: 1px;
      background: repeating-linear-gradient(
        to right,
        #8b8b8b 0,
        #8b8b8b 3px,
        transparent 0px,
        transparent 5px
      );
    }
  }
`;

export const WrapperToolTip = styled.div`
  border-top: 2px solid #eb8b55;
  padding: 12px;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .text-bold {
    color: #000000;
    font-size: 13px;
    word-wrap: break-word;
  }
  .text-code {
    font-size: 11px;
    color: #999999;
  }
  .text_icon {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
  .icon {
    color: #c4c4c4;
    font-size: 18px;
    margin-left: 10px;
    &:hover {
      color: #009acb;
    }
  }
  .wrap-popover1 {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
`;
