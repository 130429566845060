import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { RingProgress, measureTextWidth, Line, Column, ColumnConfig } from '@ant-design/plots';
import { CloudDownloadOutlined, TableOutlined, BarChartOutlined } from '@ant-design/icons';
import { findIndex, get, now, omit } from 'lodash';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import { getDataTableStorage, getSelectStorage } from './thunk';
import { authSelector } from 'containers/Auth/selectors';
import { storageManagementSelector } from './selectors';
import { formatNumber } from 'libs/utils/format';
import { DatePicker, Header } from 'components';
import StorageManagementStyled from './styles';
import { ConditionsType } from '../../types';
import { useAppDispatch } from 'hooks';
import { CoinStack } from 'assets';
import * as Types from 'types';

const { RangePicker } = DatePicker;
const { Option } = Select;

const StorageManagement: React.FC = () => {
  const [extensionSelected, setExtensionSelected] = useState<string>('');
  const [startPeriod, setStartPeriod] = useState<string | Dayjs>(
    dayjs().subtract(1, 'month').startOf('month')
  );
  const [endPeriod, setEndPeriod] = useState<string | Dayjs>(dayjs());
  const [corporateUser, setCorporateUser] = useState<string>('');
  const [segmented, setSegmented] = useState<number>(0);
  const [report, setReport] = useState<number>(1);

  const { dataTableStorage, dataSelect } = useSelector(storageManagementSelector);
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const dataFiltered = useMemo(() => {
    return dataTableStorage
      .slice(2)
      .filter((e) =>
        'file_extension' in e
          ? e.file_extension === extensionSelected
          : e.file_location === extensionSelected
      );
  }, [dataTableStorage, extensionSelected]);

  // const optionSelect = useMemo(() => {
  //   const options: Array<{
  //     value: string;
  //   }> = [];const optionSelect = useMemo(() => {
  //   //   const options: Array<{
  //   //     value: string;
  //   //   }> = [];
  //   //   if (report === 1) {
  //   //     dataTableStorage.slice(1).forEach(({ file_extension }) => {
  //   //       options.push({ value: file_extension });
  //   //     });
  //   //   }
  //   //
  //   //   if (report === 2) {
  //   //     dataTableStorage.slice(1).forEach(({ file_location }) => {
  //   //       options.push({
  //   //         value: file_location,
  //   //       });
  //   //     });
  //   //   }
  //   //
  //   //   return options;
  //   // }, [report, dataTableStorage]);
  //   if (report === 1) {
  //     dataTableStorage.slice(1).forEach(({ file_extension }) => {
  //       options.push({ value: file_extension });
  //     });
  //   }
  //
  //   if (report === 2) {
  //     dataTableStorage.slice(1).forEach(({ file_location }) => {
  //       options.push({
  //         value: file_location,
  //       });
  //     });
  //   }
  //
  //   return options;
  // }, [report, dataTableStorage]);

  const monthBetween = useMemo(() => {
    let currentMonth = dayjs(startPeriod);
    const endMonth = dayjs(endPeriod);

    const months = [];
    while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, 'month')) {
      months.push(currentMonth.format('YYYY/MM'));
      currentMonth = currentMonth.add(1, 'month');
    }

    return months;
  }, [startPeriod, endPeriod]);

  const dataLineChart = useMemo(() => {
    const data = monthBetween.map((e) => {
      const dates = Object.keys(omit(dataTableStorage[0], ['file_extension']));
      const index = findIndex(dates, (date) => date === e);
      if (index > -1) {
        return {
          time: e,
          value: Number(handleConvertSize(Number(dataTableStorage[0][e]) || 0)),
        };
      } else {
        return {
          time: e,
          value: 0,
        };
      }
    });
    return data;
  }, [dataTableStorage, monthBetween]);

  const dataColumnChart = useMemo(() => {
    const data = monthBetween.map((e) => {
      const dates = Object.keys(omit(dataTableStorage[1], ['file_extension']));
      const index = findIndex(dates, (date) => date === e);
      if (index > -1) {
        return {
          time: e,
          value: handleConvertSize(Number(dataTableStorage[1][e]) || 0),
        };
      } else {
        return {
          time: e,
          value: 0,
        };
      }
    });
    return data;
  }, [dataTableStorage, monthBetween]);

  const configColumnChart: ColumnConfig = {
    data: dataColumnChart,
    xField: 'time',
    yField: 'value',
    color: '#9DC3E6',
    appendPadding: 20,
    label: {
      position: 'top',
      style: {
        fill: '#2a2a2a',
      },
    },
    maxColumnWidth: 40,
  };

  const fetchDataStorage = useCallback(
    (conditions: Array<ConditionsType>) => {
      if (!userInfo) return;

      dispatch(
        getDataTableStorage({
          conditions: [
            ...(extensionSelected && extensionSelected !== 'ALL'
              ? [
                  {
                    id: 'file_extension',
                    search_value:
                      extensionSelected === 'image'
                        ? ['image/jpg', 'image/jpeg', 'image/png']
                        : extensionSelected === 'video'
                        ? ['video/mp4']
                        : extensionSelected === 'excel'
                        ? [
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                          ]
                        : extensionSelected === 'word'
                        ? [
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          ]
                        : [],
                    exact_match: true,
                    not_match: false,
                  },
                ]
              : []),
            ...(corporateUser === 'corporate_users'
              ? [
                  {
                    id: 'company_id',
                    search_value: ['000000000'],
                    not_match: true,
                  },
                  {
                    id: 'company_id',
                    search_value: ['000000001'],
                    not_match: true,
                  },
                ]
              : corporateUser === 'rstandard'
              ? [
                  {
                    id: 'company_id',
                    search_value: ['000000001'],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
          type: report,
          corporateUser,
        })
      );
    },
    [report, dispatch, userInfo, extensionSelected, corporateUser]
  );

  const date: ColumnsType<Types.StorageManagement.TotalStorage> = monthBetween.map((item) => ({
    title: item,
    dataIndex: item,
    key: item,
    width: 100,
    align: 'right',
    children: [
      {
        title: (
          <div>
            <span className="text-table">
              {handleConvertSize(Number(get(dataTableStorage[0], `${item}`, ''))) || 0}
            </span>
            <p className="text-table">円</p>
          </div>
        ),
        width: 100,
        className: 'sub-child',
        dataIndex: item,
        key: item,
        align: 'right',
        children: [
          {
            title: (
              <div>
                <span className="text-table">
                  {handleConvertSize(Number(get(dataTableStorage[1], `${item}`, ''))) || 0}
                </span>
                <p className="text-table">GB</p>
              </div>
            ),
            width: 100,
            className: 'sub-child',
            dataIndex: item,
            key: item,
            align: 'right',
            render: (text: number) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
                  <span>{handleConvertSize(text) || 0}</span>
                  <small>GB</small>
                </div>
              );
            },
          },
        ],
      },
    ],
  }));

  const columnTable: ColumnsType<Types.StorageManagement.TotalStorage> = [
    {
      title: 'ファイル種類',
      dataIndex: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
      key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
      width: 200,
      fixed: 'left',
      children: [
        {
          title: 'ストレージ料金',
          key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
          dataIndex: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
          className: 'sub-child',
          width: 200,
          fixed: 'left',

          children: [
            {
              title: 'ALL',
              key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
              dataIndex:
                report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
              className: 'sub-child',
              width: 200,
              fixed: 'left',
            },
          ],
        },
      ],
    },
    ...date,
  ];

  useEffect(() => fetchDataStorage([]), [fetchDataStorage]);

  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      getSelectStorage({
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  return (
    <StorageManagementStyled collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} className="header">
        <div className="wrap-header">
          <div className="item-storage">
            <Button className="btn btn-active">
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>
          </div>
        </div>
      </Header>
      <div className="table-chart">
        <div className="item-left">
          <RingProgress
            {...config}
            pixelRatio={10}
            animation={false}
            statistic={{
              title: {
                offsetY: 10,
                customHtml: (container) => {
                  const { width, height } = container.getBoundingClientRect();
                  const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                  return renderStatistic(d, 1, 'title', {
                    display: 'flex',
                    fontSize: 30,
                    textShadow: 'none',
                    fontWeight: 400,
                    color: '#777777',
                  });
                },
              },
              content: {
                customHtml: (container, _view) => {
                  const { width } = container.getBoundingClientRect();

                  return renderStatistic(width, formatNumber(9), 'content', {
                    fontSize: 13,
                    fontWeight: 400,
                    color: '#777777',
                  });
                },
              },
            }}
          />
          <div className="text-note">500GBまで無料.50GBごとに500円</div>
        </div>
        <div className="item-right">
          <Table
            className="table"
            dataSource={dataSelect.dataTableFile.map((item, index) => ({ ...item, index }))}
            columns={columns}
            pagination={false}
            rowKey="index"
          />
        </div>
      </div>
      <div className="container">
        <div className="label-content">
          <img src={CoinStack} className="icon" alt="coin-stack" />
          <span>ストレージ推移</span>
        </div>
        <div className="item-select">
          <div className="form-select">
            <div className="item">
              <span className="text-label">レポート</span>
              <Select className="select" defaultValue={report} onSelect={(e) => setReport(e)}>
                <Option value={1}>ファイル種類別推移</Option>
                <Option value={2}>機能別推移</Option>
                <Option value={3}>ユーザー別推移</Option>
              </Select>
            </div>
            <div className="item">
              <span className="text-label">期間選択</span>
              <RangePicker
                allowClear
                className="date"
                name="date-item"
                format="YYYY/MM"
                placeholder={['集計開始日', '集計終了日']}
                onChange={(dates) => {
                  if (dates) {
                    setStartPeriod(dayjs(dates[0]).format('YYYY/MM'));
                    setEndPeriod(dayjs(dates[1]).format('YYYY/MM'));
                  } else {
                    setStartPeriod('');
                    setEndPeriod('');
                  }
                }}
                defaultValue={[dayjs().subtract(1, 'month').startOf('month'), dayjs(now())]}
              />
            </div>
            <div className="item">
              <span className="text-label">ファイル種類</span>
              <Select
                className="select"
                defaultValue="ALL"
                onSelect={(e) => setExtensionSelected(e)}
              >
                <Option value="ALL">ALL</Option>
                <Option value="image">画像</Option>
                <Option value="video">動画</Option>
                <Option value="excel">Excel</Option>
                <Option value="word">Word</Option>
                <Option value="pdf">PDF</Option>
                <Option value="powerpoint">PowerPoint</Option>
                <Option value="other">その他</Option>
              </Select>
            </div>
            <div className="item">
              <span className="text-label">ユーザー種類</span>
              <Select className="select" defaultValue="ALL" onSelect={(e) => setCorporateUser(e)}>
                <Option value="ALL">ALL</Option>
                <Option value="corporate_users">法人ユーザー</Option>
                <Option value="partner">パートナー</Option>
                <Option value="rstandard">RSTANDARD</Option>
              </Select>
            </div>
            <div className="item">
              <span className="text-label">レポートタイプ</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-table">
          {segmented ? (
            <div className="chart-container">
              <div className="wrap-chart">
                <div className="wrap-title">
                  <p className="title-fee">ストレージ料金</p>
                  <p className="unit">単位：円</p>
                </div>
                <Line
                  data={dataLineChart}
                  {...configLineChart}
                  lineStyle={{
                    stroke: '#F6AC00',
                  }}
                  height={230}
                  xAxis={{
                    tickLine: null,
                  }}
                  yAxis={{
                    tickCount: 8,
                  }}
                />
              </div>
              <div className="wrap-chart">
                <div className="wrap-title">
                  <p className="title-fee">ストレージ使用量</p>
                  <p className="unit">単位：GB</p>
                </div>
                <Column
                  {...configColumnChart}
                  height={261}
                  xAxis={{
                    tickLine: null,
                  }}
                />
              </div>
            </div>
          ) : (
            <Table
              rowKey="code"
              columns={columnTable}
              dataSource={dataFiltered.length ? dataFiltered : dataTableStorage.slice(2)}
              bordered={true}
              pagination={false}
              locale={{
                emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
              }}
              scroll={{ x: 1400 }}
            />
          )}
        </div>
      </div>
    </StorageManagementStyled>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="font-size: 11px; color: #424242;">ストレージ管理</div><div style="display: flex; align-items: baseline;></div><h style="font-weight: 400;margin: 0;font-size: 30px;color: #424242;"></h><p style="font-weight: 400;margin: 0;font-size: 30px;color: #424242;">${text}<small>円</small> </p></div><div style="width: 180px; height: 2px; background-color: #DDDDDD;" /></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    }; padding-top: 15px; display: flex;flex-direction: column; "><span style="font-weight: 400;font-size: 30px;color: #424242;">${text}<small>GB</small></span><span style="font-weight: 400;margin: 0;font-size: 14px;color: #424242;">総使用量</span></div>`;
  }
};

const config = {
  height: 230,
  width: 230,
  autoFit: false,
  percent: 0.6,
  color: ['#EBEBEB', '#08A3A5'],
  innerRadius: 0.92,
  radius: 1,
  cornerRadius: 0.1,
};

export default StorageManagement;

const configLineChart = {
  xField: 'time',
  yField: 'value',
  label: {},

  tooltip: {
    showMarkers: false,
  },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: '#000',
        fill: 'red',
      },
    },
  },
  interactions: [
    {
      type: 'marker-active',
    },
  ],
};

const columns = [
  {
    title: 'ストレージ内訳',
    dataIndex: 'location',
    key: 'location',
    className: 'storage-breakdown',
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: '画像',
    dataIndex: 'image',
    key: 'image',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: '動画',
    dataIndex: 'video',
    key: 'movie',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: 'Excel',
    dataIndex: 'excel',
    key: 'excel',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: 'Word',
    dataIndex: 'word',
    key: 'word',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: 'PDF',
    dataIndex: 'pdf',
    key: 'pdf',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: 'PowerPoint',
    dataIndex: 'power_point',
    key: 'power_point',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
  {
    title: 'その他',
    dataIndex: 'other',
    key: 'other',
    width: '10%',
    render: (text: string) => (
      <span>
        {text}
        <small className="small">GB</small>
      </span>
    ),
  },
];

const handleConvertSize = (size: number) => {
  const fileSize = Number(size) / 1000 || 0;
  return `${(fileSize / 1048576).toFixed(4)}`;
};
