import React from 'react';
import { Column } from '@ant-design/plots';

import Styled from './styles';

const Development: React.FC = () => {
  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action wrap-chart">
                <span className="title">ユーザー数推移【法人・個人】</span>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">個人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">法人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter orange" />
                    <span className="legend-name">パートナーユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <div className="column">
                <span className="unit">単位：千円</span>
                <Column
                  {...config1}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    style: {
                      fill: ['#E5C82D', '#4365BD'],
                    },
                  }}
                />
              </div>
              <span className="title">ユーザー数推移【パートナー】</span>
              <div className="chart">
                <div className="column">
                  <span className="unit">単位：千円</span>
                  <Column
                    className="column-chart"
                    {...configColumn}
                    legend={false}
                    yAxis={{
                      grid: {
                        line: {
                          style: {
                            lineDash: [2, 2],
                            stroke: '#ddd',
                          },
                        },
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                    }}
                    label={{
                      position: 'top',
                      style: {
                        fill: '#F6AC00',
                        opacity: 0.6,
                        fontSize: 13,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Development;

const dataColumn = [
  {
    name: 'London',
    month: '2022/01',
    value: 18.9,
  },
  {
    name: 'London',
    month: '2022/02',
    value: 28.8,
  },
  {
    name: 'London',
    month: '2022/03',
    value: 39.3,
  },
  {
    name: 'London',
    month: '2022/04.',
    value: 81.4,
  },
  {
    name: 'London',
    month: '2022/05',
    value: 47,
  },
  {
    name: 'London',
    month: '2022/06',
    value: 20.3,
  },
  {
    name: 'London',
    month: '2022/06',
    value: 24,
  },
  {
    name: 'London',
    month: '2022/08',
    value: 35.6,
  },
];
const configColumn = {
  data: dataColumn,
  xField: 'month',
  yField: 'value',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: '#F0995A',
};

const dataColumn1 = [
  {
    name: '法人ユーザー',
    month: '2022/01',
    value: 18.9,
  },

  {
    name: '法人ユーザー',
    month: '2022/02',
    value: 28.8,
  },

  {
    name: '法人ユーザー',
    month: '2022/03',
    value: 39.3,
  },

  {
    name: '法人ユーザー',
    month: '2022/04.',
    value: 81.4,
  },

  {
    name: '法人ユーザー',
    month: '2022/05',
    value: 47,
  },

  {
    name: '法人ユーザー',
    month: '2022/06',
    value: 20.3,
  },

  {
    name: '法人ユーザー',
    month: '2022/06',
    value: 24,
  },

  {
    name: '法人ユーザー',
    month: '2022/08',
    value: 35.6,
  },

  {
    name: '全ユーザー',
    month: '2022/01',
    value: 12.4,
  },
  {
    name: '全ユーザー',
    month: '2022/02',
    value: 23.2,
  },
  {
    name: '全ユーザー',
    month: '2022/03',
    value: 34.5,
  },
  {
    name: '全ユーザー',
    month: '2022/04.',
    value: 99.7,
  },
  {
    name: '全ユーザー',
    month: '2022/05',
    value: 52.6,
  },
  {
    name: '全ユーザー',
    month: '2022/06',
    value: 35.5,
  },
  {
    name: '法人ユーザー',
    month: '2022/07',
    value: 50,
  },
  {
    name: '全ユーザー',
    month: '2022/07',
    value: 37.4,
  },

  {
    name: '全ユーザー',
    month: '2022/08',
    value: 42.4,
  },
];
const config1 = {
  data: dataColumn1,
  isGroup: true,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: ['#E5C82D', '#4365BD'],
};
