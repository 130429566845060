import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ManageReleasePDF from './ManageReleasePDF/ManageReleasePDF';
import { publicManagerSelector } from '../../selectors';
import ReleaseNoteEditing from '../ReleaseNoteEditing';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import ReleaseNewNote from '../ReleaseNewNote';
import exportPDF from 'libs/utils/exportPDF';
import ManageReleaseStyled from './styles';
import ReleaseNote from '../ReleaseNote';
import { useAppDispatch } from 'hooks';
import { getStatus } from '../../';
import * as Types from 'types';
import {
  getDataAdminReleaseNoteManagement,
  getDataAdminReleaseNoteManagementExport,
  getDataReleaseNoteID,
  getCurriculumPublishedHistory,
} from '../../thunk';

interface Props {
  visible: boolean;
  selectedRows: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  permissionNumber?: number;
}
const PER_PAGE = 10;

const Index: React.FC<Props> = ({ visible, setVisible, selectedRows, permissionNumber }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [visibleReleaseNote, setVisibleReleaseNote] = useState<boolean>(false);
  const [visibleReleaseEditing, setVisibleEditing] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleNewNote, setVisibleNewNote] = useState<boolean>(false);
  const [publish, setPublish] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [selectedRelease, setSelectedRelease] =
    useState<Types.AdminReleaseNoteManagement.ResponseType>();

  const ref = useRef(null);

  const {
    dataAdminReleaseNoteManagement,
    loading,
    dataReleaseNoteID,
    totalDataReleaseNoteID,
    curriculumPublishedHistory,
  } = useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const [releaseNoteData, setReleaseNoteData] = useState<any>([]);
  const [missingHistoryId, setMissingHistoryId] = useState<boolean>(false);
  const [recordNumber, setRecordNumber] = useState<number>(0);

  useEffect(() => {
    const index = dataReleaseNoteID.findIndex((item) => !item.curriculum_publish_history_id);
    if (index < 0) setMissingHistoryId(true);
    const data = dataReleaseNoteID.map((rawReleaseNote) => {
      const published = curriculumPublishedHistory.find(
        (history) => history.history_id === rawReleaseNote.curriculum_publish_history_id
      );
      return {
        publish_start_date: published?.publish_start_date || '',
        release_version: published?.version || '',
        publish_type: '',
        release_note_id: rawReleaseNote.release_note_id,
        release_note_title: rawReleaseNote.release_note_title,
        updatedat: rawReleaseNote.updatedat,
        createdby: rawReleaseNote.createdby,
        release_note_publish_status: Number(rawReleaseNote.publish_status),
      };
    });
    setRecordNumber(data.length);
    if (index < 0)
      data.push({
        publish_start_date: '',
        release_version: '',
        publish_type: '',
        release_note_id: '',
        release_note_title: '',
        updatedat: '',
        createdby: '',
        release_note_publish_status: 0,
      });
    setReleaseNoteData(data);
  }, [dataReleaseNoteID, curriculumPublishedHistory]);

  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAdminReleaseNoteManagementExport({
          conditions: [
            {
              id: 'provider_id',
              search_value: [selectedRows[0]?.provider_id],
            },
            {
              id: 'official_curriculum_code',
              search_value: [selectedRows[0]?.official_curriculum_code],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataAdminReleaseNoteManagementExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          provider_id: item.provider_id,
          official_curriculum_code: item.official_curriculum_code,
          history_id: item.history_id,
          publish_start_date: item.publish_start_date,
          publish_type: item.publish_type,
          release_note_id: item.release_note_id,
          updatedat: item.updatedat,
          release_note_title: item.release_note_title,
          release_version: item.release_version,
          release_note_version: item.release_note_version,
          release_note_publish_status: item.release_note_publish_status,
          createdby: item.createdby,
          official_curriculum_name: item.official_curriculum_name,
        }));

        const csvString = [
          HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${resultAction.payload.report_title}.csv`);
      }
      setShowConfirmExportFileModal(false);
    } else {
      if (!ref.current || value !== 'pdf') return;
      exportPDF(ref, '設問マスタ', 'p', true);
      setShowConfirmExportFileModal(false);
    }
  };

  const fetchDataAdminReleaseNoteManagement = useCallback(() => {
    if (visible && selectedRows.length) {
      Promise.all([
        dispatch(
          getDataReleaseNoteID({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [selectedRows[0]?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            omit_total_items: false,
            include_item_ref: true,
            omit_fields_data: true,
            use_display_id: true,
          })
        ),
        dispatch(
          getCurriculumPublishedHistory({
            conditions: [
              {
                id: 'official_curriculum_code',
                search_value: [selectedRows[0]?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            omit_total_items: false,
            include_item_ref: true,
            omit_fields_data: true,
            use_display_id: true,
          })
        ),
        dispatch(
          getDataAdminReleaseNoteManagement({
            conditions: [
              {
                id: 'provider_id',
                search_value: [selectedRows[0]?.provider_id],
              },
              {
                id: 'official_curriculum_code',
                search_value: [selectedRows[0]?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'publish_start_date',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            omit_total_items: false,
            include_item_ref: true,
          })
        ),
      ]);
    }
  }, [visible, selectedRows, dispatch, page]);

  const columns: ColumnsType<Types.AdminReleaseNoteManagement.ResponseType> = [
    {
      title: 'No.',
      dataIndex: '',
      key: 'No',
      width: '3.5%',
      render: (_value, _record, index) => <span className="no">{index + 1}</span>,
    },
    {
      title: (
        <span>
          OFFICIALカリキュラム
          <br />
          公開日時（更新日時）
        </span>
      ),
      width: '15%',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (record) => record && dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: 'バージョン',
      dataIndex: 'release_version',
      align: 'center',
      width: '7%',
    },
    {
      title: '公開種類',
      dataIndex: 'publish_type',
      key: 'publish_type',
      align: 'center',
      width: '6%',
      render: (record) =>
        record === 0 ? '未更新' : record === 1 ? '初回公開' : record === 2 ? '更新' : '最終更新',
    },
    {
      title: (
        <span>
          リリースノート
          <br />
          作成状況
        </span>
      ),
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '8%',
      render: (record) => (!record ? '未作成' : '作成済'),
    },
    {
      title: 'リリースノートタイトル',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      render: (record) => (!record ? '_' : <div className="release_note_title">{record}</div>),
      align: 'left',
      width: '18%',
    },
    {
      title: '作成・更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (record) =>
        !record ? '_' : dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
      width: '7%',
      render: (record) => (!record ? '_' : record),
    },
    {
      title: (
        <span>
          リリースノート
          <br /> 公開ステータス
        </span>
      ),
      dataIndex: 'release_note_publish_status',
      key: 'release_note_publish_status',
      align: 'center',
      width: '10%',
      render: (record) => (!record ? '_' : getStatus(record)),
    },
    {
      title: 'アクション',
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '12.3%',
      render: (_text, record) =>
        !record?.release_note_id && missingHistoryId ? (
          <Button
            className="list-release create-new"
            onClick={() => {
              setPublish(Number(record?.release_note_publish_status));
              setVisibleNewNote(true);
              setSelectedRelease(record);
            }}
          >
            新規作成
          </Button>
        ) : (
          <div className="wrap-button-table">
            <Button
              className="list-release release-left"
              onClick={() => {
                setPublish(Number(record?.release_note_publish_status));
                setVisibleReleaseNote(true);
                setSelectedRelease(record);
              }}
            >
              閲覧
            </Button>
            <Button
              disabled={permissionNumber === 1}
              className={`list-release release-right ${permissionNumber === 1 ? 'disabled' : ''}`}
              onClick={() => {
                setPublish(Number(record?.release_note_publish_status));
                setVisibleEditing(true);
                setSelectedRelease(record);
              }}
            >
              編集・削除
            </Button>
          </div>
        ),
    },
  ];

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <ManageReleasePDF
          selectedRows={selectedRows}
          permissionNumber={permissionNumber}
          visible={visible}
        />
      </div>
    );
  }, [selectedRows, permissionNumber, page]);

  useEffect(fetchDataAdminReleaseNoteManagement, [fetchDataAdminReleaseNoteManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <ManageReleaseStyled
      isEmptyData={!dataReleaseNoteID.length}
      title={<span className="title">リリースノート管理</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1600}
      visible={visible}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
    >
      {component}
      <p className="sub-title">
        公開日時別のリリースノートの作成状況一覧画面です。
        <br />
        作成・編集はリリースノート一覧ボタンをクリックしてください。
      </p>
      <div>
        <label>対象OFFICIALカリキュラム： </label>
        <div className="wrap-targer-official">
          <div className="wrap-left-side">
            <div className="text-left">
              <span>{dataAdminReleaseNoteManagement[0]?.official_curriculum_name}</span>
              <span>（ID：{dataAdminReleaseNoteManagement[0]?.official_curriculum_code})</span>
            </div>
            <div className="wrap-creator">
              <span className="label-creator">作成者：</span>
              <span>{dataAdminReleaseNoteManagement[0]?.createdby}</span>
            </div>
          </div>
          <div className="wrap-right-side">{getStatus(selectedRows[0]?.publish)}</div>
        </div>
      </div>
      <div className="wrap-button">
        <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
          <CloudDownloadOutlined
            style={{
              fontSize: 20,
            }}
          />
          <span>エクスポート</span>
        </Button>
      </div>
      <Table
        key="index"
        pagination={{
          pageSize: PER_PAGE,
          position: ['topCenter'],
          total: totalDataReleaseNoteID,
          showSizeChanger: false,
          onChange: setPage,
          showTotal: () => (
            <span className="text-count">
              {page * PER_PAGE > recordNumber ? recordNumber : page * PER_PAGE}
              件表示 /{totalDataReleaseNoteID} 名
            </span>
          ),
        }}
        loading={loading}
        className="table"
        columns={columns}
        dataSource={releaseNoteData.map((item: any, index: number) => ({ ...item, index }))}
        scroll={{
          y: '35vh',
        }}
      />
      <ReleaseNewNote
        selected={selectedRelease}
        publish={publish}
        visible={visibleNewNote}
        setVisible={setVisibleNewNote}
      />
      <ReleaseNote
        publish={publish}
        visible={visibleReleaseNote}
        setVisible={setVisibleReleaseNote}
        selected={selectedRelease}
      />
      <ReleaseNoteEditing
        publish={publish}
        visible={visibleReleaseEditing}
        setVisible={setVisibleEditing}
        selected={selectedRelease}
        setVisibleComplete={setVisibleComplete}
        page={page}
      />

      <Completed
        title="リリースノートを保存しました。"
        visible={visibleComplete}
        setVisible={setVisibleComplete}
        onSubmit={() => {
          setVisibleComplete(false);
          setVisibleEditing(false);
          fetchDataAdminReleaseNoteManagement();
        }}
      />

      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </ManageReleaseStyled>
  );
};

export default Index;
