import React, { useCallback, useEffect, useState } from 'react';
import { RightOutlined, ApartmentOutlined, SearchOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import localeJa from 'dayjs/locale/ja';
import saveAs from 'file-saver';
import lodash from 'lodash';
import dayjs from 'dayjs';

import { HEADER_CURRICULUM_MASTER_TABLE_CSV } from 'constant/header.export.constant';
import { SEARCH_PARTNER, SEARCH_PUBLISH_OPTION } from 'constant/select.constants';
import { IconLocked, IconClosed, Unpublished, IconPublish } from 'assets';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { ConditionsType, OfficialCurriculumTypes } from 'types';
import { setHeaderTitle } from 'containers/AppSettings/slice';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumListSelector } from './selectors';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import { ListCurriculumStyled } from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getOfficialCurriculum,
  getPartnerCompanies,
  getPartnerUser,
  getOfficialCurriculumName,
  getSelectProvider,
} from './thunk';

const { Option } = Select;

const PER_PAGE = 10;

const ListManagement: React.FC = () => {
  const [visibleModalCsv, setVisibleModalCsv] = useState(false);
  const [page, setPage] = useState<number>(1);

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    curriculumCompanies,
    curriculumList,
    loading,
    curriculumUser,
    searchProvider,
    searchCurriculumName,
  } = useSelector(curriculumListSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newCurriculumList: OfficialCurriculumTypes[] = lodash([
    ...curriculumList,
    ...curriculumUser.map((item) => {
      return {
        official_curriculum_code: item.official_curriculum_code,
        num_of_user: item.num_of_companies,
      };
    }),
    ...curriculumCompanies,
  ])
    .groupBy(({ official_curriculum_code }) => official_curriculum_code)
    .map((values, key) => ({
      official_curriculum_code: key,
      values: Object.assign({}, ...values),
    }))
    .filter((item) => !!item.values.provider_id)
    .value();

  const formik = useFormik({
    initialValues: {
      deletedat: '',
      official_curriculum_code: '',
      provider_id: '',
      provider_name: '',
      curriculum_name: '',
      publish: '',
    },
    validateOnBlur: false,
    onSubmit: (values) => {
      const conditions: ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      fetchManagement(conditions);
    },
    onReset: () => {
      fetchManagement();
    },
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'values',
      key: 'values',
      width: '5%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span className="curriculum_code">{item.values.official_curriculum_code}</span>
      ),
    },
    {
      title: 'カリキュラム名',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '20%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span>{item.values.curriculum_name}</span>
      ),
    },
    {
      title: '公開ステータス',
      dataIndex: 'values',
      key: 'values',
      className: 'publish',
      width: '10%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <div className="question_code_table">
          {item.values.publish === 1 ? (
            <div>
              <img src={Unpublished} className="icon" alt="employee-icon" />
              <span className="spanText">未公開</span>
            </div>
          ) : item.values.publish === 2 ? (
            <div>
              <img src={IconPublish} className="icon" alt="employee-icon" />
              <span className="spanText">公開中</span>
            </div>
          ) : item.values.publish === 3 ? (
            <div>
              <img src={IconClosed} className="icon" alt="employee-icon" />
              <span className="spanText">公開停止中</span>
            </div>
          ) : (
            <div>
              <img src={IconLocked} className="icon" alt="employee-icon" />
              <span className="spanText">編集中</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '利用企業数',
      dataIndex: 'values',
      key: 'values',
      width: '10%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item.values.num_of_companies ? <span>{item.values.num_of_companies}</span> : <span>-</span>,
    },
    {
      title: '利用ユーザー数',
      dataIndex: 'values',
      key: 'values',
      width: '10%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item.values.num_of_user ? <span>{item.values.num_of_user}</span> : <span>-</span>,
    },
    {
      title: '最終更新日時',
      dataIndex: 'values',
      key: 'values',
      width: '16%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item.values.publish_start_date ? (
          dayjs(item.values.publish_start_date).locale(localeJa).format('YYYY/MM/DD （dddd）HH:ss')
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '前回公開日時',
      dataIndex: 'values',
      key: 'values',
      width: '19%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item.values.updatedat ? (
          dayjs(item.values.updatedat).locale(localeJa).format('YYYY/MM/DD（ddd）HH:ss')
        ) : (
          <span>-</span>
        ),
    },
    {
      title: () => (
        <div className="title">
          <span>カリキュラム</span>
          <br />
          <span>ツリー</span>
        </div>
      ),
      dataIndex: 'apartmentOutlined',
      width: '10%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <div className="apartment-style">
          <ApartmentOutlined
            onClick={() => {
              dispatch(setHeaderTitle(getHeaderTitle(routes.PartnerManagement.path)));
              navigate(
                generatePath(routes.PartnerManagement.path, {
                  id: item.values.company_id,
                })
              );
            }}
          />
        </div>
      ),
    },
  ];

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = curriculumList?.map((item) => ({
        provider_id: item.provider_id,
        provider_name: item.provider_name,
        publish: item.publish,
        creator: item.creator,
        curriculum_code: item.curriculum_code,
        curriculum_name: item.curriculum_name,
        official_curriculum_code: item.official_curriculum_code,
      }));

      const csvString = [
        HEADER_CURRICULUM_MASTER_TABLE_CSV.map(({ label }) => label),
        ...listCsv.map((item: { [s: string]: unknown } | ArrayLike<unknown>) =>
          Object.values(item)
        ),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'Official-curriculum-list.csv');
    }
    setVisibleModalCsv(false);
  };

  const fetchManagement = useCallback(
    (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        dispatch(
          getOfficialCurriculum({
            conditions,
            page: page,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            sort_fields: [{ id: 'official_curriculum_code', order: 'asc' }],
          })
        );
      }
    },
    [dispatch, page, userInfo]
  );

  useEffect(() => {
    Promise.all([
      dispatch(
        getPartnerUser({
          page: page,
          per_page: 0,
        })
      ),
      dispatch(
        getPartnerCompanies({
          page: page,
          per_page: 0,
        })
      ),
      dispatch(getOfficialCurriculumName({ page: page, per_page: 0 })),
      dispatch(getSelectProvider({ page: page, per_page: 0 })),
    ]);
  }, [page, dispatch]);

  useEffect(() => {
    fetchManagement();
  }, [fetchManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <ListCurriculumStyled>
      <Header title={headerTitle} className="header" />
      <div className="curriculum-styled">
        <div className="curriculum-partner">
          <p className="curriculum-title">
            パートナーが作成したOFFICIALカリキュラムの確認と管理を行う画面です。
          </p>
          <div>
            <button
              className="btn-list"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.PartnerManagement.path)));
                navigate(generatePath(routes.PartnerManagement.path));
              }}
            >
              カリキュラム一覧 <RightOutlined />
            </button>
            <button
              className="btn-tree"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.CurriculumTree.path)));
                navigate(generatePath(routes.CurriculumTree.path));
              }}
            >
              カリキュラムツリー <RightOutlined />
            </button>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <div className="item-select">
                <Form.Item
                  name="deletedat"
                  className="item contract-status"
                  label={<span className="text-label">契約状況</span>}
                >
                  <SelectField
                    name="deletedat"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {SEARCH_PARTNER.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="provider_id"
                  className="item contract-status"
                  label={<span className="text-label">ID</span>}
                >
                  <SelectField
                    name="provider_id"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {searchProvider.map((item, index) => (
                      <Option value={item.provider_id} key={index}>
                        {item.provider_id}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="provider_name"
                  className="item partner-name"
                  label={<span className="text-label">パートナー名</span>}
                >
                  <SelectField
                    name="provider_name"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {searchProvider.map((item, index) => (
                      <Option value={item.provider_name} key={index}>
                        {item.provider_name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="official_curriculum_code"
                  className="item contract-status"
                  label={<span className="text-label">カリキュラムID</span>}
                >
                  <SelectField
                    name="official_curriculum_code"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {curriculumList.map((item, index) => (
                      <Option value={item.official_curriculum_code} key={index}>
                        {item.official_curriculum_code}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="curriculum_name"
                  className="item"
                  label={<span className="text-label">カリキュラム名</span>}
                >
                  <SelectField
                    name="curriculum_name"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {searchCurriculumName
                      .filter((i) => !!i.official_curriculum_name)
                      .map((item, index) => (
                        <Option value={item.official_curriculum_name} key={index}>
                          {item.official_curriculum_name}
                        </Option>
                      ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="publish"
                  className="item contract-status"
                  label={<span className="text-label">公開ステータス</span>}
                >
                  <SelectField
                    name="publish"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {SEARCH_PUBLISH_OPTION.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              <div className="group-btn">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span
                  className="label-reset"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className={`${newCurriculumList.length ? '' : 'style-list-no-data'}`}>
          <div className={`${newCurriculumList.length ? 'wrap-button' : 'no-data'}`}>
            <Button className="btn btn-active" onClick={() => setVisibleModalCsv(true)}>
              エクスポート
            </Button>
          </div>
          <div className={`${newCurriculumList.length ? 'text-count' : 'text-count-no-data'}`}>
            {page * PER_PAGE > curriculumList.length ? curriculumList.length : page * PER_PAGE}
            件表示 / {curriculumList.length} 名
          </div>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={newCurriculumList}
          columns={columns}
          pagination={{
            pageSize: PER_PAGE,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter', 'bottomCenter'],
          }}
        />
      </div>
      <PopupConfirmExportFile
        visible={visibleModalCsv}
        setVisible={setVisibleModalCsv}
        onSubmit={handleExportCSV}
      />
    </ListCurriculumStyled>
  );
};

export default ListManagement;
