import React from 'react';
import { QuestionCircleOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Popover, Select, Table } from 'antd';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import QuestionMasterStyled from '../../styles';
import { Header } from 'components';
import * as Types from 'types';

interface Props {
  dataQuestion: Types.QuestionSearch.ResponseType[];
  statusTime: boolean;
  indexTime: number;
  timeLimit: Types.TimeLimitType[];
  curriculumTypeSelect: Types.CreateEditSkillCheckSelectType[];
  curriculumNameSelect: Types.SelectCurriculums.ResponseSelectType[];
  questionSelect: Types.QuestionSelect[];
  scoreSelect: Types.CreateEditSkillCheckSelectType[];
  curriculumCreatorSelect: Types.CurriculumCreatorSelect[];
  page: number;
  perPage: number;
}

const { Option } = Select;

const QuestionMasterPDF: React.FC<Props> = ({
  dataQuestion,
  statusTime,
  indexTime,
  curriculumTypeSelect,
  curriculumNameSelect,
  questionSelect,
  scoreSelect,
  curriculumCreatorSelect,
  page,
  perPage,
}) => {
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (_: string, _Item: Types.QuestionSearch.ResponseType, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: '設問コード',
      dataIndex: 'question_code',
      key: 'question_code',
      width: '8%',
    },
    {
      title: '設問名',
      dataIndex: 'question_name',
      key: 'question_name',
      width: '15%',
    },
    {
      title: '設問内容抜粋',
      dataIndex: 'question_description',
      key: 'question_description',
      width: '35%',
    },
    {
      title: '製作者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '20%',
    },
    {
      title: 'スコア',
      dataIndex: 'score',
      key: 'score',
      width: '7.5%',
    },
  ];
  return (
    <>
      <QuestionMasterStyled
        collapsedMenu={collapsedMenu}
        isEmptyData={!dataQuestion?.length}
        isExport
      >
        <Header title={headerTitle} exportPDF />
        <div className="container">
          <div className="header-container">
            <p className="title">
              設問の作成・編集、作成した設問の一覧を確認できます。
              <br />
              設問に設定するデフォルトの制限時間も設定可能です。
            </p>
            {!statusTime ? (
              <div className="select-time-limit">
                <div className="label">
                  <span>制限時間の初期値</span>
                  <Popover
                    trigger={['click']}
                    placement="bottomRight"
                    title={
                      <p style={{ color: '#2A2A2A', fontSize: 16 }}>設問制限時間の初期値設定</p>
                    }
                    content={
                      <p style={{ color: '#424242', fontSize: 13, padding: '0 12px' }}>
                        設問制限時間の初期値設定とは、設問ごとに設定する制限時間の初期値（デフォルト値）を設定することです。
                        <br />
                        設問新規作成時に、設定した制限時間が初期表示されます。
                        <br />
                        あくまでも初期値ですので、設問ごとに制限時間は変更可能です。
                        <br />
                      </p>
                    }
                    color="#ffffff"
                  >
                    <QuestionCircleOutlined className="question-mark-icon" />
                  </Popover>
                  ：
                </div>
                <Select
                  className="select-limit"
                  showSearch
                  allowClear
                  value={indexTime}
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                ></Select>
                <Button className="btn-select-submit">設定</Button>
              </div>
            ) : (
              <div className="wrap-notify-successful">
                <CheckCircleOutlined className="icon-successful" />
                設定した時間を初期値として登録しました
              </div>
            )}
          </div>
          <div className="border-line" />
          <Form layout="vertical">
            <div className="form-search-pdf">
              <Form.Item
                name="curriculum_code"
                className="curriculum_name"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumNameSelect?.map(({ name, code }, index) => (
                    <Option key={index} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="required_curriculum"
                className="required_curriculum"
                label={<span className="text-label">カリキュラム種類</span>}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumTypeSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="createdby"
                className="createdby"
                label={<span className="text-label">制作者</span>}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumCreatorSelect?.map(({ createdby }, index) => (
                    <Option key={index} value={createdby}>
                      {createdby}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="question_name"
                className="question_name"
                label={<span className="text-label">設問名</span>}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="score"
                className="score"
                label={<span className="text-label">スコア（設問難易度）</span>}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {scoreSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="text-count">
              {page * perPage > dataQuestion.length ? dataQuestion.length : page * perPage}
              <span className="text-static"> 件表示</span> / {dataQuestion.length}
              <span className="text-static"> 名</span>
            </div>
            <Table
              rowKey={(record) => JSON.stringify(record)}
              className="table-pdf"
              dataSource={dataQuestion.map((item, index) => ({ ...item, index }))}
              columns={columns}
              pagination={false}
            />
          </Form>
          <div className="wrap-bottom">
            <div className="flex">
              <div className="text-label">
                選択した設問を処理：
                <Button className="btn btn-active" icon={<DeleteOutlined className="icon" />}>
                  設問削除
                </Button>
                <Button className="btn btn-active">エクスポート</Button>
              </div>
            </div>
          </div>
        </div>
      </QuestionMasterStyled>
    </>
  );
};

export default QuestionMasterPDF;
