import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { getDataInformationUser } from 'pages/Report/thunk';
import { reportSelector } from 'pages/Report/selectors';
import { loadingRef } from 'components/Loading';
import InformationUserStyled from './styles';
import { useAppDispatch } from 'hooks';

interface Props {
  visibleExpansionOfSale: boolean;
}

const data1 = [
  {
    name: '',
    code: '',
    date: {},
  },
  {
    name: 'yokomizo設計所',
    code: '100001',
    date: {
      '01/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '88',
      },
      '02/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '77',
      },
      '03/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '66',
      },
      '04/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '55',
      },
      '05/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '44',
      },
      '06/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '33',
      },
      '07/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '22',
      },
    },
  },
  {
    name: 'yokomizo設計所',
    code: '100002',
    date: {
      '01/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '887',
      },
      '02/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '777',
      },
      '03/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '667',
      },
      '04/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '557',
      },
      '05/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '447',
      },
      '06/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '337',
      },
      '07/2023': {
        name: '株式会社ボーダー',
        code: '100002',
        date1: '227',
      },
    },
  },
];

const column: ColumnsType<any> = [
  {
    title: () => (
      <div className="title">
        <div>
          <span>企業種類</span>
          <span className="code">ID</span>
        </div>
        <span>ユーザー名</span>
      </div>
    ),
    dataIndex: 'name',
    key: 'name',

    children: [
      {
        className: 'average br-1',
        title: () => (
          <div className="title">
            <div>
              <span>法人ユーザー</span>
            </div>
            <span>ALL</span>
          </div>
        ),
        dataIndex: 'name',
        key: 'name',
        width: ' 25%',
        render: (_: string, record: any) => (
          <div className="item-name">
            <span className="code-number">{record.code}</span>
            <span>{record.name}</span>
          </div>
        ),
      },
    ],
  },
  ...Object.keys(data1[1]?.date)?.map((item, index) => ({
    title: item,
    dataIndex: ['date'],
    width: '8%',
    render: (_: any) => <div>{Object.values(data1[1]?.date)[index].date1}</div>,
    children: [
      {
        className: 'average br-1',
        title: () => <div>{Object.values(data1[1]?.date)[index].date1}</div>,
        dataIndex: ['date'],
        render: (_: any) => <div>{Object.values(data1[1]?.date)[index].date1}</div>,
      },
    ],
  })),
];

const InformationUser: React.FC<Props> = ({ visibleExpansionOfSale }) => {
  const { loading } = useSelector(reportSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getDataInformationUser({
        conditions: [],
        page: 1,
        per_page: 0,
        omit_total_items: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <InformationUserStyled visibleExpansionOfSale={visibleExpansionOfSale}>
      <div className="text-count">
        3
        <span className="text-static">
          <small>件表示</small>
        </span>
        /3
        <span className="text-static">
          <small>名</small>
        </span>
      </div>
      <Table
        className="table"
        rowKey="code"
        columns={column}
        dataSource={data1.slice(1)}
        expandable={{
          defaultExpandAllRows: true,
        }}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        pagination={false}
      />
    </InformationUserStyled>
  );
};

export default InformationUser;
