import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createAdminAffiliationAssignRole,
  createAdminUsers,
  deleteAdminAffiliationRole,
  deleteAdminUsers,
  getDataAdminUserList,
  getDataAllGmail,
  getSelectAdminDepartment,
  getSelectAdminPosition,
  getSelectAdminRole,
  getSelectAdminUsers,
  updateAdminAffiliationAssignRole,
  updateAdminUsers,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  dataUserMater: Array<Types.UserMaster>;
  dataSelectDepartment: Array<Types.Selects.SelectAdminDepartmentResType>;
  dataSelectAdminUser: Array<Types.Selects.SelectAdminUsersType>;
  dataSelectPosition: Array<Types.Selects.SelectAdminPositionResType>;
  dataSelectAdminRole: Array<Types.Selects.SelectAdminRoleResType>;
  dataAdminUserList: Array<Types.EmployeeUser.ResponseType>;
  dataAllGmail: Array<string>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  dataUserMater: [],
  dataSelectDepartment: [],
  dataSelectAdminUser: [],
  dataSelectPosition: [],
  dataSelectAdminRole: [],
  dataAdminUserList: [],
  dataAllGmail: [],
};

export const userMasterSlice = createSlice({
  name: 'userMaster-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(createAdminUsers.pending, startLoading)
      .addCase(updateAdminUsers.pending, startLoading)
      .addCase(deleteAdminUsers.pending, startLoading)
      .addCase(createAdminAffiliationAssignRole.pending, startLoading)
      .addCase(updateAdminAffiliationAssignRole.pending, startLoading)
      .addCase(deleteAdminAffiliationRole.pending, startLoading)
      .addCase(getSelectAdminRole.pending, startLoading)
      .addCase(getDataAdminUserList.pending, startLoading)
      .addCase(getSelectAdminDepartment.pending, startLoading)
      .addCase(getSelectAdminPosition.pending, startLoading)
      .addCase(getDataAllGmail.pending, startLoading)
      .addCase(getSelectAdminUsers.pending, startLoading);

    builder.addCase(getSelectAdminUsers.fulfilled, (state, action) => {
      state.dataSelectAdminUser = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminRole.fulfilled, (state, action) => {
      state.dataSelectAdminRole = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataAdminUserList.fulfilled, (state, action) => {
      state.dataAdminUserList = action.payload.report_results;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataAllGmail.fulfilled, (state, action) => {
      state.dataAllGmail = action.payload.items.map((item) => item.email);
      stopLoading(state);
    });
    builder
      .addCase(createAdminUsers.fulfilled, stopLoading)
      .addCase(updateAdminUsers.fulfilled, stopLoading)
      .addCase(createAdminAffiliationAssignRole.fulfilled, stopLoading)
      .addCase(updateAdminAffiliationAssignRole.fulfilled, stopLoading)
      .addCase(deleteAdminAffiliationRole.fulfilled, stopLoading)
      .addCase(deleteAdminUsers.fulfilled, stopLoading);

    builder
      .addCase(createAdminUsers.rejected, startLoading)
      .addCase(updateAdminUsers.rejected, startLoading)
      .addCase(deleteAdminUsers.rejected, startLoading)
      .addCase(createAdminAffiliationAssignRole.rejected, startLoading)
      .addCase(updateAdminAffiliationAssignRole.rejected, startLoading)
      .addCase(deleteAdminAffiliationRole.rejected, startLoading)
      .addCase(getSelectAdminRole.rejected, startLoading)
      .addCase(getDataAdminUserList.rejected, startLoading)
      .addCase(getSelectAdminDepartment.rejected, startLoading)
      .addCase(getSelectAdminPosition.rejected, startLoading)
      .addCase(getDataAllGmail.rejected, startLoading)
      .addCase(getSelectAdminUsers.rejected, startLoading);
  },
});

// export const { setInformationRegister } = userMasterSlice.actions;

export default userMasterSlice.reducer;
