import React, { useEffect, useState } from 'react';
import { PayCircleOutlined } from '@ant-design/icons';
import { DualAxes } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { dashboardSelector } from '../selectors';
import { useAppDispatch } from '../../../hooks';
import { getDataSalesTrends, getDataUsageList, getUserCount } from '../thunk';
import Styled from './styles';

const { Option } = Select;

const SaleInfomation: React.FC = () => {
  const [selected, setSelected] = useState<string>('all');

  const {
    dataSalesTrendsDual,
    dataSalesTrendsLine,
    dataMonthlyUsageFee,
    dataSkillCheckUsageFee,
    dataPreviosMonthSale,
    dataSaleForSameMonthLastYear,
    dataUsage,
    dataCountUser,
  } = useSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const formater = new Intl.NumberFormat('en-US', {});

  const config = {
    data: [dataSalesTrendsDual, dataSalesTrendsLine],
    xField: 'time',
    yField: ['value', 'count'],
  };
  useEffect(() => {
    Promise.all([
      dispatch(
        getDataSalesTrends({
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataUsageList({
          conditions: [
            {
              id: 'contract_date',
              search_value: [dayjs().format('YYYYMM')],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getUserCount({
          conditions: [
            {
              id: 'cancellationYYYYMM',
              search_value: [null],
            },
            {
              id: 'deletedat',
              search_value: [null],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <PayCircleOutlined className="icon" /> 売上情報
        </div>
        <div className="body-info">
          <div className="top-info">
            <div className="top-detail">
              <span className="title-info">リアルタイム売上</span>
              <div className="wrap-number">
                <span className="number">
                  {formater.format(dataMonthlyUsageFee + dataSkillCheckUsageFee + dataUsage)}
                </span>
                <span className="unit">円</span>
              </div>
              <div className="wrap-bottom-detail">
                <div>
                  <span className="label">前月比：</span>
                  <span className="percent">{((1 / dataPreviosMonthSale) * 100).toFixed(4)}%</span>
                </div>
                <div>
                  <span className="label">前年比：</span>
                  <span className="percent">
                    {((1 / dataSaleForSameMonthLastYear) * 100).toFixed(4)}%
                  </span>
                </div>
              </div>
            </div>
            <div className="top-detail">
              <span className="title-info">月額利用料</span>
              <div className="wrap-number">
                <span className="number">{formater.format(dataMonthlyUsageFee)}</span>
                <span className="unit">円</span>
              </div>
              <span className="title-info">ストレージ利用料</span>
              <div className="wrap-number">
                <span className="number">{dataUsage.toFixed(4)}</span>
                <span className="unit">円</span>
              </div>
              <span className="title-info">スキルチェック利用料</span>
              <div className="wrap-number">
                <span className="number">{formater.format(dataSkillCheckUsageFee)}</span>
                <span className="unit">円</span>
              </div>
            </div>
            <div className="top-detail">
              <span className="title-info">前月売上</span>
              <div className="wrap-number">
                <span className="number">{formater.format(dataPreviosMonthSale)}</span>
                <span className="unit">円</span>
              </div>
            </div>
            <div className="top-detail">
              <span className="title-info">前年同月売上</span>
              <div className="wrap-number">
                <span className="number">{formater.format(dataSaleForSameMonthLastYear)}</span>
                <span className="unit">円</span>
              </div>
            </div>
            <div className="top-detail">
              <span className="title-info">
                ARPU<span className="note-title">（１人あたり平均売上）</span>
              </span>
              <div className="wrap-number">
                <span className="number">{formater.format(100 / dataCountUser)} </span>
                <span className="unit">円</span>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">売上推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>データ選択：</label>
                  <Select defaultValue={selected} onSelect={(e) => setSelected(e)}>
                    <Option value="all">ALL</Option>
                    <Option value="count_of_new_users">新規利用</Option>
                    <Option value="count_of_stops">利用停止</Option>
                    <Option value="count_of_monthly_users">月間利用数</Option>
                    <Option value="count_of_monthly_active_users">アクティブ</Option>
                    <Option value="activation_rate">アクティブ比</Option>
                    <Option value="churn_rate">Churn Rate</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：千円</span>
              <DualAxes
                className="dual-chart"
                {...config}
                legend={{
                  position: 'top-right',
                }}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                }}
                xAxis={{
                  tickLine: null,
                }}
                // color={['#EC4D7C', '#C38BC2', '#4E83D3', '#1AC199']}
                label={{
                  position: 'top',
                }}
                height={550}
                padding={[50, 20, 10, 35]}
                geometryOptions={[
                  {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'type',
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: ['#08A3A5', '#F6AC00', '#C3DC7B'],
                    label: {
                      position: 'top',
                    },
                  },
                  {
                    geometry: 'line',
                    seriesField: 'name',
                    lineStyle: {
                      lineWidth: 2,
                    },
                    isStack: true,
                    point: {},
                    color: '#669CCE',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default SaleInfomation;
