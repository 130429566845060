import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  INVOICE_CORRESPONDENCE,
  INVOICE_CORRESPONDENCE_LIST,
  SELECT_COMPANY,
  SELECT_MANAGER,
} from 'configs';

export const createInvoiceCorrespondence = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.InvoiceCorresponsedence.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/createInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(INVOICE_CORRESPONDENCE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getInvoiceCorrespondence = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataExportCSVInvoiceCorrespondenceList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'InvoiceCorrespondence/thunk/getDataExportCSVInvoiceCorrespondenceList',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.id, req);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectCompany = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getSelectCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_COMPANY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectManager = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getSelectManager', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_MANAGER.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInvoiceCorrespondence = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.InvoiceCorresponsedence.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/updateInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(INVOICE_CORRESPONDENCE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeInvoiceCorrespondence = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/removeInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(INVOICE_CORRESPONDENCE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
