import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS,
  ADMIN_RELEASE_NOTE_MANAGEMENT,
  CURRICULUM_PUBLISHED_HISTORY,
  OFFICIAL_CURRICULUM_MASTER,
  RELEASE_NOTE_SECTION,
  RELEASE_NOTE_DETAIL,
  RELEASE_NOTE,
  ATTACH,
  USERS,
  PROVIDER_INFORMATION,
} from 'configs';

export const getOfficialPublish = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getOfficialPublic', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS.id, {
      ...req,
      sort_fields: [
        { id: 'official_curriculum_code', order: 'asc' },
        { id: 'publish_start_date', order: 'desc' },
      ],
    });
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataAdminReleaseNoteManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataAdminReleaseNoteManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_RELEASE_NOTE_MANAGEMENT.id, req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getArchiveList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getArchiveList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS.id, {
      ...req,
      sort_fields: [
        {
          id: 'official_curriculum_code',
          order: 'asc',
        },
        { id: 'publish_start_date', order: 'desc' },
      ],
    });
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCurriculumPublishedHistory = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/CreateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUM_PUBLISHED_HISTORY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateOfficialCurriculumMaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.OfficialCurriculumMaster.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateOfficialCurriculumMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(OFFICIAL_CURRICULUM_MASTER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculumPublishedHistory = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUM_PUBLISHED_HISTORY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_DETAIL.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteDetailExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteDetailExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_DETAIL.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNote = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNoteSection = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeReleaseSectionNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/removeReleaseSectionNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNote = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNoteSection = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNoteID = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataReleaseNoteID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(RELEASE_NOTE.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataOfficialCurriculumPublishingSettingExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'public/thunk/getDataOfficialCurriculumPublishingSettingExportCSV',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS.name, req);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDataAdminReleaseNoteManagementExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataAdminReleaseNoteManagementExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_RELEASE_NOTE_MANAGEMENT.name, req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProviderInformation = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(PROVIDER_INFORMATION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialCurriculumMaster = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getOfficialCurriculumMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(OFFICIAL_CURRICULUM_MASTER.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumPublishedHistory = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(CURRICULUM_PUBLISHED_HISTORY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
