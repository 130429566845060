import { createSlice } from '@reduxjs/toolkit';

import { getDataTableStorage, getSelectStorage } from './thunk';
import * as Types from 'types';

export interface InitialState {
  dataTableStorage: Array<Types.StorageManagement.TotalStorage>;
  dataSelect: Types.StorageManagement.SelectStorage;
  dataTableFile: Array<any>;
}

const initialState: InitialState = {
  dataTableStorage: [],
  dataTableFile: [],
  dataSelect: {
    file_extension: [],
    file_location: [],
    filename: [],
    dataTableFile: [],
  },
};

export const storageManagementSlide = createSlice({
  name: 'storageManagement-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getDataTableStorage.fulfilled, (state, action) => {
      state.dataTableStorage = action.payload.report_results;
    });
    builder.addCase(getSelectStorage.fulfilled, (state, action) => {
      state.dataSelect = action.payload.report_results[0];
    });
  },
});

// export const {  } = storageManagementSlide.actions;

export default storageManagementSlide.reducer;
