import React from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'antd';

import useEditBasicInfor, { ReceivedProps, Props } from './hook';
import { SectionStyled } from './styles';
import { Modal } from 'components';

const { TextArea } = Input;

const EditBasicInfoLayout: React.FC<Props> = (props) => {
  const { isOpen, handleClose, handleSubmit, setText, text } = props;
  const { messages } = useIntl();

  return (
    <Modal
      title="基本情報"
      width={720}
      open={isOpen}
      onCancel={handleClose}
      okButton={{
        text: messages['M-21-58'] ?? 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: messages['M-21-59'] ?? 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <TextArea
          className="input-TextArea"
          showCount
          maxLength={1050}
          placeholder={messages['M-21-51'] as string}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </SectionStyled>
    </Modal>
  );
};

const EditBasicInfoModal = (props: ReceivedProps) => {
  return <EditBasicInfoLayout {...useEditBasicInfor(props)} />;
};
export default EditBasicInfoModal;
