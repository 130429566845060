import React from 'react';

import TableMonthlyByUser from './MonthlyByUserDevelopment';
import TableDevelopment from './Development';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  valueSelect: number;
}

const Table: React.FC<Props> = ({ visibleExpansionOfSale, visibleCountUsage, valueSelect }) => {
  return !valueSelect ? (
    <TableDevelopment
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
    />
  ) : (
    <TableMonthlyByUser visibleExpansionOfSale={visibleExpansionOfSale} />
  );
};

export default Table;
